import axios from 'axios';

export default {
    index (params = {}) {
        return axios.get('/api/files', {params: params})
            .then(response => {
                // handle success
                return response.data;
            });
    },
    show (id) {
        return axios.get('/api/files/'+id)
            .then(response => {
                // handle success
                return response.data
            });
    },
    store(payload) {
        if (typeof(payload.id) !== 'undefined' && payload.id) {
            var apiEndpoint = '/api/files/'+payload.id;
            var apiMethod = 'put';
        }
        else {
            var apiEndpoint = '/api/files';
            var apiMethod = 'post';
        }

        return axios({
            method: apiMethod,
            url: apiEndpoint,
            data: payload
        }).then(response => {
            return response.data;
        });
    },
    destroy(id) {
        return axios.delete('/api/files/'+id)
            .then(response => {
                return response.data;
            });
    },
    restore(id) {
        return axios.patch('/api/files/'+id+'/restore');
    },
    getVehicleFiles (params = {}) {
        return axios.get('/api/vehicles/files', {params: params})
            .then(response => {
                // handle success
                return response.data;
            });
    },
}
