<template>
  <div>
    <searchable-select ref="stateSelect" :disabled="disabled" :sync-type="syncType" v-model="selectedOption" :options="optionList"></searchable-select>
  </div>
</template>

<script>
import SearchableSelect from '../SearchableSelect';
import axiosUtils from "../../../utils/axiosUtil";

export default {

  data: function () {
    return {
      optionList: [],
      selectedOption: this.value
    }
  },

  props: {
    abbrev: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    syncType: {
      default: 'value'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SearchableSelect
  },

  created: function () {
    let vm = this;
      axiosUtils.get('/states')
          .then(state => {
              _.each(state.data.data, function (state) {
                  vm.optionList.push({label: state.name, value: state.id});
                  vm.loaded= true;
              });
          }).finally(() => {
          this.$refs.stateSelect.setSelection(this.selectedOption)
      })
  },

  watch: {

    selectedOption: function (newVal, oldVal) {
      this.$emit('input', newVal);
    },

    value: function (newVal, oldVal) {
      this.selectedOption = newVal;
      this.$refs.stateSelect.setSelection(newVal);
    }

  }
}
</script>
