<template>
  <div>
    <searchable-select :disabled="disabled" ref="mechanicSelect" v-on:input="handleChange" :options="optionList" :multi="isMulti" :sync-type="syncType"></searchable-select>
  </div>
</template>

<script>
import axiosUtils from "../../../utils/axiosUtil";
import SearchableSelect from '../SearchableSelect';

export default {

  data: function () {
    return {
      optionList: [
        {label: 'Any Mechanic', value: 'any'},
        {label: 'Any Driver', value: 'any_driver'},
        {label: 'Third Party', value: 'third_party'}
      ],
      selectedOption: this.value,
      loaded: false
    }
  },

  props: {
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multi: {
      default: true
    },
    syncType: {
      default: 'value'
    },
    includesThirdParty: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SearchableSelect
  },

  created: function () {
    let vm = this;
    let requestParams = {role: 'mechanic'};

      axiosUtils.get('/lookup/mechanics')
      .then(mechanics => {
        _.each(mechanics.data, function (mechanic) {
            const name = `${mechanic.first_name} ${mechanic.last_name}`
          vm.optionList.push({label: name, value: mechanic.id});
        });
      })
      .then(() => {
        if (vm.value !== null) {
          let mechanics = []
          _.each(vm.value, function (mechanic) {
            if (_.isNull(mechanic.user_id)) {
              if (mechanic.any) {
                mechanics.push('any');
              }

              if(mechanic.any_driver) {
                mechanics.push('any_driver');
              }

              if(mechanic.third_party) {
                mechanics.push('third_party');
              }
            } else {
              mechanics.push(mechanic.user_id);
            }
          });
          vm.$refs.mechanicSelect.setSelection(mechanics);

          vm.$emit('input', mechanics);
        }
      })
      .then(() => {
        vm.loaded = true;
      });

  },

  computed: {

    isMulti: function () {
      if (this.selectedOption instanceof Array && this.selectedOption[0] == 0) {
        return true;
      }

      return true;
    }

  },

  watch: {

    selectedOption: function (newVal, oldVal) {
      this.$emit('input', newVal);
    },

    value: function (newVal, oldVal) {
      this.selectedOption = newVal;
      this.$refs.mechanicSelect.setSelection(newVal);
    }

  },

  methods: {

    handleChange: function (event) {
      let eventData = Object.assign({}, event);
      if (this.loaded) {
        if (_.isNull(event)) {
          this.selectedOption = null;
        }
        else if (this.syncType == 'object') {
          this.selectedOption = eventData;
        }
        else {
          if (this.multi) {
            let optionArray = [];
            _.each(eventData, function (option) {
              optionArray.push(option.value);
            });
            this.selectedOption = optionArray;
          }
          else {
            this.selectedOption = event;
          }
        }
      }
    }

  }

}
</script>
