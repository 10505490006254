<template>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="font-weight-bold mt-2">{{title}} {{dataCount}}</p>
                    <a :href="add_link" class="btn btn-info btn-sm text-white"><i class="fa fa-plus"></i> Add {{title}}</a>
                </div>
            </div>
            <div class="card-body">
                <div v-if="!loading">
                    <gmap-map
                        :center="center"
                        :zoom="11"
                        style="width:100%;  height: 415px;">
                        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                        </gmap-info-window>
                        <gmap-marker
                            :key="index"
                            v-for="(gmp, index) in locations"
                            :position="gmp"
                            :clickable="true" @click="toggleInfoWindow(gmp,index)"
                        ></gmap-marker>
                    </gmap-map>
                </div>
                <div v-else>
                    <div class="d-flex justify-content-center align-items-center map-div" >
                        <Spinner message="Loading map"/>
                    </div>
                </div>
                <PanelList :lists="lists" />
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent} from 'vue'
import axiosUtils from "../../../utils/axiosUtil";
import Spinner from "vue-simple-spinner";
import PanelList from "../../modules/panels/PanelList.vue";

export default defineComponent({
    props: ['title', 'url', 'add_link'],
    name: "MapList",
    components: {
        Spinner,
        PanelList

    },
    data() {
        return {
            center: {
                lat: 39.7837304,
                lng: -100.4458825
            },
            locations: [],
            currentLocation: null,
            infoOptions: {
                content: '',
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            loading: false,
            lists: [],
            dataCount: 0
        };
    },
    created() {
        this.setLocationLatLng();
    },
    methods: {
        async setLocationLatLng () {
            this.loading = true
            const {data} = await axiosUtils.get(`${this.url}`)
            this.locations = data.data
            navigator.geolocation.getCurrentPosition(geolocation => {
                let center = {
                    lat: geolocation.coords.latitude,
                    lng: geolocation.coords.longitude
                }
                if (this.locations.length > 0) {
                    center = {
                        lat: this.locations[0].lat,
                        lng: this.locations[0].lng
                    }
                }
                this.center = center;
            });
            this.lists = this.locations.map(item => item.vehicle);
            this.dataCount = this.locations.length
            this.loading = false
        },
        toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = {lat: marker.lat, lng:marker.lng};
            this.infoOptions.content = marker.infoText;

            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;

            }
        }
    }
})
</script>

<style scoped>
    .map-div {
        width:100%;
        height: 415px;
    }
</style>
