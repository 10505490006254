import pluralize from 'pluralize'
import axiosUtils from "./axiosUtil";

class Resource {
  constructor(resourceName, pluralized) {
    this.resource = resourceName
    this.pluralized = pluralized
  }

  index(params = {}) {
    const method = 'GET'
    const url = this.getApiEndpoint()

    return axiosUtils({
      method,
      url,
      params
    })
  }

  show(id) {
    const method = 'GET'
    const url = this.getApiEndpoint() + '/' + id

    return axiosUtils({
      method,
      url
    })
  }

  custom(data) {
    let url = this.getApiEndpoint()
    let method = 'POST'

    if (typeof(data.id) !== 'undefined' && data.id) {
      url = url + '/' + data.id
      delete data.id
    }

    if (typeof(data.verb) !== 'undefined' && data.verb) {
      url = url + '/' + data.verb
      delete data.verb
    }

    return axiosUtils({
      method,
      url,
      data
    });
  }

  store(data) {
    let url = this.getApiEndpoint()
    let method = 'POST'

    if (typeof(data.id) !== 'undefined' && data.id) {
      url = url + '/' + data.id
      method = 'PUT'
      delete data.id
    }

    return axiosUtils({
      method,
      url,
      data
    });
  }

  destroy(id) {
    const method = 'DELETE'
    const url = this.getApiEndpoint() + '/' + id

    return axiosUtils({
      method,
      url
    });
  }

  getApiEndpoint(id = null) {
    let noun = this.resource
    if (this.pluralized) {
      noun = pluralize(this.resource)
    }
    return '/' + noun
  }

  getVehicleFiles(params = {}, url = '') {
    const method = 'GET'

    return axiosUtils({
      method,
      url,
      params
    })
  }
}

export default {

  make(resourceName, pluralized = true) {
    return new Resource(resourceName, pluralized)
  }

}
