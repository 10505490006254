<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between my-2">
            <h1 class="tw-font-bold tw-text-2xl">{{module}}</h1>
            <div>
                <button @click="$refs['add-modal'].show()" class="btn btn-info text-white fw-bold"><i
                        class="fa fa-plus"></i> Add {{module.slice(0, -1)}}</button>
            </div>
        </div>
        <b-card>
            <div class="d-flex justify-content-start gap-3 mb-3">
                <div>
                    <select class="form-select" aria-label="Default select example" v-model="filterBy">
                        <option selected value="" disabled>Filter By...</option>
                        <option :value="item.value" v-for="(item, index) in filterOptions" :key="index">{{ item.label }}
                        </option>
                    </select>
                </div>

                <div v-if="filterBy">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                        <input v-model="search" @input="debounceInput" type="text" class="form-control"
                            placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                </div>

                <div class="ml-auto">
                    <button type="button" class="btn btn-info ml-2 text-white" @click="$refs['edit-make-modal'].show()"
                        v-if="isAdmin">
                        <i class="fa fa-edit"></i> Make/Model List
                    </button>
                </div>
            </div>
            <div>
                <b-tabs>
                    <b-tab title="Qvin">
                        <data-table ref="userTable" :columns="fields" default_sort_direction="" default_sort_field=""
                            :show_entries="[10,15,20,50,75,100]" :show_entries_default="15" url="trucks" :items="data"
                            @getData="dataManager" :loading="loading">
                            <template v-slot:status="{item}">
                                <p>{{item.status ? formatStatus(item) : ''}}</p>
                            </template>
                            <template v-slot:cmv="{item}">
                                <p>{{formatCMV(item)}}</p>
                            </template>
                            <template v-slot:name="{item}">
                                <p class="no-break">
                                    <span class="mr-1 text-danger" v-if="item.status === 'out_of_service'"><i
                                            class="fa fa-flag"></i></span>
                                    <!-- <b-link :href="`/${moduleUrl}/${item.id}/edit`"
                                        class="underline">{{item.name}}</b-link> -->
                                    <router-link class="underline" :to="`/${moduleUrl}/${item.id}/edit`">{{ item.name
                                        }}</router-link>
                                </p>
                            </template>
                            <template v-slot:comments="{ item }">
                                <p class="comments">{{ item.comments ? item.comments : '' }}</p>
                            </template>
                            <template v-slot:action="{item}">
                                <button class="btn btn-info btn-sm text-white" @click="editHandler(item)"><i
                                        class="fa fa-edit"></i></button>
                                <button class="btn btn-danger btn-sm" @click="deleteHandler(item)"><i
                                        class="fa fa-trash"></i></button>
                            </template>
                        </data-table>
                    </b-tab>
                    <b-tab title="Dot Ready" v-if="canGetDotReady">
                        <template v-if="!loadingDotReady">
                            <data-table-local ref="userTableDotReady" :columns="fields" default_sort_direction=""
                                default_sort_field="" :show_entries="[10,15,20,50,75,100]" :show_entries_default="15"
                                url="trucks" :items="dataDotready" :loading="loadingDotReady">
                                <template v-slot:status="{item}">
                                    <p>{{item.status ? formatStatus(item) : ''}}</p>
                                </template>
                                <template v-slot:cmv="{item}">
                                    <p>{{formatCMV(item)}}</p>
                                </template>
                                <template v-slot:name="{item}">
                                    <a :href="formatLink(item)" class="underline cursor-pointer">{{item.name}}</a>
                                </template>
                                <template v-slot:action="{item}">
                                    <button class="btn btn-info btn-sm text-white" @click="showHandler(item)"><i
                                            class="fa fa-eye"></i></button>
                                </template>
                            </data-table-local>
                        </template>
                        <template v-else>
                            <div class="d-flex justify-content-center align-items-center gap-3">
                                <Spinner /> Loading
                            </div>
                        </template>
                    </b-tab>
                </b-tabs>
            </div>
            <div>
                <b-modal ref="add-modal" hide-footer title="Add New Vehicle" centered>
                    <div class="d-block">
                        <form @submit.prevent="submitHandler">
                            <div class="mb-3">
                                <label for="vehicleName" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control border"
                                    :class="{'border-danger' :(errors && errors.errors.name)}"
                                    aria-describedby="emailHelp">
                            </div>
                            <div class="mb-3">
                                <label for="vehicleCompany" class="form-label">Company</label>
                                <CompanySelect v-model="form.company_id" :value="form.company_id"
                                    :class="{'border border-danger' :(errors && errors.errors.company_id)}" />
                            </div>
                            <div class="mb-3">
                                <label for="vehicleLocation" class="form-label">Location</label>
                                <LocationSelect v-model="form.location_id" :value="form.location_id"
                                    :companyId="form.company_id"
                                    :class="{'border border-danger' :(errors && errors.errors.location_id)}" />
                            </div>
                            <button type="submit" class="btn btn-info text-white">Save</button>
                        </form>
                    </div>
                </b-modal>
            </div>
            <div>
                <b-modal ref="edit-make-modal" size="xl" hide-footer title="Edit Make/Model List" centered scrollable>
                    <div class="d-block">
                        <MakeModelList :type="module" />
                    </div>
                </b-modal>
            </div>
        </b-card>
    </div>
</template>

<script>
import {ServiceFactory} from "../../utils/ServiceFactory";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import _ from "lodash";
import Spinner from 'vue-simple-spinner';
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import CssForBootstrap4 from '../../Datatable/VuetableCssBootstrap4'
import DataTable from "../../components/dataTable/DataTable.vue";
import DataTableLocal from "../../components/dataTable/DataTableLocal.vue";
import MakeModelList from "./MakeModelList.vue";

const vehicle = ServiceFactory.get('vehicle')

export default {
    name: "Trucks",
    components: {
        LocationSelect,
        CompanySelect,
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        Pagination,
        Spinner,
        css: CssForBootstrap4,
        dataTable: DataTable,
        dataTableLocal: DataTableLocal,
        MakeModelList
    },

    data() {
        return {
            form: {
                name: null,
                location_id: null,
                company_id: null,
            },
            fieldTruck: [
                {label: 'Name',field: 'name',  sortField: 'name' },
                {label: 'Type',field: 'type', sortField: 'type' },
                {label: 'Make',field: 'model', sortField: 'model.name' },
                {label: 'Model',field: 'make', sortField: 'make.name' },
                {label: 'Plate',field: 'plate',sortField: 'plate'},
                {label: 'Qvin Code',field: 'qvin_code',  sortField: 'qvin_code'},
                {label: 'Trailer Name', field: 'trailer_name', sortField: 'trailer_name',},
                {label: 'Year', field: 'year', sortField: 'year'},
                {label: 'Tire Size', field: 'tire_size', sortField: 'tire_size',},
                {label: 'GVWR', field: 'gvwr', sortField: 'gvwr'},
                {label: 'CMV', field: 'cmv', sortField: 'cmv'},
                {label: 'ELD AOBRD Make', field: 'eld_aobrd_make', sortField: 'eld_aobrd_make',},
                {label: 'ELD AOBRD Model', field: 'eld_aobrd_model', sortField: 'eld_aobrd_model',},
                {label: 'ELD AOBRD Year', field: 'eld_aobrd_year', sortField: 'eld_aobrd_year',},
                {label: 'Company', field: 'company', sortField: 'company',},
                {label: 'Fleet', field: 'fleet', sortField: 'fleet',},
                {label: 'Comments', field: 'comments', sortField: 'comments'},
                {label: 'Actions', field: 'action',}
            ],
            fieldTrailer: [
                {label: 'Name',field: 'name',  sortField: 'name' },
                {label: 'Status',field: 'status',  sortField: 'status' },
                {label: 'Make',field: 'model', sortField: 'model.name' },
                {label: 'Model',field: 'make', sortField: 'make.name' },
                {label: 'Type',field: 'type', sortField: 'type' },
                {label: 'Year', field: 'year', sortField: 'year'},
                {label: 'Vin', field: 'vin', sortField: 'vin'},
                {label: 'Tire Size', field: 'tire_size', sortField: 'tire_size',},
                {label: 'GVWR', field: 'gvwr', sortField: 'gvwr'},
                {label: 'Plate',field: 'plate',sortField: 'plate'},
                {label: 'Qvin Code',field: 'qvin_code',  sortField: 'qvin_code',},
                {label: 'CMV', field: 'cmv', sortField: 'cmv'},
                {label: 'Company', field: 'company', sortField: 'company',},
                {label: 'Fleet', field: 'fleet', sortField: 'fleet',},
                {label: 'Purchase Date', field: 'purchased_date', sortField: 'purchased_date'},
                {label: 'Actions', field: 'action',}
            ],
            perPage: 15,
            data: {},
            dataDotready: {},
            currentPage:1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false,
            loadingDotReady: false,
            errors: null,
            filterOptions: [
                {value: 'name', label: 'Name'},
                {value: 'type', label: 'Type'},
                {value: 'plate', label: 'Plate'},
                {value: 'company', label: 'Company'},
                {value: 'fleet', label: 'Fleet'},

                {value: 'qvin_code', label: 'qvin'},
                {value: 'year', label: 'Year'},
                {value: 'tire_size', label: 'Tire Size'},
                {value: 'gvwr', label: 'GVWR'},
                {value: 'cmv', label: 'CMV'},
                {value: 'tire_size', label: 'Tire Size'},
                {label: 'ELD AOBRD Make', value: 'eld_aobrd_make'},
                {label: 'ELD AOBRD Model', value: 'eld_aobrd_model'},
                {label: 'ELD AOBRD Year', value: 'eld_aobrd_year'},
            ],
        };
    },

    watch: {
        data(newVal, oldVal) {

        },
        '$route.name': {
            handler: async function() {
                await this.getData()
                if (this.canGetDotReady) {
                    await this.getDotReadyTrucks()
                }
            },
            deep: true,
        }
    },
    computed: {
        module() {
            return this.$route.name
        },
        moduleUrl() {
            return _.lowerCase(this.$route.name)
        },
        fields() {
            return this.module === 'Trailers' ? this.fieldTrailer :  this.fieldTruck
        },
        role() {
            return this.$store.getters["user/getRoles"]
        },
        canGetDotReady() {
            return _.includes(['admin', 'fleet_owner'], this.role)
        },
        isAdmin() {
            return this.$store.getters["user/getRoles"] === 'admin'
        }
    },

    async created() {
        await this.getData()
        if (this.canGetDotReady) {
            await this.getDotReadyTrucks()
        }
    },

    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        getSortParam(sortOrder) {
            console.log(sortOrder)
        },
        changePage(page) {
            this.currentPage=page
            this.getData()
        },
        async getDotReadyTrucks() {
            this.loadingDotReady = true
            const {isSuccess, data} = await vehicle.getDotReadyTrucks(this.moduleUrl,{
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search
            });

            if (isSuccess) this.dataDotready = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong - DotReady.'
            });
            this.loadingDotReady = false
        },
        async getData() {
            this.loading = true
            const {isSuccess, data} = await vehicle.getTrucks(this.moduleUrl,{
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search
            });
            if (isSuccess) this.data = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
        editHandler(data) {
            this.$router.push(`/${this.moduleUrl}/${data.id}/edit`)
        },
        showHandler(data) {
          const module = this.moduleUrl === 'trucks' ? 'vehicles': 'trailers';
           window.location =  `${process.env.MIX_DOT_READY_URL}${module}/${data.id}/edit`
        },
        async deleteHandler(data) {
            const vm = this
            this.$dialog.confirm('Are you sure, you want to delete this item?')
                .then(async function (dialog) {
                    const {isSuccess} = await vehicle.deleteTruck(this.moduleUrl, data.id)
                    if (isSuccess) {
                        await vm.getData()
                        vm.$notify({type: 'success', 'text': 'Admin user successfully deleted.'})
                    }
                });
        },
        async submitHandler() {
            const {isSuccess, errors, data} = await vehicle.storeTruck(this.moduleUrl, this.form)
            if (isSuccess) {
                this.form = {}
                this.$router.push(`/${this.moduleUrl}/${data.id}/edit`)
            }
            else this.errors = errors
        },
        dataManager(data) {
            const direction = (data.sort_direction === 'desc' ? '-': '')
            this.sortField = `${direction}${data.sort_field}`
            this.currentPage = data.page
            this.perPage = data.paginate
            this.filterBy = data.queries
            this.search = data.search
            this.getData()
        },
        formatCMV(item) {
            if (item.cmv === null) {
                return ''
            }

            return item.cmv == 1 ? 'CMV': 'Non CMV'
        },
        formatStatus(item) {
            return _.startCase(item.status.split('_').join(' '))
        },
        formatLink(item) {
            const module = this.moduleUrl === 'trucks' ? 'vehicles': 'trailers';
            return `${process.env.MIX_DOT_READY_URL}${module}/${item.id}/edit`
        }
    }
};
</script>

<style>
table >  thead {
    white-space: nowrap;
}
.comments {
    white-space: pre;
}

</style>
