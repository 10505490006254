<template>
  <div>
    <b-row>
      <template v-if="multiple">
        <b-col md="3" v-for="file in fileList" :key="file.id">

          <b-card body-class="px-3 pt-2 pb-1" header-tag="header" footer-tag="footer" :footer-class="['p-1', 'small']"
            :header-class="['p-1', 'text-left']">
            <div slot="header" class="text-dark">
              <span>{{ fileDescription }} </span>
              <span v-if="file.group === 'dvir'"> {{ file.is_final ? "Post-trip" : "Pre-trip" }} </span>
              <span v-if="file.group === 'dvir'">{{ getCreatedString(file.created_at) }}</span>
            </div>
            <div slot="footer">
              <div class="d-flex justify-content-between">
                <div v-if="!readOnly">
                  <b-button @click="deleteFile(file.id)" variant="link" size="sm"><i class="fa fa-trash"></i></b-button>
                </div>
                <div>
                  <b-button :href="file.download_url" variant="link" size="sm"><i class="fa fa-download"></i></b-button>
                </div>
                <div v-if="!readOnly">
                  <b-button @click="editFile(file.id)" variant="link" size="sm"><i class="fa fa-edit"></i></b-button>
                </div>
              </div>
            </div>

            <div class="text-center text-muted" @click.stop="previewFile(file.public_url)">
              <div class="mb-2">
                <i class="far fa-5x text-dark" v-bind:class="getFileIcon(file.type)"></i>
              </div>
              <div class="mb-1 small font-weight-bold">{{ file.original_filename }}</div>
              <div class="small mb-1" v-if="file.expires_at">{{ getExpirationString(file.expires_at) }}</div>
            </div>
          </b-card>

        </b-col>
      </template>
      <b-col :md="multiple ? '3' : '12'" v-if="(fileList.length && readOnly) || !readOnly">

        <b-card body-class="px-3 pt-2 pb-1" header-tag="header" footer-tag="footer" :footer-class="['p-1', 'small']"
          :header-class="['p-1', 'text-left']" :bg-variant="dragging ? 'light' : null" v-if="!archive">
          <edit-folder-link v-if="heading != 'Add New File...'" slot="header" :folder-id="folder.id"
            :entity-type="vehicleType" class="edit-folder-link">
            <div class="d-inline-block text-dark">
              <div class="text-dark">{{ heading }}</div>
            </div>
            <!-- <div  class="text-dark">{{ heading }}</div> -->
          </edit-folder-link>
          <div v-if="heading == 'Add New File...'" slot="header" class="text-dark">{{ heading }}</div>
          <div slot="footer" v-if="!multiple && fileList.length">
            <div class="d-flex" v-bind:class="!readOnly ? 'justify-content-between' : 'justify-content-center'">
              <div v-if="!readOnly">
                <b-button @click="deleteFile(fileList[0].id)" variant="link" size="sm"><i
                    class="fa fa-trash"></i></b-button>
              </div>
              <div>
                <b-button :href="fileList[0].download_url" variant="link" size="sm"><i
                    class="fa fa-download"></i></b-button>
              </div>
              <div v-if="!readOnly">
                <b-button @click="editFile(fileList[0].id)" variant="link" size="sm"><i
                    class="fa fa-edit"></i></b-button>
              </div>
            </div>
          </div>
          <div v-if="!readOnly">
            <vue-dropzone :id="dropzoneId" ref="fileUploader" :options="uploadOptions" :useCustomSlot="true"
              v-on:vdropzone-file-added="handleFileAdded" :include-styling="false"
              v-on:vdropzone-drag-over="dragging = true" v-on:vdropzone-drag-leave="dragging = false"
              v-on:vdropzone-success="handleUploadSuccess" v-on:vdropzone-sending="sendingEvent">
              <div class="dropzone-custom-content">
                <div class="text-center text-muted">
                  <div v-if="!fileList.length || multiple">
                    <div class="mb-2">
                      <i class="fa fa-file fa-5x text-muted"></i>
                    </div>
                    <div class="font-weight-bold mb-2 text-muted">{{ !multiple ? 'File Missing' : 'Add File' }}</div>
                  </div>
                  <div @click.stop="previewFile(fileList[0].public_url)" v-else>
                    <div class="mb-2">
                      <i class="far fa-5x text-dark" v-bind:class="getFileIcon(fileList[0].type)"></i>
                    </div>
                    <div class="mb-1 small font-weight-bold">{{ fileList[0].original_filename }}</div>
                    <div class="small mb-1" v-if="fileList[0].expires_at">{{
                      getExpirationString(fileList[0].expires_at) }}</div>
                  </div>
                  <div>
                    <b-button size="sm" variant="secondary"><i class="fa fa-upload text-dark"></i> {{ !multiple &&
                      fileList.length ? 'Replace' : 'Upload' }}</b-button>
                  </div>
                  <div class="small help-text" v-bind:class="[dragging ? 'visible' : 'invisible']">Drop file to upload
                  </div>
                </div>
              </div>
            </vue-dropzone>
          </div>
          <div v-else>
            <div class="text-center text-muted">
              <div v-if="!fileList.length || multiple">
                <div class="mb-2">
                  <i class="fa fa-file fa-5x text-muted"></i>
                </div>
                <div class="font-weight-bold mb-2 text-muted">{{ !multiple ? 'File Missing' : 'Add File' }}</div>
              </div>
              <div v-else @click.stop="previewFile(fileList[0].public_url)">
                <div class="mb-2">
                  <i class="far fa-5x text-dark" v-bind:class="fileList[0].fileIcon"></i>
                </div>
                <div class="mb-1 small font-weight-bold">{{ fileList[0].original_filename }}</div>
                <div class="small mb-1" v-if="fileList[0].expires_at">{{
                  getExpirationString(fileList[0].expires_at) }}</div>
              </div>
            </div>
          </div>
        </b-card>

      </b-col>
    </b-row>
    <b-modal ref="editFileModal" centered title="Set Document Expiration" @ok="processUpload" @cancel="resetFields">
      <b-form-group label="Expiration Date">
        <masked-input v-model="documentExpiration" type="text" class="form-control" placeholder="MM/DD/YYYY"
          :mask="[/\d/, /\d/, '\/', /\d/, /\d/, '\/', /\d/, /\d/, /\d/, /\d/]" :guide="true"
          placeholderChar="_"></masked-input>
      </b-form-group>
    </b-modal>
    <b-modal ref="updateExpirationModal" centered title="Update Document Expiration" @ok="updateDocumentExpiration"
      @cancel="resetFields">
      <b-form-group label="Expiration Date">
        <masked-input v-model="documentExpiration" type="text" class="form-control" placeholder="MM/DD/YYYY"
          :mask="[/\d/, /\d/, '\/', /\d/, /\d/, '\/', /\d/, /\d/, /\d/, /\d/]" :guide="true"
          placeholderChar="_"></masked-input>
      </b-form-group>
    </b-modal>
    <b-modal ref="previewFileModal" centered title="File Preview" size="xl" @hidden="clearPreviewUrl">
      <iframe :src="previewUrl" style="width: 100%; height: 1000px; border: 0;"></iframe>
    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Cookies from 'js-cookie';
import moment from 'moment';
import MaskedInput from 'vue-text-mask';
import EditFolderLink from '../components/files/EditFolderLink.vue';
import JsCookie from "js-cookie";
const token = JsCookie.get('token');
export default {

  data: function () {
    return {
      uploadOptions: {
        url: '/api/files',
        createImageThumbnails: false,
        addRemoveLinks: false,
        autoProcessQueue: false,
        maxFiles: 1,
        previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
          'Authorization': `Bearer ${token}`
        }
      },
      documentExpiration: null,
      editingDocument: null,
      dragging: false,
      previewUrl: null
    }
  },

  props: {
    group: {
      type: String,
      default: 'default'
    },
    fileDescription: {
      type: String,
      default: null
    },
    vehicleId: {
      default: null
    },
    vehicleType: {
      type: String,
      default: 'vehicle'
    },
    heading: {
      type: String,
      default: 'Add New File...'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    existingFiles: {
      type: Array,
      default: function () { return []; }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    folder: {
      type: Object,
      default: null
    },
    archive: {
      type: Boolean,
      default: false
    }
  },

  components: {
    vueDropzone: vue2Dropzone,
    flatPickr,
    MaskedInput,
    EditFolderLink
  },

  created: function () {
    //console.log('folder', this.folder);
  },

  computed: {
    dropzoneId: function () {
      return this.vehicleId+'-'+this.folder.id;
    },

    hasExistingFiles: function () {
      if (this.fileList.length) {
        return true;
      }
      return false;
    },

    fileList: function(){
      let list = this.existingFiles.filter((file) => {
        if(this.archive){
          return file.archived
        }else{
          return !file.archived
        }
      });

      return list;
    }
  },

  methods: {

    handleFileAdded: function (file) {
      this.$refs.editFileModal.show();
    },

    editFile: function (fileId) {
      let file = _.find(this.fileList, function (file) {
        return file.id == fileId;
      });
      if (file) {
        let formattedExpiration = null;
        if (!_.isNull(file.expires_at)) {
          formattedExpiration = moment(file.expires_at).format('MM/DD/YYYY');
        }
        this.editingDocument = fileId;
        this.documentExpiration = formattedExpiration;
        this.$refs.updateExpirationModal.show();
      }
    },

    sendingEvent: function (file, xhr, formData) {
      if (!_.isNull(this.documentExpiration)) {
        formData.append('expires_at', this.documentExpiration);
      }
      formData.append('folder_id', this.folder.id);
      formData.append('owner_id', this.vehicleId);
      formData.append('owner_type', 'vehicle');
    },

    processUpload: function () {
      this.$refs.fileUploader.processQueue();
    },

    getFileIcon: function (fileType) {
      switch (fileType) {
        case 'spreadsheet':
          return 'fa-file-excel';
          break;
        case 'pdf':
          return 'fa-file-pdf';
          break;
        case 'image':
          return 'fa-file-image';
          break;
        default:
          return 'fa-file';
          break;
      }
    },

    deleteFile: function (fileId) {
      this.$dialog.confirm('Really archive this file?').then((dialog) => {
        this.$store.dispatch('archiveVehicleFile', fileId).then(() => {
          this.$emit('refresh', true);
          this.$notify({ type: 'success', text: 'File archived successfully!' });
        });
      });
    },

    getExpirationString: function (expirationDate) {
      if (!expirationDate) {
        return null;
      }

      return moment(expirationDate).format('MMM Do, YYYY');
    },

    getCreatedString: function (createdDate) {
      if (!createdDate) {
        return null;
      }

      return moment(createdDate).format('M/D/YYYY');
    },

    handleUploadSuccess: function (file, response) {
      this.resetFields();
      if (!this.multiple && this.fileList.length) {
        this.$store.dispatch('archiveVehicleFile', this.fileList[0].id).then(() => {
          this.$store.commit('addFile', response);
        });
      } else {
        this.$store.commit('addFile', response);
      }
      this.$emit('refresh', true);
    },

    updateDocumentExpiration: function () {
      if (!_.isNull(this.editingDocument)) {
        const params = {
          id: this.editingDocument,
          expires_at: this.documentExpiration
        };

        this.$store.dispatch('updateVehicleFile', params);
        this.$emit('refresh', true);
      }
      this.resetFields();
    },

    previewFile: function (fileUrl) {
      this.previewUrl = fileUrl;
      this.$refs.previewFileModal.show();
    },

    clearPreviewUrl: function () {
      this.previewUrl = null;
    },

    resetFields: function () {
      this.$refs.fileUploader.removeAllFiles();
      this.documentExpiration = null;
      this.editingFile = null;
    }
  }

}
</script>

<style type="scss" scoped>
.dropzone {
  min-height: 50px;
  padding: 0;
}
.vue-dropzone {
  border: 0;
}
.flatpickr-input[readonly] {background-color: white;}
</style>
