<template>
  <div>
    <div class="mb-5">
      <div v-for="note in noteListing" class="mb-3">
        <div v-if="editingNoteId === note.id">
          <b-row>
            <b-col>
              <b-form-group>
                <div v-if="!wysiwyg">
                  <b-form-textarea v-model="editingNoteBody" :state="editErrors.body ? 'invalid' : null" placeholder="Enter your note here" :rows="4"></b-form-textarea>
                </div>
                <div v-else>
                  <quill-editor ref="noteEditEditor" v-model="editingNoteBody"></quill-editor>
                </div>
                <b-form-invalid-feedback>Notes cannot be empty.</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button variant="outline-danger" @click="cancelEdit">Cancel</b-button>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" @click="updateNote">Update Note</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-card bg-variant="light" class="mb-0" style="white-space: pre;">{{ note.body }}</b-card>
          <b-row>
            <b-col>
              <div class="small">Added by {{ note.user }} {{ note.timeDifference }} <span v-if="note.edited"><em>edited</em></span></div>
            </b-col>
            <b-col class="text-right small" v-if="note.editable">
              <b-link href="#" @click="editNote(note.id)">Edit</b-link> &bull; <b-link href="#" class="text-danger" @click="deleteNote(note.id)">Delete</b-link>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <template v-if="!readOnly">
      <b-row>
        <b-col>
          <b-form-group>
            <div v-if="!wysiwyg">
              <b-form-textarea v-model="noteBody" :state="errors.body ? 'invalid' : null" placeholder="Enter your note here" :rows="4"></b-form-textarea>
            </div>
            <div v-else>
              <quill-editor ref="noteEditor" v-model="noteBody"></quill-editor>
            </div>
            <b-form-invalid-feedback>Notes cannot be empty.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <button class="btn btn-primary" @click="storeNote">Add Note</button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Note from '../../../API/note/Note';

export default {

  data: function () {
    return {
      isAdding: false,
      noteBody: null,
      editingNoteId: null,
      editingNoteBody: null,
      errors: {},
      editErrors: {},
      notes: []
    }
  },

  props: {
    entityType: {
      default: null
    },
    entityId: {
      default: null
    },
    wysiwyg: {
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
  },

  created: function () {
    this.getNotes();
  },

  computed: {

    noteListing: function () {
      let vm = this;
      let notes = [];

      _.each(vm.notes, function (note) {
        let noteJson = {};

        noteJson.id = note.id;
        noteJson.body = note.body;
        noteJson.user = note.user?.display_name;

        if (note.created_at != note.updated_at) {
          noteJson.edited = true;
        }
        else {
          noteJson.edited = false;
        }

        if (window.Spark?.userId && window.Spark?.userId == note.user.id) {
          noteJson.editable = true;
        }
        else {
          noteJson.editable = false;
        }

        noteJson.timeDifference = moment.utc(note.created_at).fromNow();

        notes.push(noteJson);
      });

      return notes;
    }

  },

  methods: {

    addNote: function () {
      this.cancelEdit();
      this.isAdding = true;
    },

    cancelAddNote: function () {
      this.isAdding = false;
      this.noteBody = null;
      this.errors = {};
    },

    editNote: function (noteId) {
      let vm = this;
      vm.cancelAddNote();
      let editingNote = vm.notes.find(function (element) {
        return element.id === noteId;
      });
      vm.editingNoteId = editingNote.id;
      vm.editingNoteBody = editingNote.body;
    },

    cancelEdit: function () {
      this.editingNoteId = null;
      this.editingNoteText = null;
      this.editErrors = {};
    },

    getNotes: function () {
      let vm = this;
      Note.index({notable_type: this.entityType, notable_id: this.entityId})
        .then(notes => {
          if(notes){
            vm.notes = notes;
            console.log("-=--", notes);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    storeNote: function () {
      let vm = this;
      vm.errors = {};

      Note.store({
        notable_type: vm.entityType,
        notable_id: vm.entityId,
        body: vm.noteBody
      }).then(incidentNote => {
        vm.notes.push(incidentNote);
        vm.cancelAddNote();
      }).catch(error => {
        if (error.response.status == '422') {
          vm.errors = error.response.data.errors;
        }
      }).finally(() => {});

    },

    updateNote: function () {
      let vm = this;
      vm.editErrors = {};

      Note.store({
        body: vm.editingNoteBody,
        id: vm.editingNoteId
      }).then(note => {
        let editingNote = vm.notes.find(function (element) {
          return element.id === vm.editingNoteId;
        });
        editingNote.body = note.body;
        editingNote.created_at = note.createdAt;
        vm.cancelEdit();
      }).catch(error => {
        if (error.response.status == '422') {
          vm.editErrors = error.response.data.errors;
        }
      }).finally(() => {});
    },

    deleteNote: function (noteId) {
      let vm = this;

      this.$dialog.confirm('Are you sure you want to delete this note?')
        .then(function (dialog) {
          Note.destroy(noteId).then(response => {
            vm.notes.splice(vm.notes.findIndex(element => element.id === noteId), 1);
          }).catch(error => {
            // error
          });
        });
    }

  }

}
</script>
