import axiosUtils from "../../utils/axiosUtil";

export default  {
    async index(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        const filter = payload.filterBy !== '' ? `&filter[search]=${payload.filterBy},${payload.search}` : ''
        const companyFilter = payload.company ? `&company=${payload.company}` : '';
        const fleetFilter = payload.fleet ? `&fleet=${payload.fleet}` : '';
        const buildUrl = `/locations?per_page=${payload.perPage}&page=${payload.currentPage}&sort=${payload.sortField || ''}${filter}${companyFilter}${fleetFilter}`;

        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async show(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.get(`/locations/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async storeLocation(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post('/locations', payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },

    async updateLocation(id, payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.put(`/locations/${id}`, payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },
    async deleteLocation(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.delete(`/locations/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
}
