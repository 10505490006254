<template>
    <div>
        <div class="container" v-if="!loading">
            <div v-if="(isFleetOwner && isSubscriptionActive) || !isFleetOwner">
                <div class="d-flex justify-content-between align-items-center my-3" v-if="!isDriver">
                    <h1 class="tw-font-bold tw-text-2xl">Dashboard</h1>
                    <div class="d-flex justify-content-between align-items-center gap-2">
                        <router-link class="btn btn-primary btn-sm text-white" :to="`/dashboard/view-setting`"
                            role="button"><i class="fa fa-cog"></i> Edit Dashboard View</router-link>
                        <router-link class="btn btn-primary btn-sm text-white" :to="`/invite-drivers`" role="button"><i
                                class="fa fa-plus"></i> Send Driver Invite</router-link>
                        <router-link class="btn btn-primary btn-sm text-white" :to="`/demo-user`" role="button"
                            v-if="isAdmin"><i class="fa fa-plus"></i> Create Demo User</router-link>
                    </div>
                </div>
                <div class="my-2" v-if="!isDriver">
                    <div class="row">
                        <!--                    <TruckMap v-if="dashboardViews.includes('Trucks')"  />-->
                        <MapList add_link="/trailers" title="Equipment" url="/equipment-locations"
                            v-if="dashboardViews.includes('Trailers')" />
                        <MapList add_link="/trucks" title="Trucks" url="/truck-locations"
                            v-if="dashboardViews.includes('Trucks')" />
                        <MapList add_link="/equipments" title="Equipments" url="/trailer-locations"
                            v-if="dashboardViews.includes('Equipment')" />
                        <RecentTruckPanel v-if="dashboardViews.includes('Recent Trucks')" />
                        <RecentTrailerPanel v-if="dashboardViews.includes('Recent Trailers')" />
                        <RecentEquipment v-if="dashboardViews.includes('Recent Equipments')" />
                        <RecentDriver v-if="dashboardViews.includes('Recent Drivers')" />
                    </div>
                </div>
                <div class="row driver-container" v-if="isDriver">
                    <div class="col-7 m-auto text-center">
                        <button @click="continueToQvin" class="btn btn-success text-white px-4">Continue to QVIN
                            App</button>
                        <div class="tw-flex tw-items-center tw-justify-center tw-rounded-br-3xl w-full mt-3">
                            <div class="tw-flex tw-flex-col store-link-container">
                                <img alt=qrr class="tw-bg-white tw-rounded tw-h-36 tw-w-auto border"
                                    src="/img/qr-google.png">
                                <a target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.dotready.qvin">
                                    <img alt="google play" class="mobile-provider-logo tw-w-auto"
                                        src="/img/mobile-provider-log-google.png">
                                </a>
                            </div>
                            <div class="tw-flex tw-flex-col store-link-container">
                                <img alt=qrr class="tw-bg-white tw-rounded tw-h-36 tw-w-auto border"
                                    src="/img/qr-apple.png">
                                <a target="_blank" href="https://apps.apple.com/ph/app/q-vin/id6463773695">
                                    <img alt="apple store" class="mobile-provider-logo tw-w-auto"
                                        src="/img/mobile-provider-log-ios.png">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TruckGraph from "../components/common/graphs/TruckGraph.vue";
import SubscribersGraph from "../components/common/graphs/SubscribersGraph.vue";

import {ServiceFactory} from "../utils/ServiceFactory"
import RecentTrailerPanel from "../components/modules/panels/RecentTrailerPanel.vue";
import RecentTruckPanel from "../components/modules/panels/RecentTruckPanel.vue";
import RecentEquipment from "../components/modules/panels/RecentEquipment.vue";
import RecentDriver from "../components/modules/panels/RecentDriver.vue";
import TruckMap from "../components/common/maps/TruckMap.vue";
import MapList from "../components/common/maps/MapList.vue";
import JsCookie from "js-cookie";

const dashboard = ServiceFactory.get('dashboard');
const auth = ServiceFactory.get('auth');
    export default {
        components: {
            MapList,
            TruckMap,
            RecentDriver,
            RecentEquipment,
            RecentTruckPanel,
            RecentTrailerPanel,
            TruckGraph,
            SubscribersGraph
        },
        data() {
            return {
                perPage: 15,
                qvin_data: [],
                trailer_data: [],
                truck_data: [],
                currentPage:1,
                sortField: null,
                filterBy: "",
                search: null,
                loading: false,
                dashboardViews: null,
                appUrl: `https://q-vin.com/login`,
                user: null,
            }
        },
        created() {
            this.getUser();
            this.getData();
            this.appUrl = `${window?.location?.protocol}//${window?.location?.host}/login` || `https://q-vin.com/login`;
        },
        computed: {
            isDriver() {
                return this.$store.getters["user/getRoles"] === 'driver'
            },
            isAdmin() {
                return this.$store.getters["user/getRoles"] === 'admin'
            },
            isFleetOwner() {
                return this.$store.getters["user/getRoles"] === "fleet_owner"
            },
            isSubscriptionActive(){
                return this.user?.subscription_status === 'active';
            },
        },
        methods: {
            async getData() {
                this.loading = true
                const { isSuccess, data, errors} = await dashboard.getDashboardData({
                    perPage: this.perPage,
                    currentPage: this.currentPage,
                    sortField: this.sortField,
                    filterBy: this.filterBy,
                    search: this.search
                });
                if (isSuccess)  {
                    this.qvin_data = data.qvin_sub
                    this.trailer_data = data.trucks
                    this.truck_data = data.trailers
                    this.dashboardViews = data.dashboard_view
                }
                else {
                    this.$notify({
                        type: 'danger',
                        text: 'Something went wrong.'
                    });
                    let message = errors.message.toLowerCase();
                    if(message.includes("inactive")){
                        window.location.href = '/pricing';
                    }
                }
                this.loading = false
            },
            convertToLowerCaseWithUnderscores(input){
                return input
                    .toLowerCase()
                    .replace(/\s+/g, '_');
            },
            continueToQvin() {
                window.location.replace(this.appUrl);
            },
            async getUser(){
                const hasToken = JsCookie.get('token')
                if (hasToken) {
                    const { isSuccess, data, errors } = await auth.me();
                    if (isSuccess) {
                        await this.$store.dispatch("user/setUser", data);
                        this.user = this.$store.getters['user/getUser'];
                    }
                    else console.log(errors)
                }
            }
        }
    }
</script>
<style scoped>
.driver-container {
    margin-top: 20vh;
}
.mobile-provider-logo {
    height: 3.4rem;
}

.mobile-provider-container {
    width: 35%;
}

.store-link-container {
    align-items: center;
}
</style>