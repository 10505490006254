<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between my-2">
            <h1 class="tw-font-bold tw-text-2xl">Fleets</h1>
            <div>
                <!-- <button class="btn btn-info text-white fw-bold" @click="addDotReadyHandler" role="button"><i class="fa fa-plus"></i> Add DOT Ready Fleet Driver</button> -->
                <router-link class="btn btn-info text-white fw-bold" to="/fleets/add" role="button"><i
                        class="fa fa-plus"></i> Add Fleet</router-link>
            </div>
        </div>
        <b-card>
            <div class="d-flex justify-content-start gap-3 mb-3">
                <div>
                    <select class="form-select" aria-label="Default select example" v-model="filterBy">
                        <option selected value="" disabled>Filter By...</option>
                        <option :value="item.value" v-for="(item, index) in filterOptions" :key="index">{{ item.label }}
                        </option>
                    </select>
                </div>

                <div v-if="filterBy">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                        <input v-model="search" @input="debounceInput" type="text" class="form-control"
                            placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                </div>
            </div>
            <b-tabs>
                <b-tab title="Qvin" @click="changeTab('qvin')">
                    <data-table ref="userTable" :columns="fields" default_sort_direction="" default_sort_field=""
                        :show_entries="[10,15,20,50,75,100]" :show_entries_default="15" url="trucks" :items="data"
                        @getData="dataManager" :loading="loading">
                        <template v-slot:status="{item}">
                            <p>{{formatStatus(item)}}</p>
                        </template>
                        <template v-slot:action="{item}">
                            <button class="btn btn-info btn-sm text-white" @click="editHandler(item)"><i
                                    class="fa fa-edit"></i></button>
                            <button class="btn btn-danger btn-sm" @click="deleteHandler(item)"><i
                                    class="fa fa-trash"></i></button>
                        </template>
                    </data-table>
                </b-tab>
                <b-tab title="Dot Ready" @click="changeTab('dot')">
                    <template v-if="!loadingDotReady">
                        <data-table-local ref="fleetTableDotReady" :columns="fields" default_sort_direction=""
                            default_sort_field="" :show_entries="[10,15,20,50,75,100]" :show_entries_default="15"
                            :items="dotReadyData" :loading="loadingDotReady">
                            <template v-slot:action="{item}">
                                <button class="btn btn-info btn-sm text-white" @click="showHandler(item)"><i
                                        class="fa fa-eye"></i></button>
                            </template>
                        </data-table-local>
                    </template>
                    <template v-else>
                        <div class="d-flex justify-content-center align-items-center gap-3">
                            <Spinner /> Loading
                        </div>
                    </template>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import _ from "lodash";
import Spinner from 'vue-simple-spinner';
import axiosUtil from "../../utils/axiosUtil";

import {ServiceFactory} from "../../utils/ServiceFactory";
import dataTableLocal from "../../components/dataTable/DataTableLocal.vue";
import dataTable from "../../components/dataTable/DataTable.vue";
const fleet = ServiceFactory.get('fleet')

export default {
    name: "Fleets",
    components: {
        dataTable,
        dataTableLocal,
        Pagination,
        Spinner
    },

    data() {
        return {
            fields: [
                {label: 'Organization Name',field: 'name',  sortField: 'name' },
                {label: 'Companies',field: 'company_count' },
                {label: 'Drivers',field: 'driver_count' },
                {label: 'Trucks',field: 'truck_count' },
                {label: 'Trailers',field: 'trailer_count' },
                {label: 'Equipments',field: 'equipment_count' },
                {label: 'Status',field: 'status',  sortField: 'status' },
                {label: 'Action',field: 'action'},
            ],
            dotReadyFields: [
                { label: 'Organization Name', field: 'name', sortField: 'name' },
                { label: 'Companies', field: 'company_count' },
                { label: 'Drivers', field: 'driver_count' },
                { label: 'Trucks', field: 'truck_count' },
                { label: 'Trailers', field: 'trailer_count' },
                { label: 'Equipments', field: 'equipment_count' },
                { label: 'Status', field: 'status', sortField: 'status' },
                { label: 'Action', field: 'action' },
            ],
            filterOptions: [
                {value: 'name', label: 'Name'},
                {value: 'email', label: 'Email'},
            ],
            perPage: 15,
            data: [],
            dotReadyData: {},
            currentPage:1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false,
            loadingDotReady:false
        };
    },

    watch: {
        sortField(n, o) {
            this.getData()
        }
    },

    async created() {
        await this.getData()
        // if (this.canGetDotReady) {
        //     await this.getDotReadyData()
        // }
    },
    computed: {
        role() {
            return this.$store.getters["user/getRoles"]
        },
        canGetDotReady() {
            return _.includes(['admin', 'fleet_owner'], this.role)
        }
    },
    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        getSortParam(sortOrder) {
            console.log(sortOrder)
        },
        changePage(page) {
            this.currentPage=page
            this.getData()
        },
        async getData() {
            this.loading = true
            const {isSuccess, data} = await fleet.index({
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search,
                onlyQvin: true
            });
            if (isSuccess) this.data = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
        async getDotReadyData() {
            this.loadingDotReady = true
            const { isSuccess, data } = await fleet.getDotReadyFleets({
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search,
            });
            if (isSuccess) this.dotReadyData = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong - Dot Ready.'
            });
            this.loadingDotReady = false
        },
        dataManager(sortOrder) {
            if (sortOrder) {
                const direction = (sortOrder.sort_direction === 'desc' ? '-' : '')
                this.sortField = `${direction}${sortOrder.sort_field}`
            }
            let data = { data: _.slice(this.locations.data) };
            return data;
        },
        editHandler(data) {
            this.$router.push(`/fleets/${data.id}/edit`)
        },
        addDotReadyHandler(data) {
            window.location = process.env.MIX_DOT_READY_URL
        },
        async deleteHandler(data) {
            const vm = this
            this.$dialog.confirm('Are you sure, you want to delete this item?')
                .then(async function (dialog) {
                    const {isSuccess} = await subscriber.deleteSubscriber(data.id)
                    if (isSuccess) {
                        await vm.getData()
                        vm.$notify({type: 'success', 'text': 'Subscriber successfully deleted.'})
                    }
                });
        },
        showHandler() {
            window.location =  `${process.env.MIX_DOT_READY_URL}`
        },
        formatStatus(item) {
            return _.startCase(item.status)
        },
        async changeTab(tab){
            this.perPage = 15;
            this.currentPage = 1;
            this.sortField = null;
            this.filterBy = "";
            this.search = null;
            if(tab === 'qvin'){
                await this.getData()
            }else{
                if (this.canGetDotReady) {
                    await this.getDotReadyData()
                }
            }
        }
    }
};
</script>
