<template>

    <section id="footer">
        <div class="d-flex">
            <div class="container">
                <p class="fw-bold mt-3 text-center text-sm-start">CONTACT US</p>
                <div class="p-1">
                    <div class="row">
                        <div class="col-md-3 d-flex">
                            <img alt="" class="rounded img-fluid" src="img/qvin-logo-large-2.png"/>
                        </div>
                        <div class="col-md-3 d-flex justify-content-center  align-items-center">
                            <p class="fw-bold text-center text-sm-left">
                                Call us at (432) 653-0104
                                <br><br>
                                606 E. Spring Street, Suite H Cookeville, TN 38501
                            </p>
                        </div>
                        <div class="col-md-3 d-flex flex-column align-self-center fw-bold text-center">
                            <p>Products</p>
                            <p>Services</p>
                            <p>About Us</p>
                            <p>Contact</p>
                        </div>
                        <div class="col-md-3 d-flex flex-column align-self-center fw-bold text-center">
                            <p>Privacy Policy</p>
                            <p>EULA</p>
                            <p>Refund Policy</p>
                            <p>Shipping Policy</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ContactUs"
}
</script>

<style scoped>

</style>
