<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h1 class="tw-font-bold tw-text-2xl">Locations</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/locations/add" role="button"><i class="fa fa-plus"></i> Add Location</router-link>
            </div>
        </div>
        <b-row v-if="!loading">
            <b-col md="3" v-if="data.length" class="border-r">
                <div class="text-uppercase text-muted font-weight-bold small">Location List</div>
                <b-card no-body class="mt-3 border-0">
                    <b-list-group>
                        <b-list-group-item :to="{name: 'locations.edit', params: {id: location.id}}" v-for="(location, index) in data" :key="index" class="mb-0">{{location.name}}</b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-col>
            <b-col v-else>
                <div class="text-uppercase text-muted font-weight-bold" v-if="this.loading"><p>Loading.</p></div>
                <div class="text-uppercase text-muted font-weight-bold" v-if="!this.loading && data.length === 0"><h1>No data found.</h1></div>
            </b-col>
            <b-col>
                <router-view></router-view>
            </b-col>
        </b-row>
        <b-row v-else  align-h="center">
            <b-col>
                <p>Loading...</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {ServiceFactory} from "../../utils/ServiceFactory";
const location = ServiceFactory.get('location')
export default {
    name: "Locations",
    data() {
        return {
            perPage: 15,
            data: [],
            currentPage:1,
            sortField: null,
            filterBy: "",
            search: null,
            loading: false
        }
    },
    mounted() {
      this.getData();
    },
    methods: {
        async getData() {
            this.loading = true
            const {isSuccess, data} = await location.index({
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search
            });
            if (isSuccess) this.data = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
    }
}
</script>

<style scoped>

</style>
