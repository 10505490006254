<template>
    <div>
        <div class="d-flex justify-content-between">
            <h1 class="tw-font-bold tw-text-2xl">{{editing ? 'Edit' : 'Add'}} Subscriber</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/subscribers" role="button"><i class="fa fa-arrow-left"></i>  Back to list</router-link>
            </div>
        </div>
        <b-tabs>
            <b-tab title="General" active>
                <div class="row gap-2">
                    <div class="col">
                        <div >
                            <DisplayError :errors="errors" />
                        </div>
                        <form @submit.prevent="submitHandler">
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control" aria-describedby="emailHelp">
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Phone #</label>
                                <VuePhoneNumberInput
                                    v-model="form.phone"
                                    default-country-code="US"/>
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Email</label>
                                <input v-model="form.email" type="email" class="form-control">
                            </div>
                            <hr>

                            <div id="driver" v-if="form.type === 'driver'">
                                <p class="fw-bold">Driver</p>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">DOT #</label>
                                    <input v-model="form.dot_number" type="number" class="form-control">
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Truck Make</label>
                                    <MakeSelect v-model="form.truck_make"  />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Truck Model</label>
                                    <ModelSelect v-model="form.truck_model" />
                                </div>

                                <div class="mb-3">
                                    <b-form-group label="Driver Type" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="form.driver_type"
                                            :options="driver_type"
                                            :aria-describedby="ariaDescribedby"
                                            name="plain-inline"
                                            plain
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Company</label>
                                    <CompanySelect v-model="form.company" :value="form.company" />
                                </div>
                            </div>

                            <div id="driver" v-if="form.type === 'mechanic'">
                                <p class="fw-bold">Mechanic</p>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Company</label>
                                    <CompanySelect v-model="form.company" :value="form.company"  />
                                </div>
                            </div>

                            <div id="driver" v-if="form.type === 'police'">
                                <p class="fw-bold">Police Officer</p>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Country</label>
                                    <input v-model="form.country" type="text" class="form-control" aria-describedby="emailHelp">
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">City</label>
                                    <input v-model="form.city" type="text" class="form-control" aria-describedby="emailHelp">
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">State</label>
                                    <input v-model="form.state" type="text" class="form-control" aria-describedby="emailHelp">
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Badge #</label>
                                    <input v-model="form.badge" type="text" class="form-control" aria-describedby="emailHelp">
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Department</label>
                                    <input v-model="form.department" type="text" class="form-control" aria-describedby="emailHelp">
                                </div>

                                <div class="mb-3">
                                    <toggle-button v-model="form.signed_officer_agreement" label="Signed Officer Agreement"></toggle-button>
                                </div>

                            </div>

                            <div class="mb-3">
                                <toggle-button v-model="form.signed_officer_agreement" label="Send Invitation Email"></toggle-button>
                            </div>
                            <button type="submit" class="btn btn-info text-white">Save Subscriber</button>
                        </form>
                    </div>
                    <div class="col">
                        <b-form-group label="User Type" v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                v-model="form.type"
                                :options="user_type"
                                :aria-describedby="ariaDescribedby"
                                name="plain-stacked"
                                plain
                                stacked
                            ></b-form-radio-group>
                        </b-form-group>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div></template>

<script>
import ToggleButton from "../../components/common/ToggleButton.vue";
import SearchableSelect from "../../components/common/SearchableSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import MakeSelect from "../../components/common/selects/MakeSelect.vue";
import ModelSelect from "../../components/common/selects/ModelSelect.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import axiosUtils from "../../utils/axiosUtil";

import {ServiceFactory} from "../../utils/ServiceFactory";
import DisplayError from "../../components/common/DisplayError.vue";
const subscriber = ServiceFactory.get('subscriber')
const lookup = ServiceFactory.get('lookup')

export default {
    components: {
        DisplayError,
        ModelSelect,
        MakeSelect,
        CompanySelect,
        SearchableSelect,
        ToggleButton,
        VuePhoneNumberInput
    },
    name: "FormSubscriber",
    data() {
        return {
            form: {
                id:null,
                name: null,
                email: null,
                dot_number: null,
                type: null,
                company: null,
                company_label: null,
                truck_model: null,
                truck_model_label: null,
                truck_make: null,
                truck_make_label: null,
                driver_type: null,
                signed_officer_agreement: true,
                sendInvitationEmail: true,
                country: null,
                state: null,
                city: null,
                department: null,
                badge:null
            },
            user_type: [
                { text: 'Driver', value: 'driver' },
                { text: 'Mechanic', value: 'mechanic' },
                { text: 'Police Officer', value: 'police' }
            ],
            driver_type: [
                { text: 'Owner/Operator', value: 'owner' },
                { text: 'Employee', value: 'employee' },
            ],
            companyList: [],
            modelList: [],
            makeList: [],
            errors: null
        }
    },
    async created() {
        if (this.editing) {
            this.getDetails()
        }
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        }
    },
    methods: {
        async getDetails() {
            const {isSuccess, data} = await subscriber.showSubscriber(this.$route.params.id)
            if (isSuccess) {
                this.form.id = data.id
                this.form.name = data.name
                this.form.email = data.email
                this.form.phone = data.phone
                this.form.type = _.lowerCase(data.type)
                this.form.company = data.company
                this.form.truck_make = data.truck_make
                this.form.truck_model = data.truck_model
                this.form.dot_number = data.dot_number
                this.form.driver_type = data.driver_type
                this.form.country = data.country
                this.form.state = data.state
                this.form.city = data.city
                this.form.badge = data.badge
                this.form.department = data.department
            }
        },
        async submitHandler() {
            if (this.editing) await this.updateSubscriber()
            else await this.addSubscriber()
        },
        async updateSubscriber() {
            const {isSuccess, errors} = await subscriber.updateSubscriber(this.$route.params.id, this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Subscriber successfully updated.'})
                this.form = {}
                this.$router.push('/subscribers')
            }
            else this.errors = errors
        },
        async addSubscriber() {
            const {isSuccess, errors} = await subscriber.storeSubscriber(this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Subscriber successfully created.'})
                this.form = {}
                this.$router.push('/subscribers')
            }
            else this.errors = errors
        },
    }
}
</script>

<style scoped>

</style>
