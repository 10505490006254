<template>
    <section>
        <div class="d-flex">
            <div class="container">
                <p class="fw-bold mt-3 text-center text-sm-start">ABOUT US</p>
                <div class="p-5">
                    <div class="row">
                        <div class="col-md-3 d-flex justify-content-center ">
                            <img alt="" class="rounded img-fluid" src="/img/dot-ready-logo.png"/>
                        </div>
                        <div class="col-md-9 d-flex  align-items-center mt-2 mt-sm-0">
                            <p class="fw-bold">
                                QVIN is from the brains behind DOTReady, the trucking compliance and fleet management
                                software currently serving clients with thousands of assets.
                                DOTReady is integrated with QVIN, allowing you to stay ready for the DOT in every way
                                possible.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutUs"
}
</script>

<style scoped>

</style>
