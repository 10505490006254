<template>

    <section>
        <div class="container px-5 mt-2">
            <div class="d-flex justify-content-evenly my-1">
                <p class="fw-bolder display-6">FLEET TRACKING IS DONE RIGHT.</p>
            </div>
            <div class="row mb-3">
                <div class="col-md-6 d-flex justify-content-center order-md-2 mb-3 mb-sm-0">

                    <img alt=""  class="rounded img-fluid" src="/img/broken-bus.gif"/>
                </div>
                <div class="col-md-6 d-flex align-items-center order-md-1">
                    <p class="h4">
                        We know you have a lot of choices in fleet tracking. <br>
                        And some of them are the wrong choice. <br>
                        Stop complicated hardware installations. <br>
                        Stop buying expensive hardware. <br>
                        Stop relying on hardware that fails in the field, <br>
                        leaving your equipment stranded. <br>
                        <span class="fw-bolder">
                Finally, no more worrying about where <br>
                your equipment is and where it's being used.
                </span>
                    </p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-6 d-flex justify-content-center mb-3 mb-sm-0">
                    <img alt="" class="rounded img-fluid" src="/img/connect.gif"/>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <p class="h4">
                        QVIN is a simple way to track your equipment in the yard, in <br>
                        the field, and when it disappears. <br>
                        Using a high quality sticker that connects to the <br>
                        QVIN app, your drivers, your location managers, <br>
                        and your staff can <span class="fw-bold">manage equipment effortlessly.</span>
                    </p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-6 d-flex justify-content-center order-md-2 mb-3 mb-sm-0">
                    <img alt="" class="rounded img-fluid w-75" src="/img/phone.gif"/>
                </div>
                <div class="col-md-6 d-flex align-items-center order-md-1">
                    <p class="h4">
                        Did they leave it on the side of the road? Are you <br>
                        incurring liabilities that you aren't aware of? <br>
                        Even with expensive tracking equipment, <br>
                        you won't know what your employees are doing. <br>
                        <span class="fw-bolder">
                    QVIN solves this problem by making drivers <br>
                    compliant while tracking your equipment.
                </span>
                    </p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-6 d-flex justify-content-center mb-3 mb-sm-0">
                    <img alt="" class="rounded img-fluid " src="/img/find.gif"/>
                </div>
                <div class="col-md-6 d-flex  align-items-center">
                    <p class="h4">
                        Now you can find your equipment when it <br>
                        becomes lost or stolen quickly and easily through <br>
                        QVIN's publicly crowdsourced Am I Lost app. <br>
                        Offer public rewards and locate equipment quickly. <br>
                        <span class="fw-bold">
                QVIN can make your equipment stay yours.
                </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center my-5">
            <div class="container mx-4 mx-sm-0 text-center">
                <h1>ISN'T IT TIME YOUR EQUIPMENT WORKED <i>FOR YOU?</i></h1>
                <h1 class="fw-bold">CALL QVIN FOR A DEMO TODAY AT (432) 653-0104.</h1>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Features"
}
</script>

<style scoped>

</style>
