import axiosUtils from "../../utils/axiosUtil";

export default  {
    async getDashboardData(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        const buildUrl = 'dashboard-data'

        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async getDashboardViews() {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        const buildUrl = 'dashboard-views'

        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async updatePanelVisibility(panel, payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        const buildUrl = `dashboard-views/${panel.id}`

        return await axiosUtils.put(buildUrl, {is_visible: payload})
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async panelChart(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        const buildUrl = `dashboard-views/panel/${payload}`

        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },


}
