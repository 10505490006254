<template>
  <div>
    <div v-if="files.length">
      <div class="small font-weight-bold text-uppercase">{{ heading }}</div>
      <hr class="mt-1" />
    </div>
    <b-row>
      <b-col :sm="columns" v-for="file in files" :key="file.id">
        <b-card body-class="p-3" footer-tag="footer" header-tag="header" :header-class="['p-1', 'text-left']" :footer-class="['p-1', 'small']">
          <div slot="header" class="text-dark">{{ file.original_filename }}</div>
          <div slot="footer">
            <div class="d-flex justify-content-between">
              <div>
                <b-button @click="deleteFile(file.id)" variant="link" size="sm"><i class="fa fa-trash"></i></b-button>
              </div>
              <div v-if="expirations">
                <b-button @click="showExpirationDateModal(file)" variant="link" size="sm"><i class="fa fa-calendar-times"></i></b-button>
              </div>
              <div>
                <b-button :href="file.download_url" variant="link" size="sm"><i class="fa fa-download"></i></b-button>
              </div>
            </div>
          </div>
          <div class="text-center text-muted">
            <b-link @click="previewFile(file.public_url)" target="_blank" class="text-muted">
              <i class="fa fa-file fa-5x"></i>
              <div class="small py-1">{{ file.original_filename }}</div>
            </b-link>
            <div class="small" v-if="expirations" style="line-height: 1rem; min-height: 1rem;">{{ file.expires_at | localDate }}</div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="expirationDateModal" title="Expiration Date (Optional)" @ok="handleOk" @hidden="clearExpirationModal">
      <b-form-group label="Expiration Date" class="mt-3" :state="expirationErrors.expires_at ? false : null">
        <masked-input type="text" class="form-control" v-model="file.formattedExpiration" v-bind:class="expirationErrors.expires_at ? 'is-invalid' : ''" placeholder="MM/DD/YYYY" :mask="[/\d/, /\d/, '\/', /\d/, /\d/, '\/', /\d/, /\d/, /\d/, /\d/]" :guide="true" placeholderChar="_"></masked-input>
        <b-form-invalid-feedback>{{ expirationErrors.expires_at ? expirationErrors.expires_at[0] : '' }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="File Type" class="mt-3" :state="expirationErrors.group ? false : null" v-if="showGroupName" description="The file type will identify this file in expiration alerts">
        <b-form-input v-model="file.group" :state="null"></b-form-input>
        <b-form-invalid-feedback>{{ expirationErrors.group ? expirationErrors.group[0] : '' }}</b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
    <b-modal ref="previewFileModal" centered title="File Preview" size="xl" @hidden="clearPreviewUrl">
      <iframe :src="previewUrl" style="width: 100%; height: 1000px; border: 0;"></iframe>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import MaskedInput from 'vue-text-mask';
import File from '../../API/files/File';

export default {
  components: {
    MaskedInput
  },

  data: function () {
    return {
      file: {},
      expirationErrors: {},
      previewUrl: null
    }
  },

  props: {
    files: {
      default: function () { return []; }
    },
    heading: {
      default: 'Files'
    },
    columns: {
      default: 4
    },
    expirations: {
      type: Boolean,
      default: false
    },
    showGroupName: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    deleteFile: function (fileId) {
      let vm = this;

      vm.$dialog
        .confirm('Really delete this file?')
        .then(function (dialog) {
          File.destroy(fileId)
            .then(() => {
              vm.$emit('file-deleted', fileId);
            });
        });
    },

    previewFile: function (fileUrl) {
      this.previewUrl = fileUrl;
      this.$refs.previewFileModal.show();
    },

    clearPreviewUrl: function () {
      this.previewUrl = null;
    },

    showExpirationDateModal: function (file) {
      this.file = file;
      if (!_.isNull(file.expires_at)) {
        this.file.formattedExpiration = moment(file.expires_at).format('MM/DD/YYYY');
      } else {
        this.file.formattedExpiration = null;
      }
      this.$refs.expirationDateModal.show();
    },

    clearExpirationModal: function () {
      this.file = {};
      this.expirationErrors = {};
    },

    handleOk: function (event) {
      event.preventDefault();
      File.store({
        id: this.file.id,
        expires_at: this.file.formattedExpiration
      }).then(response => {
        this.$emit('file-updated', response);
        this.$refs.expirationDateModal.hide();
      }).catch(error => {
        if (error.response.status == '422') {
          this.expirationErrors = error.response.data.errors;
        }
      });
    }
  }
}
</script>
