<template>
    <div>
        <SearchableSelect :disabled="disabled" ref="locationSelect" v-on:input="handleChange" :options="optionList"></SearchableSelect>
    </div>
</template>

<script>
import SearchableSelect from "../SearchableSelect.vue";
import axiosUtils from "../../../utils/axiosUtil";
import Spinner from "vue-simple-spinner";

export default {

    data: function () {
        return {
            optionList: [],
            selectedOption: this.value,
            loaded: false
        }
    },

    props: {
        value: {
            default: null
        },
        companyId: {
            default: null
        },
        excludeLocationId: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    components: {
        SearchableSelect,
        Spinner

    },

    created: function () {
        this.getLocations();
    },
    watch: {
        selectedOption: function (newVal, oldVal) {
            this.$emit('input', newVal);
        },

        value: function (newVal, oldVal) {
            this.selectedOption = newVal;
            this.$refs.locationSelect.setSelection(newVal);
        },

        companyId: function (newVal, oldVal) {
            this.getLocations();
        },

        excludeLocationId: function () {
            this.getLocations();
        }

    },

    methods: {

        handleChange: function (event) {
            if (this.loaded) {
                this.selectedOption = event;
            }
        },

        getLocations: function () {
            let vm = this;
            let requestParams = {};

            if (!_.isNull(vm.companyId)) {
                requestParams.company_id = vm.companyId;
            }

            vm.optionList = [];

            let company = vm.companyId ? `?company=${vm.companyId}` : '';
            axiosUtils.get(`/lookup/locations${company}`)
                .then(locations => {
                    _.each(locations.data, function (location) {
                        vm.optionList.push({label: location.name, value: location.id});
                        vm.loaded = true;
                    })
            }).finally(() => {
                this.$refs.locationSelect.setSelection(this.value)
            })
        }

    }

}
</script>
