import _ from 'lodash';
import moment from 'moment';
import ApiResource from "../../utils/ApiResource";


const vehicle = {
  state: () => ({
    vehicle: null,
    files: null
	}),

  getters: {
    currentFiles: (state) => (folderId) => {
      if (!Array.isArray(state.files)) {
        return [];
      }

      return state.files.filter(file => {
        return file.folder_id === folderId && !file.deleted_at;
      });
    },

    archivedFiles: (state) => (folderId) => {
      if (!Array.isArray(state.files)) {
        return [];
      }

      return state.files.filter(file => {
        return file.folder_id === folderId && file.deleted_at;
      });
    },

    hasArchivedFiles: (state) => {
      if (!Array.isArray(state.files)) {
        return false;
      }

      const archivedIndex = state.files.findIndex(file => {
        return file.deleted_at !== null;
      });

      return archivedIndex !== -1;
    }
  },

  mutations: {
    setFiles(state, payload) {
      state.files = payload;
    },

    archiveFile(state, fileId) {
      const fileIndex = _.findIndex(state.files, { id: fileId });
      if (fileIndex !== -1) {
        state.files[fileIndex].deleted_at = moment().toJSON();
      }
    },

    addFile(state, payload) {
      const folderIndex = _.findIndex(state.files?.data, { id: payload.folder_id });
      if (folderIndex !== -1) {
        state.files?.data[folderIndex].files.push(payload);
      }
    },

    updateFile(state, payload) {
      const folderIndex = _.findIndex(state.files?.data, { id: payload.folder_id });
      if (folderIndex !== -1) {
        const fileIndex = _.findIndex(state.files?.data[folderIndex].files, { id: payload.id });
        if (fileIndex !== -1) {
          state.files?.data[folderIndex].files.splice(fileIndex, 1, payload);
        }
      }
    }
  },

  actions: {
    getVehicleFiles({ commit }, vehicleId) {
      const params = {
        owner_id: vehicleId,
        owner_type: 'vehicle',
        include_deleted: true
      };

      return ApiResource.make('file').getVehicleFiles(params, 'vehicles/files').then(response => {
        commit('setFiles', response.data)
      });
    },

    archiveVehicleFile({ commit }, fileId) {
      return ApiResource.make('file').destroy(fileId).then(response => {
        commit('archiveFile', fileId)
      });
    },

    updateVehicleFile({ commit }, payload) {
      return ApiResource.make('file').store(payload).then(response => {
        commit('updateFile', response.data)
      });
    }
  }
};

export default vehicle;
