import axios from 'axios';
import JsCookie from 'js-cookie';

const baseURL = `${window.location.origin}/api`;

const axiosUtils = axios.create({
    baseURL,
});

axiosUtils.interceptors.request.use(
    (config) => {
        const token = JsCookie.get('token');
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosUtils.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && [401, 419].includes(error.response.status)) {
            // Check if the current URL is already '/login' to avoid infinite redirects
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

// Handle unhandled promise rejections
window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled Promise Rejection:', event.reason);
});

export default axiosUtils;
