<template>
    <section id="copyright">
        <p class="p-3">Copyright 2022 QVIN LLC. All Rights Reserved.</p>
    </section>
</template>

<script>
export default {
    name: "Copyright"
}
</script>

<style scoped>

</style>
