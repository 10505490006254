<template>
    <div>
        <section id="header tw-relative ">
            <div
                class="tw-absolute tw-top-0 tw-end-0 tw-py-40 lg:tw-py-96 tw-px-32 md:tw-px-48 xl:tw-px-60 tw-rounded-bl-3xl tw-bg-qvin-1">
                <!-- Your content goes here -->
            </div>
            <Nav bg="white" />
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center lg:tw-space-x-40">
                <div class="w-full lg:tw-w-1/2 tw-order-1 lg:tw-order-0">
                    <div class="tw-flex tw-flex-col lg:tw-justify-start tw-mt-16 md:tw-mt-10 tw-mx-10 2xl:tw-mx-32">
                        <p class="tw-text-gray-500 fw-semibold my-0">Fines draining your accounts? Assets missing
                            without a trace? </p>
                        <p class="tw-text-gray-500 fw-semibold"> Tons of manual paperwork threatening your bottom line?
                        </p>
                        <div class="tw-flex tw-items-center tw-space-x-4">
                            <div>
                                <p class="tw-text-gray-600 tw-text-xl md:tw-text-3xl fw-semibold my-2">Stop wasting
                                    money.</p>
                                <p class="tw-text-gray-600 tw-text-xl md:tw-text-3xl fw-semibold">Start tracking smart
                                    with.</p>
                            </div>
                            <img alt="apple store" class="my-1" src="/img/QVIN-Logo-no-with-tm.png"
                                style="height: 120px; width: 120px">
                        </div>
                    </div>
                    <div
                        class="tw-bg-qvin-1 tw-py-8 w-full xl:tw-w-4/5 tw-rounded-br-[6rem] tw-mb-20 tw-flex tw-justify-center tw-items-center">
                        <div class="tw-flex tw-flex-col store-link-container">
                            <img alt=qrr class="tw-bg-white tw-rounded tw-h-36 tw-w-auto" src="/img/qr-google.png">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dotready.qvin">
                                <img alt="google play" class="mobile-provider-logo tw-w-auto"
                                    src="/img/mobile-provider-log-google.png">
                            </a>
                        </div>
                        <div class="tw-flex tw-flex-col store-link-container">
                            <img alt=qrr class="tw-bg-white tw-rounded tw-h-36 tw-w-auto" src="/img/qr-apple.png">
                            <a target="_blank" href="https://apps.apple.com/ph/app/q-vin/id6463773695">
                                <img alt="apple store" class="mobile-provider-logo tw-w-auto"
                                    src="/img/mobile-provider-log-ios.png">
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    class="w-full lg:tw-w-1/2 tw-flex tw-justify-center lg:tw-justify-start tw-z-50 tw-order-0 lg:tw-order-1 ml-0">
                    <div class="tw-relative tw-flex tw-justify-center">
                        <img src="/img/tablet.png" alt="tablet" class="tw-w-9/12 lg:tw-w-10/12">
                        <div
                            class="tw-absolute tw-top-0 tw-inset-0 tw-flex tw-items-center tw-justify-center lg:tw-justify-around w-full">
                            <video src="/vids/qvin-presentation.mp4"
                                class="tw-h-44 md:tw-h-72 lg:tw-h-[11.5rem] xl:tw-h-60 2xl:tw-h-[18.5rem] w-auto"
                                controls></video>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    </div>
</template>

<script>
import Nav from "./Nav.vue";

export default {
    name: "LandingPageNav",
    components: {Nav},
    data() {
        return {
            toggleMobileNav: false
        }
    },
    computed: {
        winSize() {
            console.log(window)
        },
    },
    methods: {
        handleRedirect() {
            this.$router.push('/login')
        }
    }
}
</script>

<style scoped>
.mobile-provider-logo {
    height: 3.4rem;
}
.mobile-provider-container {
    width: 35%;
}
.store-link-container {
    align-items: center;
}
</style>
