<template>
  <div>
    <searchable-select ref="vehicleTypeSelect" v-model="selectedOption" :options="optionList" :disabled="disabled"></searchable-select>
  </div>
</template>

<script>
import SearchableSelect from '../SearchableSelect';

export default {

  data: function () {
    return {
      optionList: [
        'Straight Truck',
        'Truck Tractor',
        'Trailer',
        'Hazmat Cargo Tank Trailer',
        'Hazmat Cargo Tank Truck',
        'Motor Coach',
        'Mini Bus 16\+',
        'School Bus 16\+',
        'Limousine 16\+',
        'School Bus 1\-8',
        'School Bus 9\-15',
        'Limousine 1\-8',
        'Limousine 9\-15',
        'Van 1\-8',
        'Van 9\-15'
      ],
      selectedOption: this.value
    }
  },

  props: {
    value: {
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SearchableSelect
  },

  created: function () {
    let vm = this;
  },

  watch: {

    selectedOption: function (newVal, oldVal) {
      this.$emit('input', newVal);
    },

    value: function (newVal, oldVal) {
      this.$refs.vehicleTypeSelect.setSelection(newVal);
    }

  }
}
</script>
