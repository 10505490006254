<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <div class="row w-100">
                        <h4 class="tw-font-bold tw-text-xl col-sm">Locations</h4>
                        <div class="col-sm-5 text-right">
                            <div class="form-group row m-0">
                                <label for="company" class="form-label col-sm-4 mt-1">Company:</label>
                                <div class="col-sm-8">
                                    <CompanySelect v-model="company" :value="company" :fleetId="fleetId" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-success btn-sm text-white" @click="addHandler()"><i
                            class="fa fa-plus"></i></button>
                </div>
                <div class="row">
                    <data-table ref="userTable" :columns="fields" default_sort_direction="" default_sort_field=""
                        :show_entries="[10, 15, 20, 50, 75, 100]" :show_entries_default="10" url="fleets"
                        :items="locations" @getData="dataManager" :loading="loading">
                        <template v-slot:action="{ item }">
                            <button class="btn btn-info btn-sm text-white" @click="editHandler(item, 'location')"><i
                                    class="fa fa-edit"></i></button>
                            <button class="btn btn-danger btn-sm" @click="deleteHandler(item, 'location')"><i
                                    class="fa fa-trash"></i></button>
                        </template>
                    </data-table>
                </div>
            </div>
        </div>
        <div>
            <b-modal ref="location-modal" size="lg" hide-footer centered>
                <template #modal-title>
                    {{ isAdd ? 'Add Location' : 'Edit Location' }}
                </template>
                <div class="d-block">
                    <location-form :location_id="selected?.id" :fleet="fleetId" @reload="reloadData"
                        :isAdd="isAdd"></location-form>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { ServiceFactory } from "../../utils/ServiceFactory";
import DisplayError from "../../components/common/DisplayError.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import CssForBootstrap4 from '../../Datatable/VuetableCssBootstrap4'
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import DataTable from "../../components/dataTable/DataTable.vue";
import Spinner from 'vue-simple-spinner';
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import LocationForm from "../Locations/LocationForm.vue";

const lookup = ServiceFactory.get('lookup')
const location = ServiceFactory.get('location')
export default {
    name: "FormAdmin",
    components: {
        DisplayError,
        CompanySelect,
        dataTable: DataTable,
        Pagination,
        Spinner,
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        css: CssForBootstrap4,
        LocationForm
    },
    data() {
        return {
            fields: [
                { label: 'Name', field: 'name', sortField: 'name' },
                { label: 'Address', field: 'address', sortField: 'address' },
                { label: 'City', field: 'city', sortField: 'city' },
                { label: 'State', field: 'state', },
                { label: 'Postal_code', field: 'postal_code', sortField: 'postal_code' },
                { label: 'Timezone', field: 'timezone', sortField: 'timezone' },
                { label: 'Action', field: 'action' },
            ],
            errors: null,
            company: null,
            loading: false,
            css: VuetableCssBootstrap4,
            locations: [],
            perPage: 10,
            currentPage: 1,
            sortField: null,
            selected: null,
        }
    },
    props: {
        fleetId: {
            default: null
        },
    },
    watch: {
        company(n, o) {
            this.getData()
        },
        sortField(n, o) {
            this.getData()
        }
    },
    async created() {
        this.getRoles();
        this.getData();
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        },
        isAdmin() {
            return this.$store.getters["user/getRoles"] === 'admin'
        },
        isFleetOwner() {
            return this.$store.getters["user/getRoles"] === 'fleet_owner'
        },
        isAdd() {
            return this.selected ? false : true;
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const { isSuccess, data } = await location.index({
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                company: this.company,
                filterBy: '',
                search: null,
                fleet: this.fleetId
            });
            if (isSuccess) this.locations = { data: data }
            else this.$notify({
                type: 'danger',
                text: 'Something went wrong.'
            });
            this.loading = false
        },
        dataManager(sortOrder) {
            if (sortOrder) {
                const direction = (sortOrder.sort_direction === 'desc' ? '-' : '')
                this.sortField = `${direction}${sortOrder.sort_field}`
            }
            let data = { data: _.slice(this.locations.data) };
            return data;
        },
        async getRoles() {
            const { isSuccess, data } = await lookup.show('roles')
            if (isSuccess) this.roles = data
            else this.$notify({ type: 'error', text: 'Something went wrong.' })

        },
        reloadData() {
            this.getData();
            this.$refs['location-modal'].hide()
        },
        editHandler(data, type) {
            this.selected = data;
            if (this.isAdmin || this.isFleetOwner) {
                this.$refs['location-modal'].show()
            } else {
                this.$router.push(`/locations/${data.id}/edit`);
            }
        },
        addHandler() {
            this.selected = null;
            if (this.isAdmin) {
                this.$refs['location-modal'].show()
            } else {
                this.$router.push(`/locations/add`);
            }
        },
        deleteHandler(item, type) {}
    }
}
</script>

<style scoped></style>
