<template>
    <div v-if="loaded">
        <div class="d-flex justify-content-between align-items-center my-2">
            <h1 class="tw-font-bold tw-text-2xl">{{ exists ? 'Edit' : 'Add' }} Inspection</h1>
            <router-link class="btn btn-info text-white fw-bold" to="/inspections" role="button"><i class="fa fa-arrow-left"></i>  Back to list</router-link>
        </div>
        <b-card>
            <b-row>
                <b-col md="7">
                    <b-form-group label="Drivers">
                        <driver-select v-model="inspection.drivers"></driver-select>
                    </b-form-group>
                    <b-form-group label="Vehicle">
                        <vehicle-select v-model="inspection.vehicle_id" type="vehicle" :multi="false"></vehicle-select>
                    </b-form-group>
                    <b-form-group label="Trailer">
                        <trailer-select v-model="inspection.trailer_id" type="trailer"></trailer-select>
                    </b-form-group>
                    <b-form-group label="Ticket #">
                        <template v-if="exists">
                            <editable-field v-model="inspection.ticket_number" placeholder="Ticket #"></editable-field>
                        </template>
                        <template v-else>
                            <b-form-input v-model="inspection.ticket_number" placeholder="Ticket #"></b-form-input>
                        </template>
                    </b-form-group>
                    <b-form-group label="Authority">
                        <template v-if="exists">
                            <editable-field v-model="inspection.authority" placeholder="Authority"></editable-field>
                        </template>
                        <template v-else>
                            <b-form-input v-model="inspection.authority" placeholder="Authority"></b-form-input>
                        </template>
                    </b-form-group>
                    <b-form-row class="mb-2">
                        <b-col>
                            <toggle-button v-model="inspection.citations_issued" label="Citations issued?"></toggle-button>
                        </b-col>
                        <b-col>
                            <toggle-button v-model="inspection.data_q" label="Data Q this incident?"></toggle-button>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-3">
                        <b-col>
                            <toggle-button v-model="inspection.reported_violations" label="Reported Violations?"></toggle-button>
                        </b-col>
                    </b-form-row>
                    <div class="mb-3" v-if="inspection.violations && inspection.violations.length">
                        <div class="small text-uppercase font-weight-bold text-muted mb-2">Violations</div>
                        <b-table :items="inspection.violations" small :fields="['code', 'description']"></b-table>
                    </div>
                    <b-form-group label="Additional Comments">
                        <template v-if="exists">
                            <editable-field v-model="inspection.notes" placeholder="notes" type="textarea"></editable-field>
                        </template>
                        <template v-else>
                            <b-form-textarea placeholder="Additional Comments" v-model="inspection.notes" :rows="8"></b-form-textarea>
                        </template>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Date / Time" class="mb-4">
                        <flat-pickr v-model="inspection.inspection_time" class="form-control" placeholder="Select Date / Time" :config="{enableTime: true, dateFormat: 'Y-m-d H:i:S'}"></flat-pickr>
                    </b-form-group>
                    <div v-if="exists">
                        <file-listing :files="files" v-on:file-deleted="removeFile"></file-listing>
                    </div>
                    <vue-dropzone ref="fileUploader" v-on:vdropzone-success="handleFileUploaded" v-on:vdropzone-queue-complete="handleUploadComplete" :options="uploadOptions" id="inspection-file-uploader" :useCustomSlot="true" @vdropzone-sending="onSending">
                        <div class="dropzone-custom-content">
                            <strong>Click here or drag files to upload</strong>
                        </div>
                    </vue-dropzone>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div v-if="!exists" class="pt-3">
                        <button class="btn btn-primary" @click="saveInspection">
                           Add Inspection
                        </button>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import _ from 'lodash';
import Cookies from 'js-cookie';
import DriverSelect from '../../components/common/selects/DriverSelect.vue';
import VehicleSelect from '../../components/common/selects/VehicleSelect.vue';
import TrailerSelect from '../../components/common/selects/TrailerSelect.vue';
import ToggleButton from '../../components/common/ToggleButton.vue';
import EditableField from '../../components/common/EditableField.vue';
import FileListing from './FileListing';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {ServiceFactory} from "../../utils/ServiceFactory";

const vehicle = ServiceFactory.get('vehicle')
import Inspection from "../../API/inspection/Inspection";


export default {

    data: function () {
        return {
            inspection: {},
            files: [],
            loaded: false,
            uploadOptions: this.getUploadOptions()
        }
    },

    props: {
        inspectionId: {
            default: null
        }
    },

    components: {
        DriverSelect,
        VehicleSelect,
        ToggleButton,
        flatPickr,
        EditableField,
        vueDropzone: vue2Dropzone,
        FileListing,
        TrailerSelect
    },

    computed: {
        exists: function () {
            return !!this.$route.params.id
        }
    },

    created: function () {
        let vm = this;

        if (vm.exists) {
            Inspection.show(this.$route.params.id)
                .then(inspection => {
                    let newInspection = _.pick(inspection, [
                        'id', 'vehicle_id', 'ticket_number', 'authority', 'data_q', 'violations',
                        'reported_violations', 'citations_issued', 'notes', 'inspection_time'
                    ]);
                    newInspection.drivers = _.map(inspection.drivers, function (driver) {
                        return driver.id;
                    });
                    newInspection.trailers = _.map(inspection.trailers, function (trailer) {
                        return trailer.id;
                    });
                    vm.inspection = newInspection;
                    vm.files = inspection.files;
                })
                .catch(error => {
                    console.log(error);
                })
                .then(() => {
                    vm.loaded = true;
                });
        }
        else {
            vm.loaded = true;
        }
    },

    methods: {

        saveInspection: function (redirect = true) {
            let vm = this;

            Inspection.store(vm.inspection)
                .then(inspection => {
                    if (!vm.exists) {
                        if (vm.$refs.fileUploader.getQueuedFiles().length) {
                            vm.$refs.fileUploader.processQueue();
                        }
                        else {

                            vm.handleUploadComplete();
                        }
                    }
                    this.$notify({
                        type: 'success',
                        text: 'Inspection saved successfully!'
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },

        removeFile: function (event) {
            let existingIndex = _.findIndex(this.files, function (file) {
                return file.id == event;
            });

            if (existingIndex > -1) {
                this.files.splice(existingIndex, 1);

                this.$notify({
                    type: 'success',
                    text: 'File deleted successfully!'
                });
            }
        },

        onSending: function (file, xhr, formData) {
            formData.append('owner_id', this.inspection.id);
            formData.append('owner_type', 'inspection');
        },

        getUploadOptions: function () {

            let uploadOptions = {
                autoProcessQueue: false,
                url: '/api/files',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
                }
            };

            if (this.inspectionId) {
                uploadOptions.autoProcessQueue = true;
            }

            return uploadOptions;
        },

        handleUploadComplete: function() {
            if (!this.exists) {
                this.$router.push({path: '/inspections'});
                this.$notify({
                    type: 'success',
                    text: 'Inspection saved successfully!'
                });
            }
        },

        handleFileUploaded: function (file, response) {
            this.$refs.fileUploader.removeFile(file);
            this.files.push(response);
        }

    },

    watch: {

        inspection: {
            handler: function (newVal, oldVal) {
                if (!this.loaded || !this.exists) return;

                this.saveInspection(false);
            },
            deep: true
        }

    }

}
</script>
