export default {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fas fa-tachometer-alt',
            roles: ['admin', 'trailer_owner','fleet_owner', 'location_manager']
        },
        {
            name: 'Admin Users',
            url: '/adminusers',
            icon: 'fa fa-user-circle',
            roles: ['admin', 'trailer_owner','fleet_owner']
        },
                {
            name: 'Users',
            url: '/users',
            icon: 'fa fa-user-circle',
            roles: ['admin', 'trailer_owner','fleet_owner']
        },
        {
            name: 'Fleet',
            url: '/fleets',
            icon: 'fa fa-network-wired',
            roles: ['admin','fleet_owner']
        },
        {
            name: 'Companies',
            url: '/companies',
            icon: 'fa fa-building',
            roles: ['admin']
        },
        {
            name: 'Trucks',
            url: '/trucks',
            icon: 'fa fa-truck',
            roles: ['admin', 'trailer_owner','fleet_owner', 'location_manager']
        },
        {
            name: 'Trailers',
            url: '/trailers',
            icon: 'fa fa-trailer',
            roles: ['admin', 'trailer_owner','fleet_owner', 'location_manager']
        },
        {
            name: 'Equipments',
            url: '/equipments',
            icon: 'fas fa-dolly-flatbed',
            roles: ['admin', 'trailer_owner','fleet_owner', 'location_manager']
        },
        {
            name: 'Inspection',
            url: '/inspections',
            icon: 'fas fa-search',
            roles: ['admin', 'trailer_owner','fleet_owner', 'location_manager']
        },
        {
            name: 'Work Orders',
            url: '/work-orders',
            icon: 'fas fa-file',
            roles: ['admin', 'trailer_owner','fleet_owner', 'location_manager']
        },
        {
            name: 'Drivers',
            url: '/drivers',
            icon: 'fa fa-user',
            roles: ['admin','fleet_owner', 'location_manager']
        },
        {
            name: 'Mechanics',
            url: '/mechanics',
            icon: 'fa fa-wrench',
            roles: ['admin']
        },
        {
            name: 'Police Officers',
            url: '/police-officers',
            icon: 'fa fa-id-badge',
            roles: ['admin']
        },
        {
            name: 'Locations',
            url: '/locations',
            icon: 'fas fa-location-arrow',
            roles: ['trailer_owner','fleet_owner']
        },
    ]
}
