<template>
    <section>
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-bg-qvin-2 tw-justify-center tw-items-center tw-pt-20">
            <div class="w-full lg:tw-w-1/2 tw-flex tw-order-1 lg:tw-order-0">
                <img src="/img/call-now.png" alt="Phone with Qr Code" class="tw-w-3/4 tw-h-auto">
            </div>
            <div class="lg:tw-w-1/2 tw-order-0 lg:tw-order-1 text-center lg:tw-text-left">
                <p class="tw-my-0 tw-text-2xl tw-text-white">Ready to take control of your fleet?</p>
                <p class="tw-my-0 tw-text-2xl tw-text-white">Call us today for demo at</p>
                <p class="tw-my-0 tw-text-5xl tw-text-white">1-833-339-8434</p>
                <p class="tw-font-bold tw-mt-10 tw-text-2xl tw-text-white">Wasted minutes means wasted money.</p>
            </div>
        </div>
        <div class="tw-pt-20 md:tw-pt-48 tw-pb-20 tw-w-full">
            <div class="tw-text-center tw-font-bold">
                <p class="tw-text-3xl md:tw-text-5xl tw-text-qvin-2 tw-my-0">Q-VIN MAKES DVIRs</p>
                <p class="tw-text-3xl md:tw-text-5xl tw-text-qvin-2 tw-my-0">EASY AS 1-2-3!</p>
            </div>

            <div class="tw-mx-20 xl:tw-mx-48" v-for="(item,index) in processItems" :key="index">
                <div class="tw-my-5 tw-flex-col xl:tw-flex-row tw-flex tw-justify-around tw-items-center">
                    <div class="">
                        <img :src="item.img" alt="Step 1" class="tw-h-80 xl:tw-h-[40rem]">
                    </div>
                    <div class="">
                        <div class="tw-flex tw-items-center md:tw-justify-center tw-space-x-2 tw-font-bold">
                            <p class="tw-text-6xl xl:tw-text-9xl tw-my-0">{{index + 1}}</p>
                            <div>
                                <p class="tw-my-0 tw-text-2xl xl:tw-text-5xl">{{item.title1}}</p>
                                <p class="tw-my-0 tw-text-qvin-1 tw-text-2xl xl:tw-text-5xl">{{item.title2}}</p>
<!--                                <template v-else>-->
<!--                                    <p class="tw-my-0 tw-text-3xl tw-text-5xl">Fast</p>-->
<!--                                    <p class="tw-my-0 tw-text-qvin-1 tw-text-5xl">Fast</p>-->
<!--                                </template>-->
                            </div>
                        </div>
                        <div class="tw-p-0 w-full xl:tw-w-3/4 tw-text-justify tw-font-bold tw-text-gray-500">{{item.subTitle}}</div>
                    </div>
                </div>
            </div>

        </div>

    </section>
</template>

<script>
export default {
    name: "Process",
    data() {
        return {
            processItems: [
                {
                    img: '/img/step1.png',
                    title1: 'SIGN IN TO',
                    title2: 'Q-VIN',
                    subTitle: 'Drivers can make an account or use a pre-existing one from DOTReady.'
                },
                {
                    img: '/img/step2.png',
                    title1: 'SCAN A Q-VIN',
                    title2: 'CODE',
                    subTitle: 'Point your smart-phone camera at the code on the truck or trailer.'
                },
                {
                    img: '/img/step3.png',
                    title1: 'FILL OUT THE',
                    title2: 'DVIR',
                    subTitle: 'Use our enhanced quickform to perform the inspection so you can hit the road safely!'
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>
