<template>
    <div class="tw-h-screen tw-flex">
        <div class="tw-hidden lg:tw-flex tw-w-full lg:tw-w-1/2 login_img_section tw-justify-around tw-items-center"
            v-if="!modal">
            <div class="tw-bg-black tw-opacity-20 tw-inset-0 tw-z-0"></div>
            <div class="tw-w-full tw-mx-auto tw-px-20 tw-flex-col tw-items-center tw-space-y-6">
            </div>
        </div>
        <div class="tw-flex tw-w-full tw-justify-center tw-items-center tw-bg-white tw-space-y-8"
            :class="`${modal ? '' : 'lg:tw-w-1/2'}`">
            <div class="tw-w-full tw-px-8 md:tw-px-32 lg:tw-px-24">
                <div class="tw-flex tw-justify-center">
                    <a class="navbar-brand" href="/"><img alt="qvin logo" class="tw-h-50 tw-w-50"
                            src="/img/QVIN-Logo-no-sl.png"></a>
                </div>
                <p class="tw-text-center tw-text-xl tw-font-bold tw-mb-2">Welcome back! Login in to your account!</p>
                <div class="card-body">
                    <div v-if="errors" class="alert alert-danger">
                        <ul class="tw-text-sm mb-0">
                            <li>{{ errors.message }}</li>
                            <li v-for="(error, index) in errors.errors">{{ error[0] }}</li>
                        </ul>
                    </div>
                    <form @submit.prevent="submitHandler">
                        <div class="tw-mb-6">
                            <label for="email"
                                class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-white">Email</label>
                            <input v-model="email" type="email" id="email"
                                class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-p-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500"
                                required>
                        </div>
                        <div class="tw-mb-6">
                            <label for="password"
                                class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-white">Password</label>
                            <input v-model="password" type="password" id="password"
                                class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-p-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500"
                                required>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Submit</button>
                        <div class="tw-flex tw-justify-end tw-items-center my-2">
                            <div class="tw-flex tw-space-x-2"><span>Don't have an account yet?</span><a
                                    class="tw-underline tw-text-blue-600" href="/signup" @click="toSignup">Click here</a></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ServiceFactory} from "../utils/ServiceFactory";
import JsCookie from "js-cookie";

const authService = ServiceFactory.get('auth')
export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            errors: null
        }
    },
    props: {
        modal: {
            default: false
        }
    },
    methods: {
        async submitHandler() {
            const {isSuccess, errors, data} = await authService.signInUser('/signin', {
                email: this.email,
                password: this.password
            })
            if (isSuccess) {
                if(this.modal){
                    await this.setUser();
                    this.$emit('closeModal');
                }else{
                    window.location.href = '/dashboard'
                }
            } else {
                this.errors = errors
                console.log(errors)
            }
        },
        toSignup(e){
            if(this.modal){
                this.$emit('redirect');
                e.preventDefault();
            }
        },
        async setUser(){
            const hasToken = JsCookie.get('token')
            if (hasToken) {
                const { isSuccess, data, errors } = await authService.me();
                if (isSuccess) {
                    await this.$store.dispatch("user/setUser", data)
                }
                else console.log(errors)
            }
        }
    }
}
</script>

<style scoped>
.login_img_section {
    background: linear-gradient(rgba(2, 2, 2, .5), rgba(0, 0, 0, .7)), url('https://images.unsplash.com/photo-1504846257989-a76209d9d2ac?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80') center center;
}
</style>
