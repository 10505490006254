import axiosUtils from "../../utils/axiosUtil";

export default  {
    async show(payload, endpoint) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.get(`/${endpoint}/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },

    async update(id, payload, endpoint) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.put(`/${endpoint}/${id}`, payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },

    async store(payload, endpoint) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post(`/${endpoint}`, payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },

    async delete(payload, endpoint) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.delete(`/${endpoint}/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },

}
