<template>
    <b-dropdown id="user-dropdown-menu" variant="light">
        <template v-slot:button-content v-if="user">
            <i class="fa fa-user"></i> {{ user.full_name }}
        </template>
        <b-dropdown-item :to="{ name: 'UpdatePlan' }"><i class="fa fa-map-signs"></i> Update Plan</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'Billing' }"><i class="fa fa-credit-card"></i> Update Billing</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'settings' }"><i class="fa fa-wrench"></i> Your Settings</b-dropdown-item>
        <b-dropdown-item @click="logout"><i class="fa fa-lock"></i> Logout</b-dropdown-item>
    </b-dropdown>
</template>

<script>
import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'
import {ServiceFactory} from "../../utils/ServiceFactory";
import {mapGetters} from "vuex";

const authService = ServiceFactory.get('auth')
export default {
    name: 'DefaultHeaderDropdownAccnt',
    components: {
        AppHeaderDropdown
    },
    computed: {
       ...mapGetters("user", {"user": "getUser"})
    },
    methods: {
        async logout() {
            const {isSuccess} = await authService.signOutUser('/signout')
            if (isSuccess) window.location.href = '/login'
            else this.$notify({type: 'error', text: 'Something went wrong.'})
        }
    }
}
</script>
