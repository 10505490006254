<template>
    <section id="download-qvin-section">
        <div class="d-flex">
            <div class="container">
                <p class="fw-bold mt-3">DOWNLOAD QVIN</p>
                <div class="row mb-3">
                    <div class="col-md-4 d-flex flex-column">
                        <img alt="" class="rounded img-fluid mx-auto"
                             src="/img/qvin-logo-large.png" style="height: 200px; width: 200px"/>
                        <p class="fw-bold text-center pb-0 align-self-center">QVIN <br> The DVIR App That Avoids Fines
                        </p>
                        <img alt="" class="rounded img-fluid mx-auto mb-2"
                             src="/img/apple-store.png" style="height: 60px; width: 190px"/>
                        <img alt="" class="rounded img-fluid mx-auto"
                             src="/img/google-playstore.png" style="height: 60px; width: 190px"/>
                    </div>
                    <div class="col-md-8 d-flex  align-items-start">
                        <p class="h6 fw-bold mt-3 lh-base">
                            OUR “Bubble” is safe! Drivers, Mechanics and the Back Office are finally able to communicate
                            effectively even when they don’t own the Trailer.
                            QVIN only shows the required paperwork they need, allowing drivers and mechanics to sign
                            DVIR’s as required by law and view the paperwork that they would normally need to have
                            anyway.
                            Can you imagine keeping paper documents in a plastic bubble that’s always exposed to the
                            elements and that anyone can physically access?
                            No more worries about lost paperwork when it counts. Worried about stolen trailers or damage
                            claims? With QVIN crowdsourced security and proprietary damage reports, QVIN's got you
                            covered.
                            Register your trailer in-the-field. <br><br>
                            Mechanics can sign in to QVIN and sign an existing DVIR to get OOS back on track.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "DownloadSection"
}
</script>

<style scoped>

</style>
