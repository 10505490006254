<template>
    <div>
        <Nav bg="blue" />
        <div class="py-5 text-center" v-if="step === 1">
            <h1 class="tw-font-black text-center mt-5 pb-5">How many vehicles do you have?</h1>
            <div class="m-auto mt-5 p-0 px-3 rounded text-center w-50 slider-main-container">
                <div class="row relative py-1">
                    <div class="col-lg-9 col-xl-10 col-8 pr-0 py-2 slider-container">
                        <vue-slider v-model="form.vehicle_count" :dot-options="dotOptions" :max="3000" :contained="true"
                            v-bind="sliderOptions">
                            <template v-slot:tooltip="tooltip">
                                <div>
                                    <img src="/images/truck-slider.png" alt="QVIN" style="height: 2.8rem" />
                                </div>
                            </template>
                        </vue-slider>
                    </div>
                    <div class="col-lg-3 col-xl-2 col-4 align-content-center">
                        <input name="vehicle_count" v-model="form.vehicle_count" type="number"
                            class="border font-weight-bold font-xl form-control text-center tw-text-qvin-2" max="3000">
                    </div>
                </div>
            </div>
            <p class="font-weight-bold text-gray-primary mt-3">{{ form.vehicle_count }} Vehicles</p>
        </div>
        <div class="py-5 tw-w-1/3 m-auto" v-if="step === 2">
            <h1 class="tw-font-black text-center mt-5 pb-5 m-auto px-3">What company do you work for?</h1>
            <div class="m-auto mt-5 w-75 px-2">
                <label for="company" class="font-weight-bold form-label">Company</label>
                <input name="company" v-model="form.company" type="text" class="form-control border">
            </div>
        </div>
        <div class="py-5 tw-w-1/3 m-auto" v-if="step === 3">
            <h1 class="tw-font-black text-center mt-5 pb-5 m-auto px-3">What industry is your company in?</h1>
            <div class="m-auto mt-5 w-75 px-2">
                <label for="industry" class="font-weight-bold form-label">Industry</label>
                <input name="industry" v-model="form.industry" type="text" class="form-control border">
            </div>
        </div>
        <div class="py-5 tw-w-4/5 m-auto" v-if="step > 3">
            <h1 class="tw-font-black text-center mt-5 pb-5 m-auto px-3">Let's complete your purchase.</h1>
            <div class="row">
                <div class="col-7">
                    <div class="accordion">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                <div class="accordion-button font-weight-bold font-xl" type="button">
                                    <i class="fa fa-check-circle pr-1"></i>
                                    <div>
                                        <span>Confirm your plan</span>
                                        <p class="font-weight-normal font-xs m-0">QVIN - {{ form.plan }} Plan</p>
                                    </div>
                                </div>
                            </h2>
                            <div class="accordion-collapse">
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="billingInfo-heading">
                                <button class="accordion-button font-weight-bold font-xl collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#billingInfo" aria-expanded="false"
                                    aria-controls="billingInfo" :disabled="!paymentMethod" @click="editBilling">
                                    <span>
                                        <i class="fa fa-check-circle pr-1" v-if="paymentMethod"></i>Enter your billing
                                        info
                                    </span>
                                    <span class="ml-auto p-1 edit-billing" v-if="paymentMethod"><i
                                            class="fa fa-pen"></i></span>
                                </button>
                            </h2>
                            <div id="billingInfo" class="accordion-collapse collapse"
                                :class="`${paymentMethod ? '' : 'show'}`" aria-labelledby="billingInfo-heading">
                                <div class="accordion-body">
                                    <form>
                                        <div class="row mb-2">
                                            <div class="col-sm">
                                                <label for="firstname" class="mb-0 font-sm form-label">First
                                                    Name</label>
                                                <input name="firstname" v-model="form.first_name" type="text"
                                                    class="form-control border" required
                                                    :class="`${!form.first_name && showError ? 'border-danger' : ''}`">
                                            </div>
                                            <div class="col-sm">
                                                <label for="lastname" class="mb-0 font-sm form-label">Last
                                                    Name</label>
                                                <input name="lastname" v-model="form.last_name" type="text"
                                                    class="form-control border" required
                                                    :class="`${!form.last_name && showError ? 'border-danger' : ''}`">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col">
                                                <label for="email" class="mb-0 font-sm form-label">
                                                    Email to receive invoices
                                                </label>
                                                <input name="email" v-model="form.email" type="email"
                                                    class="form-control border" required
                                                    :class="`${(!form.email || !emailValid) && showError ? 'border-danger' : ''}`">
                                                <p class="font-xs mb-0 text-danger text-right"
                                                    v-if="!emailValid && showError">
                                                    Invalid email
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col">
                                                <label for="country"
                                                    class="mb-0 font-sm form-label">Country/Region</label>
                                                <region-select class="form-select" v-model="form.region"
                                                    :country="form.country" :region="form.region" required
                                                    :class="`${!form.region && showError ? 'border-danger' : ''}`" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col">
                                                <label for="address1" class="mb-0 font-sm form-label">Address
                                                    1</label>
                                                <input name="address1" v-model="form.address1" type="text"
                                                    class="form-control border" required
                                                    :class="`${!form.address1 && showError ? 'border-danger' : ''}`">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col">
                                                <label for="address2" class="mb-0 font-sm form-label">Address
                                                    2</label>
                                                <input name="address2" v-model="form.address2" type="text"
                                                    class="form-control border">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col">
                                                <label for="city" class="mb-0 font-sm form-label">City</label>
                                                <input name="city" v-model="form.city" type="text"
                                                    class="form-control border" required
                                                    :class="`${!form.city && showError ? 'border-danger' : ''}`">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col">
                                                <label for="state" class="mb-0 font-sm form-label">State</label>
                                                <state-select v-model="form.state" name="state" class="rounded" required
                                                    :class="`${!form.state && showError ? 'border-danger border' : ''}`" />
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col">
                                                <label for="zip" class="mb-0 font-sm form-label">Zip
                                                    Code</label>
                                                <input name="zip" v-model="form.zip" type="text"
                                                    class="form-control border" required
                                                    :class="`${!form.zip && showError ? 'border-danger' : ''}`">
                                            </div>
                                        </div>
                                        <div class="row mb-2 mt-5">
                                            <div class="col">
                                                <button type="button"
                                                    class="btn btn-info py-2 text-white tw-bg-qvin-1 w-100"
                                                    @click="proceed">Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="paymentMethod-heading">
                                <button class="accordion-button font-weight-bold font-xl collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#paymentMethod" aria-expanded="false"
                                    aria-controls="paymentMethod" disabled>
                                    Choose a payment method
                                </button>
                            </h2>
                            <div id="paymentMethod" class="accordion-collapse collapse"
                                :class="`${paymentMethod ? 'show' : ''}`" aria-labelledby="paymentMethod-heading">
                                <div class="accordion-body">
                                    <div class="card px-4 pb-3"
                                        :class="`${form.payment_method === 'card' ? 'border-primary-subtle' : ''}`">
                                        <div class="align-items-center flex-row tw-flex">
                                            <div class="form-check mr-2">
                                                <input class="form-check-input" type="radio" name="paymentMethod"
                                                    id="creditCard" value="card" v-model="form.payment_method">
                                            </div>
                                            <div>
                                                <label class="font-weight-bold form-check-label ml-3" for="creditCard">
                                                    Credit Card
                                                    <img class="w-75" src="/img/cards.png" alt="Credit Cards" />
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="font-xs">Pay with your credit card via Stripe</p>
                                            <div class="row">
                                                <div class="col-6">
                                                    <label for="card_number" class="mb-0 font-sm form-label">Card
                                                        Number</label>
                                                    <input type="text" inputmode="numeric" autocomplete="cc-number"
                                                        maxlength="19" placeholder="1234 1234 1234 1234"
                                                        v-model="form.card_number" class="form-control border"
                                                        :disabled="form.payment_method === 'paypal'"
                                                        v-on:input="formatCardNumber" 
                                                        :class="`${errors?.card_number ? 'border-danger' : ''}`"/>

                                                </div>
                                                <div class="col-3">
                                                    <label for="expiry_date" class="mb-0 font-sm form-label">Expiry
                                                        Date</label>
                                                    <input name="expiry_date" v-model="form.expiry_date" type="text"
                                                        class="form-control border text-center" placeholder="MM / YY"
                                                        :disabled="form.payment_method === 'paypal'" maxlength='7'
                                                        v-on:input="formatExpiry"
                                                        :class="`${errors?.expiry_date ? 'border-danger' : ''}`">
                                                </div>
                                                <div class="col-3">
                                                    <label for="cvv" class="mb-0 font-sm form-label">Card Code
                                                        (CVC)</label>
                                                    <input name="cvc" v-model="form.cvc" type="text"
                                                        class="form-control border" placeholder="CVC"
                                                        :disabled="form.payment_method === 'paypal'" maxlength='4'
                                                        v-on:input="formatCVC"
                                                        :class="`${errors?.cvc ? 'border-danger' : ''}`">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="align-items-center card flex flex-row px-4"
                                        :class="`${form.payment_method === 'paypal' ? 'border-primary-subtle' : ''}`">
                                        <div class="form-check mr-3">
                                            <input class="form-check-input" type="radio" name="paymentMethod"
                                                id="paypal" value="paypal" v-model="form.payment_method">
                                        </div>
                                        <div>
                                            <label class="form-check-label" for="paypal">
                                                <img class="w-75" src="/img/paypal.png" alt="PayPal" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col-5 pt-4">
                    <div class="row">
                        <span class="align-content-center col-5 font-weight-bold font-xl px-3">Summary</span>
                        <div class="col-7 d-flex justify-content-end">
                            <div class="border border-2 p-1 tw-rounded-3xl">
                                <div class="tw-flex tw-items-center tw-space-x-2 tw-rounded-3xl">
                                    <div :class="`tw-px-6 tw-py-2 tw-rounded-3xl tw-cursor-pointer ${form.billing_period === 'monthly' ? 'tw-bg-qvin-2 tw-text-white' : ''}`"
                                        @click="setPayment('monthly')">
                                        <p class="my-0">Monthly</p>
                                    </div>
                                    <div :class="`tw-px-6 tw-py-2 tw-rounded-3xl tw-cursor-pointer ${form.billing_period === 'yearly' ? 'tw-bg-qvin-2 tw-text-white' : ''}`"
                                        @click="setPayment('yearly')">
                                        <p class="my-0">Yearly</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="align-items-baseline justify-content-between mt-4 px-3 tw-flex">
                        <div>
                            <p class="font-lg font-weight-bold mb-0">QVIN - {{ form.plan }} Plan</p>
                            <p class="font-xs mb-0">{{ form.vehicle_count }} Vehicles X ${{
                                form.rate }} / {{ form.billing_period == 'monthly' ? 'month' : 'year' }}</p>
                        </div>
                        <p class="font-sm">
                            {{ form.total_amount.toLocaleString('en-US', { minimumFractionDigits:
                            2,maximumFractionDigits: 2}) }}
                            USD /
                            {{ form.billing_period == 'monthly' ? 'month' : 'year' }}
                        </p>
                    </div>
                    <div class="justify-content-between mt-5 mb-5 px-3 tw-flex align-items-center">
                        <p class="font-lg font-weight-bold mb-0">Total</p>
                        <p class="font-weight-bold font-xl mb-0">
                            {{ form.total_amount.toLocaleString('en-US', { minimumFractionDigits:
                            2,maximumFractionDigits: 2 }) }} USD
                        </p>
                    </div>
                    <button type="button" class="btn btn-info py-3 text-white tw-bg-qvin-2 w-100 mb-3"
                        :disabled="(step < 5 || !form.card_number || !form.expiry_date || !form.cvc) || processingPay"
                        @click="payNow">Pay
                        Now</button>
                    <p class="font-xs text-gray-secondary">By purchasing, you acknowledge that your subscription will be
                        renewed annually, unless you switch
                        to manual renewal.</p>
                    <div class="bg-success-subtle mt-4 pt-3 row text-center">
                        <p class="font-xs text-success"><i class="fa fa-lock mr-1" aria-hidden="true"></i>Secure SSL
                            encrypted payment</p>
                        <p class="font-xs text-success"><i class="fa fa-clock mr-1" aria-hidden="true"></i>30-day money
                            back guarantee for Pro or
                            Advanced plans only</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="step < 4">
            <div class="m-auto text-center w-50 mt-5">
                <button type="button" class="btn btn-info py-3 text-white tw-bg-qvin-1 w-25" @click="next">Next</button>
            </div>
            <div class="mb-5 mt-5 text-center">
                <p class="font-sm text-decoration-underline text-gray-secondary" role="button" @click="back">Back</p>
            </div>
        </div>
        <notifications position="top right" />
        <QvinFooter />
    </div>
</template>

<script>
import { ServiceFactory } from "../utils/ServiceFactory";

const authService = ServiceFactory.get('auth')
import '../../css/slider.css'
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import {
    Aside as AppAside,
    Footer as TheFooter,
    Header as AppHeader,
    Sidebar as AppSidebar, SidebarFooter, SidebarMinimizer, SidebarNav, SidebarToggler
} from "@coreui/vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";
import StateSelect from '../components/common/selects/StateSelect.vue';
import axiosUtils from "../utils/axiosUtil";

export default {
    name: 'Checkout',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
        StateSelect
    },
    data() {
        return {
            form: {
                vehicle_count : 0,
                plan: ``,
                company: null,
                industry: null,
                billing_period: null,
                rate: 0,
                total_amount: 0,
                first_name: null,
                last_name: null,
                email: null,
                country: "US",
                region: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip: null,
                payment_method: 'card',
                card_number: null,
                expiry_date: null,
                cvc: null,
            },
            step: 1,
            email: '',
            password: '',
            password_confirmation: '',
            first_name: '',
            last_name: '',
            errors: null,
            selectedPayment: 'monthly',
            max: 3000,
            dotOptions: [
                {
                    tooltip: 'always',
                    dotStyle: {
                        zIndex: 3000
                    }
                },
                {
                    tooltip: 'none',
                    disabled: true
                }
            ],
            sliderOptions: {
                dotSize: 20,
            },
            params: ``,
            paymentMethod: false,
            showError: false,
            emailValid: false,
            processingPay: false,
        }
    },
    async created() {
        this.params = this.$route.query;
        this.form.vehicle_count = Number(this.params?.v || 0);
        this.form.plan = this.params?.plan || ``;
        this.form.billing_period = this.params?.period || ``;
    },
    computed: {
        totalPrice: function () {
            if(!this.form.plan) return 0;
            let months = this.form.billing_period === 'monthly' ? 1 : 11;
            let total = (this.form.vehicle_count * this.form.rate) * months;
            return total;
        },
    },
    methods: {
        back(){
            if(this.step > 1){
                this.step--;
            } else this.$router.push('/pricing')
        },
        next(){
            this.step++;
        },
        totalCostComputation(plan) {
            const counter = this.form.vehicle_count;
            if (plan === 'Starter') {
                if (counter < 101) {
                    return 21;
                } else if (counter >= 101 && counter <= 300) {
                    return 18;
                } else if (counter >= 301 && counter <= 1500) {
                    return 15;
                } else if (counter >= 1501 && counter <= 3000) {
                    return 10;
                }

            } else if (plan === 'Pro') {
                if (counter < 101) {
                    return 30;
                } else if (counter >= 101 && counter <= 300) {
                    return 27;
                } else if (counter >= 301 && counter <= 1500) {
                    return 24;
                } else if (counter >= 1501 && counter <= 3000) {
                    return 18;
                }
            } else {
                return ''
            }
        },
        proceed(){
            if(this.checkForm()){
                this.step = 5;
                this.paymentMethod = true;
            }
        },
        editBilling(){
            this.step = 4;
            this.paymentMethod = false;
        },
        checkForm(){
            let { first_name, last_name, email, country, region, address1, city, state, zip } = this.form;
            this.showError = true;
            this.validEmail(email)
            if (!first_name || !last_name || !email || !country || !region || !address1 || !city || !state || !zip || !this.emailValid){
                return false;
            } return true;
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.emailValid = re.test(email);
        },
        formatExpiry(){
            let format = this.form.expiry_date.replace(/[^0-9\s/]/g, '');
            if(this.form.expiry_date.length === 2){
                format = this.form.expiry_date.replace(/\D/g, '');
            }
            
            if(format.length === 2){
                format += ' / ';
            }
            
            if(format.length === 7){
                format = this.form.expiry_date.replace(/\s/g, '');
                let s = format.split('/');
                format = `${s[0]} / ${s[1]}`
            }

            this.form.expiry_date = format;
        },
        formatCardNumber(){
            let format = this.form.card_number.replace(/\D/g, '');
            let src = /^(\d{4})(\d{4})(\d{4})(\d{4})$/
            let dst = '$1 $2 $3 $4'
            this.form.card_number = format.replace(src, dst);
        },
        formatCVC(){
            let format = this.form.cvc.replace(/\D/g, '');
            this.form.cvc = format;
        },
        payNow(){
            this.processingPay = true;
            axiosUtils.post('/subscribe', this.form)
                .then(response => {
                    if (response.data.messages.resultCode === 'Error') {
                        response.data.messages.message.forEach(e => {
                            this.$notify({ type: 'error', 'text': e.text });
                        });
                    }else{
                        this.errors = null;
                        this.$notify({ type: 'success', 'title': 'Subscription Complete!', 'text': 'You will be redirected to your dashboard shortly.' });
                        setTimeout(() => {
                            window.location.href = '/dashboard';
                        }, 2000);
                    }
                }).catch(error => {
                    this.processingPay = false;
                    this.errors = error.response.data.errors;
                    let message = error.response.data.message || 'Something went wrong.';
                    this.$notify({ type: 'error', 'title': 'Subscription Failed', 'text': message.includes('validation.') ? 'Something went wrong.' : message });
                })
        },
        setPayment(mode) {
            this.form.billing_period = mode;
        },
    },
    watch: {
        'form.vehicle_count': function () {
            this.form.rate = this.totalCostComputation(this.form.plan);
            this.form.total_amount = this.totalPrice;
        },
        'form.billing_period': function () {
            this.form.total_amount = this.totalPrice;
        },
        'form.email': function(){
            this.validEmail(this.form.email);
        }
    }
}
</script>

<style scoped>
.top-feature {
    font-size: 12px;
}

.feature-description {
    font-size: 14px;
}
.slider-container {
    padding-top: 0.7rem;
}
.vue-slider {
    height: 10px !important;
}

:deep(.vue-slider-dot-tooltip-top) {
    top: -5px !important;
}

:deep(.vue-slider-dot-handle) {
    background-color: #025490;
}

:deep(.vue-slider-process) {
    background-color: #025490;
}
.text-gray-primary {
    color: #242424;
}
.text-gray-secondary {
    color: #979797;
}
.slider-main-container {
    box-shadow: 0 0 10px rgb(0 0 0 / 36%);
}
.accordion-button::after {
    background-image: initial;
    width: 0;
    content: unset;
}
.accordion-button:not(.collapsed)::after {
    background-image: initial;
    width: 0;
    content: unset;
}
</style>
