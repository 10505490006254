<template>
    <div>
        <Nav bg="blue" />
        <div class="container-fluid py-5">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="d-flex justify-content-center">
                        <a class="navbar-brand" href="#"><img alt="qvin logo" src="/img/QVIN-Logo-no-sl.png"
                                style="height: 150px; width: 150px"></a>
                    </div>
                    <div>
                        <h1 class="tw-font-black text-center">Automate your vehicle operations with Q-VIN&trade;!</h1>
                        <div class="d-flex justify-content-center mt-5 mb-2">
                            <div class="tw-bg-gray-100 tw-rounded-3xl tw-py-3 tw-px-2">
                                <div class="tw-flex tw-items-center tw-space-x-2 tw-rounded-3xl ">
                                    <div :class="`tw-px-6 tw-py-2 tw-rounded-3xl tw-cursor-pointer ${selectedPayment==='monthly' ? 'tw-bg-qvin-2 tw-text-white': 'tw-bg-gray-200'}`"
                                        @click="setPayment('monthly')">
                                        <p class="my-0">Monthly</p>
                                    </div>
                                    <div :class="`tw-px-6 tw-py-2 tw-rounded-3xl tw-cursor-pointer ${selectedPayment==='yearly' ? 'tw-bg-qvin-2 tw-text-white': 'tw-bg-gray-200'}`"
                                        @click="setPayment('yearly')">
                                        <p class="my-0">Yearly</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center mx-3 my-3 tw-space-x-3">
                            <p class="tw-bg-qvin-2 tw-text-white tw-py-2 tw-px-3 tw-rounded-3xl">Number of Vehicles</p>
                            <div class="tw-w-full md:tw-w-1/2">
                                <vue-slider v-model="counter" :max="max" />
                                <p class="font-weight-bold text-center"><span v-if="counter <= 5"></span> {{counter}}
                                    vehicles <span class="font-weight-normal" v-if="counter >= 3000">(more than 3,000?
                                        Book a demo)</span></p>
                            </div>
                        </div>
                        <div class="row row-cols-1 row-cols-md-3 g-4">
                            <div class="col" v-for="(plan, index) in plans" :key="index">
                                <div class="card pricing" :class="index === 1 && 'tw-border tw-border-qvin-2'">
                                    <div class="card-header text-center tw-bg-qvin-2 text-white" v-if="index === 1">
                                        Most Popular
                                    </div>
                                    <div>
                                        <div class="px-4 py-4"
                                            :class="`${index === 1 && 'tw-bg-qvin-2 tw-text-white'}`">
                                            <p class="tw-text-4xl tw-font-black tw-text-center">{{plan.title}}</p>
                                            <h1 class="tw-text-center"
                                                :class="`${index === 2 ? 'tw-my-5 tw-text-3xl' : 'tw-font-bold tw-text-4xl'}`">
                                                <span v-if="index !== 2">$</span>{{totalCostComputation(plan)}}
                                            </h1>
                                            <p class="text-center tw-font-bold" v-if="index !== 2">Per Unit</p>
                                            <p class="text-center tw-font-bold tw-text-sm tw-text-gray-900"
                                                :class="`${index === 1 ? 'tw-text-gray-200' : 'tw-text-gray-900'}`">
                                                {{index < 2 ? 'Annual is 1 month free' : '' }}</p>
                                                    <button @click="signUp(plan)" type="button"
                                                        :class="`btn btn-primary tw-font-black btn-block ${index !== 1 ? 'tw-bg-qvin-2' : 'tw-bg-white tw-text-qvin-2'}`">Start
                                                        Free Trial</button>
                                                    <p class="font-weight-bold text-center my-3 top-feature">
                                                        {{plan.headings}}</p>
                                        </div>

                                        <div class="px-4 py-1">
                                            <div v-for="(feature, index) in plan.features" :key="index">
                                                <p class="font-weight-bold tw-my-2"><i
                                                        class="fa fa-check-circle tw-text-qvin-2 mr-2"
                                                        style="font-size: 20px"></i>{{feature.title}}</p>
                                                <p class="tw-text-sm mt-1">{{feature.description}}</p>
                                            </div>
                                            <p @click="redirectToComparison"
                                                class="font-weight-bold tw-text-qvin-2 tw-text-sm tw-mt-1 tw-cursor-pointer"
                                                style="text-decoration: underline">See full Plan Comparison <i
                                                    class="fas fa-arrow-right"></i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-modal ref="signup-modal" size="lg" hide-footer centered>
                <template #modal-title></template>
                <div class="d-block">
                    <login v-if="signType === 'login'" :modal=true @closeModal="closeModal"
                        @redirect="signType = 'signup'"></login>
                    <signup v-if="signType === 'signup'" :modal=true @closeModal="closeModal"
                        @redirect="signType = 'login'"></signup>
                </div>
            </b-modal>
        </div>
        <notifications position="top right" />
        <QvinFooter />
    </div>
</template>

<script>
import {ServiceFactory} from "../utils/ServiceFactory";

const authService = ServiceFactory.get('auth')
import '../../css/slider.css';
import VueSlider from 'vue-slider-component';
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import {
    Aside as AppAside,
    AsideToggler,
    Footer as TheFooter,
    Header as AppHeader,
    Sidebar as AppSidebar, SidebarFooter, SidebarMinimizer, SidebarNav, SidebarToggler
} from "@coreui/vue";
import DefaultAside from "../components/containers/DefaultAside.vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";
import Login from "./Login.vue";
import Signup from "./Signup.vue";
import JsCookie from "js-cookie";

export default {
    name: 'Pricing',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
        Login,
        Signup
    },
    data() {
        return {
            email: '',
            password: '',
            password_confirmation: '',
            first_name: '',
            last_name: '',
            errors: null,
            plans: [
                {
                    title: 'Starter',
                    monthly_pricing: '4',
                    annual_pricing: '3',
                    headings: 'Features:',
                    features: [
                        { title: 'VIN Lookups' },
                        { title: 'Odometer & Fuel Logs' },
                        { title: 'Vehicle Groups & Teams' },
                        { title: 'Vehicle Expiration Reminders' },
                        { title: 'Vehicle Assignments' },
                        { title: 'Trailer Assignments' },
                        { title: 'Custom Fields' },
                        { title: 'Police Inspection Capability' },
                        {
                            title: 'Offline Wallet Storage During Trips',
                            description: 'Sync your wallet when you get back to civilization, WiFi or mobile, including license information, insurance, and other uploaded documents'
                        },
                        {
                            title: 'Offline Bubble Storage',
                            description: 'Carry the bubble with you for easy access'
                        },
                        { title: 'Multiple Locations Supported' },
                        {
                            title: 'Unlimited Drivers & Mechanics',
                            description: 'Manage all the users of your equipment'
                        },
                        { title: 'DVIR Reports' },
                        { title: 'Available on Android and iOS' },
                        { title: 'Dashboard on the Web' },
                        { title: 'Phone Support' },
                        { title: 'Email Support' },
                        { title: 'Full 2FA Support' },
                        { title: 'Vehicle Linking' },
                        { title: 'QVIN Stickers included' },
                        { title: 'Location tracking' },
                    ]
                },
                {
                    title: 'Pro',
                    monthly_pricing: '6',
                    annual_pricing: '5',
                    headings: 'Features:',
                    features: [
                        { title: 'Full Maintenance Workflow' },
                        { title: 'Issues List' },
                        {
                            title: 'Service Reminders',
                            description: 'Automated from Expirations and Reminders'
                        },
                        {
                            title: 'Work Orders',
                            description: 'Automated from Expirations and Reminders'
                        },
                        {
                            title: 'Mechanic Tasks & Assignments',
                            description: 'Manual Entries, Automated Entries, and more'
                        },
                        { title: 'FMCSA Approved Templates' },
                        { title: 'API Access' },
                        {
                            title: 'Unlimited Admin, HR, Dispatch, & Other Users',
                            description: 'Customize Data Access'
                        },
                        { title: 'Maintenance Reports' },
                        { title: 'Wall Display Dashboard/Dispatch Dashboard' },
                        { title: 'Included Onboarding Services' },
                    ]
                },
                {
                    title: 'Advanced',
                    monthly_pricing: '8',
                    annual_pricing: '7',
                    headings: 'Features:',
                    features: [
                        { title: 'Full Compliance Workflow' },
                        {
                            title: 'DOTReady Integration',
                            description: 'Complete Compliance with DOTReady without keeping track of all your paperwork'
                        },
                        {
                            title: 'DOTReady',
                            description: 'Full Service DOT Compliance software'
                        },
                        {title: 'Automated Employee Screening*'},
                        {title: 'MVR'},
                        {title: 'PSP'},
                        {title: 'FMCSA Clearinghouse'},
                        {title: 'Previous Employer Verifications'},
                        {title: 'D&A Testing'},
                        {title: 'Criminal Background Checks'},
                        {title: 'Full DOT Compliant Employment Screening Packages'},
                        {title: 'Full Employee Management'},
                        {title: 'DQ/HR File Management'},
                        {title: 'Full Fleet Management'},
                        {title: 'Full Equipment Management'},
                        {title: 'Accident Reporting/Management'},
                        {title: 'Full access Work Order Management'},
                        {title: 'Full access Inspection Management'},
                        {title: 'SMS data integration'},
                        {title: 'Additional Services at discounted rates: *'},
                        {title: 'DOT Compliance Consulting'},
                        {title: 'Full Service Employee Screening'},
                        {title: 'Mock DOT Audits'},
                        {title: 'Managed DOT Services'},
                        {title: 'DOT Compliance Training'},
                    ]
                }
            ],
            counter: 5,
            selectedPayment: 'monthly',
            max: 3000,
            selectedPlan: null,
            signType: 'login',
        }
    },
    methods: {
        async submitHandler() {
            const {isSuccess, errors, data} = await authService.signUp('/signup', {
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                first_name: this.first_name,
                last_name: this.last_name
            })
            if (isSuccess) {
                window.location.href = '/dashboard'
            } else {
                this.errors = errors
                console.log(errors)
            }
        },
        totalCostComputation(plan) {
            const counter = this.counter
            if (plan.title === 'Starter') {
                if (counter < 101) {
                    return 21;
                } else if (counter >= 101 && counter <= 300) {
                    return 18;
                } else if (counter >= 301 && counter <= 1500) {
                    return 15;
                } else if (counter >= 1501 && counter <= 3000) {
                   return 10;
                }

            } else if (plan.title === 'Pro') {
                if (counter < 101) {
                    return 30;
                } else if (counter >= 101 && counter <= 300) {
                    return 27;
                } else if (counter >= 301 && counter <= 1500) {
                    return 24;
                } else if (counter >= 1501 && counter <= 3000) {
                    return 18;
                }
            } else if (plan.title === 'Advanced') {
                return 'Call for quote'
            } else {
                return ''
            }
        },
        setPayment(mode) {
            this.selectedPayment = mode
        },
        redirectToComparison() {
            this.$router.push('/pricing-full-comparison')
        },
        signUp(plan){
            let hasToken = JsCookie.get('token')
            this.selectedPlan = plan;
            if(hasToken){
                this.redirectToCheckout();
            } else this.$refs['signup-modal'].show();
        },
        closeModal(){
            this.$refs['signup-modal'].hide();
            this.$notify({ type: 'success', 'title': 'Login Successful!', 'text': 'You are now being redirected to the checkout page.' });
            setTimeout(() => this.redirectToCheckout(), 2000);
        },
        redirectToCheckout() {
            this.$router.push(`/checkout?v=${this.counter}&plan=${this.selectedPlan.title}&period=${this.selectedPayment}`)
        }
    }
}
</script>

<style scoped>
    .top-feature {
       font-size: 12px;
    }

    .feature-description {
        font-size: 14px;
    }

    .vue-slider-dot-tooltip-inner {
        border-color: #b72f4a !important;
        background-color: #a72842 !important;
    }
</style>
