<template>
    <div v-if="errors" class="alert alert-danger">
        <ul>
            <li>{{ errors.message }}</li>
            <li v-for="(error, index) in errors.errors">{{ error[0] }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "DisplayError",
    props: ['errors']
}
</script>

<style scoped>

</style>
