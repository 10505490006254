<template>
    <div>
        <Nav bg="blue" />
        <div class="container-fluid py-5">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="d-flex justify-content-center">
                        <a class="navbar-brand" href="#"><img alt="qvin logo" src="/img/QVIN-Logo-no-sl.png"
                                style="height: 150px; width: 150px"></a>
                    </div>
                    <div class="text-justify">
                        <h1 class="tw-font-black text-center">End User License Agreement</h1>
                        <p class="text-center tw-text-gray-500 tw-text-sm">Last updated February 9th, 2023</p>
                        <div class="mb-3 text-center">
                            <a class="tw-no-underline hover:tw-no-underline tw-font-bold text-dark" href="/contact-us"
                                :class="`${currentRoute === '/contact-us' ? 'pb-1 border-bottom border-info' : ''}`">Contact
                                Us</a>
                        </div>
                        <div class="pt-3">
                            <p>
                                This End User License Agreement (“Agreement “) is a binding agreement between your
                                company (“Licensee” or “You”) and QVIN LLC (“QVIN”). This Agreement governs your use of
                                QVIN, LLC web, mobile, or desktop applications (each accessed via web browser or
                                downloaded and installed applications is referred to herein as the “Application “). The
                                Application is licensed, not sold, to your company.
                            </p>
                            <p>
                                This Agreement applies to Licensee’s purchase and use of the Application, whether
                                obtained directly from QVIN or from an authorized reseller. BY CLICKING “AGREE” and/or
                                SIGNING CONTRACT YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT;
                                (B) REPRESENT THAT YOU ARE OF LEGAL AGE AND A LEGAL REPRESENTATIVE OF YOUR ORGANIZATION
                                TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE
                                LEGALLY BOUND BY ITS TERMS.
                            </p>
                            <p>
                                IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE APPLICATION.
                            </p>
                            <ol>
                                <li>
                                    <p>License Grant.</p>
                                    <p>
                                        Subject to the terms of this Agreement and your subscription in accordance with
                                        Section 2 of this Agreement, QVIN grants you a limited, nonexclusive and
                                        nontransferable license to access via web or download and install and use the
                                        Application on a computer or device owned or otherwise controlled by you (“your
                                        Computer”) strictly in accordance with the Application’s documentation.
                                    </p>
                                </li>
                                <li>
                                    <p>Purchasing Through Resellers</p>
                                    <p>
                                        This Agreement applies whether you purchase the Application directly from QVIN,
                                        or through resellers. If you purchase through a reseller, the Application’s
                                        details shall be as stated in a subscription confirmation issued
                                        by a reseller to Licensee, and the reseller is responsible for the accuracy of
                                        any such subscription confirmation. Resellers are not authorized to make any
                                        promises or commitments on QVIN’ behalf, and QVIN is not
                                    </p>
                                </li>
                                <li>
                                    <p>Subscription Services</p>
                                    <p>
                                        You must pay QVIN subscription fees in accordance with Section 3(a) below or
                                        reseller’s terms of purchase, whichever is applicable.
                                    </p>
                                    <div>
                                        <p>
                                            (a) Monthly Subscription: When you subscribe to the Application on a monthly
                                            subscription basis, you must pay the monthly fee to access and use the
                                            Application. Fees are described in the applicable Service listing on the
                                            QVIN’ website and this contract. All fees charged will be processed through
                                            an ACH draft set-up prior to using the application service. The first
                                            monthly fee will start to accrue as of the first day immediately following
                                            any trial period (if applicable). The fees do not include any taxes or
                                            duties of any kind, which may be imposed by any governmental entity on the
                                            transactions contemplated by this Agreement, and you will be solely
                                            responsible for all such taxes. All amounts are quoted in and payable in US
                                            dollars. QVIN reserves the right to suspend your access to and use of the
                                            Application if you fail to pay any undisputed amount owed on or before its
                                            due date. You may cancel your subscription at any time by providing 30 days
                                            written notice to QVIN; upon cancellation, you (i) will not receive a refund
                                            for the billing period during which you cancel your subscription; and (ii)
                                            will be able to access your subscription and receive updates of the
                                            relevant subscription until the subscription
                                            termination date.
                                        </p>
                                        <p>
                                            (b) Refunds: QVIN may provide you a refund, on its behalf, of the full
                                            subscription price if: (i) the Application does not function properly; or
                                            (ii) you request a refund from QVIN within a calendar week after purchase.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <p>License Restrictions.</p>
                                    <p>
                                        (a) copy the Application, except as expressly permitted by this license;
                                    </p>
                                    <p>
                                        (b) modify, translate, adapt or otherwise create derivative works or
                                        improvements, whether or not patentable, of the Application;
                                    </p>
                                    <p>
                                        (c) reverse engineer, disassemble, decompile, decode or otherwise attempt to
                                        derive or gain access to the source code of the Application or any part thereof;
                                    </p>
                                    <p>
                                        (d) remove, delete, alter or obscure any trademarks or any copyright, trademark,
                                        patent or other intellectual property or proprietary rights notices from the
                                        Application, including any copy thereof; or,
                                    </p>
                                    <p>
                                        (e) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer
                                        or otherwise make available the Application or any features or functionality of
                                        the Application, to any third party for any reason, including by making the
                                        Application available on a network where it is capable of being accessed by more
                                        than one device at any time.
                                    </p>
                                </li>
                                <li>
                                    <p>Reservation of Rights.</p>
                                    <p>
                                        You acknowledge and agree that, whether provided by QVIN or a Reseller the
                                        Application is provided under license on a subscription basis, and not sold, to
                                        you. You do not acquire any ownership interest in the Application under this
                                        Agreement, or any other rights thereto other than to use the Application in
                                        accordance with the license granted, and subject to all terms, conditions and
                                        restrictions, under this Agreement. QVIN reserves and shall retain its entire
                                        right, title and interest in and to the Application, including all copyrights,
                                        trademarks and other intellectual property rights therein or relating thereto,
                                        except as expressly granted to you in this Agreement.
                                    </p>
                                </li>
                                <li>
                                    <p>Collection and Use of Information.</p>
                                    <p>
                                        (a) You acknowledge that when you access, download, install or use the
                                        Application, QVIN may use automatic means (including, for example, cookies and
                                        web beacons) to collect information about your Computer and about your use of
                                        the Application. You also may be required to provide certain information about
                                        yourself as a condition to downloading, installing or using the Application or
                                        certain of its features or functionality. All information we collect through or
                                        in connection with this Application is subject to our Privacy Policy. By
                                        downloading, installing, using and providing information to or through this
                                        Application, you consent to all actions taken by us with respect to your
                                        information in compliance with the Privacy Policy.
                                    </p>
                                    <p>
                                        (b) If you provide us with information relating to personal contact information
                                        (the “Data”) of third parties (e.g. email, mail, telephone, fax), such
                                        information shall be provided by you free of any charge or fee.
                                    </p>
                                    <p>
                                        You warrant that: (i) you make no claim of copyright or other intellectual
                                        property rights in the Data; (ii) you have all necessary rights to provide the
                                        Data to us and warrant that the Data was not collected, generated, compiled,
                                        obtained and/or being supplied to us in any manner that would subject us to
                                        legal or regulatory liability for the use as contemplated herein; (iii) none of
                                        the individuals included in the Data have “opted out” of receiving future
                                        messages from you; (iv) your providing the Data to us does not infringe any
                                        rights of any third party; (v) there are no material suits, claims, charges or
                                        proceedings currently pending or threatened against you relating to the Data;
                                        and (vi) the individuals whose contact information is included in the Data
                                        reside in the United States.
                                    </p>
                                </li>
                                <li>
                                    <p>Geographic Restrictions.</p>
                                    <p>
                                        The Content and Services are based in the state of Tennessee in the United
                                        States and provided for access and use only by persons located in the United
                                        States. You acknowledge that you may not be able to access all or some of the
                                        Content and Services outside of the United States and that access thereto may
                                        not be legal by certain persons or in certain countries. If you access the
                                        Content and Services from outside the United States, you are responsible for
                                        compliance with local laws.
                                    </p>
                                </li>
                                <li>
                                    <p>Updates.</p>
                                    <p>
                                        QVIN may from time to time in its sole discretion develop and provide
                                        Application updates, which may include upgrades, bug fixes, patches and other
                                        error corrections and/or new features (collectively, including related
                                        documentation, “Updates “). Updates may also modify or delete in their entirety
                                        certain features and functionality. You agree that QVIN has no obligation to
                                        provide any Updates or to continue to provide or enable any particular features
                                        or functionality. Based on your Web settings, when your Computer is connected to
                                        the internet either:
                                    </p>
                                    <p>
                                        (a) the Application will automatically download and install all available
                                        Updates; or
                                    </p>
                                    <p>
                                        (b) you may receive notice of or be prompted to download and install available
                                        Updates. You shall promptly download and install all Updates and acknowledge and
                                        agree that the Application or portions thereof may not properly operate should
                                        you fail to do so. You further Agreement.
                                    </p>
                                </li>
                                <li>
                                    <p>Third Party Materials.</p>
                                    <p>
                                        The Application may display, include or make available third-party content
                                        (including data, information, applications and other products services and/or
                                        materials) or provide links to third-party websites or services, including
                                        through third-party advertising (“Third Party Materials “). You acknowledge and
                                        agree that QVIN is not responsible for Third Party Materials, including their
                                        accuracy, completeness, timeliness, validity, copyright compliance, legality,
                                        decency, quality or any other aspect thereof. QVIN does not assume and will not
                                        have any liability or responsibility to you or any other person or entity for
                                        any Third-Party Materials. Third Party Materials and links thereto are provided
                                        solely as a convenience to you and you access and use them at entirely at your
                                        own risk and subject to such third parties’ terms and conditions.
                                    </p>
                                </li>
                                <li>
                                    <p>Term and Termination.</p>
                                    <p>
                                        (a) The term of Agreement commences when you acknowledge your acceptance and
                                        will continue in effect until terminated by you or QVIN as set forth in this
                                        Section.
                                    </p>
                                    <p>
                                        (b) You may terminate this Agreement by informing QVIN, deleting the
                                        Application, and all copies thereof from your Computer.
                                    </p>
                                    <p>
                                        (c) QVIN may terminate this Agreement at any time with reasonable advance notice
                                        if QVIN ceases to support the Application, which QVIN may do in its sole
                                        discretion. Other reasons for app removal include: (i) an allegation or actual
                                        infringement of any intellectual property right or right of publicity or privacy
                                        of any third party; (ii) an allegation of actual defamation; (iii) an allegation
                                        or determination that an app does not comply with applicable law; (iv) QVIN
                                        ceasing to do business; or (v) QVIN filing a petition in bankruptcy, dissolving,
                                        or otherwise finding itself unable to pay its debts as they come due.
                                    </p>
                                    <p>
                                        (d) This Agreement will terminate immediately and automatically without any
                                        notice if you violate any of the terms and conditions of this Agreement.
                                    </p>
                                    <p>
                                        (e) A third-party integration may be removed from the QVIN Application at any
                                        time if it is determined that the integration (i) may infringe or otherwise
                                        violate the Intellectual Property rights or any other rights of any third party;
                                        (ii) violates any applicable law, regulation, card association rule, or is
                                        subject to an injunction; (iii) may contain a virus, malware or spyware, or may
                                        have an adverse impact on QVIN’ systems.
                                    </p>
                                    <p>
                                        (f) Upon termination: (i) all rights granted to you under this Agreement will
                                        also terminate; and (ii) you must cease all use of the Application and delete
                                        all copies of the Application from your Computer and/or access account.
                                    </p>
                                    <p>
                                        (g) Termination will not limit any of QVIN’ rights or remedies at law or in
                                        equity.
                                    </p>
                                    <p>
                                        (h) Licensee hereby understands and agrees that if reseller’s agreement with
                                        QVIN terminates QVIN will provide Licensee with written notice thereof; and
                                        immediately thereupon, Licensee’s payment obligation to reseller with respect to
                                        the Application, if any, automatically becomes Licensee’s payment obligation to
                                        QVIN in accordance with the terms and conditions set forth herein, without
                                        licensee’s further action or consent.
                                    </p>
                                </li>
                                <li>
                                    <p>Disclaimer of Warranties.</p>
                                    <p>
                                        THE APPLICATION IS PROVIDED TO LICENSEE “AS IS” AND WITH ALL FAULTS AND DEFECTS
                                        WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
                                        LAW, QVIN, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR
                                        RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES,
                                        WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE
                                        APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                                        PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT
                                        OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT
                                        LIMITATION TO THE FOREGOING, QVIN PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES
                                        NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL
                                        MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH
                                        ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT
                                        INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR
                                        THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. SOME JURISDICTIONS DO NOT
                                        ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS
                                        ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE
                                        EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
                                    </p>
                                </li>
                                <li>
                                    <p>Limitation of Liability.</p>
                                    <p>
                                        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL QVIN OR ITS
                                        AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS,
                                        HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE
                                        THE APPLICATION OR THE CONTENT AND SERVICES FOR: (a) PERSONAL INJURY, PROPERTY
                                        DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS
                                        OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION OR ANY OTHER
                                        CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES (b)
                                        DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID
                                        BY YOU FOR THE APPLICATION. THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH
                                        DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
                                        OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR QVIN WAS
                                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW
                                        CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF
                                        LIABILITY MAY NOT APPLY TO YOU.
                                    </p>
                                </li>
                                <li>
                                    <p>Indemnification.</p>
                                    <p>
                                        You agree to indemnify, defend and hold harmless QVIN and its officers,
                                        directors, employees, agents, affiliates, successors and assigns from and
                                        against any and all losses, damages, liabilities, deficiencies, claims, actions,
                                        judgments, settlements, interest, awards, penalties, fines, costs, or expenses
                                        of whatever kind, including attorneys’ fees, arising from or relating to your
                                        use or misuse of the Application or your breach of this Agreement. Furthermore,
                                        you agree that QVIN, LLC assumes no responsibility for the content you submit or
                                        make available through this Application.
                                    </p>
                                </li>
                                <li>
                                    <p>Export Regulation.</p>
                                    <p>
                                        The Application may be subject to US export control laws, including the US
                                        Export Administration Act and its associated regulations. You shall not,
                                        directly or indirectly, export, re-export or release the Application to, or make
                                        the Application accessible from, any jurisdiction or country to which export,
                                        re-export or release is prohibited by law, rule or regulation. You shall comply
                                        with all applicable federal laws, regulations and rules, and complete all
                                        required undertakings (including obtaining any necessary export license or other
                                        governmental approval), prior to exporting, re-exporting, releasing or otherwise
                                        making the Application available outside the US.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        QVIN and its affiliates are not responsible or liable to you in any way with
                                        respect to your use of the Application.
                                    </p>
                                </li>
                                <li>
                                    <p>Resellers.</p>
                                    <p>
                                        If Licensee acquired the Licensed Software through a Reseller, Licensee
                                        acknowledges that (i) payment and delivery terms for the Licensed Software must
                                        be established separately and independently between the Licensee and the
                                        Reseller; (ii) this Agreement constitutes the entire agreement between the
                                        Licensee and QVIN regarding the license rights for the Application as described
                                        above and is controlling; (iii) the terms and conditions of any purchase order
                                        or any other agreement between the Licensee and the Reseller are not binding on
                                        Company; (iv) the Reseller is not authorized to alter, amend or modify the terms
                                        of this Agreement or to otherwise grant any license or other rights relating in
                                        any way to the Application; and (v) Licensee’s nonpayment of any amount due to a
                                        Reseller or any other relevant third party relating to its licensed rights under
                                        this Agreement shall constitute a basis for Proverb’s termination of this
                                        Agreement. Licensee further acknowledges that Company makes no representation or
                                        warranty with regard to any services provided by any Reseller, or any actions or
                                        failures to act by any Reseller.
                                    </p>
                                </li>
                                <li>
                                    <p>Severability.</p>
                                    <p>
                                        If any provision of this Agreement is illegal or unenforceable under applicable
                                        law, the remainder of the provision will be amended to achieve as closely as
                                        possible the effect of the original term and all other provisions of this
                                        Agreement will continue in full force and effect.
                                    </p>
                                </li>
                                <li>
                                    <p>Governing Law.</p>
                                    <p>
                                        This Agreement is governed by and construed in accordance with the internal laws
                                        of Tennessee without giving effect to any choice or conflict of law provision or
                                        rule. Any legal suit, action or proceeding arising out of or related to this
                                        Agreement or the Application shall be instituted exclusively in the federal
                                        courts of the United States or the courts of Texas in each case located in
                                        Midland County. You waive any and all objections to the exercise of jurisdiction
                                        over you by such courts and to venue in such courts.
                                    </p>
                                </li>
                                <li>
                                    <p>Entire Agreement.</p>
                                    <p>
                                        This Agreement and our Privacy Policy constitute the entire agreement between
                                        you and Proverb with respect to the Application and supersede all prior or
                                        contemporaneous understandings and agreements, whether written or oral, with
                                        respect to the Application.
                                    </p>
                                </li>
                                <li>
                                    <p>Waiver.</p>
                                    <p>
                                        No failure to exercise, and no delay in exercising, on the part of either party,
                                        any right or any power hereunder shall operate as a waiver thereof, nor shall
                                        any single or partial exercise of any right or power hereunder preclude further
                                        exercise of that or any other right hereunder. In the event of a conflict
                                        between this Agreement and any applicable purchase or other terms, the terms of
                                        this Agreement shall govern.
                                    </p>
                                    <p>
                                        By signing you agree that you have read and understand this QVIN, LLC EULA.
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QvinFooter />
    </div>
</template>

<script>
import { ServiceFactory } from "../utils/ServiceFactory";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import { Header as AppHeader, } from "@coreui/vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";

const authService = ServiceFactory.get('auth')

export default {
    name: 'EULA',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
    },
    computed: {
        currentRoute() {
            return this.$route.path
        }
    },
    methods: {
        handleRedirect() {
            this.$router.push('/pricing')
        }
    }
}
</script>

<style scoped>
.top-feature {
    font-size: 12px;
}

.feature-description {
    font-size: 14px;
}

.text-blue-qvin {
    color: #025490;
}
</style>
