<template>
  <div>
    <searchable-select :disabled="disabled" ref="statusSelect" v-model="selectedOption" :options="optionList"></searchable-select>
  </div>
</template>

<script>
import SearchableSelect from '../SearchableSelect';

export default {

  data: function () {
    return {
      optionList: [
        {label: 'In Service', value: 'in_service'},
        {label: 'Out of Service', value: 'out_of_service'},
        {label: 'Sold', value: 'sold'},
        {label: 'Deleased', value: 'deleased'},
        {label: 'Salvaged', value: 'salvaged'}
      ],
      selectedOption: this.value
    }
  },

  props: {
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SearchableSelect
  },

  created: function () {
    let vm = this;
  },

  watch: {

    selectedOption: function (newVal, oldVal) {
      this.$emit('input', newVal);
    },

    value: function (newVal, oldVal) {
      this.selectedOption = newVal;
      this.$refs.statusSelect.setSelection(newVal);
    }

  },

  methods: {



  }

}
</script>
