// Bootstrap4 with FontAwesome
export default {
    table: {
        tableWrapper: "",
        tableHeaderClass: "bg-danger",
        tableBodyClass: "mb-0",
        tableClass: "table table-striped table-hover",
        loadingClass: "loading",
        ascendingIcon: "fa fa-chevron-up",
        descendingIcon: "fa fa-chevron-down",
        ascendingClass: "sorted-asc",
        descendingClass: "sorted-desc",
        // sortableIcon: "fa fa-sort",
        detailRowClass: "",
        handleIcon: "fa-bars text-secondary",
        renderIcon(classes, options) {
            return `<i class="${classes.join(" ")}" ${options}></span>`;
        }
    },

    pagination: {
        wrapperClass: 'pagination float-right',
        activeClass: 'active text-white',
        disabledClass: 'disabled',
        pageClass: 'page-item page-link',
        linkClass: 'page-link',
        paginationClass: 'pagination',
        paginationInfoClass: 'float-left',
        dropdownClass: 'form-control',
        icons: {
            first: 'fas fa-angle-double-left',
            prev: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            last: 'fas fa-angle-double-right',
        }
    },
    loadingClass: 'Please Wait...'
};
