import axiosUtils from "../../utils/axiosUtil";

export default {
  index (params) {
    return axiosUtils.get(`/inspections${params}`,)
      .then(response => {
        return response.data
      });
  },
  show (id) {
    return axiosUtils.get('/inspections/'+id)
      .then(response => {
        return response.data
      });
  },
  store(payload) {
    if (typeof(payload.id) !== 'undefined') {
      var apiEndpoint = '/inspections/'+payload.id;
      var apiMethod = 'put';
    }
    else {
      var apiEndpoint = '/inspections';
      var apiMethod = 'post';
    }

    return axiosUtils({
      method: apiMethod,
      url: apiEndpoint,
      data: payload
    }).then(response => {
      return response.data;
    });
  },
  destroy(id, perm = false) {
    const params = {}
    if (perm) {
      params.force = true
    }

    return axiosUtils.delete('/inspections/'+id, { data: params })
      .then(response => {
        return response.data;
      });
  },
  restore(id) {
    return axiosUtils.patch('/inspections/'+id+'/restore');
  }
}
