<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between my-2">
            <h1 class="tw-font-bold tw-text-2xl">{{module}}</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" :to="`/work-orders/add`" role="button"><i class="fa fa-plus"></i>  Add Work Order</router-link>
            </div>
        </div>
        <b-card>
            <div class="d-flex justify-content-start gap-3 mb-3">
                <div>
                    <select class="form-select" aria-label="Default select example" v-model="filterBy">
                        <option selected value="" disabled>Filter By...</option>
                        <option :value="item.value" v-for="(item, index) in filterOptions" :key="index">{{ item.label }}</option>
                    </select>
                </div>

                <div v-if="filterBy">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                        <input v-model="search" @input="debounceInput" type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                </div>
            </div>
            <div>
                <data-table
                    ref="userTable"
                    :columns="fields"
                    default_sort_direction=""
                    default_sort_field=""
                    :show_entries="[10,15,20,50,75,100]"
                    :show_entries_default="15"
                    url="trucks"
                    :items="data"
                    @getData="dataManager"
                    :loading="loading"
                >
                    <template v-slot:status="{item}">
                        <p>{{item.status ? formatStatus(item) : ''}}</p>
                    </template>
                    <template v-slot:inspection_time="{item}">
                        <p>{{item.inspection_time ? formatInspectionTime(item) : ''}}</p>
                    </template>
                    <template v-slot:vehicle="{item}">
                        <p>{{item.vehicle}}</p>
                    </template>
                    <template v-slot:action="{item}">
                        <button class="btn btn-info btn-sm text-white" @click="editHandler(item)"><i class="fa fa-edit"></i></button>
                        <button class="btn btn-danger btn-sm" @click="deleteHandler(item)"><i class="fa fa-trash"></i></button>
                    </template>
                </data-table>
            </div>
            <div>
                <b-modal ref="add-modal" hide-footer title="Add New Vehicle" centered >
                    <div class="d-block">
                        <form @submit.prevent="submitHandler">
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control border" :class="{'border-danger' :(errors && errors.errors.name)}" aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Location</label>
                                <LocationSelect v-model="form.location_id" :value="form.location_id" :class="{'border border-danger' :(errors && errors.errors.location_id)}"/>
                            </div>
                            <button type="submit" class="btn btn-info text-white">Save</button>
                        </form>
                    </div>
                </b-modal>
            </div>
        </b-card>
    </div>
</template>

<script>
import {ServiceFactory} from "../../utils/ServiceFactory";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import _ from "lodash";
import Spinner from 'vue-simple-spinner';
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import CssForBootstrap4 from '../../Datatable/VuetableCssBootstrap4'
import DataTable from "../../components/dataTable/DataTable.vue";
import moment from "moment";

const vehicle = ServiceFactory.get('vehicle')
const inspection = ServiceFactory.get('inspection')
const workOrder = ServiceFactory.get('workOrder')

export default {
    name: "WorkOrders",
    components: {
        LocationSelect,
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        Pagination,
        Spinner,
        css: CssForBootstrap4,
        dataTable: DataTable
    },

    data() {
        return {
            form: {
                name: null,
                location_id: null
            },
            fields: [
                {label: 'ID',field: 'created_at',  sortField: 'created_at' },
                {label: 'Vehicle/Trailer',field: 'vehicle', sortField: 'vehicle.name' },
                {label: 'Status',field: 'status', sortField: 'status' },
                {label: 'Mechanic',field: 'mechanic' },
                {label: 'Completed',field: 'completed_at', sortField: 'completed_at' },
                {label: 'Description',field: 'description', sortField: 'description' },
                {label: 'Action',field: 'action'},
            ],
            perPage: 15,
            data: {},
            currentPage:1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false,
            errors: null,
            filterOptions: [
                {value: 'name', label: 'Name'},
                {value: 'type', label: 'Type'},
                {value: 'plate', label: 'Plate'},

                {value: 'qvin_code', label: 'qvin'},
                {value: 'year', label: 'Year'},
                {value: 'tire_size', label: 'Tire Size'},
                {value: 'gvwr', label: 'GVWR'},
                {value: 'cmv', label: 'CMV'},
                {value: 'tire_size', label: 'Tire Size'},
                {label: 'ELD AOBRD Make', value: 'eld_aobrd_make'},
                {label: 'ELD AOBRD Model', value: 'eld_aobrd_model'},
                {label: 'ELD AOBRD Year', value: 'eld_aobrd_year'},
            ],
        };
    },

    watch: {
        data(newVal, oldVal) {

        },
        '$route.name': {
            handler: function() {
                this.getData()
            },
            deep: true,
        }
    },
    computed: {
        module() {
            return "Work Orders"
        },
        moduleUrl() {
            return 'work-orders'
        },
    },

    async created() {
        await this.getData()
    },

    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        getSortParam(sortOrder) {
            console.log(sortOrder)
        },
        changePage(page) {
            this.currentPage=page
            this.getData()
        },
        async getData() {
            this.loading = true
            const {isSuccess, data} = await workOrder.getWorkOrder(this.moduleUrl,{
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search
            });
            if (isSuccess) this.data = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
        editHandler(data) {
            this.$router.push(`/${this.moduleUrl}/${data.id}/edit`)
        },
        async deleteHandler(data) {
            const vm = this
            this.$dialog.confirm('Are you sure, you want to delete this item?')
                .then(async function (dialog) {
                    const {isSuccess} = await vehicle.deleteTruck(this.moduleUrl, data.id)
                    if (isSuccess) {
                        await vm.getData()
                        vm.$notify({type: 'success', 'text': 'Admin user successfully deleted.'})
                    }
                });
        },
        async submitHandler() {
            const {isSuccess, errors, data} = await vehicle.storeTruck(this.moduleUrl, this.form)
            if (isSuccess) {
                this.form = {}
                this.$router.push(`/${this.moduleUrl}/${data.id}/edit`)
            }
            else this.errors = errors
        },
        dataManager(data) {
            const direction = (data.sort_direction === 'desc' ? '-': '')
            this.sortField = `${direction}${data.sort_field}`
            this.currentPage = data.page
            this.perPage = data.paginate
            this.filterBy = data.queries
            this.search = data.search
            this.getData()
        },
        formatInspectionTime(item) {
            return moment(item.inspection_time).format('DD/MM/YYYY')
        },
        formatStatus(item) {
            return _.startCase(item.status)
        }
    }
};
</script>

<style>
table >  thead {
    white-space: nowrap;
}


</style>
