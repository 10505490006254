<template>
    <section class="vehicle-inspection-section">
        <div class="container">
            <div class="d-flex justify-content-center">
                <p class="h1 text-danger fw-bold my-5">Is your Driver Vehicle Inspection Report Safe? Is it done?</p>
            </div>
            <div class="row mb-3">
                <div class="col-md-6 d-flex justify-content-center mb-3 mb-sm-0">
                    <img alt="" class="rounded img-fluid" src="/img/inspection-image-1.png"/>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <p class="h4">
                        If you're trucking right now, you're probably out of <br>
                        compliance with Federal regulations and risking <br>
                        heavy fines every day. The DOT requires inspections of <br>
                        every vehicle & trailer, for every trip. It also requires <br>
                        that 3 signatures be on the report IF defects are found <br>
                        on your DVIR.
                    </p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-md-6 d-flex align-items-start order-sm-2">
                    <p class="h4">
                        Just for DVIRs, your fine can be: <br>
                        <span class="fw-bold">Up to $1,270 /day</span> for failing to complete a DVIR <br>
                        <span class="fw-bold">Up to $12,700</span> for falsifying a DVIR <br>
                        <span class="fw-bold">Up to $15,420 </span> for failing to repair a reported defect. <br>
                        <br>
                        Why would you trust a "bubble" for something so valuable?
                    </p>
                </div>
                <div class="col-md-6 d-flex justify-content-center mb-3 mb-sm-0 order-sm-1">
                    <img alt="" class="rounded img-fluid" src="/img/inspection-image-2.png"/>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center text-center">
            <p class="h2 fw-bold mb-5 mt-2">NOW ALL OWNERS AND DRIVERS HAVE AN ALTERNATIVE TO<span class="text-danger">OOS</span>
                <br>
                VIOLATIONS AND EXPENSIVE TRACKING DEVICES.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: "ReportSection"
}
</script>

<style scoped>

</style>
