import axiosUtils from "../../utils/axiosUtil";
import JsCookie from "js-cookie";

export default {
    async signInUser(url, payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post(`${url}`, payload)
            .then(async response => {
                requestResult.data = response.data.data;
                await JsCookie.set('token', response.data.data.access_token)
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async signUp(url, payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post(`${url}`, payload)
            .then(async response => {
                requestResult.data = response.data.data;
                await JsCookie.set('token', response.data.data.access_token)
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async signOutUser(url) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post(`${url}`)
            .then(async response => {
                requestResult.data = response.data.data;
                await JsCookie.remove('token')
                await JsCookie.remove('company_id', 1)
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async me() {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.get('/me')
            .then(response => {
                requestResult.data = response.data.data
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async resetPassword(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post(`/update-password`, payload)
            .then(async response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
}
