<script>
import {defineComponent} from 'vue';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default defineComponent({
    name: "YouCanSeeSection",
    components: { LottieAnimation }
})
</script>

<template>
    <div class="tw-bg-qvin-2">
        <div
            class="tw-bg-qvin-1 tw-w-full tw-relative tw-mt-20 md:tw-mt-32 lg:tw-mt-60 tw-rounded-br-[7rem]">
            <div class="tw-flex tw-justify-center tw-z-10">
                <div class="tw--top-32 tw-absolute">
                    <div
                        class="tw-px-5 md:tw-px-20 tw-py-5 text-center tw-shadow-2xl tw-rounded-lg tw-w-full tw-bg-white">
                        <p class="tw-uppercase tw-text-qvin-1 tw-text-2xl tw-font-bold my-0">You can see Qvin in action
                            and how it can work for you.</p>
                        <p class="tw-font-bold tw-text-2xl tw-text-qvin-1 tw-my-0">Call us today for a demo at</p>
                        <p class="tw-font-bold tw-text-4xl md:tw-text-5xl tw-text-sky-800 tw-my-1 tw-font-bold">
                            1-833-339-8434</p>
                    </div>
                </div>
            </div>
            <div class="tw-px-10 tw-py-32 md:tw-py-24 tw-z-0">
                <div class="text-center">
                    <p class="my-0">
                        <span class="tw-font-bold tw-text-4xl tw-text-white tw-mr-2 tw-my-0">Track your Fleet</span>
                        <span class="tw-font-bold tw-text-4xl tw-text-sky-800 my-0">Anytime</span>
                    </p>
                    <p class="my-0">
                        <span class="tw-font-bold tw-text-4xl tw-text-white tw-mr-2">and</span>
                        <span class="tw-font-bold tw-text-4xl tw-text-sky-800">Anywhere</span>
                    </p>
                    <p class="tw-text-white tw-mt-1 tw-font-bold">Q-VIN™ is the simple, reliable way to track your fleet
                        trips and stay legal, no matter where your vehicles go.</p>
                </div>

                <div class="lg:tw-mx-16 xl:tw-mx-24 2xl:tw-mx-40">
                    <div
                        class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-my-12 md:tw-my-32">
                        <div class="w-full md:tw-w-1/2 tw-font-bold tw-order-1 md:tw-order-0">
                            <p class="tw-my-0">
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800">Full-Time
                                    Tracking</span>
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white">for</span>
                            </p>
                            <p class="tw-my-0">
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white">Your Business
                                    With</span>
                            </p>
                            <p class="tw-my-0">
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white">Just a</span>
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800">Sticker</span>
                            </p>
                            <p class="tw-white tw-text-white tw-mt-10 w-full md:tw-w-3/4">Q-VIN™ uses a durable sticker
                                that attaches to your equipment and connects to our easy-to-use mobile app. Without
                                complicated hardware or installations, your drivers, managers, and staff gain real-time
                                oversight and alerts of your fleet right from their phones or computer.
                            </p>
                        </div>

                        <div
                            class="w-full md:tw-w-1/2 tw-flex tw-justify-center md:tw-justify-end tw-order-0 md:tw-order-1 tw-mx-10 md:tw-mx-0">
                            <img src="/img/phone-with-qr-code.png" alt="Phone with Qr Code" class="tw-h-80 lg:tw-h-96">
                        </div>
                    </div>

                    <div
                        class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-my-12 md:tw-my-32">
                        <div class="w-full md:tw-w-1/2 tw-flex tw-justify-center">
                            <div><lottie-animation path="img/truck.json" :height="600" /></div>
                        </div>
                        <div class="w-full md:tw-w-1/2">
                            <p class="tw-my-0">
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800">Simplified</span>
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white">Compliance</span>
                            </p>
                            <p class="tw-white tw-font-bold tw-text-white tw-mt-5 w-full md:tw-w-3/4">Q-VIN™ digitizes
                                mandatory DVIR and DOT inspections and records, removing painful paperwork. Drivers fill
                                out digital forms, and defects can be reported and repaired quickly. Complete every
                                required form, for every trip, with zero hassle and avoid $15,420 per violation.
                            </p>
                        </div>

                    </div>

                    <div
                        class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-my-12 md:tw-my-32">
                        <div class="w-full md:tw-w-1/2">
                            <p class="tw-my-0">
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800">Recover</span>
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white">Lots or</span>
                            </p>
                            <p class="tw-my-0">
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800">Stolen
                                    Equipments</span>
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white">Fast</span>
                            </p>
                            <p class="tw-white tw-font-bold tw-text-white tw-mt-5 w-full md:tw-w-3/4">If any of your
                                fleet does go missing, Q-VIN™ makes recovery quick and easy. Our public "Fleet Quest"
                                app crowdsources the search, allowing you to offer rewards for information that leads to
                                finding your lost equipment. By tapping into public help, you can locate misplaced
                                assets faster than ever.</p>
                        </div>
                        <div class="w-full md:tw-w-1/2 tw-flex tw-justify-center">
                            <div>
                                <lottie-animation path="img/connect.json" :height="400" />
                            </div>
                        </div>
                    </div>

                    <div
                        class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-my-12 md:tw-my-32">
                        <div class="w-full md:tw-w-1/2">
                            <img src="/img/paperworks.png" alt="Phone with Qr Code"
                                class="tw-h-72 md:tw-h-60 lg:tw-h-72 xl:tw-h-96">
                        </div>
                        <div class="w-full md:tw-w-1/2 tw-mt-2 lg:tw-mt-0">
                            <p class="tw-my-0">
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800">No more</span>
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white">Fines,</span>
                            </p>
                            <p class="tw-my-0">
                                <span class="tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white">Theft, and
                                    Paperwork</span>
                            </p>
                            <p class="tw-white tw-font-bold tw-text-white tw-mt-5 w-full md:tw-w-3/4">Q-VIN™ solves
                                major fleet headaches like non-compliance fines, theft, misuse, lost equipment, and
                                hours of tedious paperwork that drain time and money. With real-time tracking and
                                digitized reporting, you face less compliance risk, liability, and time wasted on manual
                                processes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
