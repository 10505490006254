<template>
    <div>
        <LandingPageNav />
        <YouCanSeeSection />
        <Process />

        <div class="tw-mx-10 lg:tw-mx-32">
            <div class="tw-flex tw-flex-col xl:tw-flex-row tw-justify-between tw-items-center">
                <div>
                    <p class="tw-text-4xl tw-font-black tw-my-0">Is your Driver Vehicle </p>
                    <div>
                        <span class="tw-text-4xl tw-font-black tw-my-0">Inspection Report</span>
                        <span class="tw-text-4xl tw-text-red-400 tw-font-black tw-my-0">safe?</span>
                    </div>
                    <div>
                        <span class="tw-text-4xl tw-font-black">is it </span>
                        <span class="tw-text-4xl tw-text-red-400 tw-font-black"> done?</span>
                    </div>
                    <p class="tw-my-2 tw-font-bold">
                        If you're trucking right now, you're probably out of
                        compliance with Federal regulations and risking heavy fines every day. The DOT requires
                        inspections of every vehicle & trailer, for every trip. It also requires that 3 signatures be on
                        the report IF defects are found on your DVIR.
                    </p>
                    <p class="tw-font-bold tw-my-0">Just for DVIRs, your fine can be:</p>
                    <p class="tw-my-0 tw-font-bold">Up to $12,700 for falsifying a DVIR</p>
                    <p class="tw-my-0 tw-font-bold">Up to $1,270 /day for failing to complete a DVIR</p>
                    <p class="tw-font-bold">Up to $15,420 for failing to repair a reported defect.</p>
                    <p class="tw-font-bold">Why would you trust a "bubble" for something so valuable?</p>
                </div>
                <div>
                    <img src="/img/conversion-to-qvin.png" alt="Conversion 1"
                        class="tw-h-[26.5rem] lg:tw-h-[35rem] xl:tw-h-[45rem]">
                </div>
            </div>
        </div>

        <div class="tw-mx-10 md:tw-mx-32 tw-mb-20">
            <div class="tw-font-black tw-text-3xl tw-text-center">NOW ALL OWNERS AND DRIVERS HAVE AN ALTERNATIVE TO
                <span class="tw-text-red-400">OOS</span> VIOLATIONS AND EXPENSIVE TRACKING DEVICES.
            </div>
        </div>

        <div class="xl:tw-mx-20 2xl:tw-mx-40 xl:tw-mb-10">
            <div
                class="tw-flex tw-justify-between md:tw-justify-around tw-px-10 tw-w-full tw-bg-qvin-2 xl:tw-rounded-3xl">
                <div class="tw-py-3">
                    <img src="/img/phone_mockup.png" alt="Phone Mock up"
                        class="tw-h-40 md:tw-h-80 lg:tw-h-[25rem] xl:tw-h-[35rem] tw-w-auto">
                </div>
                <div class="tw-flex tw-items-center">
                    <div class="tw-text-white">
                        <p class="tw-my-0 tw-text-2xl md:tw-text-4xl lg:tw-text-5xl xl:tw-text-6xl tw-font-bold">
                            Qvin&trade;</p>
                        <p class="tw-my-0 tw-font-bold">The DVIR app That Avoids Fines</p>
                        <p
                            class="tw-my-0 tw-text-2xl md:tw-text-4xl lg:tw-text-5xl xl:tw-text-6xl tw-font-bold tw-mt-3">
                            DOWNLOAD</p>
                        <p class="tw-my-0 tw-text-2xl md:tw-text-4xl lg:tw-text-5xl xl:tw-text-6xl tw-font-bold">THE APP
                            NOW</p>
                    </div>
                </div>
                <div class="tw-hidden lg:tw-flex tw-items-end">
                    <div
                        class="tw-flex tw-items-center tw-flex-col tw-space-y-2 tw-bg-white tw-px-0 tw-pb-32 tw-pt-10 tw-rounded-t-2xl">
                        <a target="_blank" class="text-center"
                            href="https://play.google.com/store/apps/details?id=com.dotready.qvin">
                            <img src="/img/google-playstore.png" class="tw-w-3/4 tw-cursor-pointer"
                                alt="Google Playstore">
                        </a>
                        <a target="_blank" class="text-center" href="https://apps.apple.com/ph/app/q-vin/id6463773695">
                            <img src="/img/apple-store.png" class="tw-w-3/4 tw-cursor-pointer" alt="Google Playstore">
                        </a>
                    </div>
                </div>
            </div>
            <div
                class="tw-flex lg:tw-hidden tw-items-center tw-flex-col tw-space-y-2 tw-bg-white tw-px-0 tw-pb-32 tw-pt-10 tw-rounded-t-2xl">
                <a target="_blank" class="text-center"
                    href="https://play.google.com/store/apps/details?id=com.dotready.qvin">
                    <img src="/img/google-playstore.png" class="tw-w-1/2 md:tw-w-2/5 tw-cursor-pointer"
                        alt="Google Playstore">
                </a>
                <a target="_blank" class="text-center" href="https://apps.apple.com/ph/app/q-vin/id6463773695">
                    <img src="/img/apple-store.png" class="tw-w-1/2 md:tw-w-2/5 tw-cursor-pointer"
                        alt="Google Playstore">
                </a>
            </div>
        </div>

        <QvinFooter />
    </div>
</template>

<script>
import LandingPageNav from "../Nav/LandingPageNav.vue";
import TradeShowLogos from "./TradeShowLogos.vue";
import Features from "./Features.vue"
import ReportSection from "./ReportSection.vue";
import Process from "./Process.vue";
import DownloadSection from "./DownloadSection.vue";
import AboutUs from "./AboutUs.vue";
import ContactUs from "./ContactUs.vue";
import Copyright from "./Copyright.vue";
import YouCanSeeSection from "./YouCanSeeSection.vue";
import QvinFooter from "./QvinFooter.vue";

export default {
    name: "Home.vue",
    components: {
        QvinFooter,
        YouCanSeeSection,
        Copyright,
        ContactUs,
        AboutUs,
        DownloadSection,
        Process,
        ReportSection,
        Features,
        TradeShowLogos,
        LandingPageNav
    }
}
</script>

<style scoped>

</style>
