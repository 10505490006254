<template>
    <div class="col">
        <div v-if="!loading">
            <p class="text-uppercase small font-weight-bold mb-2">Recent Location</p>
            <div v-if="location" class="card tw-h-80">
                <div class="card-body">
                    <google-map :origin="center" v-slot="{ directionsURL }">
                        <iframe style="height: 100%; width: 100%" id="map" :src=" directionsURL " frameborder="0"></iframe>
                    </google-map>
                </div>
            </div>
            <div v-else class="text-center">
                <p>No Recent Location</p>
            </div>
        </div>
        <div v-else>
            <div class="d-flex justify-content-center align-items-center map-div">
                <Spinner message="Loading map" />
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import Spinner from "vue-simple-spinner";
import GoogleMap from "../GoogleMap.vue";

export default defineComponent({
    props: {
        location: {
            default: null
        },
    },
    name: "TruckProfileMap",
    components: {
        Spinner,
        GoogleMap
    },
    data() {
        return {
            center: {
                lat: 0,
                lng: 0
            },
            locations: [],
            currentLocation: null,
            infoOptions: {
                content: '',
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            loading: true,
            lists: [],
            dataCount: 0
        };
    },
    created() {
        this.setLocationLatLng();
    },
    methods: {
        setLocationLatLng() {
            this.loading = true;

            if(this.location){
                this.center = {
                    lat: _.toNumber(this.location.lat),
                    lng: _.toNumber(this.location.lng)
                }
            }
            this.loading = false;
        },
        toggleInfoWindow: function (marker, idx) {
            this.infoWindowPos = { lat: marker.lat, lng: marker.lng };
            this.infoOptions.content = marker.infoText;

            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;

            }
        }
    }
})
</script>

<style scoped>
.map-div {
    width: 100%;
    height: 415px;
}
</style>
