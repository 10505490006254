<template>
    <div>
        <SearchableSelect :disabled="disabled" ref="fleetSelect" v-on:input="handleChange" :options="optionList">
        </SearchableSelect>
    </div>
</template>

<script>
import SearchableSelect from "../SearchableSelect.vue";
import axiosUtils from "../../../utils/axiosUtil";
import Spinner from "vue-simple-spinner";

export default {

    data: function () {
        return {
            optionList: [],
            selectedOption: this.value,
            loaded: false
        }
    },

    props: {
        value: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fleetId: {
            default: null
        }
    },

    components: {
        SearchableSelect,
        Spinner

    },

    created: function () {
        this.getFleets();
    },
    watch: {

        selectedOption: function (newVal, oldVal) {
            this.$emit('input', newVal);
            // this.$emit('company', _.find(this.optionList, ['value', newVal]))
        },

        value: function (newVal, oldVal) {
            this.selectedOption = newVal;
            this.$refs.fleetSelect.setSelection(newVal);
        },

        fleetId: function (newVal, oldVal) {
            this.getFleets();
        },

        excludeLocationId: function () {
            this.getFleets();
        }

    },

    methods: {

        handleChange: function (event) {
            if (this.loaded) {
                this.selectedOption = event;
            }
        },

        getFleets: function () {
            let vm = this;
            let requestParams = {};

            if (!_.isNull(vm.fleetId)) {
                requestParams.team_id = vm.fleetId;
            }

            vm.optionList = [];
            axiosUtils.get(`/fleets`)
                .then(fleets => {
                    _.each(fleets.data.data, function (fleet) {
                        vm.optionList.push({ label: fleet.name, value: fleet.id });
                        vm.loaded = true;
                    })
                }).finally(() => {
                    this.$refs.fleetSelect.setSelection(this.selectedOption)
                })
        }

    }

}
</script>
