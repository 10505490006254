import Vue from "vue";

import BootstrapVue from "bootstrap-vue";
import router from "./router";
import {store} from './store'
import Notifications from 'vue-notification';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import * as VueGoogleMaps from "vue2-google-maps"

import AdminContainer from "./components/containers/AdminContainer.vue";
import App from "./App.vue";
import Home from "./components/landingPage/Home.vue";
import Login from "./pages/Login.vue";
import VueApexCharts from 'vue-apexcharts'
import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import './bootstrap'
import DriverAccountCreation from "./pages/Driver/DriverAccountCreation.vue";
import UserAccountCreation from "./pages/User/UserAccountCreation.vue";

console.log(process.env.MIX_GOOGLE_MAP_KEY, 'key')

Vue.use(BootstrapVue)
Vue.use(Notifications)
Vue.use(VuejsDialog)
Vue.use(VueGoogleMaps, {
    load: {
        key: `${process.env.MIX_GOOGLE_MAP_KEY}`,
        libraries: "places"
    }
});


Vue.component('landing-page', Home)
Vue.component('admin', AdminContainer)
Vue.component('login', Login)
Vue.component('driver-account-creation', DriverAccountCreation)
Vue.component('user-account-creation', UserAccountCreation)
Vue.component('app', App)


const app = new Vue({
    el: '#app',
    router,
    store
});
