<template>
    <div>
        <SearchableSelect :disabled="disabled" ref="driverSelect" v-on:input="handleChange" :options="optionList"></SearchableSelect>
    </div>
</template>

<script>
import SearchableSelect from "../SearchableSelect.vue";
import axiosUtils from "../../../utils/axiosUtil";
import Spinner from "vue-simple-spinner";

export default {

    data: function () {
        return {
            optionList: [],
            selectedOption: this.value,
            loaded: false
        }
    },

    props: {
        value: {
            default: null
        },
        companyId: {
            default: null
        },
        excludeLocationId: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    components: {
        SearchableSelect,
        Spinner

    },

    created: function () {
        this.getCompanies();
    },
    watch: {

        selectedOption: function (newVal, oldVal) {
            this.$emit('input', newVal);
            // this.$emit('company', _.find(this.optionList, ['value', newVal]))
        },

        value: function (newVal, oldVal) {
            this.selectedOption = newVal;
            this.$refs.driverSelect.setSelection(newVal);
        },

        companyId: function (newVal, oldVal) {
            this.getCompanies();
        },

        excludeLocationId: function () {
            this.getCompanies();
        }

    },

    methods: {

        handleChange: function (event) {
            if (this.loaded) {
                this.selectedOption = event;
            }
        },

        getCompanies: function () {
            let vm = this;
            let requestParams = {};

            if (!_.isNull(vm.companyId)) {
                requestParams.company_id = vm.companyId;
            }

            vm.optionList = [];

            let company = vm.companyId ? `?company=${vm.companyId}` : '';
            axiosUtils.get(`/lookup/drivers${company}`)
                .then(drivers => {
                    _.each(drivers.data, function (driver) {
                        // const name = `${driver.first_name} ${driver.last_name}`
                        const name = driver.name;
                        vm.optionList.push({label: name, value: driver.id});
                        vm.loaded = true;
                    })
            }).finally(() => {
                this.$refs.driverSelect.setSelection(this.selectedOption)
            })
        }

    }

}
</script>
