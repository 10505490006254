<template>
    <div>
        <Nav bg="blue"/>
        <div class="tw-mt-20">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center lg:tw-space-x-34">
                <div class="w-full lg:tw-w-3/4 tw-order-1 lg:tw-order-0">
                    <div
                        class="tw-bg-qvin-1 tw-py-8 md:tw-px-10 w-full xl:tw-w-4/5 tw-rounded-br-[6rem] tw-mb-20 tw-flex tw-justify-center tw-items-center">
                        <div class="tw-px-5 md:tw-px-0">
                            <p class="tw-text-white tw-text-2xl md:tw-text-4xl tw-font-bold">FAQs</p>
                            <p class="tw-text-white tw-w-3/4 md:w-full">Everything you need to know about Q-VIN™. Can't
                                find the answer
                                you're looking for? Please chat with our friendly team.</p>
                        </div>
                        <img alt="apple store" class="tw-h-20 lg:tw-h-52 tw-w-auto" src="/img/faq.png">
                    </div>
                </div>
            </div>
            <h1 class="tw-text-center tw-my-10 tw-font-bold tw-text-6xl">FAQ coming soon...</h1>
<!--            <div class="tw-max-w-screen-xl tw-mx-auto tw-my-10 tw-px-5 tw-bg-white tw-min-h-sceen">-->
<!--                <div class="tw-grid tw-divide-y tw-divide-neutral-200 tw-max-w-xl tw-mx-auto tw-mt-8">-->
<!--                    <div class="tw-py-5">-->
<!--                        <details class="tw-group">-->
<!--                            <summary-->
<!--                                class="tw-flex tw-justify-between tw-items-center tw-font-medium tw-cursor-pointer tw-list-none">-->
<!--                                <span> What is a SAAS platform?</span>-->
<!--                                <span class="tw-transition tw-group-open:tw-rotate-180">-->
<!--                                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor"-->
<!--                                         stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path-->
<!--                                        d="M6 9l6 6 6-6"></path>-->
<!--                                    </svg>-->
<!--                                </span>-->
<!--                            </summary>-->
<!--                            <p class="tw-text-neutral-600 tw-mt-3 tw-group-open:tw-animate-fadeIn">-->
<!--                                SAAS platform is a cloud-based software service that allows users to access-->
<!--                                and use a variety of tools and functionality.-->
<!--                            </p>-->
<!--                        </details>-->
<!--                    </div>-->
<!--                    <div class="tw-py-5">-->
<!--                        <details class="tw-group">-->
<!--                            <summary-->
<!--                                class="tw-flex tw-justify-between tw-items-center tw-font-medium tw-cursor-pointer tw-list-none">-->
<!--                                <span> What is a SAAS platform?</span>-->
<!--                                <span class="tw-transition tw-group-open:tw-rotate-180">-->
<!--                                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor"-->
<!--                                     stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path-->
<!--                                    d="M6 9l6 6 6-6"></path>-->
<!--                                </svg>-->
<!--                              </span>-->
<!--                            </summary>-->
<!--                            <p class="tw-text-neutral-600 tw-mt-3 tw-group-open:tw-animate-fadeIn">-->
<!--                                SAAS platform is a cloud-based software service that allows users to access-->
<!--                                and use a variety of tools and functionality.-->
<!--                            </p>-->
<!--                        </details>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="w-full tw-my-3 tw-bg-qvin-1 tw-h-fit tw-text-white tw-font-bold tw-mt-10 xl:tw-mt-0">
            <div class="tw-bg-qvin-2 tw-px-10 tw-py-20">
                <div
                    class="tw-flex tw-flex-col md:tw-flex-row tw-justify-center md:tw-justify-between xl:tw-mx-32 tw-items-center">
                    <div>
                        <p class="tw-text-2xl md:tw-text-6xl tw-w-3/4">Join thousands of satisfied customers using
                            Q-VIN&trade;</p>
                        <p class="tw-font-bold">Questions? Call us at 1-833-339-8434 or email info@q-vin.com</p>
                    </div>
                    <a class="tw-py-2 tw-w-52 text-center tw-px-3 tw-rounded tw-bg-white tw-border-0 tw-text-sm tw-text-qvin-1 tw-font-bold tw-no-underline hover:tw-no-underline"
                       href="/book-a-demo">Book A Demo</a></div>
            </div>
        </div>
        <QvinFooter/>
    </div>
</template>

<script>
import {ServiceFactory} from "../utils/ServiceFactory";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import {Header as AppHeader,} from "@coreui/vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";

const authService = ServiceFactory.get('auth')

export default {
    name: 'Faq',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
    },
    data() {
        return {
            email: '',
            password: '',
            password_confirmation: '',
            first_name: '',
            last_name: '',
            errors: null,
            counter: 5,
            selectedPayment: 'monthly',
            starterItems: [
                'Qvin Set Included',
                'Odometer & Fuel Entries',
                'Vehicle Groups and Teams',
                'Expiration Reminder',
                'Qvin Set Included',
                'Vehicle Assignment and Scheduling',
                'Vehicle & Trailer Custom Field',
            ]
        }
    },
    methods: {
        handleRedirect() {
            this.$router.push('/pricing')
        }
    }
}
</script>

<style scoped>
.top-feature {
    font-size: 12px;
}

.feature-description {
    font-size: 14px;
}

</style>
