<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between my-2">
            <h1 class="tw-font-bold tw-text-2xl">Drivers</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/invite-drivers" role="button"><i class="fa fa-plus"></i> Invite Drivers</router-link>
            </div>
        </div>
        <b-card>
            <div class="d-flex justify-content-start gap-3 mb-3">
                <div>
                    <select class="form-select" aria-label="Default select example" v-model="filterBy">
                        <option selected value="" disabled>Filter By...</option>
                        <option :value="item.value" v-for="(item, index) in filterOptions" :key="index">{{ item.label }}</option>
                    </select>
                </div>

                <div v-if="filterBy">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                        <input v-model="search" @input="debounceInput" type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                </div>
            </div>
            <data-table
                ref="userTable"
                :columns="fields"
                default_sort_direction=""
                default_sort_field=""
                :show_entries="[10,15,20,50,75,100]"
                :show_entries_default="15"
                url="trucks"
                :items="data"
                @getData="dataManager"
                :loading="loading"
            >
                <template v-slot:status="{item}">
                    <p>{{formatStatus(item)}}</p>
                </template>
                <template v-slot:action="{item}">
                    <button class="btn btn-info btn-sm text-white" @click="editHandler(item)"><i class="fa fa-edit"></i></button>
                    <button class="btn btn-danger btn-sm" @click="deleteHandler(item)"><i class="fa fa-trash"></i></button>
                </template>
            </data-table>
        </b-card>
    </div>
</template>

<script>
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import _ from "lodash";
import Spinner from 'vue-simple-spinner';

import {ServiceFactory} from "../../utils/ServiceFactory";
import dataTableLocal from "../../components/dataTable/DataTableLocal.vue";
import dataTable from "../../components/dataTable/DataTable.vue";
import axiosUtils from "../../utils/axiosUtil";
const driver = ServiceFactory.get('users')

export default {
    name: "Drivers",
    components: {
        dataTable,
        dataTableLocal,
        Pagination,
        Spinner
    },

    data() {
        return {
            fields: [
                { label: 'Name', field: 'name', sortField: 'first_name' },
                { label: 'Phone', field: 'phone_number', sortField: 'phone_number' },
                { label: 'Email', field: 'email', sortField: 'email' },
                { label: 'Company', field: 'company_name', sortField: 'company_name' },
                { label: 'Fleet', field: 'fleet', sortField: 'fleet' },
                { label: 'Action', field: 'action' },
            ],
            filterOptions: [
                {value: 'name', label: 'Name'},
                {value: 'email', label: 'Email'},
            ],
            perPage: 15,
            data: [],
            currentPage:1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false,
            loadingDotReady:false
        };
    },

    async created() {
        await this.getData()
    },
    computed: {
        role() {
            return this.$store.getters["user/getRoles"]
        },
        canGetDotReady() {
            return _.includes(['admin', 'fleet_owner'], this.role)
        }
    },
    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        getSortParam(sortOrder) {
            console.log(sortOrder)
        },
        changePage(page) {
            this.currentPage=page
            this.getData()
        },
        getData() {
            this.loading = true

            const filter = this.filterBy !== '' ? `&filter[search]=${this.filterBy},${this.search}` : ''
            const buildUrl = `/invite-drivers?per_page=${this.perPage}&page=${this.currentPage}&sort=${this.sortField || ''}${filter}`;

             axiosUtils.get(buildUrl)
                .then(response => {
                    this.data = response.data
                })
                .catch(error => {
                    this.$notify({
                        type: 'danger',
                        text: 'Something Went Wrong.'
                    });
                });

            this.loading = false
        },
        dataManager(data) {
            const direction = (data.sort_direction === 'desc' ? '-': '')
            this.sortField = `${direction}${data.sort_field}`
            this.perPage = data.paginate
            this.currentPage = data.page
            this.filterBy = data.queries
            this.search = data.search
            this.getData()
        },
        editHandler(data) {
            this.$router.push(`/drivers/${data.id}/edit`)
        },
        addDotReadyHandler(data) {
            window.location = process.env.MIX_DOT_READY_URL
        },
        async deleteHandler(data) {
            const vm = this
            this.$dialog.confirm('Are you sure, you want to delete this item?')
                .then(async function (dialog) {
                    const { isSuccess } = await driver.delete(data.id, 'drivers')
                    if (isSuccess) {
                        await vm.getData()
                        vm.$notify({type: 'success', 'text': 'Driver successfully deleted.'})
                    }
                });
        },
        showHandler() {
            window.location =  `${process.env.MIX_DOT_READY_URL}`
        },
        formatStatus(item) {
            let status = '-'
            if (item.status == '1') {
                status = 'Applicant'
            } else if (item.status == '2') {
                status = 'Employee'
            }
            return status
        }
    }
};
</script>
