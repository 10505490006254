<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between">
            <h1 class="tw-font-bold tw-text-2xl">{{editing ? 'Edit' : 'Add'}} {{isAdmin ? 'Admin' : 'Company'}} User</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/adminusers" role="button"><i class="fa fa-arrow-left"></i>  Back to list</router-link>
            </div>
        </div>
        <b-card>
            <div class="w-50">
                <DisplayError :errors="errors" />
            </div>
            <form class="w-50" @submit.prevent="submitHandler">
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">First Name</label>
                    <input v-model="form.first_name" type="text" class="form-control" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Last Name</label>
                    <input v-model="form.last_name" type="text" class="form-control" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Nick Name</label>
                    <input v-model="form.nickname" type="type" class="form-control" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Email</label>
                    <input v-model="form.email" type="email" class="form-control">
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Role</label>
                    <select name="roles" id="roles" class="form-select" v-model="form.role">
                        <option value="" selected>Please select a value</option>
                        <option :value="role.name" selected v-for="(role, index) in roles" :key="index">{{formatRole(role.name)}}</option>
                    </select>
                </div>
                <button type="submit" class="btn btn-info text-white">Save User</button>
            </form>

        </b-card>
    </div>
</template>

<script>
import {ServiceFactory} from "../../utils/ServiceFactory";
import DisplayError from "../../components/common/DisplayError.vue";

const user = ServiceFactory.get('admin')
const lookup = ServiceFactory.get('lookup')
export default {
    name: "FormAdmin",
    components: {
        DisplayError
    },
    data() {
        return {
            form: {
                first_name: null,
                last_name: null,
                nickname: null,
                email: null,
                role: null
            },
            roles: null,
            errors: null
        }
    },
    created() {
       this.getRoles()
       if (this.editing) {
           this.getUserDetail()
       }
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        },
        isAdmin() {
            return this.$store.getters["user/getRoles"] === 'admin'
        }
    },
    methods: {
        async getRoles() {
            const {isSuccess, data} = await lookup.show('roles')
            if (isSuccess) this.roles = data
            else this.$notify({type: 'error', text: 'Something went wrong.'})

        },
        async getUserDetail() {
            const {isSuccess, data} = await user.showUser(this.$route.params.id)
            if (isSuccess) {
                this.form.first_name = data.first_name
                this.form.last_name = data.last_name
                this.form.nickname = data.nickname
                this.form.email = data.email
                this.form.role = data.roles
            }
        },
        async submitHandler() {
            if (this.editing) await this.updateAdminUser()
            else await this.addAdminUser()
        },
        async addAdminUser() {
            const {isSuccess, errors} = await user.storeUser(this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Admin user successfully created.'})
                this.form = {}
                this.$router.push('/adminusers')
            }
            else this.errors = errors
        },
        async updateAdminUser() {
            const {isSuccess, errors} = await user.updateUser(this.$route.params.id, this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Admin user successfully updated.'})
                this.form = {}
                this.$router.push('/adminusers')
            }
            else this.errors = errors
        },
        formatRole(role) {
           return _.startCase(role)
        }
    }
}
</script>

<style scoped>

</style>
