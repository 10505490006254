<template>
    <div v-if="loaded">
        <div class="d-flex justify-content-between">
            <h1 class="tw-font-bold tw-text-2xl">{{module.slice(0, -1)}} Profile</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" :to="`/${moduleUrl}`" role="button"><i
                        class="fa fa-arrow-left"></i> Back to list</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col col-lg-3">
                <div class="mb-3">
                    <label for="vehicleName" class="form-label">Name</label>
                    <input v-model="form.name" type="text" class="form-control border"
                        :class="{'border-danger' :(errors && errors.errors.name)}" aria-describedby="emailHelp">
                </div>

                <div class="mb-3">
                    <label for="vehicleCompany" class="form-label">Company</label>
                    <CompanySelect v-model="form.company_id" :value="form.company_id"
                        :class="{ 'border border-danger': (errors && errors.errors.company_id) }" />
                </div>

                <div class="mb-3">
                    <label for="vehicleLocation" class="form-label">Location</label>
                    <LocationSelect v-model="form.location_id" :value="form.location_id" :companyId="form.company_id"
                        :class="{'border border-danger' :(errors && errors.errors.location_id)}" />
                </div>

                <div class="mb-3">
                    <label for="vehicleType" class="form-label">Vehicle Type</label>
                    <VehicleTypeSelect v-model="form.vehicle_type" :value="form.vehicle_type"
                        :class="{'border border-danger' :(errors && errors.errors.vehicle_type)}" />
                </div>

                <div class="mb-3" v-if="module=='Trucks'">
                    <label for="vehicleTrailer" class="form-label">Trailer</label>
                    <TrailerSelect v-model="form.trailer_id" :value="form.trailer_id"
                        :class="{'border border-danger' :(errors && errors.errors.trailer_id)}"
                        :companyId="form.company_id" />
                </div>

                <b-form-group>
                    <b-form-radio-group v-model="form.cmv">
                        <b-form-radio value="1">CMV</b-form-radio>
                        <b-form-radio value="0">Non-CMV</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group>
                    <b-form-radio-group v-model="form.ownership">
                        <b-form-radio value="Leased">Leased</b-form-radio>
                        <b-form-radio value="Owned">Owned</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>

                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Year</label>
                    <year-select v-model="form.year"></year-select>
                </div>

                <div class="mb-3">
                    <div class="tw-flex tw-justify-between">
                        <label for="exampleInputEmail1" class="form-label">Make</label>
                        <label>
                            <input type="checkbox" v-model="other_make"> Other
                        </label>
                    </div>
                    <MakeSelect v-model="form.make_id" :type="type" v-if="!other_make" />
                    <EditableField v-model.lazy="form.other_make" type="text"
                        :class="{ 'border-danger': (errors && errors.errors.other_make) }" aria-describedby="emailHelp"
                        v-else></EditableField>
                </div>

                <div class="mb-3">
                    <div class="tw-flex tw-justify-between">
                        <label for="exampleInputEmail1" class="form-label">Model</label>
                        <label>
                            <input type="checkbox" v-model="other_model"> Other
                        </label>
                    </div>
                    <ModelSelect v-model="form.model_id" v-if="!other_model" />
                    <EditableField v-model.lazy="form.other_model" type="text"
                        :class="{ 'border-danger': (errors && errors.errors.other_model) }" aria-describedby="emailHelp"
                        v-else></EditableField>
                </div>

                <template v-if="module=='Trucks'">
                    <b-form-group label="ELD/AOBRD Make (As Applicable)">
                        <EditableField v-model="form.eld_aobrd_make"></EditableField>
                    </b-form-group>

                    <b-form-group label="ELD/AOBRD Model (As Applicable)">
                        <editable-field v-model="form.eld_aobrd_model"></editable-field>
                    </b-form-group>
                    <b-form-group label="ELD/AOBRD Year (As Applicable)">
                        <editable-field v-model="form.eld_aobrd_year"></editable-field>
                    </b-form-group>
                </template>
                <b-form-group label="Mileage" v-if="module == 'Trucks'">
                    <editable-field v-model="form.mileage" type="numeric"></editable-field>
                </b-form-group>
                <b-form-group label="Tire Size">
                    <editable-field v-model="form.tire_size"></editable-field>
                </b-form-group>
                <b-form-group label="GVWR">
                    <editable-field v-model="form.gvwr"></editable-field>
                </b-form-group>

                <b-form-group label="Owner">
                    <editable-field v-model="form.owner"></editable-field>
                </b-form-group>
                <b-form-group label="VIN #">
                    <editable-field v-model="form.vin"></editable-field>
                </b-form-group>
                <b-form-group label="Plate #">
                    <editable-field v-model="form.plate"></editable-field>
                </b-form-group>

                <b-form-group label="Purchase Date">
                    <editable-field v-model="form.purchased_at" type="maskedDate"
                        :value="form.purchased_at"></editable-field>
                </b-form-group>

                <b-form-group label="Comments">
                    <editable-field v-model="form.comments" type="textarea" :value="form.comments"></editable-field>
                </b-form-group>

            </div>
            <div class="col col-lg-9">
                <b-tabs>
                    <b-tab title="General" active>
                        <div class="row">
                            <b-col class=" text-center">
                                <b-form-group label="Status" class="mb-0" label-class="font-weight-bold  mb-0 py-1">
                                    <div class="p-2">
                                        <status-select v-model="form.status"></status-select>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="text-center">
                                <b-form-group label="Drivers" class="mb-0" label-class="font-weight-bold mb-0 py-1">
                                    <div class="p-2">
                                        <driver-select v-model="form.driver_id"
                                            :companyId="form.company_id"></driver-select>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </div>
                        <div class="gap-2 my-5 row">
                            <TruckProfileMap :location="form.recent_location" />
                        </div>
                        <div class="row gap-2">
                            <vehicle-file-listing :vehicle-id="form.id" :vehicle-type="vehicleEntityType"
                                :companyId="form.company_id" :type="form.type"></vehicle-file-listing>
                        </div>
                    </b-tab>
                    <b-tab title="Inspection">
                        <inspection-list />
                    </b-tab>
                    <b-tab title="Work Orders">
                        <work-order-list />
                    </b-tab>
                    <b-tab title="Code">
                        <div class="row">
                            <button type="button" @click="generateCode()"
                                class="btn btn-info ml-auto mr-2 text-white w-25" :disabled="checking"><i
                                    class="fa fa-gears"></i> Generate
                                Code</button>
                        </div>
                        <div class="row">
                            <b-form-group label="QVIN Code">
                                <editable-field v-model="form.qvin_code"></editable-field>
                            </b-form-group>
                            <b-form-group label="Current Mileage">
                                <editable-field v-model="form.current_mileage"></editable-field>
                            </b-form-group>
                        </div>
                        <div class="row text-center" v-if="form.qvin_code">
                            <qrcode-vue :value="qrCode()" size="200" level="H"></qrcode-vue>
                        </div>
                        <div class="row" v-if="isAdmin">
                            <GPSLocationList :type="type" :vehicleId="form.id" />
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <div>
            <b-modal ref="upgrade-modal" hide-footer title="Upgrade Your Plan?" centered
                header-class="font-weight-bold upgrade-modal">
                <div class="d-block text-center">
                    <p class="font-weight-bold mt-2">
                        You have no more vehicles left in your plan. This will cost an additional ${{ additionalAmount
                        }} on
                        your {{ billing_period }}
                        subscription. Are you sure you want to add this vehicle?
                    </p>
                    <img src="/images/qvin-truck.png" alt="QVIN" style="height: 6rem" class="my-3" />
                    <div class="justify-content-around tw-flex">
                        <button type="button" class="btn btn-info text-white w-25" @click="upgradePlan()">Ok</button>
                        <button type="button" class="btn btn-light w-25" @click="cancel()">Cancel</button>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import ToggleButton from "../../components/common/ToggleButton.vue";
import SearchableSelect from "../../components/common/SearchableSelect.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import {ServiceFactory} from "../../utils/ServiceFactory";
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import DisplayError from "../../components/common/DisplayError.vue";
import VehicleTypeSelect from "../../components/common/selects/VehicleTypeSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import TrailerSelect from "../../components/common/selects/TrailerSelect.vue";
import YearSelect from "../../components/common/selects/YearSelect.vue";
import MakeSelect from "../../components/common/selects/MakeSelect.vue";
import StatusSelect from "../../components/common/selects/StatusSelect.vue";
import DriverSelect from "../../components/common/selects/DriverSelect.vue";
import ModelSelect from "../../components/common/selects/ModelSelect.vue";
import InspectionList from "../../components/common/lists/InspectionList.vue";
import WorkOrderList from "../../components/common/lists/WorkOrderList.vue";
import EditableField from "../../components/common/EditableField.vue";
import VehicleFileListing from "../VehicleFileListing.vue";
import GPSLocationList from "./GPSLocationList.vue";
import TruckProfileMap from "./TruckProfileMap.vue";
import moment from "moment";
import _ from "lodash";
import QrcodeVue from 'qrcode.vue';
const vehicle = ServiceFactory.get('vehicle')

export default {
    components: {
        ModelSelect,
        EditableField,
        MakeSelect,
        YearSelect,
        StatusSelect,
        VehicleTypeSelect,
        DisplayError,
        LocationSelect,
        SearchableSelect,
        ToggleButton,
        VuePhoneNumberInput,
        VehicleFileListing,
        InspectionList,
        WorkOrderList,
        DriverSelect,
        CompanySelect,
        TrailerSelect,
        QrcodeVue,
        GPSLocationList,
        TruckProfileMap
    },
    name: "FormTruck",
    data() {
        return {
            form: {
                id:null,
                name: null,
                type: null,
                comments: null,
                location_id: null,
                vehicle_type: null,
                cmv: null,
                ownership: null,
                year:null,
                make_id: null,
                model_id: null,
                eld_aobrd_make: null,
                eld_aobrd_model: null,
                eld_aobrd_year: null,
                tire_size: null,
                gvwr: null,
                owner: null,
                vin: null,
                plate: null,
                purchased_at: null,
                registration_state_id: null,
                status: null,
                drives: null,
                company_id: null,
                qvin_code: null,
                current_mileage: null,
                qvin_url: null,
                trailer_id: null,
                other_model: null,
                other_make: null,
                driver_id: null,
                mileage: null,
                recent_location: null
            },
            equipment_type: [
                { text: 'Large Equipment', value: 'large_equipment' },
                { text: 'Small Equipment', value: 'small_equipment' }
            ],
            errors: null,
            loaded: false,
            other_make: false,
            other_model: false,
            plan_rate: 0,
            billing_period: 'monthly',
            checking: false,
        }
    },
    async created() {
        this.loaded = false
        if (this.editing) {
            await this.getDetails()
        }
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        },
        vehicleEntityType() {
            if (this.vehicleType === 'trailer') return 'vehicle';
            return this.vehicleType;
        },
        moduleUrl() {
            return _.lowerCase(this.$route.name)
        },
        module() {
            return this.$route.name
        },
        type(){
            return this.$route.name === "Trucks" ? 'vehicle' : 'trailer'
        },
        additionalAmount(){
            let multiplier = this.billing_period === 'monthly' ? 1 : 11;
            return this.plan_rate * multiplier;
        },
        isAdmin() {
            return this.$store.getters["user/getRoles"] === 'admin'
        }
    },
    watch: {
        form: {
            handler: function(newVal, oldVal) {
                if (!this.loaded) return;
                this.updateTruck();
            },
            deep: true
        },
        other_make(n, o) {
            if(this.other_make) {
                this.form.make_id = null
            }else this.form.other_make = null;
        },
        other_model(n, o) {
            if (this.other_model) {
                this.form.model_id = null
            } else this.form.other_model = null;
        },
    },
    methods: {
        async getDetails() {
            const {isSuccess, data} = await vehicle.showTruck(this.moduleUrl, this.$route.params.id)
            if (isSuccess) {
                _.each(data, (value, key) => {
                    if (value) {
                        if (key == 'type') {
                           this.form[key]  = _.snakeCase(data[key])
                        } else {
                            this.form[key] = data[key]
                        }
                    }
                    if (key === "other_make" && value) this.other_make = true;
                    if (key === "other_model" && value) this.other_model = true;
                })
            }
            this.loaded = true
        },
        async submitHandler() {
            if (this.editing) await this.updateEquipment()
            else await this.addEquipment()
        },
        async updateTruck() {
            const {isSuccess, errors} = await vehicle.updateTruck(this.moduleUrl, this.$route.params.id, this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Truck successfully updated.'})
                // this.form = {}
                // this.getDetails()
                // location.reload()
                // this.$router.push('/trucks')
            }
            else this.errors = errors
        },
        hasError(name) {
            return _.has(this.errors, name)
        },
        qrCode() {
            this.form.qvin_url = `https://q-vin.com/${this.form?.qvin_code}`
             return this.form.qvin_url;
        },
        async generateCode(){
            if(!this.form.vin) this.$notify({type: 'warning', text: 'Please enter VIN # before generating code.'})
            if(!this.form.qvin_code){
                this.checking = true;
                const { isSuccess, data } = await vehicle.checkPlan(`${this.moduleUrl}/check-plan`, this.$route.params.id)
                if (isSuccess) {
                    if (data?.has_remaining){
                        this.generate();
                    }else{
                        this.plan_rate = data?.plan_rate;
                        this.billing_period = data?.billing_period;
                        this.$refs['upgrade-modal'].show();
                    }
                }
                this.checking = false;
            }else this.generate();
        },
        generate(){
            var hash = this.cyrb53(this.form.vin);
            this.form.qvin_url = `https://q-vin.com/${hash}`
            this.form.qvin_code = hash;
        },
        cyrb53 : function(str, seed = 0) {
            let h1 = 0xdeadbeef ^ seed,
                h2 = 0x41c6ce57 ^ seed;
            for (let i = 0, ch; i < str.length; i++) {
                ch = str.charCodeAt(i);
                h1 = Math.imul(h1 ^ ch, 2654435761);
                h2 = Math.imul(h2 ^ ch, 1597334677);
            }

            h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
            h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

            return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        },
        cancel(){
            this.$refs['upgrade-modal'].hide();
        },
        async upgradePlan(){
            const { isSuccess, data } = await vehicle.upgradePlan(`${this.moduleUrl}/upgrade-plan`, this.form.company_id)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Plan successfully upgraded.'})
                this.generate();
                this.$refs['upgrade-modal'].hide();
            }
        }
    }
}
</script>

<style scoped>
:deep(.upgrade-modal .modal-title) {
    text-align: center !important;
    width: 100% !important;
    font-weight: 700 !important;
}
</style>
