import axiosUtils from "../../utils/axiosUtil";

export default {
    async getUsers(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        const filter = payload.filterBy !== '' ? `&filter[search]=${payload.filterBy},${payload.search}` : ''
        const buildUrl = `/users?per_page=${payload.perPage}&page=${payload.currentPage}&sort=${payload.sortField || ''}${filter}`;

        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = response.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },

    async storeUser(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post('/users', payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },

    async updateUser(id, payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.put(`/users/${id}`, payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },


    async deleteUser(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.delete(`/users/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },

    async showUser(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.get(`/users/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },

    async resendInvite(id){
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post('/user/resend-invite', {user_id : id})
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            }).catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            })
    }
}
