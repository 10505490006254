<template>
	<div>
		<b-button @click.prevent="$refs.addFolderModal.show()">
      <i class="fa fa-plus"></i>
      <span> Add Folder</span>
    </b-button>
		<b-modal :busy="loading" v-on:hidden="resetFields" v-on:ok="createFolder" ref="addFolderModal" centered title="Add Folder">
			<b-form-group label="Name" class="mb-4">
				<b-form-input v-model="name" :state="errors.name ? false : null" placeholder="Folder name" autofocus></b-form-input>
				<b-form-invalid-feedback>{{ errors.name ? errors.name[0] : '' }}</b-form-invalid-feedback>
			</b-form-group>
			<b-form-group>
				<b-form-checkbox v-model="showAlerts" switch>
					Show expiration alerts for files in this folder
				</b-form-checkbox>
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>
export default {

  data: function () {
    return {
      loading: false,
      modalShown: false,
      name: null,
      showAlerts: false,
      errors: {}
    }
  },

  props: {
    entityType: {
      type: String,
      required: true
    },
    companyId: {
      default: null
    }
  },

  methods: {
    resetFields() {
      this.errors = {};
      this.name = null;
      this.showAlerts = false;
    },

    showModal() {
      this.$refs.addFolderModal.show();
    },

    createFolder(event) {
      event.preventDefault();

      const params = {
        name: this.name,
        show_alerts: this.showAlerts,
        entity_type: this.entityType,
        company_id: this.companyId
      }

      this.errors = {};
      this.loading = true;

      this.$store.dispatch('createFolder', params).then(() => {
        this.$emit('refresh', true);
        this.$refs.addFolderModal.hide();
        this.$notify({
          type: 'success',
          text: 'Folder created successfully!'
        });
      }).catch(error => {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      }).finally(() => {
        this.loading = false;
      })
    }
  }

}
</script>
