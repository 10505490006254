<script>
import {defineComponent} from 'vue';
import { ServiceFactory } from "../../utils/ServiceFactory";
import JsCookie from "js-cookie";

const authService = ServiceFactory.get('auth')
export default defineComponent({
    props: ['bg'],
    name: "Nav",
    data() {
        return {
            authenticated: false,
        }
    },
    computed: {
        currentRoute() {
            return this.$route.path
        }
    },
    created() {
        const hasToken = JsCookie.get('token')
        if (hasToken) this.authenticated = true;
    },
    methods: {
        handleRedirect() {
            this.$router.push('/login')
        },
        async logout() {
            const { isSuccess } = await authService.signOutUser('/signout')
            if (isSuccess) window.location.href = '/pricing';
            else this.$notify({ type: 'error', text: 'Something went wrong.' })
        }
    }
})
</script>

<template>
    <nav class="lg:tw-mx-10 navbar navbar-expand-lg text-darker lg:bg-qvin-white py-0">
        <!-- Container for positioning -->
        <a class="navbar-brand" href="/"><img alt="qvin logo" src="/img/QVIN-Logo-no-with-tm.png"
                style="height: 75px; width: 75px"></a>
        <div class="d-flex align-items-center gap-3">
            <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                class="navbar-toggler mr-1 tw-border tw-bg-qvin-1" data-bs-target="#navbarSupportedContent"
                data-bs-toggle="collapse" type="button" style="color: white!important;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="tw-w-8 tw-h-8 tw-text-white">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </button>
        </div>

        <div id="navbarSupportedContent" class="collapse navbar-collapse mb-0">
            <div class="d-none d-md-block d-lg-block mb-2  ms-auto  mb-lg-0 mx-5">
                <div class="tw-flex tw-justify-center tw-items-center tw-space-x-12">
                    <div>
                        <a class="tw-no-underline hover:tw-no-underline tw-font-bold text-dark" href="/"
                            :class="`${currentRoute === '/' ? 'pb-1 border-bottom border-info': ''}`">What is
                            QVIN&trade;?</a>
                    </div>
                    <!--                        <div>-->
                    <!--                            <a class="tw-no-underline hover:tw-no-underline tw-font-bold text-dark" href="/download" :class="`${currentRoute === '/download' ? 'pb-1 border-bottom border-info': ''}`">Download</a>-->
                    <!--                        </div>-->
                    <div>
                        <a class="tw-no-underline hover:tw-no-underline tw-font-bold text-dark" href="/pricing"
                            :class="`${currentRoute === '/pricing' ? 'pb-1 border-bottom border-info': ''}`">Pricing</a>
                    </div>
                    <div>
                        <a class="tw-no-underline hover:tw-no-underline tw-font-bold text-dark" href="/faq"
                            :class="`${currentRoute === '/faq' ? 'pb-1 border-bottom border-info': ''}`">About Us</a>
                    </div>
                    <div>
                        <a class="tw-no-underline hover:tw-no-underline tw-font-bold text-dark" href="/contact-us"
                            :class="`${currentRoute === '/contact-us' ? 'pb-1 border-bottom border-info': ''}`">Contact
                            Us</a>
                    </div>

                </div>

            </div>

            <!--    Mobile       -->
            <div class="d-block d-md-none ms-auto  mb-lg-0 tw-bg-white tw-py-2">
                <div class="navbar-nav ms-auto mb-lg-0 text-center tw-bg-white">
                    <div class="tw-bg-qvin-1 tw-mx-3 tw-p-4 w-full tw-rounded-lg">
                        <a class="tw-p-4 w-full tw-text-white tw-text-xl tw-font-bold tw-no-underline tw-focus:tw-text-white"
                            href="/">What is Q-VIN?</a>
                    </div>
                    <!--                    <div class="tw-bg-qvin-1 tw-mx-3 tw-p-4 w-full tw-rounded-lg tw-my-1">-->
                    <!--                        <a class="tw-p-4 w-full tw-text-white tw-text-xl tw-font-bold tw-no-underline tw-focus:tw-text-white" href="/download">Download</a>-->
                    <!--                    </div>-->
                    <div class="tw-bg-qvin-1 tw-mx-3 tw-p-4 w-full tw-rounded-lg tw-my-1">
                        <a class="tw-p-4 w-full tw-text-white tw-text-xl tw-font-bold tw-no-underline tw-focus:tw-text-white"
                            href="/pricing">Pricing</a>
                    </div>

                    <div class="tw-bg-qvin-1 tw-mx-3 tw-p-4 w-full tw-rounded-lg tw-my-1">
                        <a class="tw-p-4 w-full tw-text-white tw-text-xl tw-font-bold tw-no-underline tw-focus:tw-text-white"
                            href="/faq">About Us</a>
                    </div>

                    <div class="tw-bg-qvin-1 tw-mx-3 tw-p-4 w-full tw-rounded-lg tw-my-1">
                        <a class="tw-p-4 w-full tw-text-white tw-text-xl tw-font-bold tw-no-underline tw-focus:tw-text-white"
                            href="/contact-us">Contact Us</a>
                    </div>
                </div>

            </div>

            <ul class="d-none d-md-block d-lg-block navbar-nav ms-auto mb-2 mb-lg-0 mx-5 tw-cursor-pointer">
                <li class="mx-3" v-if="!authenticated" @click="handleRedirect">
                    <div class="tw-z-20 tw-px-10 tw-py-2 tw-rounded-3xl tw-shadow tw-bg-white tw-border-2 tw-border-qvin-1 tw-text-qvin-1 tw-font-bold tw-cursor-pointer"
                        v-if="bg=='white'">Login</div>
                    <div class="tw-z-20 tw-px-10 tw-py-2 tw-rounded-3xl tw-shadow tw-bg-qvin-1 tw-text-white tw-border-2 tw-border-qvin-1 tw-text-qvin-1 tw-font-bold tw-cursor-pointer"
                        v-else>Login</div>
                </li>
                <li v-else @click="logout">
                    <div class="tw-z-20 tw-px-10 tw-py-2 tw-rounded-3xl tw-shadow tw-bg-white tw-border-2 tw-border-qvin-1 tw-text-qvin-1 tw-font-bold tw-cursor-pointer"
                        v-if="bg == 'white'">Logout</div>
                    <div class="tw-z-20 tw-px-10 tw-py-2 tw-rounded-3xl tw-shadow tw-bg-qvin-1 tw-text-white tw-border-2 tw-border-qvin-1 tw-text-qvin-1 tw-font-bold tw-cursor-pointer"
                        v-else>Logout</div>
                </li>
            </ul>

        </div>
    </nav>
</template>

<style scoped>

</style>
