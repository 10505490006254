<template>
    <div class="d-flex justify-content-center align-items-center">
        <div class="card mt-5 w-50">
            <div class="card-header"><i class="fa fa-file"></i> Request History</div>
            <div class="card-body">
                <form @submit.prevent="handleSubmit">
                    <div v-if="errors" class="alert alert-danger">
                        <ul class="tw-text-sm">
                            <li v-for="(error, index) in errors.errors">{{ error[0] }}</li>
                        </ul>
                    </div>
                    <p class="text-center font-weight-bold">This request for:</p>
                    <div class="mt-3 d-flex justify-content-center align-items-center gap-2">
                        <label class="radio-inline">
                            <input v-model="form.request_for" value="Last DVIR" type="radio" name="request_for" checked> Last DVIR
                        </label>
                        <label class="radio-inline">
                            <input v-model="form.request_for" value="Document" type="radio" name="request_for"> Documents
                        </label>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label font-weight-bold">Extra comment for the request:</label>
                        <div class="col-sm-10">
                            <textarea class="form-control" cols="5" rows="5" v-model="form.comment"></textarea>
                        </div>
                    </div>
                    <p class="text-center font-weight-bold">Send via link</p>
                    <div class="mt-3 d-flex justify-content-center align-items-center gap-2">
                        <label class="radio-inline">
                            <input v-model="form.send_via" value="sms" type="radio" name="optradio" checked> Text/SMS
                        </label>
                        <label class="radio-inline">
                            <input v-model="form.send_via" value="email" type="radio" name="optradio"> Email
                        </label>
                    </div>
                    <div class="mt-2 d-flex justify-content-center align-items-center">
                        <button type="submit" class="btn btn-primary">Request Information</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import VehicleSelect from "../../components/common/selects/VehicleSelect.vue";
import TrailerSelect from "../../components/common/selects/TrailerSelect.vue";
import axiosUtils from "../../utils/axiosUtil";

export default defineComponent({
    name: "FormRequestHistory",
    components: {TrailerSelect, VehicleSelect},
    data() {
        return {
            form: {
                send_via: '',
                request_for: '',
                comment: ''
            },
            errors: null
        }
    },
    methods: {
        handleSubmit () {
            axiosUtils.put(`/request-history/${this.$route.params.driver_id}`, this.form)
                .then(response => {
                    this.$notify({ type: 'success', 'text': 'Request information sent.' });
                    console.log(response);
                    this.$router.push(`/drivers/${this.$route.params.driver_id}/edit`)
                }).catch(errors => {
                    this.errors = errors.response.data;
                    this.$notify({ type: 'error', 'text': 'Request information failed.' });
                })
        }
    }
})
</script>
<style scoped>

</style>
