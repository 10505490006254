<template>
  <div class="flex flex-col mt-2">

    <!-- filters   -->
    <div slot="filters" class="mb-2">
      <slot name="filters"></slot>
      <p class="underline mt-2 mx-2 font-weight-bold cursor-pointer" @click="clearFilter" v-if="search">Clear filter</p>
    </div>
    <!-- end filters   -->

    <!--  selected item indicator  -->
    <div class="d-flex gap-3">
      <div v-if="checked.length > 0" class="flex items-center space-x-1">
        <button class="bg-gray-700 text-gray-100 text-sm py-1 px-4 rounded" v-if="showExport">Export</button>
        <p class="text-sm px-2" v-if="!selectPage">Selected items <span class="font-bold">{{ checked.length }}</span></p>
      </div>
    </div>

    <div v-if="selectPage">
      <div v-if="selectAllPage">
        <p class="text-sm">You are currently selecting all <span class="font-bold">{{ checked.length }}</span> items</p>
      </div>
      <div v-else>
        <p class="text-sm">
          You have selected <span class="font-bold">{{ checked.length }}</span> items, Do you want to select all items
          {{ items.meta.total }}?
          <a class="text-blue-600 font-bold" href="#" @click.prevent="selectAll">Select All</a>
        </p>
      </div>
    </div>
    <!--  end selected item indicator  -->

    <div class="">
      <div class="py-2 min-w-full sm:px-6 lg:px-8" v-if="items">
        <div class="table-responsive">
          <table class="table table-hover table-striped">
            <thead class="bg-white border-b">
            <tr>
              <th class="text-xs text-blue-600 px-6 font-extrabold text-left" scope="col" v-if="show_checked">
                <input v-model="selectPage" type="checkbox" value="">
              </th>
              <th v-for="(column, index) in columns" :key="index"  scope="col">
                <div class="d-flex gap-1">
                  <p v-if="column.sortField" class="underline cursor-pointer uppercase px-2" @click="changeSort(column.sortField)">{{ column.label }}</p>
                  <p v-else class="underline cursor-pointer uppercase px-2">{{ column.label }}</p>
                  <div v-if="column.sortField">
                    <span v-if="column.sortField !== undefined && sort_field != column.sortField"><i class="fa fa-sort text-secondary"></i></span>
                    <span v-if="sort_direction == 'desc' && sort_field == column.sortField ">&uarr;</span>
                    <span v-if="sort_direction == 'asc' && sort_field == column.sortField ">&darr;</span>
                  </div>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <template  v-if="!loading">
                <tr
                    v-for="(item, index) in items.data"
                    :key="index"
                    :class="rowClass(item.id)"
                >
                    <td class="w-10" v-if="show_checked"><input v-model="checked" :value="item.id" type="checkbox"/></td>
                    <template v-for="(column, index) in columns" >
                        <td :key="index">
                            <p v-if="checkSlots(column.field)">
                                <slot :item="item" :name="column.field"></slot>
                            </p>
                            <p v-else> {{ item[column.field] }}</p>
                        </td>
                    </template>
                </tr>
            </template>
            <template v-else>
                <tr><td class="text-center" :colspan="columns?.length"><Spinner class="mb-2"/> Loading...</td></tr>
            </template>
            <template v-if="items.data?.length == 0">
                <tr><td class="text-center" :colspan="columns?.length">No data found!</td></tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mx-10 gap-3" v-if="items">
        <div class="d-flex gap-2 align-items-center">
          <span class="text-sm">Show</span>
          <select id="per_page" v-model="paginate" class="form-select-sm" name="per_page">
            <option v-for="(entry, index) in showEntries" :key="index" :value="entry">{{ entry }}</option>
          </select>
          <span>entries</span>
        </div>
        <div class="d-flex justify-content-between align-items-center gap-3">
            <p v-if="items.meta"><span class="font-weight-bold">total:</span> {{items.meta?.total}} item/s</p>
            <Pagination :data="items" :limit="5" @pagination-change-page="getData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Pagination from './LaravelVuePagination';
  import axiosUtils from "../../utils/axiosUtil";
  import Spinner from "vue-simple-spinner";
  export default  {
      components: {
        Pagination,
        Spinner
      },
     props: {
         show_entries: {
             type: Array
         },
         columns: {
             type: Array
         },
         url: {
             type: String,
         },
         show_entries_default: Number,
         default_sort_field: String,
         default_sort_direction: String,
         filter: Object,
         showExport: {
             type: Boolean,
             default: false
         },
         items: {},
         show_checked: {
             type: Boolean,
             default: false
         },
         loading: {
             type: Boolean,
             default: false
         }
     },
     data() {
          return {
              paginate: this.show_entries_default,
              search: "",
              showEntries: this.show_entries,
              checked: [],
              selectPage: false,
              selectAllPage: false,
              selectType: "",
              sort_field: this.default_sort_field,
              sort_direction: this.default_sort_direction,
          }
      },
      watch: {
         paginate: function() {
             this.getData();
         },
         selectPage: function(value) {
             this.checked = [];
             if (value) {
                 this.items.data.forEach(item => {
                     this.checked.push(item.id)
                 })
             } else {
                 this.checked = [];
                 this.selectAllPage = false
             }
         }
      },
      methods: {
          rowClass (id) {
              return this.isChecked(id) ? 'bg-info' : 'bg-white';
          },
          selectAll() {
             this.selectType = "all"
             this.getData()
          },
          getData(page = 1) {
              // this.loading = true;

              const params = {
                  page: page,
                  paginate: this.paginate,
                  queries: this.search,
                  selectType: this.selectType,
                  sort_direction: this.sort_direction,
                  sort_field: this.sort_field,
              }

              this.$emit('getData', params)
          },
          isChecked (id) {
              return this.checked.includes(id)
          },
          changeSort(field) {
              if (this.sort_field === field) {
                  this.sort_direction = this.sort_direction === "asc" ? "desc" : "asc";
              } else {
                  this.sort_field = field
                  this.sort_direction = 'asc'
              }
              this.getData()
          },
          checkSlots(slot) {
              return !!this.$scopedSlots[slot]
          }
      }
  }
</script>

<style>
    .w-10 {
        width: 80px;
    }
</style>
