<template>
  <div>
    <searchable-select v-model="selectedOption" ref="timezoneSelect" :options="optionList"></searchable-select>
  </div>
</template>

<script>
import SearchableSelect from '../SearchableSelect';

export default {

  data: function () {
    return {
      optionList: [
        {label: 'Alaska', value: 'America/Anchorage'},
        {label: 'Hawaii', value: 'Pacific/Honolulu'},
        {label: 'Pacific Standard Time', value: 'America/Los_Angeles'},
        {label: 'Mountain Standard Time', value: 'America/Denver'},
        {label: 'Mountain Standrd Time (Arizona)', value: 'America/Phoenix'},
        {label: 'Central Standard Time', value: 'America/Chicago'},
        {label: 'Eastern Standard Time', value: 'America/New_York'}
      ],
      selectedOption: this.value
    }
  },

  props: {
    value: {
      default: null
    }
  },

  components: {
    SearchableSelect
  },

  watch: {

    selectedOption: function (newVal, oldVal) {
      this.$emit('input', newVal);
    },

    value: function (newVal, oldVal) {
      this.$refs.timezoneSelect.setSelection(newVal);
    }

  },

  methods: {


  }
}
</script>
