var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-bg-qvin-2"},[_c('div',{staticClass:"tw-bg-qvin-1 tw-w-full tw-relative tw-mt-20 md:tw-mt-32 lg:tw-mt-60 tw-rounded-br-[7rem]"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"tw-px-10 tw-py-32 md:tw-py-24 tw-z-0"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"lg:tw-mx-16 xl:tw-mx-24 2xl:tw-mx-40"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-my-12 md:tw-my-32"},[_c('div',{staticClass:"w-full md:tw-w-1/2 tw-flex tw-justify-center"},[_c('div',[_c('lottie-animation',{attrs:{"path":"img/truck.json","height":600}})],1)]),_vm._v(" "),_vm._m(3)]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-my-12 md:tw-my-32"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"w-full md:tw-w-1/2 tw-flex tw-justify-center"},[_c('div',[_c('lottie-animation',{attrs:{"path":"img/connect.json","height":400}})],1)])]),_vm._v(" "),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-justify-center tw-z-10"},[_c('div',{staticClass:"tw--top-32 tw-absolute"},[_c('div',{staticClass:"tw-px-5 md:tw-px-20 tw-py-5 text-center tw-shadow-2xl tw-rounded-lg tw-w-full tw-bg-white"},[_c('p',{staticClass:"tw-uppercase tw-text-qvin-1 tw-text-2xl tw-font-bold my-0"},[_vm._v("You can see Qvin in action\n                        and how it can work for you.")]),_vm._v(" "),_c('p',{staticClass:"tw-font-bold tw-text-2xl tw-text-qvin-1 tw-my-0"},[_vm._v("Call us today for a demo at")]),_vm._v(" "),_c('p',{staticClass:"tw-font-bold tw-text-4xl md:tw-text-5xl tw-text-sky-800 tw-my-1 tw-font-bold"},[_vm._v("\n                        1-833-339-8434")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-4xl tw-text-white tw-mr-2 tw-my-0"},[_vm._v("Track your Fleet")]),_vm._v(" "),_c('span',{staticClass:"tw-font-bold tw-text-4xl tw-text-sky-800 my-0"},[_vm._v("Anytime")])]),_vm._v(" "),_c('p',{staticClass:"my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-4xl tw-text-white tw-mr-2"},[_vm._v("and")]),_vm._v(" "),_c('span',{staticClass:"tw-font-bold tw-text-4xl tw-text-sky-800"},[_vm._v("Anywhere")])]),_vm._v(" "),_c('p',{staticClass:"tw-text-white tw-mt-1 tw-font-bold"},[_vm._v("Q-VIN™ is the simple, reliable way to track your fleet\n                    trips and stay legal, no matter where your vehicles go.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-my-12 md:tw-my-32"},[_c('div',{staticClass:"w-full md:tw-w-1/2 tw-font-bold tw-order-1 md:tw-order-0"},[_c('p',{staticClass:"tw-my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800"},[_vm._v("Full-Time\n                                Tracking")]),_vm._v(" "),_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white"},[_vm._v("for")])]),_vm._v(" "),_c('p',{staticClass:"tw-my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white"},[_vm._v("Your Business\n                                With")])]),_vm._v(" "),_c('p',{staticClass:"tw-my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white"},[_vm._v("Just a")]),_vm._v(" "),_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800"},[_vm._v("Sticker")])]),_vm._v(" "),_c('p',{staticClass:"tw-white tw-text-white tw-mt-10 w-full md:tw-w-3/4"},[_vm._v("Q-VIN™ uses a durable sticker\n                            that attaches to your equipment and connects to our easy-to-use mobile app. Without\n                            complicated hardware or installations, your drivers, managers, and staff gain real-time\n                            oversight and alerts of your fleet right from their phones or computer.\n                        ")])]),_vm._v(" "),_c('div',{staticClass:"w-full md:tw-w-1/2 tw-flex tw-justify-center md:tw-justify-end tw-order-0 md:tw-order-1 tw-mx-10 md:tw-mx-0"},[_c('img',{staticClass:"tw-h-80 lg:tw-h-96",attrs:{"src":"/img/phone-with-qr-code.png","alt":"Phone with Qr Code"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full md:tw-w-1/2"},[_c('p',{staticClass:"tw-my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800"},[_vm._v("Simplified")]),_vm._v(" "),_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white"},[_vm._v("Compliance")])]),_vm._v(" "),_c('p',{staticClass:"tw-white tw-font-bold tw-text-white tw-mt-5 w-full md:tw-w-3/4"},[_vm._v("Q-VIN™ digitizes\n                            mandatory DVIR and DOT inspections and records, removing painful paperwork. Drivers fill\n                            out digital forms, and defects can be reported and repaired quickly. Complete every\n                            required form, for every trip, with zero hassle and avoid $15,420 per violation.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full md:tw-w-1/2"},[_c('p',{staticClass:"tw-my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800"},[_vm._v("Recover")]),_vm._v(" "),_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white"},[_vm._v("Lots or")])]),_vm._v(" "),_c('p',{staticClass:"tw-my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800"},[_vm._v("Stolen\n                                Equipments")]),_vm._v(" "),_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white"},[_vm._v("Fast")])]),_vm._v(" "),_c('p',{staticClass:"tw-white tw-font-bold tw-text-white tw-mt-5 w-full md:tw-w-3/4"},[_vm._v("If any of your\n                            fleet does go missing, Q-VIN™ makes recovery quick and easy. Our public \"Fleet Quest\"\n                            app crowdsources the search, allowing you to offer rewards for information that leads to\n                            finding your lost equipment. By tapping into public help, you can locate misplaced\n                            assets faster than ever.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-my-12 md:tw-my-32"},[_c('div',{staticClass:"w-full md:tw-w-1/2"},[_c('img',{staticClass:"tw-h-72 md:tw-h-60 lg:tw-h-72 xl:tw-h-96",attrs:{"src":"/img/paperworks.png","alt":"Phone with Qr Code"}})]),_vm._v(" "),_c('div',{staticClass:"w-full md:tw-w-1/2 tw-mt-2 lg:tw-mt-0"},[_c('p',{staticClass:"tw-my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-sky-800"},[_vm._v("No more")]),_vm._v(" "),_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white"},[_vm._v("Fines,")])]),_vm._v(" "),_c('p',{staticClass:"tw-my-0"},[_c('span',{staticClass:"tw-font-bold tw-text-3xl md:tw-text-4xl tw-text-white"},[_vm._v("Theft, and\n                                Paperwork")])]),_vm._v(" "),_c('p',{staticClass:"tw-white tw-font-bold tw-text-white tw-mt-5 w-full md:tw-w-3/4"},[_vm._v("Q-VIN™ solves\n                            major fleet headaches like non-compliance fines, theft, misuse, lost equipment, and\n                            hours of tedious paperwork that drain time and money. With real-time tracking and\n                            digitized reporting, you face less compliance risk, liability, and time wasted on manual\n                            processes.")])])])
}]

export { render, staticRenderFns }