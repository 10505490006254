<template>
    <div>
        <Nav bg="blue" />
        <div class="container py-5">
            <div class="text-center">
                <p class="tw-cursor-pointer tw-text-qvin-2 tw-font-bold" @click="handleRedirect"><i class="fa fa-arrow-left"></i><span> Back to Pricing</span></p>
                <p class="tw-text-3xl tw-font-bold">Full Comparison</p>
                <p class="tw-text-gray-600 tw-text-sm">All plans include unlimited users, using our website and the Q-VIN™ app on iOS or Android with our amazing customer support.</p>
            </div>
            <div class="w-full md:tw-flex md:tw-justify-center">
                <table class="border tw-hidden lg:tw-block">
                    <thead>
                        <tr class="tw-text-white tw-bg-qvin-2 border-top">
                            <th class="tw-px-16 tw-py-6">Vehicle Inventory Management</th>
                            <th class="tw-px-16 tw-py-6">Starter</th>
                            <th class="tw-px-16 tw-py-6">Pro</th>
                            <th class="tw-px-16 tw-py-6">Advanced</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-bottom">
                            <td class="tw-px-5 tw-py-6 tw-font-bold">VIN LOOKUP*</td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        </tr>

                        <tr class="border-bottom">
                            <td class="tw-px-5 tw-py-6 tw-font-bold">VIN LOOKUP*</td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        </tr>

                        <tr class="border-bottom">
                            <td class="tw-px-5 tw-py-6 tw-font-bold">VIN LOOKUP*</td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        </tr>

                        <tr class="border-bottom">
                            <td class="tw-px-5 tw-py-6 tw-font-bold">VIN LOOKUP*</td>
                            <td class="tw-px-5 tw-py-6 text-center">---</td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                            <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        </tr>
                    </tbody>
                </table>
                <div class="border tw-block lg:tw-hidden">
                    <div>
                        <div class="tw-text-white tw-bg-qvin-2 border-top">
                            <p class="my-0 tw-pt-3 text-center">Starter</p>
                        </div>
                        <div class="tw-text-white tw-bg-qvin-2 border-top">
                            <p class="tw-py-2 text-left tw-px-5">Vehicle Inventory Management</p>
                        </div>
                        <div class="tw-flex tw-flex-col tw-justify-between tw-px-5">
                            <div class="tw-py-3 border-bottom tw-font-bold tw-flex tw-justify-between" v-for="(starterItem, index) in starterItems" :key="index"><span>{{starterItem}}</span> <i class="fa fa-check"></i></div>
                        </div>
                    </div>
                    <div>
                        <div class="tw-text-white tw-bg-qvin-2 border-top">
                            <p class="my-0 tw-pt-3 text-center">Pro</p>
                        </div>
                        <div class="tw-text-white tw-bg-qvin-2 border-top">
                            <p class="tw-py-2 text-left tw-px-5">Vehicle Inventory Management</p>
                        </div>
                        <div class="tw-flex tw-flex-col tw-justify-between tw-px-5">
                            <div class="tw-py-3 border-bottom tw-font-bold tw-flex tw-justify-between" v-for="(starterItem, index) in starterItems" :key="index"><span>{{starterItem}}</span> <i class="fa fa-check"></i></div>
                        </div>
                    </div>
                    <div>
                        <div class="tw-text-white tw-bg-qvin-2 border-top">
                            <p class="my-0 tw-pt-3 text-center">Advanced</p>
                        </div>
                        <div class="tw-text-white tw-bg-qvin-2 border-top">
                            <p class="tw-py-2 text-left tw-px-5">Vehicle Inventory Management</p>
                        </div>
                        <div class="tw-flex tw-flex-col tw-justify-between tw-px-5">
                            <div class="tw-py-3 border-bottom tw-font-bold tw-flex tw-justify-between" v-for="(starterItem, index) in starterItems" :key="index"><span>{{starterItem}}</span> <i class="fa fa-check"></i></div>
                        </div>
                    </div>
                    <div>
                        <div class="tw-text-white tw-bg-qvin-2 border-top">
                            <p class="my-0 tw-pt-3 text-center">Maintenance</p>
                        </div>
                        <div class="tw-text-white tw-bg-qvin-2 border-top">
                            <p class="tw-py-2 text-left tw-px-5">Vehicle Inventory Management</p>
                        </div>
                        <div class="tw-flex tw-flex-col tw-justify-between tw-px-5">
                            <div class="tw-py-3 border-bottom tw-font-bold tw-flex tw-justify-between" v-for="(starterItem, index) in starterItems" :key="index"><span>{{starterItem}}</span> <i class="fa fa-check"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-flex tw-justify-center">
                <table class="border tw-hidden lg:tw-block">
                    <thead>
                    <tr class="tw-text-white tw-bg-qvin-2 border-top">
                        <th class="tw-px-[8.3rem] tw-py-6">Maintenance</th>
                        <th class="tw-px-16 tw-py-6">Starter</th>
                        <th class="tw-px-16 tw-py-6">Pro</th>
                        <th class="tw-px-16 tw-py-6">Advanced</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-bottom">
                        <td class="tw-px-5 tw-py-6 tw-font-bold">VIN LOOKUP*</td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                    </tr>

                    <tr class="border-bottom">
                        <td class="tw-px-5 tw-py-6 tw-font-bold">VIN LOOKUP*</td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                    </tr>

                    <tr class="border-bottom">
                        <td class="tw-px-5 tw-py-6 tw-font-bold">VIN LOOKUP*</td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                    </tr>

                    <tr class="border-bottom">
                        <td class="tw-px-5 tw-py-6 tw-font-bold">VIN LOOKUP*</td>
                        <td class="tw-px-5 tw-py-6 text-center">---</td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                        <td class="tw-px-5 tw-py-6 text-center"><i class="fa fa-check"></i></td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <QvinFooter />
    </div>
</template>

<script>
import {ServiceFactory} from "../utils/ServiceFactory";

const authService = ServiceFactory.get('auth')
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import {
    Aside as AppAside,
    AsideToggler,
    Footer as TheFooter,
    Header as AppHeader,
    Sidebar as AppSidebar, SidebarFooter, SidebarMinimizer, SidebarNav, SidebarToggler
} from "@coreui/vue";
import DefaultAside from "../components/containers/DefaultAside.vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";

export default {
    name: 'FullComparison',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
    },
    data() {
        return {
            email: '',
            password: '',
            password_confirmation: '',
            first_name: '',
            last_name: '',
            errors: null,
            counter: 5,
            selectedPayment: 'monthly',
            starterItems: [
                'Qvin Set Included',
                'Odometer & Fuel Entries',
                'Vehicle Groups and Teams',
                'Expiration Reminder',
                'Qvin Set Included',
                'Vehicle Assignment and Scheduling',
                'Vehicle & Trailer Custom Field',
            ]
        }
    },
    methods: {
        handleRedirect() {
            this.$router.push('/pricing')
        }
    }
}
</script>

<style scoped>
    .top-feature {
       font-size: 12px;
    }

    .feature-description {
        font-size: 14px;
    }

</style>
