<template>
  <div class="d-flex">
    <div class="mr-3">
      <toggle-button color="#20a8d8" :disabled="disabled" v-model="currentValue" :sync="true"></toggle-button>
    </div>
    <div class="align-self-center pt-1" v-if="label">{{ label }}</div>
  </div>
</template>

<script>
import ToggleButton from 'vue-js-toggle-button/src/Button.vue';

export default {

  data: function () {
    return {
      currentValue: this.value
    }
  },

  props: {
    value: {
      default: false
    },
    label: {
      default: null
    },
    disabled: {
      default: false
    }
  },

  components: {
    ToggleButton
  },

  watch: {

    value: function (newVal, oldVal) {
      this.currentValue = newVal;
    },

    currentValue: function (newVal, oldVal) {
      this.$emit('input', newVal);
    }

  }

}
</script>

<style type="scss" scoped>
label.vue-js-switch {
  margin-bottom: 0;
}
</style>
