import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import adminPaths from "../NavItemPaths/admin-paths";
import JsCookie from "js-cookie";
import AdminContainer from "../components/containers/AdminContainer.vue";
import Login from "../pages/Login.vue";
import Home from "../components/landingPage/Home.vue";
import Signup from "../pages/Signup.vue";
import Pricing from "../pages/Pricing.vue";
import ContactUs from "../pages/ContactUs.vue";
import BookADemo from "../pages/BookADemo.vue";
import FullComparison from "../pages/FullComparison.vue";
import Faq from "../pages/Faq.vue";
import TermsOfService from "../pages/TermsOfService.vue";
import ShippingPolicy from "../pages/ShippingPolicy.vue";
import EULA from "../pages/EULA.vue";
import PrivacyPolicy from "../pages/PrivacyPolicy.vue";
import Checkout from "../pages/Checkout.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/signup',
            name: 'Login',
            component: Signup
        },
        {
            path: '/pricing',
            name: 'Pricing',
            component: Pricing
        },
        {
            path: '/checkout',
            name: 'Checkout',
            component: Checkout
        },
        {
            path: '/contact-us',
            name: 'ContactUs',
            component: ContactUs
        },
        {
            path: '/book-a-demo',
            name: 'BookADemo',
            component: BookADemo
        },
        {
            path: '/pricing-full-comparison',
            name: 'FullComparison',
            component: FullComparison
        },
        {
            path: '/faq',
            name: 'FAQ',
            component: Faq
        },
        {
            path: '/terms-of-service',
            name: 'Terms of Service',
            component: TermsOfService
        },
        {
            path: '/shipping-policy',
            name: 'Shipping Policy',
            component: ShippingPolicy
        },
        {
            path: '/privacy-policy',
            name: 'Privacy Policy',
            component: PrivacyPolicy
        },
        {
            path: '/eula',
            name: 'EULA',
            component: EULA
        },
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/admin',
            component: AdminContainer,
            children: adminPaths
        },
    ]
});


router.beforeEach((to, from, next) => {
    NProgress.start();
    const token = JsCookie.get('token');
    const publicPath = ['/', '/signup', '/pricing', '/contact-us', '/book-a-demo', '/pricing-full-comparison', '/faq', '/terms-of-service', '/invite-drivers', '/invite-user', '/shipping-policy', '/eula', '/privacy-policy', '/checkout']
    if (to.path !== '/login' && !publicPath.includes(to.path)) {
        if (!token) {
            next({ name: 'Login' })
        } else {
            next()
        }
    } else {
        next()
    }
});

router.afterEach((to, from) => {
    NProgress.done();
});

export default router;
