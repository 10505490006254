import axiosUtils from "../../utils/axiosUtil";

export default  {
    async getInspections(url, payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}
        const filter = payload.vehicle == undefined ? '' : `&filter[vehicle_id]=${payload.vehicle}`;
        const buildUrl = `/${url}?per_page=${payload.perPage}&page=${payload.currentPage}&sort=${payload.sortField || ''}${filter}`;

        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = response.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
}
