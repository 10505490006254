import authService from "../API/auth/authService";
import adminUserService from "../API/user/adminUserService";
import userService from "../API/user/userService";
import usersService from "../API/user/usersService";
import SubscriberService from "../API/subscriber/subscriberService";
import LookUpService from "../API/lookup/lookUpService";
import EquipmentService from "../API/equipment/equipmentService";
import TruckService from "../API/truck/truckService";
import InspectionsService from "../API/inspection/inspectionsService";
import WorkOrderService from "../API/workorder/workOrderService";
import LocationService from "../API/locations/locationService";
import FleetService from "../API/fleet/fleetService";
import DashboardService from "../API/dashboard/dashboardService";
import CompanyService from "../API/companies/companyService";

const services = {
    auth: authService,
    admin: adminUserService,
    user: userService,
    subscriber: SubscriberService,
    lookup: LookUpService,
    equipment: EquipmentService,
    vehicle: TruckService,
    inspection: InspectionsService,
    workOrder: WorkOrderService,
    location: LocationService,
    fleet: FleetService,
    dashboard: DashboardService,
    company: CompanyService,
    users: usersService
}

export const ServiceFactory = {
    get: name => services[name]
}
