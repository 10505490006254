<template>
    <div id="company-general-edit">
        <h2 class="tw-font-bold tw-text-2xl" v-if="!(this.company_id || this.isAdd)">{{editing ? 'Edit Company' : 'Add Company'}}</h2>
        <b-tabs>
            <b-tab title="General" active>
                <div v-if="company" v-bind:class="company.id ? 'company-edit-'+company.id : 'company-add'">

                    <b-form @submit.prevent="submitHandler">
                        <b-form-group label="Company Name">
                            <b-form-input v-model="company.name" name="name" size="md"
                                :state="errors.name ? false : null" placeholder="Company Name"></b-form-input>
                            <b-form-invalid-feedback>Company name is required.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label="Company DOT #">
                            <b-form-input v-model="company.dot_number" name="dot_number"
                                :state="errors.dot_number ? false : null" placeholder="Company DOT #"></b-form-input>
                            <b-form-invalid-feedback>Company DOT # is required.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label="Fleet">
                            <select name="team" id="team" class="form-select" v-model="company.team_id"
                                :disabled="fleet">
                                <option :value="fleet.id" selected v-for="(fleet, index) in fleets" :key="index">
                                    {{fleet.name}}</option>
                            </select>
                        </b-form-group>
                        <div class="d-flex justify-content-between">
                            <div>
                                <b-button type="submit" variant="primary">Save Changes</b-button>
                            </div>
                            <div v-if="isCompanyLoaded && (role === 'admin') && editing">
                                <b-button @click="deleteCompany" variant="outline-danger"
                                    v-b-modal.delete-company-modal>Delete Company</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>

            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import {ServiceFactory} from "../../utils/ServiceFactory";
const companyApi = ServiceFactory.get('company')
const fleet = ServiceFactory.get('fleet')
export default {

    data: function () {
        return {
            company: null,
            reassignCompanyId: null,
            errors: {},
            loaded: false,
            moduleName: 'Company',
            fleets: [],
        }
    },

    props: {
        company_id: {
            default: null
        },
        fleet: {
            default: null
        },
        isAdd:{
            type: Boolean,
            default: false
        }
    },

    components: {},

    created: function () {
        this.loadFleets();
        this.loadCompany();
    },

    computed: {

        isCompanyLoaded: function () {
            if (this.company && _.get(this.company, 'id', null)) {
                return true;
            }
            return false;
        },
        companyId() {
            return this.isAdd ? '' : this.company_id || this.$route?.params?.id
        },
        role() {
            return this.$store.getters["user/getRoles"];
        },
        editing() {
            return this.isAdd ? false : !!this.$route.params.id
        }

    },

    methods: {

        loadCompany: function () {
            let vm = this;
            vm.reassignCompanyId = null;
            if (vm.companyId) {
                companyApi.show(vm.companyId)
                    .then(company => {
                        vm.company = {
                            id : company.data.id,
                            name : company.data.name,
                            url : company.data.url,
                            team_id : company.data.team_id,
                            dot_number : company.data.dot_number,
                            address_addl_info : company.data.address_addl_info,
                            about_us : company.data.about_us,
                            consumer_report_authorization : company.data.consumer_report_authorization,
                            consumer_report_disclosure : company.data.consumer_report_disclosure,
                            investigative_consumer_report_disclosure : company.data.investigative_consumer_report_disclosure,
                        };
                    })
                    .catch(error => {

                    }).then(() => {

                });
            }
            else {
                vm.company = vm.makeEmptyCompany();
            }
        },

        loadFleets: function() {
            let vm = this;
            fleet.getFleets()
                .then(fleet => {
                    vm.fleets = fleet.data;
                })
                .catch(error => {

                }).then(() => {});
        },

        async updateCompany() {
            const {isSuccess, errors} = await companyApi.updateCompany(this.company.id, this.company)
            if (isSuccess) {
                this.$notify({type: 'success', text: `${this.moduleName} successfully updated.`})
                this.company = {}
                this.redirect();
            }
            else this.errors = errors
        },
        async submitHandler() {
            if (this.editing) await this.updateCompany()
            else await this.addCompany()
        },

        async addCompany() {
            const {isSuccess, errors} = await companyApi.storeCompany(this.company)
            if (isSuccess) {
                this.$notify({type: 'success', text: `${this.moduleName} successfully created.`})
                this.company = {}
                this.redirect();
            }
            else this.errors = errors
        },
        async deleteCompany() {
            const {isSuccess, errors} = await companyApi.deleteCompany(this.company.id)
            if (isSuccess) {
                this.$notify({type: 'success', text: `${this.moduleName} successfully deleted.`})
                this.company = {}
                this.$router.push('/companies')
            }
            else this.errors = errors
        },
        redirect(){
            if (this.company_id || this.isAdd) {
                this.$emit('reload');
            } else this.$router.push('/companies')
        },
        makeEmptyCompany: function () {
            return {
                id : null,
                name : null,
                url : null,
                team_id: this.fleet || null,
                dot_number : null,
                address_addl_info : null,
                about_us : null,
                consumer_report_authorization : null,
                consumer_report_disclosure : null,
                investigative_consumer_report_disclosure : null,
            }
        }

    },

    watch: {
        '$route': function (newVal, oldVal) {
            this.errors = {};
            this.loadCompany();
        }
    }

}
</script>
