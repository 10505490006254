<template>
    <div class="d-flex justify-content-center align-items-center">
        <div class="card mt-5 w-50">
            <div class="card-header"><i class="fa fa-car"></i> Send Driver Invite</div>
            <div class="card-body">
                <form @submit.prevent="handleSubmit">
                    <div v-if="errors && errors.errors" class="alert alert-danger">
                        <ul class="tw-text-sm mb-0">
                            <li v-for="(error, index) in errors.errors">{{ error[0] }}</li>
                        </ul>
                    </div>
                    <div class="mb-3 text-center" v-if="qr_path">
                        <img class="w-25" :src="qr_path">
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Name (required)</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="form.name" :disabled="success">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Phone # (required)</label>
                        <vue-phone-number-input class="col-sm-10" @update="onUpdate" v-model="phone" :disabled="success"
                            clearable valid-color="#68CD86" />
                    </div>
                    <div class=" form-group row">
                        <label class="col-sm-2 col-form-label">Email (required)</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" v-model="form.email" :disabled="success">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Company</label>
                        <div class="col-sm-10">
                            <company-select type="company" :multi="false" v-model="form.company_id"
                                :disabled="success"></company-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Truck</label>
                        <div class="col-sm-10">
                            <vehicle-select type="vehicle" :multi="false" v-model="form.truck_id"
                                :disabled="success"></vehicle-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Trailer</label>
                        <div class="col-sm-10">
                            <trailer-select type="trailer" v-model="form.trailer_id"
                                :disabled="success"></trailer-select>
                        </div>
                    </div>
                    <p class="text-center font-weight-bold">Send via link</p>
                    <div class="mt-3 d-flex justify-content-center align-items-center gap-2">
                        <label class="radio-inline">
                            <input v-model="form.send_via" value="sms" type="radio" name="optradio" checked
                                :disabled="success"> Text/SMS
                        </label>
                        <label class="radio-inline">
                            <input v-model="form.send_via" value="email" type="radio" name="optradio"
                                :disabled="success"> Email
                        </label>
                    </div>
                    <div class="mt-2 d-flex justify-content-center align-items-center">
                        <button type="submit" class="btn btn-primary" v-if="!success">Send Invite</button>
                    </div>

                </form>
                <div class="d-flex justify-content-center align-items-center">
                    <button type="button" @click="newInvite()" class="btn btn-primary mr-3" v-if="success">New
                        Invite</button>
                    <button type="button" @click="resendInvite()" class="btn btn-primary" v-if="success">Resend
                        Invite</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import VehicleSelect from "../../components/common/selects/VehicleSelect.vue";
import TrailerSelect from "../../components/common/selects/TrailerSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import axiosUtils from "../../utils/axiosUtil";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default defineComponent({
    name: "InviteDriver",
    components: { TrailerSelect, VehicleSelect, CompanySelect, VuePhoneNumberInput },
    data() {
        return {
            form: {
                name: '',
                phone_number: '',
                email: '',
                company_id: '',
                truck_id: '',
                trailer_id: '',
                send_via: ''
            },
            errors: null,
            success: false,
            qr_path: null,
            phone: '',
        }
    },
    methods: {
        handleSubmit () {
            axiosUtils.post('/invite-drivers', this.form)
                .then(response => {
                    console.log(response);
                    this.qr_path = response?.data?.data?.qr_path || null;
                    this.$notify({type: 'success', 'text': 'Driver successfully invited'});
                    this.errors = null;
                    this.success = true;
                }).catch(errors => {
                    this.errors = errors.response.data;
                    this.$notify({ type: 'error', 'text': 'Driver invite failed' });
                })
        },
        resendInvite () {
            axiosUtils.post('/resend-invite', this.form)
                .then(response => {
                    this.$notify({type: 'success', 'text': 'Invitation has been successfully resent.'});
                }).catch(errors => {
                    this.errors = errors.response.data;
                    this.$notify({ type: 'error', 'text': 'Driver resend invite failed' });
                })
        },
        newInvite() {
            this.success = false;
            this.qr_path = null;
            this.form = {
                name: '',
                phone_number: '',
                email: '',
                company_id: '',
                truck_id: '',
                trailer_id: '',
                send_via: ''
            };
            this.phone = '';
        },
        onUpdate(e){
            this.form.phone_number = e.formattedNumber || '';
        }
    }
})
</script>
<style scoped>
:deep(.input-tel input) {
    border: 1px solid #dee2e6 !important;
}
:deep(.input-tel__input::placeholder) {
    color: transparent !important;
}
:deep(.country-selector input) {
    border: 1px solid #dee2e6 !important;
}
</style>
