import Vue from 'vue';
import Vuex from 'vuex';

import user from "./modules/user";
import vehicle from "./modules/vehicles";
import folders from "./modules/folders";

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        user,
        vehicle,
        folders
    },
    // strict: debug
    // plugins: debug ? [createLogger()] : []
});
