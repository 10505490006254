<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between my-2">
            <h1 class="tw-font-bold tw-text-2xl">Settings</h1>
        </div>
        <b-card>
            <form @submit.prevent="submitHandler">
                <div class="w-100">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email Address</label>
                        <input id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control"
                            placeholder="Enter email" type="email" v-model="email" readonly>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Current Password</label>
                        <div class="input-group" id="show_hide_password">
                            <input id="exampleInputPassword1" class="form-control" placeholder="Password"
                                :type="viewCurrentPass ? 'text' : 'password'" v-model="current_password">
                            <div class="input-group-append" @click="viewCurrentPass = !viewCurrentPass">
                                <span class="input-group-text"><i
                                        :class="[viewCurrentPass ? 'fa fa-eye-slash' : 'fa fa-eye']"
                                        aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">New Password</label>
                        <div class="input-group" id="show_hide_password">
                            <input id="exampleInputPassword1" class="form-control" placeholder="Password"
                                :type="viewPassword ? 'text' : 'password'" v-model="password">
                            <div class="input-group-append" @click="viewPassword = !viewPassword">
                                <span class="input-group-text"><i
                                        :class="[viewPassword ? 'fa fa-eye-slash' : 'fa fa-eye']"
                                        aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Confirm New Password</label>
                        <div class="input-group" id="show_hide_password">
                            <input id="exampleInputPassword1" class="form-control" placeholder="Confirm Password"
                                :type="viewConfirmPassword ? 'text' : 'password'" v-model="password_confirmation">
                            <div class="input-group-append" @click="viewConfirmPassword = !viewConfirmPassword">
                                <span class="input-group-text"><i
                                        :class="[viewConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye']"
                                        aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="errors" class="mb-3">
                        <p class="alert alert-danger mb-1" v-for="(error, index) in errors?.errors">
                            <span v-for="(e, i) in error">{{ e }}</span>
                        </p>
                    </div>
                    <button class="btn btn-primary" type="submit" v-if="!isSuccess">Update</button>
                </div>
            </form>
        </b-card>
    </div>
</template>

<script>
import { ServiceFactory } from "../../utils/ServiceFactory";
const auth = ServiceFactory.get('auth')
export default {
    name: 'Setting',
    data() {
        return {
            user: null,
            email: '',
            current_password: '',
            password: '',
            password_confirmation: '',
            errors: null,
            isSuccess: false,
            viewCurrentPass: false,
            viewPassword: false,
            viewConfirmPassword: false,
            deviceType: '',
        }
    },
    async created() {
        const { isSuccess, data, errors } = await auth.me();
        if (isSuccess) {
            let {email} = data;
            this.user = data;
            this.email = email;
        }
    },
    computed: {
    },
    methods: {
        async submitHandler() {
            let payload = {
                email: this.email,
                current_password: this.current_password,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }

            const { isSuccess, errors, data } = await auth.resetPassword(payload)
            if (isSuccess) {
                this.$notify({ type: 'success', 'text': 'Password successfully updated' });
                this.resetForm();
            } else {
                this.errors = errors
                console.log(errors)
            }
        },
        closeWindow() {
            window.close();
        },
        resetForm() {
            this.current_password = '';
            this.password = '';
            this.password_confirmation = '';
            this.errors = null;
            this.viewCurrentPass = false;
            this.viewPassword = false;
            this.viewConfirmPassword = false;
        }
    }
}
</script>

<style scoped>

</style>
