import axiosUtils from "../../utils/axiosUtil";

export default  {
    async index(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        const filter = payload.filterBy !== '' ? `&filter[search]=${payload.filterBy},${payload.search}` : ''
        const onlyQvin = payload.onlyQvin ? `&qvin=true` : '';
        const buildUrl = `/fleets?per_page=${payload.perPage}&page=${payload.currentPage}&sort=${payload.sortField || ''}${filter}${onlyQvin}`;

        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = response.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async getDotReadyFleets(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        const filter = payload.filterBy !== '' ? `&filter[search]=${payload.filterBy},${payload.search}` : ''
        const buildUrl = `/dotready/fleets?per_page=${payload.perPage}&page=${payload.currentPage}&sort=${payload.sortField || ''}${filter}`;

        // return await axiosUtils.get(`/lookup/dotready/drivers`)
        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async showFleet(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        // const filter = payload.filterBy !== '' ? `&filter[search]=${payload.filterBy},${payload.search}` : ''
        // const buildUrl = `/admin/users?per_page=${payload.perPage}&page=${payload.currentPage}&sort=${payload.sortField || ''}${filter}`;

        return await axiosUtils.get(`/fleets/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },

    async updateFleet(id, payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.put(`/fleets/${id}`, payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },

    async storeFleet(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post('/fleets', payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },

    async deleteSubscriber(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.delete(`/subscribers/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async getFleets(){
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        // const filter = payload.filterBy !== '' ? `&filter[search]=${payload.filterBy},${payload.search}` : ''
        // const buildUrl = `/admin/users?per_page=${payload.perPage}&page=${payload.currentPage}&sort=${payload.sortField || ''}${filter}`;

        return await axiosUtils.get(`/fleets`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    }

}
