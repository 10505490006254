<template>
    <div>
        <Nav bg="blue" />
        <div class="container-fluid py-5">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="d-flex justify-content-center">
                        <a class="navbar-brand" href="#"><img alt="qvin logo" src="/img/QVIN-Logo-no-sl.png"
                                style="height: 150px; width: 150px"></a>
                    </div>
                    <div class="text-justify">
                        <h1 class="tw-font-black text-center">Privacy Policy</h1>
                        <p class="text-center tw-text-gray-500 tw-text-sm">Last updated February 9, 2023</p>

                        <div>
                            <p>
                                We value your privacy very highly. Please read this Privacy Policy carefully before
                                using the 
                                <a class="tw-no-underline hover:tw-no-underline" href="https://www.dot-ready.com" target="_blank">www.dot-ready.com</a> 
                                Website (the “Website”) operated by DOTReady, a(n) Sole
                                Proprietorship formed in Tennessee, United States (“us,” “we,” “our”) as this Privacy
                                Policy contains important information regarding your privacy and how we may use the
                                information we collect about you.
                            </p>
                            <p>
                                Your access to and use of the Website is conditional upon your acceptance of and
                                compliance with this Privacy Policy. This Privacy Policy applies to everyone, including,
                                but not limited to: visitors, users, and others, who wish to access or use the Website.
                            </p>
                            <p>
                                By accessing or using the Website, you agree to be bound by this Privacy Policy. If you
                                disagree with any part of the Privacy Policy, then you do not have our permission to
                                access or use the Website.
                            </p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">What information we collect</p>
                            <p>
                                We collect any and all information that you enter on this Website. We collect the
                                following information about you:
                            </p>
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center">Category of information</th>
                                        <th scope="col" class="text-center">Specific piece of information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Identifying information</td>
                                        <td>Name</td>
                                    </tr>
                                    <tr>
                                        <td>Identifying information</td>
                                        <td>Billing address</td>
                                    </tr>
                                    <tr>
                                        <td>Identifying information</td>
                                        <td>Phone number</td>
                                    </tr>
                                    <tr>
                                        <td>Identifying information</td>
                                        <td>IP address</td>
                                    </tr>
                                    <tr>
                                        <td>Identifying information</td>
                                        <td>Email address</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">How we may use your information</p>
                            <p>
                                We may use the information that you provide us for the following:
                            </p>
                            <ul class="lg:tw-gap-8 lg:tw-grid lg:tw-grid-cols-4">
                                <li>Auditing compliance;</li>
                                <li>Marketing and advertising;</li>
                                <li>Performing services;</li>
                                <li>Providing customer service.</li>
                            </ul>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Sale of your information</p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Cookies</p>
                            <p>
                                A cookie is a small piece of data sent from a website and stored on your device by your
                                browser. This Website collects cookies and may use cookies for reasons including, but
                                not limited to:
                            </p>
                            <ul class="lg:tw-gap-8 lg:tw-grid lg:tw-grid-cols-3">
                                <li>Analyzing our Website traffic;</li>
                                <li>Analyzing your interactions with advertisements;</li>
                                <li>Identifying if you are signed in to the Website;</li>
                                <li>Testing content on the Website;</li>
                                <li>Storing information about your preferences;</li>
                                <li>Recognizing when you return to the Website.</li>
                            </ul>
                            <p>
                                Most web browsers automatically accept cookies. However, you can modify your browser
                                settings to decline cookies, if you prefer. This setting may prevent you from taking
                                full advantage of the Website.
                            </p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Children’s privacy</p>
                            <p>
                                This Website is intended for use by a general audience and does not offer services to
                                children. Should a child whom we know to be under 18 send personal information to us, we
                                will use that information only to respond to that child to inform him or her that they
                                cannot use this Website.
                            </p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Analytics programs</p>
                            <p>
                                This Website uses Google Analytics to collect information about you and your behaviors.
                                If you would like to opt out of Google Analytics, please visit
                                <a class="tw-no-underline hover:tw-no-underline"
                                    href="https://tools.google.com/dlpage/gaoptout/"
                                    target="_blank">https://tools.google.com/dlpage/gaoptout/</a>.
                            </p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Exercising your rights</p>
                            <p>
                                Residents of Nevada may opt out of future sales of personal information by submitting a
                                consumer request to <a class="tw-no-underline hover:tw-no-underline"
                                    href="mailto:info@q-vin.com">info@q-vin.com</a>.
                            </p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Third-party websites</p>
                            <p>
                                This Website may contain hyperlinks to websites operated by parties other than us. We
                                provide such hyperlinks for your reference only. We do not control such websites and are
                                not responsible for their contents or the privacy or other practices of such websites.
                                It is up to you to read and fully understand their Privacy Policies. Our inclusion of
                                hyperlinks to such websites does not imply any endorsement of the material on such
                                websites or any association with their operators.
                            </p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Do Not Track</p>
                            <p>
                                Do Not Track is a preference you can set on your browser to inform websites that you do
                                not want to be tracked. We do not support Do Not Track (“DNT”). You can either enable or
                                disable Do Not Track by visiting the Preferences or Settings page of your browser.
                            </p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Changes to Privacy Policy</p>
                            <p>
                                We reserve the right to amend this Privacy Policy at any time. We will notify you of any
                                changes to this Privacy Policy by posting the updated Privacy Policy to this website or
                                application.
                            </p>
                        </div>
                        <div class="pt-3">
                            <p class="font-weight-bold font-xl">Questions</p>
                            <p>
                                If you have any questions about this Privacy Policy, please contact us at
                                <a class="tw-no-underline hover:tw-no-underline"
                                    href="mailto:info@q-vin.com">info@q-vin.com</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QvinFooter />
    </div>
</template>

<script>
import { ServiceFactory } from "../utils/ServiceFactory";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import { Header as AppHeader, } from "@coreui/vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";

const authService = ServiceFactory.get('auth')

export default {
    name: 'PrivacyPolicy',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
    },
    computed: {
        currentRoute() {
            return this.$route.path
        }
    },
    methods: {
        handleRedirect() {
            this.$router.push('/pricing')
        }
    }
}
</script>

<style scoped>
.top-feature {
    font-size: 12px;
}

.feature-description {
    font-size: 14px;
}

.text-blue-qvin {
    color: #025490;
}
</style>
