import axiosUtils from "../../utils/axiosUtil";

export default  {
    async index(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        let buildUrl = `/companies`;
        
        if(payload){
            const filter = payload.filterBy !== '' ? `&filter[search]=${payload.filterBy},${payload.search}` : ''
            const fleetFilter = payload.fleet ? `&fleet=${payload.fleet}` : '';
            buildUrl = `/companies?per_page=${payload.perPage}&page=${payload.currentPage}&sort=${payload.sortField || ''}${filter}${fleetFilter}`;
        }

        return await axiosUtils.get(buildUrl)
            .then(response => {
                requestResult.data = payload ? response.data.data : response;
                // requestResult.data = response;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async show(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.get(`/companies/${payload}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
    async storeCompany(payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.post('/companies', payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },

    async updateCompany(id, payload) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.put(`/companies/${id}`, payload)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });

    },
    async deleteCompany(id) {
        let requestResult = {status: 200, isSuccess: true, data: {}, errors: {}}

        return await axiosUtils.delete(`/companies/${id}`)
            .then(response => {
                requestResult.data = response.data.data;
                return requestResult;
            })
            .catch(error => {
                requestResult.isSuccess = false;
                requestResult.status = error.response.status;
                Object.assign(requestResult.errors, error.response.data)
                return requestResult;
            });
    },
}
