<template>
    <div>
        <Nav bg="blue" />
        <div class="container py-2">
            <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-space-x-20">
                <div class="w-full tw:tw-w-1/2 tw-px-10 tw-pt-12 tw-pb-5  rounded tw-shadow-2xl xl:tw-shadow-2xl">
                    <p class="text-center tw-text-3xl tw-font-bold">Book your personalized demo</p>
                    <p class="text-center tw-text-sm tw-text-gray-500 tw-mb-10">See how Q-VIN can help you save time and money with a hands-on demonstration by filling out the form below.</p>
                    <div class="tw-flex tw-space-x-3 tw-my-5">
                        <input type="text" class="form-control" placeholder="First Name">
                        <input type="text" class="form-control" placeholder="Last Name">
                    </div>
                    <div class="tw-my-5">
                        <input type="text" class="form-control" placeholder="Company Name">
                    </div>
                    <div class="tw-my-5">
                        <input type="text" class="form-control" placeholder="Phone Number">
                    </div>
                    <div class="tw-my-5">
                        <input type="email" class="form-control" placeholder="Work Email">
                    </div>
                    <div class="tw-my-5">
                        <select name="fleet_size" class="form-control">
                            <option value="" selected>Fleet Size</option>
                            <option value="1">1-10 Vehicles</option>
                        </select>
                    </div>
                    <div class="tw-my-5">
                        <select name="fleet_size" class="form-control">
                            <option value="" selected>Select Your Industry</option>
                            <option value="1">1-10 Vehicles</option>
                        </select>
                    </div>
                    <div class="tw-my-5">
                        <button class="btn btn-block btn-info tw-text-white tw-py-5">Get Started</button>
                    </div>
                </div>
                <div class="w-full xl:tw-w-1/2 tw-bg-qvin-1 tw-h-fit tw-text-white tw-font-bold rounded tw-mt-10 xl:tw-mt-0">
                    <div class="tw-pt-10 tw-px-10">
                        <p class="tw-text-xl"><span><i class="fas fa-check-circle"></i></span><span> Vehicle Inventory Management</span></p>
                        <p>Store detailed vehicle and large assent records and pull 90+ vehicle specs with the VIN Decoder.</p>

                        <p class="tw-text-xl"><span><i class="fas fa-check-circle"></i></span><span> Vehicle Inventory Management</span></p>
                        <p>Store detailed vehicle and large assent records and pull 90+ vehicle specs with the VIN Decoder.</p>

                        <p class="tw-text-xl"><span><i class="fas fa-check-circle"></i></span><span> Vehicle Inventory Management</span></p>
                        <p>Store detailed vehicle and large assent records and pull 90+ vehicle specs with the VIN Decoder.</p>
                        <p class="my-0 tw-text-xl">you may have heard about us at:</p>
                    </div>
                    <div class="tw-bg-white tw-px-5 tw-py-8">
                        <img src="/img/dot-ready-logo1.png" class="tw-h-6 tw-mr-2" alt="qvin">
                        <img src="/img/abc-new.png" class="tw-h-6 tw-mr-2" alt="abc">
                        <img src="/img/NewYorkTimes.png" class="tw-h-6 tw-mr-2" alt="new york times">
                        <img src="/img/Fox_News_Channel_log.png" class="tw-h-6 tw-mr-2" alt="fox">
                        <img src="/img/cnn.png" class="tw-h-6 tw-mr-2" alt="cnn">
                    </div>
                    <div class="tw-bg-qvin-2 tw-p-10 rounded-bottom">
                        <p class="text-center tw-text-2xl">Question?</p>
                        <div class="tw-flex tw-justify-center tw-space-x-5">
                            <p>1-833-339-8434</p>
                            <p>info@q-vin.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QvinFooter />
    </div>
</template>

<script>
import {ServiceFactory} from "../utils/ServiceFactory";

const authService = ServiceFactory.get('auth')
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import {
    Aside as AppAside,
    AsideToggler,
    Footer as TheFooter,
    Header as AppHeader,
    Sidebar as AppSidebar, SidebarFooter, SidebarMinimizer, SidebarNav, SidebarToggler
} from "@coreui/vue";
import DefaultAside from "../components/containers/DefaultAside.vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";

export default {
    name: 'BookADemo',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
    },
    data() {
        return {
            email: '',
            password: '',
            password_confirmation: '',
            first_name: '',
            last_name: '',
            errors: null,
            counter: 5,
            selectedPayment: 'monthly'
        }
    },
    methods: {
        async submitHandler() {
            const {isSuccess, errors, data} = await authService.signUp('/signup', {
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                first_name: this.first_name,
                last_name: this.last_name
            })
            if (isSuccess) {
                window.location.href = '/dashboard'
            } else {
                this.errors = errors
                console.log(errors)
            }
        },
        totalCostComputation(plan) {
           const pricing = this.selectedPayment === 'monthly' ? plan.monthly_pricing : plan.annual_pricing
           return this.counter * pricing
        },
        setPayment(mode) {
            this.selectedPayment = mode
        }
    }
}
</script>

<style scoped>
    .top-feature {
       font-size: 12px;
    }

    .feature-description {
        font-size: 14px;
    }

</style>
