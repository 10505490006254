<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between">
            <h1 class="tw-font-bold tw-text-2xl">{{ editing ? 'Edit' : 'Add' }} User
            </h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/adminusers" role="button"><i
                        class="fa fa-arrow-left"></i> Back to list</router-link>
            </div>
        </div>
        <b-card>
            <div class="w-50">
                <DisplayError :errors="errors" />
            </div>
            <form class="w-50" @submit.prevent="submitHandler">
                <div class="mb-3">
                    <label for="userFirstName" class="form-label">First Name</label>
                    <input v-model="form.first_name" type="text" class="form-control" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                    <label for="userLastName" class="form-label">Last Name</label>
                    <input v-model="form.last_name" type="text" class="form-control" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                    <label for="userNickName" class="form-label">Nick Name</label>
                    <input v-model="form.nickname" type="type" class="form-control" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                    <label for="userEmail" class="form-label">Email</label>
                    <input v-model="form.email" type="email" class="form-control">
                </div>
                <div class="mb-3">
                    <label class="form-label">Phone</label>
                    <vue-phone-number-input @update="onUpdate" v-model="phone" clearable valid-color="#68CD86" />
                </div>
                <div class="mb-3" v-if="isAdmin">
                    <label class="form-label">Fleet</label>
                    <fleet-select type="fleet" :multi="false" v-model="form.team" :value="form.team"></fleet-select>
                </div>
                <div class="mb-3">
                    <label class="form-label">Company</label>
                    <company-select type="company" :multi="false" v-model="form.company" :value="form.company"
                        :fleetId="form.team"></company-select>
                </div>
                <div class="mb-3">
                    <label class="form-label">Location</label>
                    <LocationSelect v-model="form.location" :value="form.location" :companyId="form.company" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Role</label>
                    <select name="roles" id="roles" class="form-select" v-model="form.role">
                        <option value="" selected>Please select a value</option>
                        <option :value="role.name" selected v-for="(role, index) in roles" :key="index">
                            {{ formatRole(role.name) }}</option>
                    </select>
                </div>
                <p class="text-center font-weight-bold">Send via link</p>
                <div class="mt-3 d-flex justify-content-center align-items-center gap-2">
                    <label class="radio-inline">
                        <input v-model="form.send_via" value="sms" type="radio" name="optradio" checked> Text/SMS
                    </label>
                    <label class="radio-inline">
                        <input v-model="form.send_via" value="email" type="radio" name="optradio">
                        Email
                    </label>
                </div>
                <button type="submit" class="btn btn-info text-white">
                    {{ editing ? "Save User" : "Add User and Send Invite" }}
                </button>
                <button @click="resendInvite()" type="button" class="btn btn-success text-white" v-if="editing"
                    :disabled="isResending || responded">
                    Resend Invite
                </button>
            </form>

        </b-card>
    </div>
</template>

<script>
import { ServiceFactory } from "../../utils/ServiceFactory";
import DisplayError from "../../components/common/DisplayError.vue";
import FleetSelect from "../../components/common/selects/FleetSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

const user = ServiceFactory.get('user')
const lookup = ServiceFactory.get('lookup')
export default {
    name: "FormUser",
    components: {
        DisplayError,
        CompanySelect,
        LocationSelect,
        FleetSelect,
        VuePhoneNumberInput
    },
    data() {
        return {
            form: {
                first_name: null,
                last_name: null,
                nickname: null,
                email: null,
                role: null,
                phone_number: null,
                team: null,
                company: null,
                location: null,
                send_via: null,
            },
            roles: null,
            errors: null,
            isResending: false,
            responded: false,
            phone: '',
        }
    },
    created() {
        this.getRoles()
        if (this.editing) {
            this.getUserDetail()
        }
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        },
        isAdmin() {
            return this.$store.getters["user/getRoles"] === 'admin'
        }
    },
    methods: {
        async getRoles() {
            const { isSuccess, data } = await lookup.show('roles')
            if (isSuccess) this.roles = data.filter((role) => !['driver', 'admin', 'fleet_driver', 'mechanic', 'police_officer'].includes(role.name));
            else this.$notify({ type: 'error', text: 'Something went wrong.' })

        },
        async getUserDetail() {
            const { isSuccess, data } = await user.showUser(this.$route.params.id)
            if (isSuccess) {
                this.form.first_name = data.first_name
                this.form.last_name = data.last_name
                this.form.nickname = data.nickname
                this.form.email = data.email
                this.form.role = data.roles
                this.form.phone_number = data.phone_number
                this.form.team = data.fleet_id
                this.form.company = data.company_id
                this.form.location = data.location
                this.form.send_via = data.send_via
                this.responded = data.responded ? true : false;
                this.phone = data.phone_number
            }
        },
        async submitHandler() {
            if (this.editing) await this.updateAdminUser()
            else await this.addAdminUser()
        },
        async addAdminUser() {
            const { isSuccess, errors } = await user.storeUser(this.form)
            if (isSuccess) {
                this.$notify({ type: 'success', text: 'Admin user successfully created.' })
                this.form = {}
                this.$router.push('/users')
            }
            else this.errors = errors
        },
        async updateAdminUser() {
            const { isSuccess, errors } = await user.updateUser(this.$route.params.id, this.form)
            if (isSuccess) {
                this.$notify({ type: 'success', text: 'Admin user successfully updated.' })
                this.form = {}
                this.$router.push('/users')
            }
            else this.errors = errors
        },
        formatRole(role) {
            return _.startCase(role)
        },
        async resendInvite(){
            this.isResending = true;
            const { isSuccess, errors } = await user.resendInvite(this.$route.params.id)
            if(isSuccess){
                this.$notify({ type: 'success', 'text': 'Invitation has been successfully resent.' });
                this.isResending = false;
            }
            else {
                this.$notify({ type: 'error', 'text': 'Resend invitation failed' });
                this.isResending = false;
            }
        },
        onUpdate(e) {
            this.form.phone_number = e.formattedNumber || '';
        }
    }
}
</script>
<style scoped>
:deep(.input-tel input) {
border: 1px solid #dee2e6 !important;
}
:deep(.input-tel__input::placeholder) {
    color: transparent;
}
:deep(.country-selector input) {
border: 1px solid #dee2e6 !important;
}
</style>
