<template>
  <div>
    <b-link class="link-unstyled">
      <slot></slot>
      <div v-if="showActions" class="d-none ml-2 edit-folder-icon" @click="showModal">
        <i class="fa fa-edit"></i>
      </div>
      <div v-if="showActions" class="d-none ml-2 edit-folder-icon text-danger" @click="deleteFolder">
        <i class="fa fa-trash"></i>
      </div> 
    </b-link>
    <b-modal :busy="loading" @hidden="resetFields" @ok="editFolder" ref="editFolderModal" centered title="Edit Folder">
      <b-form-group label="Name" class="mb-4">
        <b-form-input v-model="name" :state="errors.name ? false : null" placeholder="Folder name" autofocus></b-form-input>
        <b-form-invalid-feedback>{{ errors.name ? errors.name[0] : '' }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="showAlerts" switch>
          Show expiration alerts for files in this folder
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';

export default {

  data: function () {
    return {
      loading: false,
      modalShown: false,
      name: '',
      showAlerts: false,
      showActions: true,
      childfolder: false,      
      errors: {}
    }
  },

  props: {
    folderId: {
      type: [String, Number],
      required: true
    },
    entityType: {
      type: String,
      required: false
    }
  },

  created: function () {    
    if(this.entityType === 'owner') {
      const folder = _.find(this.$store.state.folders.folders.owner, { id: this.folderId });
      this.showActions = _.isNull(folder.deleted_at) ? true : false;
    }
  },

  methods: {
    loadFolder() {
      if(this.entityType === 'vehicle') {
        let folder = _.find(this.$store.state.folders.folders.vehicle, { id: this.folderId });        
        if (_.isUndefined(folder)) {
          folder = this.getChildFolder('vehicle');
          if (_.isNull(folder)) {
            console.log('No Folder');
          }
        }
        this.name = folder.name;
        this.showAlerts = folder.show_alerts;
      }

      if(this.entityType === 'owner') {
        let folder = _.find(this.$store.state.folders.folders.owner, { id: this.folderId });
        if (_.isUndefined(folder)) {
          folder = this.getChildFolder('owner');
          if (_.isNull(folder)) {
            console.log('No Folder');
          }
        }
        this.name = folder.name;
        this.showAlerts = folder.show_alerts;
      }

      if(this.entityType === 'large_equipment') {
        let folder = _.find(this.$store.state.folders.folders.large_equipment, { id: this.folderId });
        if (_.isUndefined(folder)) {
          folder = this.getChildFolder('large_equipment');
          if (_.isNull(folder)) {
            console.log('No Folder');
          }
        }
        this.name = folder.name;
        this.showAlerts = folder.show_alerts;
      }

      if(this.entityType === 'small_equipment') {
        let folder = _.find(this.$store.state.folders.folders.small_equipment, { id: this.folderId });
        if (_.isUndefined(folder)) {
          folder = this.getChildFolder('small_equipment');
          if (_.isNull(folder)) {
            console.log('No Folder');
          }
        }
        this.name = folder.name;
        this.showAlerts = folder.show_alerts;
      }

      if(this.entityType === 'trailer') {
        let folder = _.find(this.$store.state.folders.folders.trailer, { id: this.folderId });
        if (_.isUndefined(folder)) {
          folder = this.getChildFolder('vehicle');
          if (_.isNull(folder)) {
            console.log('No Folder');
          }
        }
        this.name = folder.name;
        this.showAlerts = folder.show_alerts;
      }


    },

    getChildFolder(entityType) {
        //Child folders are not listed in the state, you have to 
        //filter through the children of the primary folders
        const folders =  _.get(this.$store.state.folders.folders, entityType, []);
        
        const parentFolder = _.find(folders, folder => {
          return _.some(folder.children, { id: this.folderId });
        });

        if (_.isUndefined(parentFolder)) {
          console.log('No Parent Folder');
          return null;
        }
        
        this.childfolder = true;

        return _.find(parentFolder.children, { id: this.folderId });
    },

    resetFields() {
      this.errors = {};
      this.name = null;
      this.showAlerts = false;
    },

    showModal() {
      this.loadFolder();
      this.$refs.editFolderModal.show();
    },

    editFolder(event) {
      event.preventDefault();

      const params = {
        id: this.folderId,
        name: this.name,
        show_alerts: this.showAlerts,
      }

      this.errors = {};
      this.loading = true;

      this.$store.dispatch('updateFolder', params).then(() => {
        this.$refs.editFolderModal.hide();
        
        if (this.childfolder) {
          this.getFolders();
        }
        
        this.$notify({
          type: 'success',
          text: 'Folder updated successfully!'
        });
      }).catch(error => {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      }).finally(() => {
        this.loading = false;
      })
    },

    deleteFolder(event) {
      let vm = this;
      vm.$dialog
        .confirm('Are you sure you want to delete this folder? This operation cannot be undone.')
        .then(function (dialog) {
          vm.$store.dispatch('deleteFolder', vm.folderId).then(() => {
            vm.$notify({
              type: 'success',
              text: 'Folder deleted successfully!'
            });
          })
        });
    },

    getFolders() {
      this.$store.dispatch('getFolders', {
        entityType: this.entityType,
        companyId: this.$root.company.id
      })
    }


  }

}
</script>
