<template>
    <div>
        <Nav bg="blue" />
        <div class="container-fluid py-5">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="d-flex justify-content-center">
                        <a class="navbar-brand" href="#"><img alt="qvin logo" src="/img/QVIN-Logo-no-sl.png"
                                style="height: 150px; width: 150px"></a>
                    </div>
                    <div class="text-justify">
                        <h1 class="tw-font-black text-center">Q-VIN Shipping Policy</h1>

                        <div class="mt-5 tw-py-12">
                            <div>
                                <p class="font-2xl font-weight-bold text-center">UNITED STATES</p>
                                <table class="table table-borderless text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Order</th>
                                            <th scope="col">Delivery Service</th>
                                            <th scope="col">Cost</th>
                                            <th scope="col">Estimated Delivery Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Over 40 assets</td>
                                            <td>Standard Ground</td>
                                            <td>FREE</td>
                                            <td>3-5 Business Days</td>
                                        </tr>
                                        <tr>
                                            <td>Under 40 assets</td>
                                            <td>Standard Ground</td>
                                            <td>$10.00</td>
                                            <td>3-5 Business Days</td>
                                        </tr>
                                        <tr>
                                            <td>All</td>
                                            <td>Overnight</td>
                                            <td>$100.00</td>
                                            <td>1 Business Day</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="lg:tw-max-w-screen-xl lg:tw-px-8 sm:tw-px-6 tw-max-w-xl tw-mx-auto tw-px-4">
                    <div class="row text-white">
                        <div class="col mr-3 p-5 rounded tw-bg-qvin-1">
                            <p class="mb-0">
                                <span class="font-weight-bold">Standard Shipping:</span>
                                Orders placed before 12pm PST should be received within 3-5 business
                                days. Orders placed on Saturdays, Sundays or public holidays are handled on the
                                Monday
                                or the first working day after. (Working days exclude Saturdays, Sundays and
                                public
                                holidays). Price is Per Package.
                            </p>
                        </div>
                        <div class="col p-5 rounded tw-bg-qvin-1">
                            <p class="mb-0">
                                <span class="font-weight-bold">Overnight Shipping:</span>
                                Orders placed before 12pm PST should be received the next business
                                day. Orders placed on Saturdays, Sundays or public holidays are handled on the
                                Monday or
                                the first working day after. (Working days exclude Saturdays, Sundays and public
                                holidays). Price is Per Package.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="justify-content-center mt-5 row tw-py-12">
                <div class="col-lg-8">
                    <div>
                        <p class="font-2xl font-weight-bold text-center">HAWAII, ALASKA & OTHER US TERRITORIES
                        </p>
                        <table class="table table-borderless text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Order</th>
                                    <th scope="col">Delivery Service</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Estimated Delivery Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>All</td>
                                    <td>Standard Ground</td>
                                    <td>$20.00</td>
                                    <td>5-10 Business Days</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col">
                    <div class="tw-py-12 tw-bg-white">
                        <div class="tw-max-w-xl tw-mx-auto lg:tw-max-w-screen-xl">
                            <div class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-8">
                                <div class="p-4 text-white tw-bg-qvin-1 rounded">
                                    <div
                                        class="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-text-white">
                                        <img class="tw-h-12 tw-w-12" src="/img/shipping-policy.png">
                                    </div>
                                    <div class="tw-mt-5">
                                        <h5 class="tw-text-lg tw-leading-6 font-weight-bold">
                                            SHIPPING POLICY</h5>
                                        <p class="tw-mt-2 tw-text-base tw-leading-6">
                                            100% of orders are typically shipped within one business day. Orders
                                            placed over the weekend are dispatched on Mondays. Please note, we
                                            are currently only able to ship within the United States or to US
                                            territories.
                                        </p>
                                    </div>
                                </div>
                                <div class="lg:tw-mt-0 p-4 text-white tw-bg-qvin-1 tw-mt-10 rounded">
                                    <div
                                        class="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-text-white">
                                        <img class="tw-h-12 tw-w-12" src="/img/lost-orders.png">
                                    </div>
                                    <div class="tw-mt-5">
                                        <h5 class="tw-text-lg tw-leading-6 font-weight-bold">
                                            DELAYED OR LOST ORDERS
                                        </h5>
                                        <p class="tw-mt-2 tw-text-base tw-leading-6">
                                            A parcel is considered lost after it has been in the postal system
                                            for a period of 15 business days for US orders and 21 business days
                                            for international orders. We cannot take responsibility for delays
                                            caused by the courier. Although orders normally take much less than
                                            that we are unable to dispatch a replacement until this period of
                                            time has elapsed.
                                        </p>
                                    </div>
                                </div>
                                <div class="lg:tw-mt-0 p-4 text-white tw-bg-qvin-1 tw-mt-10 rounded">
                                    <div
                                        class="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-md tw-text-white">
                                        <img class="tw-h-12 tw-w-12" src="/img/wrong-address.png">
                                    </div>
                                    <div class="tw-mt-5">
                                        <h5 class="tw-text-lg tw-leading-6 font-weight-bold">
                                            WRONG OR INCOMPLETE ADDRESS
                                        </h5>
                                        <p class="tw-mt-2 tw-text-base tw-leading-6">
                                            If your package is returned to us due to an incorrect or incomplete
                                            address, you must pay all delivery cost to re-deliver your order to
                                            a corrected address. We are not responsible for the loss of your
                                            order if the address provided at checkout is incomplete or
                                            incorrect.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QvinFooter />
    </div>
</template>

<script>
import { ServiceFactory } from "../utils/ServiceFactory";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import { Header as AppHeader, } from "@coreui/vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";

const authService = ServiceFactory.get('auth')

export default {
    name: 'ShippingPolicy',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
    },
    computed: {
        currentRoute() {
            return this.$route.path
        }
    },
    methods: {
        handleRedirect() {
            this.$router.push('/pricing')
        }
    }
}
</script>

<style scoped>
.top-feature {
    font-size: 12px;
}

.feature-description {
    font-size: 14px;
}

.text-blue-qvin {
    color: #025490;
}
</style>
