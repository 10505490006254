<template>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="font-weight-bold mt-2">Recent Drivers: {{dataCount}}</p>
                    <button class="btn btn-info btn-sm text-white"><i class="fa fa-plus"></i> Add Trailers</button>
                </div>
            </div>
            <div class="card-body">
                <div class="w-full" v-if="!loading">
                    <apexchart ref="driverPanel" height="400" width="600" type="line" :options="chartOptions" :series="series"></apexchart>
                </div>
                <PanelList :lists="lists" />
            </div>
        </div>
    </div>
</template>

<script>

import {ServiceFactory} from "../../../utils/ServiceFactory";
import PanelList from "./PanelList.vue";

const dashboard = ServiceFactory.get('dashboard')
export default {
    name: "RecentDriver",
    components: {PanelList},
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'driverPanel',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                }
            },
            series: [{
                name: 'Drivers',
                data: []
            }],
            loading: false,
            dataCount: 0,
            lists: []
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.loading = true
            const {isSuccess, data} = await dashboard.panelChart('recent-drivers');
            if (isSuccess)  {

                this.series = [{
                    name: 'Drivers',
                    data: data.totals
                }]
                this.chartOptions = {
                    ...this.chartOptions,
                    xaxis: {
                        categories: data.months,
                    }
                }
                this.dataCount = data.totals.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                this.lists = data.lists

            }
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
    }
}
</script>
