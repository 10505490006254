import _ from 'lodash';
import ApiResource from "../../utils/ApiResource";

const folders = {
  state: () => ({
    folders: {}
	}),

  mutations: {
    setFoldersForEntity(state, payload) {
      state.folders = { ...state.folders, [payload.entityType]: payload.folders };
    },

    addFolder(state, payload) {
      // state.folders[payload.entity_type].push(payload);
    },

    updateFolder(state, payload) {
      const folderIndex = _.findIndex(state.folders[payload.entity_type], { id: payload.id });
      if (folderIndex !== -1) {
        state.folders[payload.entity_type].splice(folderIndex, 1, payload);
      }
    },

    deleteFolder(state, id) {
      if(state.folders['vehicle']) {
        const folderIndex = _.findIndex(state.folders['vehicle'], { id });
        if (folderIndex !== -1) {
          state.folders['vehicle'].splice(folderIndex, 1);
        }
      }

      if(state.folders['owner']){
        const folderIndex = _.findIndex(state.folders['owner'], { id });
        if (folderIndex !== -1) {
          state.folders['owner'].splice(folderIndex, 1);
        }
      }

    }
  },

  actions: {
    getFolders({ commit }, payload) {
      payload.include_deleted = !_.isNull(payload.include_deleted)
        ? payload.include_deleted
        : false;

      return ApiResource.make('folder').index({
        entity_type: payload.entityType,
        company_id: payload.companyId,
        include_deleted: payload.include_deleted
      }).then(response => {
        commit('setFoldersForEntity', {
          entityType: payload.entityType,
          folders: response.data
        });
      });
    },

    createFolder({ commit }, payload) {
      return ApiResource.make('folder').store(payload).then(response => {
        commit('addFolder', response.data)
      });
    },

    updateFolder({ commit }, payload) {
      return ApiResource.make('folder').store(payload).then(response => {
        commit('updateFolder', response.data)
      });
    },

    deleteFolder({ commit }, id) {
      return ApiResource.make('folder').destroy(id).then(response => {
        commit('deleteFolder', id)
      });
    }
  }
};

export default folders;
