<template>
    <div id="location-general-edit">
        <h2 class="tw-font-bold tw-text-2xl" v-if="!(this.location_id || this.isAdd)">{{editing ? 'Edit Location' : 'Add Location'}}</h2>
        <b-tabs>
            <b-tab title="General" active>
                <div v-if="location" v-bind:class="location.id ? 'location-edit-'+location.id : 'location-add'">

                    <b-form @submit.prevent="submitHandler">
                        <b-form-group label="Location Name">
                            <b-form-input v-model="location.name" name="name" size="md"
                                :state="errors.name ? false : null" placeholder="Location Name"></b-form-input>
                            <b-form-invalid-feedback>Location name is required.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label="Location DOT #">
                            <b-form-input v-model="location.dot_number" name="dot_number"
                                :state="errors.dot_number ? false : null" placeholder="Company DOT #"></b-form-input>
                            <b-form-invalid-feedback>Location DOT # is required.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label="Company" :state="errors.company ? false : null">
                            <company-select ref="companySelect" v-model="location.company_id"
                                name="company" :fleetId="fleet"></company-select>
                            <b-form-invalid-feedback>Company is required.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-row>
                            <b-col md="8">
                                <b-form-group label="Address">
                                    <b-form-input v-model="location.address" name="address"
                                        :state="errors.address ? false : null" placeholder="Address"></b-form-input>
                                    <b-form-invalid-feedback>Please enter a valid address.</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Apt/Suite">
                                    <b-form-input v-model="location.apt_suite" name="apt_suite"
                                        :state="errors.apt_suite ? false : null" placeholder="Apt/Suite"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col md="5">
                                <b-form-group label="City">
                                    <b-form-input v-model="location.city" name="city"
                                        :state="errors.city ? false : null" placeholder="City"></b-form-input>
                                    <b-form-invalid-feedback>Please enter a valid city.</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col md="4" class="state-select">
                                <b-form-group label="State" :state="errors.state_id ? false : null">
                                    <state-select v-model="location.state_id" name="state_id"></state-select>
                                    <b-form-invalid-feedback>Please select a state.</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Postal Code">
                                    <b-form-input v-model="location.postal_code" name="postal_code"
                                        :state="errors.postal_code ? false : null"
                                        placeholder="Postal Code"></b-form-input>
                                    <b-form-invalid-feedback>Please enter a valid postal code.</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="time-zone-select">
                            <b-col>
                                <b-form-group label="Timezone" :state="errors.timezone ? false : null">
                                    <timezone-select ref="timezoneSelect" v-model="location.timezone"
                                        name="timezone"></timezone-select>
                                    <b-form-invalid-feedback>Timezone is required.</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <div class="d-flex justify-content-between">
                            <div>
                                <b-button type="submit" variant="primary">Save Changes</b-button>
                            </div>
                            <div v-if="isLocationLoaded && (role === 'admin') && editing">
                                <b-button @click="deleteLocation" variant="outline-danger"
                                    v-b-modal.delete-location-modal>Delete Location</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>

            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import StateSelect from '../../components/common/selects/StateSelect.vue';
import CompanySelect from '../../components/common/selects/CompanySelect.vue';
import TimezoneSelect from '../../components/common/selects/TimezoneSelect';

import {ServiceFactory} from "../../utils/ServiceFactory";
const locationApi = ServiceFactory.get('location')
export default {

    data: function () {
        return {
            location: null,
            reassignLocationId: null,
            errors: {},
            loaded: false,
            moduleName: 'Location'
        }
    },

    props: {
        location_id: {
            default: null
        },
        fleet: {
            default: null
        },
        isAdd: {
            type: Boolean,
            default: false
        }
    },

    components: {
        StateSelect,
        TimezoneSelect,
        CompanySelect
    },

    created: function () {
        this.loadLocation();
    },

    computed: {

        isLocationLoaded: function () {
            if (this.location && _.get(this.location, 'id', null)) {
                return true;
            }
            return false;
        },
        locationId() {
            return this.isAdd ? '' : this.location_id || this.$route?.params?.id
        },
        role() {
            return this.$store.getters["user/getRoles"];
        },
        editing() {
            return this.isAdd ? false : !!this.$route.params.id
        }

    },

    methods: {

        loadLocation: function () {
            let vm = this;
            vm.reassignLocationId = null;
            if (vm.locationId) {
                locationApi.show(vm.locationId)
                    .then(location => {
                        vm.location = {
                            id: location.data.id,
                            name: location.data.name,
                            address: location.data.address,
                            city: location.data.city,
                            dot_number: location.data.dot_number,
                            state_id: location.data.state_id,
                            company_id: location.data.company_id,
                            timezone: location.data.timezone,
                            postal_code: location.data.postal_code,
                            apt_suite: null
                        };
                    })
                    .catch(error => {

                    }).then(() => {

                });
            }
            else {
                vm.location = vm.makeEmptyLocation();
            }
        },

        async updateLocation() {
            const {isSuccess, errors} = await locationApi.updateLocation(this.location.id, this.location)
            if (isSuccess) {
                this.$notify({type: 'success', text: `${this.moduleName} successfully updated.`})
                this.location = {}
                this.redirect();
            }
            else this.errors = errors
        },
        async submitHandler() {
            if (this.editing) await this.updateLocation()
            else await this.addLocation()
        },

        async addLocation() {
            const {isSuccess, errors} = await locationApi.storeLocation(this.location)
            if (isSuccess) {
                this.$notify({type: 'success', text: `${this.moduleName} successfully created.`})
                this.location = {}
                this.redirect();
            }
            else this.errors = errors
        },
        async deleteLocation() {
            const {isSuccess, errors} = await locationApi.deleteLocation(this.location.id)
            if (isSuccess) {
                this.$notify({type: 'success', text: `${this.moduleName} successfully deleted.`})
                this.location = {}
                window.location = '/locations'
            }
            else this.errors = errors
        },
        redirect() {
            if (this.location_id || this.isAdd) {
                this.$emit('reload');
            } else this.$router.push('/locations')
        },
        makeEmptyLocation: function () {
            return {
                id: null,
                name: null,
                address: null,
                city: null,
                dot_number: null,
                state_id: null,
                company_id: null,
                timezone: null,
                postal_code: null,
                apt_suite: null
            }
        }

    },

    watch: {
        '$route': function (newVal, oldVal) {
            this.errors = {};
            this.loadLocation();
        }
    }

}
</script>
