
const state = {
    user: null,
    location: null
}

const getters = {
    getUser: state => state.user,
    getRoles: state => state.user?.roles,
}

const mutations = {
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_LOCATION(state, payload) {
        state.location = payload;
    },
}

const actions = {
    setUser({ commit }, payload) {
        commit('SET_USER', payload);
    },
    setLocation({ commit }, payload) {
        commit('SET_LOCATION', payload);
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
