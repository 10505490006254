<template>
    <div>
        <div class="d-flex justify-content-between">
            <h1>Form Driver</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/drivers" role="button"><i
                        class="fa fa-arrow-left"></i> Back to list</router-link>
            </div>
        </div>
        <b-tabs>
            <b-tab title="Profile" active>
                <div class="row">
                    <div class="col-4">
                        <div>
                            <DisplayError :errors="errors" />
                        </div>
                        <form @submit.prevent="submitHandler">
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control border"
                                    :class="{'border-danger' :(errors && errors.errors.name)}"
                                    aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Email</label>
                                <input v-model="form.email" type="text" class="form-control border"
                                    :class="{'border-danger' :(errors && errors.errors.email)}"
                                    aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Phone Number</label>
                                <vue-phone-number-input @update="onUpdate" v-model="phone" clearable
                                    valid-color="#68CD86" />
                                <!-- <input v-model="form.phone_number" type="text" class="form-control border"
                                    :class="{'border-danger' :(errors && errors.errors.phone_number)}"
                                    aria-describedby="emailHelp"> -->
                            </div>
                            <b-form-group label="Company">
                                <company-select type="company" :multi="false"
                                    v-model="form.company_id"></company-select>
                            </b-form-group>
                            <b-form-group label="Truck">
                                <vehicle-select v-model="form.truck_id" type="vehicle" :multi="false"></vehicle-select>
                            </b-form-group>
                            <b-form-group label="Trailer">
                                <trailer-select v-model="form.trailer_id" type="trailer"
                                    :multi="false"></trailer-select>
                            </b-form-group>

                            <b-form-group label="Status" class="mb-3" label-class="font-weight-bold  mb-0 py-1">
                                <status-select v-model="form.status"></status-select>
                            </b-form-group>

                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="font-weight-bold mt-2">Request History</p>
                                        <router-link class="btn btn-info text-white fw-bold"
                                            :to="`/request-history/${this.form.id}`" role="button">Request
                                            Information</router-link>
                                        <!-- <a :href="`/request-history/${this.form.id}`"
                                            class="btn btn-info btn-sm text-white">Request Information</a> -->
                                    </div>
                                </div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="(request) in requestHistories" :key="request.id">
                                        <div class="row">
                                            <div class="col-9">
                                                <div class="align-items-center d-flex justify-content-between">
                                                    <p class="mb-1">
                                                        Requested for: <span class="font-weight-bold">{{
                                                            request.request_for}}</span>
                                                    </p>
                                                    <p class="mb-1 font-sm">{{ request.created_at}}</p>
                                                    <p class="badge badge-success font-xs mb-1 text-uppercase">{{
                                                        request.send_via }}</p>
                                                </div>
                                                <p class="font-sm mb-0">Comment: <span>{{ request.comment }}</span></p>
                                            </div>
                                            <div class="col-3 text-right">
                                                <button type="button" class="btn btn-outline-danger mr-1 mb-1"
                                                    @click="deleteHistory(request.id)"><i class="fa fa-trash"
                                                        aria-hidden="true"></i></button>
                                                <button type="button" class="btn btn-outline-success mb-1"
                                                    @click="resolveHistory(request.id)" v-if="!request.resolved_at"><i
                                                        class="fa fa-check" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="card">
                                <div class="card-header">Trucks Driven</div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item text-decoration-underline text-primary" role="button"
                                        v-for="(truck, index) in trucks_driven"
                                        @click="gotoVehicle(truck.id, 'trucks')">{{ truck.name }}</li>
                                </ul>
                            </div>

                            <div class="card">
                                <div class="card-header">Trailers Pulled</div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item text-decoration-underline text-primary" role="button"
                                        v-for="(trailer, index) in trailers_pulled"
                                        @click="gotoVehicle(trailer.id, 'trailers')">{{
                                        trailer.name }}</li>
                                </ul>
                            </div>

                            <button type="submit" class="btn btn-info text-white">Save Driver</button>
                        </form>
                    </div>
                    <div class="col-8">
                        <div class="row text-center" v-if="invitation_code">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <img class="m-auto my-3 w-25" :src="qr_path" v-if="qr_path">
                                            <p>Invitation Code: <strong>{{ invitation_code }}</strong></p>
                                        </div>
                                        <div class="row">
                                            <button type="button" @click="resendInvite()"
                                                class="btn btn-primary col-3 m-auto" :disabled="isResending"
                                                v-if="!responded">Resend
                                                Invite</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <DriverProfileMap add_link="/trailers" title="Equipment" url="/equipment-locations" />
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <p class="font-weight-bolder mb-0">Recent DVIR</p>
                                        <data-table ref="recentDvirs" :columns="fields" default_sort_direction=""
                                            default_sort_field="" :show_entries="[10]" :show_entries_default="10"
                                            url="drivers" :items="recent_dvirs" :loading="loading">
                                        </data-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>

        </b-tabs>
    </div>
</template>

<script>
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import Spinner from 'vue-simple-spinner';
import dataTable from "../../components/dataTable/DataTable.vue";

import ToggleButton from "../../components/common/ToggleButton.vue";
import SearchableSelect from "../../components/common/SearchableSelect.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import {ServiceFactory} from "../../utils/ServiceFactory";
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import DisplayError from "../../components/common/DisplayError.vue";
import axiosUtils from "../../utils/axiosUtil";
import toast from "bootstrap/js/src/toast";
import TrailerSelect from "../../components/common/selects/TrailerSelect.vue";
import VehicleSelect from "../../components/common/selects/VehicleSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import MapList from "../../components/common/maps/MapList.vue";
import DriverProfileMap from "../../components/common/maps/DriverProfileMap.vue";
import StatusSelect from "../../components/common/selects/StatusSelect.vue";
import DriverSelect from "../../components/common/selects/DriverSelect.vue";
const driver = ServiceFactory.get('users')

export default {
    components: {
        DriverSelect, StatusSelect,
        DriverProfileMap,
        MapList,
        VehicleSelect, TrailerSelect, CompanySelect,
        DisplayError,
        LocationSelect,
        SearchableSelect,
        ToggleButton,
        VuePhoneNumberInput,
        dataTable,
        Pagination,
        Spinner
    },
    name: "FormDriver",
    data() {
        return {
            form: {
                id:null,
                name: null,
                email: null,
                phone_number: null,
                trailer_id: null,
                truck_id: null,
                company_id: null,
            },
            trailers_pulled: [],
            trucks_driven: [],
            recent_dvirs: [],
            errors: null,
            requestHistories: [],
            qr_path: null,
            invitation_code: null,
            isResending: false,
            css: VuetableCssBootstrap4,
            loading: false,
            fields: [
                { label: 'Truck', field: 'truck_name'},
                { label: 'Trailer', field: 'trailer_name'},
                { label: 'Date', field: 'prior_dvir_date'},
                { label: 'Status', field: 'status'},
                { label: 'Location', field: 'location'},
            ],
            responded: false,
            phone: '',
        }
    },
    async created() {
        await this.getDetails();
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        }
    },
    methods: {
        getDetails() {
            axiosUtils.get(`/invite-drivers/${this.$route.params.id}/edit`)
                .then(response => {
                    const { id, name, email, phone_number, trailer_id, truck_id, qr_path = null, company_id, invitation_code, trailers_pulled, trucks_driven, recent_dvirs, responded } = response.data.data
                    this.form.id = id
                    this.form.name = name
                    this.form.email = email
                    this.form.phone_number = phone_number
                    this.form.trailer_id = trailer_id
                    this.form.truck_id = truck_id
                    this.form.company_id = company_id
                    this.qr_path = qr_path
                    this.invitation_code = invitation_code
                    this.trailers_pulled = trailers_pulled
                    this.trucks_driven = trucks_driven
                    this.recent_dvirs = { data: recent_dvirs }
                    this.responded = responded ? true : false;
                    this.phone = phone_number
                    this.getRequestHistories()
                })
                .catch((_) => {
                    this.$notify({
                        type: 'danger',
                        text: 'Something Went Wrong.'
                    });
                });
        },
        getRequestHistories() {
            axiosUtils.get(`/request-history/${this.form.id}`)
                .then(response => {
                    this.requestHistories = response.data
                })
                .catch((_) => {
                    this.$notify({
                        type: 'danger',
                        text: 'Something Went Wrong.'
                    });
                });
        },
        async submitHandler() {
            if (this.editing) await this.updateDriver()
            else await this.addDriver()
        },
        async updateDriver() {
            const {isSuccess, errors} = await driver.update(this.$route.params.id, this.form, 'drivers')
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Driver successfully updated.'})
                this.form = {}
                this.$router.push('/drivers')
            }
            else this.errors = errors
        },
        async addDriver() {
            const { isSuccess, errors } = await driver.store(this.form, 'invite-drivers')
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Driver successfully created.'})
                this.form = {}
                this.$router.push('/drivers')
            }
            else this.errors = errors
        },
        hasError(name) {
            return _.has(this.errors, name)
        },
        resendInvite () {
            this.isResending = true;
            axiosUtils.post('/resend-invite', this.form)
                .then(response => {
                    this.isResending = false;
                    this.qr_path = response.data?.data?.qr_path;
                    this.$notify({type: 'success', 'text': 'Invitation has been successfully resent.'});
                }).catch(errors => {
                    this.isResending = false;
                    this.$notify({ type: 'error', 'text': 'Resend invite failed' });
                })
        },
        gotoVehicle(id, type){
            this.$router.push(`/${type}/${id}/edit`);
        },
        resolveHistory(id){
            axiosUtils.put(`/request-history/resolve/${id}`, {})
                .then(response => {
                    this.getRequestHistories()
                    this.$notify({ type: 'success', 'text': 'Request information resolved' });
                }).catch(errors => {
                    this.$notify({ type: 'error', 'text': 'Request information resolve failed' });
                })
        },
        deleteHistory(id){
            axiosUtils.delete(`/request-history/${id}`)
                .then(response => {
                    this.getRequestHistories()
                    this.$notify({ type: 'success', 'text': 'Request information deleted' });
                }).catch(errors => {
                    this.$notify({ type: 'error', 'text': 'Request information delete failed' });
                })
        },
        onUpdate(e) {
            this.form.phone_number = e.formattedNumber || '';
        }
    }
}
</script>

<style scoped>
:deep(.input-tel input) {
    border: 1px solid #dee2e6 !important;
}
:deep(.input-tel__input::placeholder) {
    color: transparent !important;
}
:deep(.country-selector input) {
    border: 1px solid #dee2e6 !important;
}
</style>
