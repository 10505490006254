<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "QvinFooter"
})
</script>

<template>
    <div>
        <section class="xl:tw-px-40 tw-pt-10 xl:tw-pt-32 tw-pb-20 tw-bg-qvin-1">
            <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-space-x-10 tw-items-center tw-mx-10 xl:tw-mx-0">
                <div class="tw-bg-white tw-p-5 tw-rounded-2xl">
                    <img src="/img/dot-ready-logo1.png" class="tw-w-3/4 xl:tw-w-fit" alt="Dot Ready">
                </div>
                <p class="tw-text-white tw-font-bold tw-mt-2 xl:tw-mt-0">Q-VIN™ is from the brains behind DOTReady, the
                    trucking compliance and fleet management software currently serving clients with thousands of
                    assets. DOTReady is integrated with Q-VIN™, allowing you to stay ready for the DOT in every way
                    possible. The team at Q-VIN has over 100 years of experience in working with transportation and
                    trucking, owning their own fleets and knowing what needs to be done for trucking to work right.</p>
            </div>
            <div class="w-full tw-pt-0.5 tw-bg-white w-full tw-my-5"></div>
            <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-justify-between tw-items-center tw-mt-10 xl:tw-mt-16">
                <div class="tw-my-2">
                    <img src="/img/qvin-logo-white.png" alt="qvin logo white" class="w-96 h-96">
                    <p class="tw-my-0 tw-text-white tw-font-bold">606 E. Spring Street, Suite</p>
                    <p class="tw-my-0 tw-text-white tw-font-bold">H, Cookeville, Tennessee, TN 38501</p>
                    <p class="tw-my-0 tw-text-white tw-font-bold">Call us at 1-833-339-8434</p>
                </div>

                <div class="tw-text-white tw-font-bold text-center">
                    <p>
                        <a class="tw-no-underline hover:tw-no-underline text-white" href="#">Products</a>
                    </p>
                    <p>
                        <a class="tw-no-underline hover:tw-no-underline text-white" href="#">Services</a>
                    </p>
                    <p>
                        <a class="tw-no-underline hover:tw-no-underline text-white" href="/faq">About Us</a>
                    </p>
                    <p>
                        <a class="tw-no-underline hover:tw-no-underline text-white" href="/contact-us">Contact</a>
                    </p>
                </div>
                <div class="tw-text-white tw-font-bold text-center">
                    <p>
                        <a class="tw-no-underline hover:tw-no-underline text-white" href="/privacy-policy">Privacy
                            Policy</a>
                    </p>
                    <p>
                        <a class="tw-no-underline hover:tw-no-underline text-white" href="/eula">EULA</a>
                    </p>
                    <p>
                        <a class="tw-no-underline hover:tw-no-underline text-white" href="/shipping-policy">Shipping
                            Policy</a>
                    </p>
                    <!-- <p>Refund Policy</p> -->
                </div>

                <div>
                    <p class="tw-text-4xl tw-text-white tw-font-bold tw-text-left">Download Now</p>
                    <div
                        class="tw-bg-qvin-1 w-full xl:tw-w-4/5 tw-rounded-br-3xl tw-flex tw-justify-center tw-items-center">
                        <div class="tw-flex tw-flex-col store-link-container">
                            <img alt=qrr class="tw-bg-white tw-rounded tw-h-36 tw-w-auto" src="/img/qr-google.png">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dotready.qvin">
                                <img alt="google play" class="mobile-provider-logo tw-w-auto"
                                    src="/img/mobile-provider-log-google.png">
                            </a>
                        </div>
                        <div class="tw-flex tw-flex-col store-link-container">
                            <img alt=qrr class="tw-bg-white tw-rounded tw-h-36 tw-w-auto" src="/img/qr-apple.png">
                            <a target="_blank" href="https://apps.apple.com/ph/app/q-vin/id6463773695">
                                <img alt="apple store" class="mobile-provider-logo tw-w-auto"
                                    src="/img/mobile-provider-log-ios.png">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <div class="tw-py-5 xl:tw-px-32 tw-bg-qvin-2">
            <p class="tw-text-white tw-my-0 tw-mx-4">Copyright 2023 Q-VIN LLC. All Rights Reserved</p>
        </div>
    </div>

</template>

<style scoped>
.mobile-provider-logo {
    height: 3.4rem;
}

.mobile-provider-container {
    width: 35%;
}
.store-link-container {
    align-items: center;
}
</style>
