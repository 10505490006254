<template>
    <div class="app">
        <AppHeader fixed style="padding-bottom: 50px">
            <SidebarToggler class="d-lg-none" display="md" mobile/>
            <b-link class="navbar-brand" to="/dashboard">
                <img :src="navbarLogo" alt="DOTReady" class="navbar-brand-full"
                     style="max-width: 120px; max-height: 80px;">
            </b-link>
            <SidebarToggler class="d-md-down-none" display="lg"/>
            <b-navbar-nav class="ml-auto mr-3">
                <DefaultHeaderDropdownAccnt/>
            </b-navbar-nav>
        </AppHeader>
        <div class="app-body">
            <AppSidebar fixed>
                <SidebarNav :navItems="navItems" class="mt-3"></SidebarNav>
                <SidebarFooter/>
                <SidebarMinimizer/>
            </AppSidebar>
            <main class='main'>
                <div class="container-fluid py-4">
                    <router-view/>
                </div>
            </main>
        </div>
        <notifications position="top right"/>
    </div>
</template>

<script>
import nav from '../../NavItemPaths/_nav-admin'
import {
    Aside as AppAside,
    AsideToggler,
    Footer as TheFooter,
    Header as AppHeader,
    Sidebar as AppSidebar,
    SidebarFooter,
    SidebarMinimizer,
    SidebarNav,
    SidebarToggler
} from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import _ from 'lodash'

export default {
    name: 'AdminContainer',
    components: {
        AsideToggler,
        AppHeader,
        AppSidebar,
        AppAside,
        TheFooter,
        DefaultAside,
        DefaultHeaderDropdownAccnt,
        SidebarFooter,
        SidebarToggler,
        SidebarNav,
        SidebarMinimizer
    },
    data() {
        return {}
    },
    computed: {
        navItems: function () {
            let navItems = [];

            const role = this.$store.getters['user/getRoles'];
            const user = this.$store.getters['user/getUser'];
            _.each(nav.items, function (navItem) {
                if (navItem.roles.includes(role)) {
                    if ((role === 'fleet_owner' || role === 'trailer_owner') && navItem.name === 'Admin Users') {
                        navItem.name = 'Company User'
                    }
                    if ((role === 'fleet_owner' && user?.subscription_status !== 'active')) return false;

                    navItems.push(navItem);
                }
            });

            return navItems;
        },
        navbarLogo: function () {
            return '/img/QVIN-Logo-no-sl.png';
        }
    },
}
</script>
