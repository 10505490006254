<script>
import _ from 'lodash';
import VueCharts from 'vue-chartjs'
import { Line } from 'vue-chartjs'

export default {
    name: 'SubscribersGraph',
    extends: Line,
    props: {
        data: {
            default: null
        }
    },
    data() {
       return {
           chartData: this.data
       }
    },
    mounted () {
        let labels = [];
        let data = [];
        _.each(this.chartData, function (interval) {
            labels.push(interval.label);
            data.push(interval.total);
        });

        this.renderChart({
                labels: labels,
                datasets: [
                    {
                        label: 'Subscribers',
                        backgroundColor: '#44abb6',
                        data: data
                    }
                ]
            },{maintainAspectRatio:false},
            {legend: { display: false },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                    }]
                }
            },)
    }
}

</script>
