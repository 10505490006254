<template>
    <router-view></router-view>
</template>

<script>
import {ServiceFactory} from "./utils/ServiceFactory";
import JsCookie from "js-cookie";

const auth = ServiceFactory.get('auth')
export default {
    name: "App.vue",
    async created() {
       const hasToken = JsCookie.get('token');
       if (hasToken) {
           const {isSuccess, data, errors} = await auth.me();
           if (isSuccess) {
               await this.$store.dispatch("user/setUser", data)
           }
           else console.log(errors)
       };
    }
}
</script>

<style scoped>

</style>
