<template>
    <div>
        <v-select v-model="selectedOption" :disabled="disabled" v-on:input="handleSelection" :taggable="taggable" :options="options" :multiple="multi">
        </v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select';

export default {

    data: function () {
        return {
            selectedOption: null,
            loaded: false
        }
    },

    props: {
        alphaSort: {
            type: Boolean,
            default: true
        },
        multi: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        selected: {
            default: null
        },
        options: {
            type: Array,
            default: function () { return []; }
        },
        valueAttr: {
            type: String,
            default: 'value'
        },
        labelAttr: {
            type: String,
            default: 'label'
        },
        syncType: {
            type: String,
            default: 'value'
        },
        value: {
            default: null
        },
        taggable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    components: {
        vSelect
    },

    created: function () {

        let vm = this;

        new Promise((resolve, reject) => {
            vm.initValues();
            resolve();
        }).then(() => {
            vm.loaded = true;
        });

    },

    computed: {
        optionList: function () {
            let vm = this;
            return _.map(vm.options, function (option) {
                if (typeof option === 'object' && option !== null) {
                    let optionLabel = _.get(option, vm.labelAttr, null);
                    let optionValue = _.get(option, vm.valueAttr, null);

                    if (optionLabel && optionValue) {
                        return {
                            label: optionLabel,
                            value: optionValue
                        }
                    } else if (optionValue) {
                        return optionValue;
                    }
                } else if (option !== null) {
                    return option;
                }
            });
        }
    },

    watch: {

        selectedOption: function (newVal, oldVal) {
            return;
            let vm = this;

            if (!vm.loaded) return;

            if (vm.syncType == 'value') {
                if (vm.multi) {
                    let returnArray = [];

                    _.each(vm.selectedOption, function (selected) {
                        let optionValue = _.get(selected, 'value', null);
                        if (optionValue) {
                            returnArray.push(selected.value);
                        }
                        else {
                            returnArray.push(selected);
                        }
                    });
                    this.$emit('input', vm.selectedOption);
                }
                else {
                    let optionValue = _.get(vm.selectedOption, 'value', null);
                    if (optionValue) {
                        this.$emit('input', optionValue);
                    }
                    else {
                        this.$emit('input', vm.selectedOption);
                    }
                }
            }
            else {
                this.$emit('input', vm.selectedOption);
            }
        }

    },

    methods: {

        initValues: function () {
            if (this.value !== null) {
                this.setSelection(this.value);
            }
        },

        setSelection: function (selectedValue) {
            let vm = this;

            vm.loaded = false;

            new Promise((resolve, reject) => {

                if (selectedValue!==null && typeof selectedValue !== 'object') {
                    // this.value is a string
                    vm.selectedOption = _.find(vm.options, function (option) {
                        if (typeof option === 'object') {
                            return option.value == selectedValue;
                        }
                        else {
                            return option == selectedValue;
                        }
                    });
                }
                else if (_.isArray(selectedValue)) {
                    let selected = [];

                    _.each(selectedValue, function (selectedOption) {
                        let foundOption = _.find(vm.options, function (option) {
                            if (typeof option === 'object') {
                                return option.value == selectedOption;
                            }
                            else {
                                return option == selectedOption;
                            }
                        });

                        if (foundOption) {
                            selected.push(foundOption);
                        }
                    });

                    vm.selectedOption = selected;
                }
                else if (_.isNull(selectedValue)) {
                    vm.selectedOption = null;
                }

                resolve();
            }).then(() => {
                vm.loaded = true;
            });
        },

        handleSelection: function (val) {
            let vm = this;

            if (!vm.loaded) return;

            if (vm.syncType == 'value') {
                if (vm.multi) {
                    this.$emit('input', val);
                }
                else {
                    let optionValue = _.get(val, 'value', null);
                    if (optionValue) {
                        this.$emit('input', optionValue);
                    }
                    else {
                        this.$emit('input', val);
                    }
                }
            }
            else {
                this.$emit('input', val);
            }

        }

    }

}
</script>

<style type="scss">
.v-select .dropdown-toggle {
    background-color: #ffffff;
}

.v-select.dropdown .dropdown-toggle::after {
    content: inherit;
}

.v-select.dropdown .form-control {
    height: 25px;
    line-height: 1.5;
    padding: inherit;
}

.v-select.dropdown .dropdown-toggle::after {
    display: none;
}

.v-select .dropdown-toggle {
    border-color: #e4e7ea;
}

.is-invalid .v-select .dropdown-toggle {
    border-color: #f86c6b;
}
</style>
