<template>
    <div class="custom-table">
        <table class="table table-striped">
            <thead class="thead-info">
            <th>Name</th>
            <th>Driver</th>
            </thead>
            <tbody>
            <tr v-for="(list, index) in lists" :key="index">
                <td>{{list.name}}</td>
                <td>{{formatDriverName(list)}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "PanelList",
    props: ['lists'],
    methods: {
        formatDriverName(list) {
            if(!list.drivers) {
                return '-'
            }
            if (list.drivers.length <= 0) {
                return '-'
            }
            return `${list.drivers[0].first_name} ${list.drivers[0].last_name}`;
        }
    }
})
</script>

<style scoped>
    .custom-table {
        height: 250px;
        overflow-y: auto;
    }
</style>
