<template>
    <div>
        <Nav bg="blue" />
        <div class="container py-5">
            <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-space-x-20">
                <div class="w-full tw:tw-w-1/2 tw-px-10 tw-pt-20 tw-pb-5  rounded tw-shadow-2xl xl:tw-shadow-2xl">
                    <p class="text-center tw-text-3xl tw-font-bold">Contact Us</p>
                    <p class="text-center tw-text-sm tw-text-gray-500 tw-mb-10">Send us a message, compliments, or any
                        feedback you have. We read every message and respond as quickly as possible.</p>
                    <div class="tw-flex tw-space-x-3 tw-my-5">
                        <input type="text" class="form-control" placeholder="First Name">
                        <input type="text" class="form-control" placeholder="Last Name">
                    </div>
                    <div class="tw-my-5">
                        <input type="text" class="form-control" placeholder="Company Name">
                    </div>
                    <div class="tw-my-5">
                        <input type="text" class="form-control" placeholder="Phone Number">
                    </div>
                    <div class="tw-my-5">
                        <input type="email" class="form-control" placeholder="Email Address">
                    </div>
                    <div class="tw-my-5">
                        <textarea rows="10" cols="10" class="form-control"></textarea>
                    </div>
                    <div class="tw-my-5">
                        <p class="tw-text-sm">
                            This site is protected by reCAPTCHA and the
                            <span class="tw-text-blue-700 tw-font-bold">Google Privacy Policy</span> and
                            <span class="tw-text-blue-700 tw-font-bold">
                                <a class="tw-no-underline hover:tw-no-underline" href="/terms-of-service"> Terms of
                                    Service
                                </a>
                            </span>
                            apply.
                        </p>
                        <button class="btn btn-block btn-info tw-text-white tw-py-5">Submit</button>
                    </div>
                </div>
                <div
                    class="w-full xl:tw-w-1/2 tw-bg-qvin-1 tw-h-fit tw-text-white tw-font-bold rounded tw-mt-10 xl:tw-mt-0">
                    <div class="tw-p-10">
                        <p class="tw-text-3xl">Sales</p>
                        <div class="tw-flex tw-space-x-5">
                            <p>1-833-339-8434</p>
                            <p><a class="tw-no-underline hover:tw-no-underline text-white" href="mailto:info@q-vin.com">info@q-vin.com</a></p>
                        </div>
                        <p class="tw-text-3xl">Customer Support</p>
                        <div class="tw-flex tw-space-x-5">
                            <p>1-833-339-8434</p>
                            <p><a class="tw-no-underline hover:tw-no-underline text-white" href="mailto:info@q-vin.com">info@q-vin.com</a></p>
                        </div>
                        <p class="tw-text-3xl">Headquarters</p>
                        <div class="tw-flex tw-space-x-5">
                            <p>1-833-339-8434</p>
                            <p><a class="tw-no-underline hover:tw-no-underline text-white" href="mailto:info@q-vin.com">info@q-vin.com</a></p>
                        </div>
                    </div>
                    <div class="tw-bg-qvin-2 tw-p-10 rounded-bottom">
                        <div class="tw-flex tw-justify-between tw-items-center ">
                            <p class="my-0 tw-text-3xl tw-w-1/2">Join thousands of satisfied customers using
                                Q-VIN&trade;</p>
                            <a class="tw-py-2 tw-px-3 tw-rounded tw-bg-white tw-border-0 tw-text-sm tw-text-qvin-1 tw-font-bold tw-no-underline hover:tw-no-underline"
                                href="/book-a-demo">Book A Demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QvinFooter />
    </div>
</template>

<script>
import {ServiceFactory} from "../utils/ServiceFactory";

const authService = ServiceFactory.get('auth')
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import {
    Aside as AppAside,
    AsideToggler,
    Footer as TheFooter,
    Header as AppHeader,
    Sidebar as AppSidebar, SidebarFooter, SidebarMinimizer, SidebarNav, SidebarToggler
} from "@coreui/vue";
import DefaultAside from "../components/containers/DefaultAside.vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";

export default {
    name: 'ContactUs',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
    },
    data() {
        return {
            email: '',
            password: '',
            password_confirmation: '',
            first_name: '',
            last_name: '',
            errors: null,
            plans: [
                {
                    title: 'Starter',
                    description: 'For fleets that want to organize vehicle inventory and manage inspections',
                    monthly_pricing: '4',
                    annual_pricing: '3',
                    other_info_monthly: 'per vehicle, per month billed monthly, $3 billed annually',
                    other_info_annually: 'per vehicle, per month billed annually, $4 billed monthly',
                    headings: 'Top features:',
                    features: [
                        {
                            title: 'Vehicle Inventory Management',
                            description: 'Store detailed vehicle and large asset records and pull 90+ vehicle specs with the VIN Decoder.'
                        },
                        {
                            title: 'Inspections',
                            description: 'Receive custom or OEM-recommended service reminders automatically.'
                        },
                        {
                            title: 'PM Scheduling & Reminders',
                            description: 'Store detailed vehicle and large asset records and pull 90+ vehicle specs with the VIN Decoder.'
                        },
                        {
                            title: 'Maintenance Tracking',
                            description: 'Schedule and track planned and unplanned maintenance activities.'
                        },
                        {
                            title: 'Reporting',
                            description: 'Understand total cost of ownership and see detailed vehicle insights.'
                        },
                    ]
                },
                {
                    title: 'Pro',
                    description: 'For fleets that want to centralize fleet data and automate outsourced maintenance',
                    monthly_pricing: '6',
                    annual_pricing: '5',
                    other_info_monthly: 'per vehicle, per month billed monthly, $5 billed annually',
                    other_info_annually: 'per vehicle, per mon  th billed annually, $6 billed monthly',
                    headings: 'Everything in Starter, plus:',
                    features: [
                        {
                            title: 'Outsourced Maintenance',
                            description: 'Automate maintenance approvals and get one bill, that includes all vendors.'
                        },
                        {
                            title: 'Fuel & Telematics Integrations',
                            description: 'Sync mileage, location and fuel usage to flag exceptions in real-time.'
                        },
                        {
                            title: 'Vehicle Assignments & Scheduling',
                            description: 'Manage assignments, notify operators and analyze vehicle utilization.'
                        },
                        {
                            title: 'Fault & Recall Management',
                            description: 'Automatically trigger maintenance workflows from DTC faults and NHTSA recalls.'
                        },
                        {
                            title: 'Customizable Roles & Data Fields',
                            description: 'Tailor user permissions and create data fields to suit your organization.'
                        },
                        {
                            title: 'API Access',
                            description: 'Read and write data access with internal or third-party software applications.'
                        },
                    ]
                },
                {
                    title: 'Advanced',
                    description: 'For fleets that perform maintenance in-house or keep parts on hand',
                    monthly_pricing: '8',
                    annual_pricing: '7',
                    other_info_monthly: 'per vehicle, per month billed monthly, $8 billed annually',
                    other_info_annually: 'per vehicle, per mon  th billed annually, $7 billed monthly',
                    headings: 'Everything in Pro, plus:',
                    features: [
                        {
                            title: 'Work Order Management',
                            description: 'Create and assign work orders; track maintenance from start to finish.'
                        },
                        {
                            title: 'Parts & Inventory Management',
                            description: 'Avoid unnecessary inventory expenses and stockouts with usage and cost tracking.'
                        },
                        {
                            title: 'Purchase Orders',
                            description: 'Generate, track and approve POs, and automatically validate received orders.'
                        },
                        {
                            title: 'Webhooks',
                            description: 'Automatically push data to other systems and applications based on Fleetio events.'
                        },
                    ]
                }
            ],
            counter: 5,
            selectedPayment: 'monthly'
        }
    },
    methods: {
        async submitHandler() {
            const {isSuccess, errors, data} = await authService.signUp('/signup', {
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                first_name: this.first_name,
                last_name: this.last_name
            })
            if (isSuccess) {
                window.location.href = '/dashboard'
            } else {
                this.errors = errors
                console.log(errors)
            }
        },
        totalCostComputation(plan) {
           const pricing = this.selectedPayment === 'monthly' ? plan.monthly_pricing : plan.annual_pricing
           return this.counter * pricing
        },
        setPayment(mode) {
            this.selectedPayment = mode
        }
    }
}
</script>

<style scoped>
    .top-feature {
       font-size: 12px;
    }

    .feature-description {
        font-size: 14px;
    }

</style>
