<template>
    <div class="m-auto">
        <div class="card mx-5 p-4">
            <div class="row text-center">
                <div><img :src="qvinLogoUrl" alt="QVIN" style="height: 7vh" /></div>
                <div>
                    <p class="font-weight-900 mb-1 font-xl">
                        Let's update your Q-VIN<sup>TM</sup> subscription.
                    </p>
                    <p class="font-weight-900 mb-1 font-xl">
                        You currently have {{ form.vehicle_count }} vehicles signed up on a {{
                        form.billing_period }} account.
                    </p>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-8 text-center">
                    <p class="font-weight-900 font-lg text-gray-primary">
                        How many vehicles do you want to sign up?
                    </p>
                    <button type="button"
                        class="btn btn-info font-weight-900 font-xl mb-5 py-3 text-white tw-bg-qvin-2 w-100"
                        @click="updateVehicleCount()">
                        All of my vehicles
                    </button>
                    <p class="font-weight-900 font-2xl text-gray-primary">or</p>
                    <div class="m-auto mt-5 p-0 px-3 rounded text-center slider-main-container">
                        <div class="row relative py-1">
                            <div class="col-lg-9 col-xl-10 col-8 pr-0 py-2 slider-container">
                                <vue-slider v-model="vehicle_count" :dot-options="dotOptions" :max="3000"
                                    :contained="true" v-bind="sliderOptions">
                                    <template v-slot:tooltip="tooltip">
                                        <div>
                                            <img src="/images/truck-slider.png" alt="QVIN" style="height: 2.8rem" />
                                        </div>
                                    </template>
                                </vue-slider>
                            </div>
                            <div class="col-lg-3 col-xl-2 col-4 align-content-center">
                                <input name="vehicle_count" v-model="vehicle_count" type="number" max="3000"
                                    class="border font-weight-bold font-xl form-control text-center tw-text-qvin-2">
                            </div>
                        </div>
                    </div>
                    <p class="font-weight-bold text-gray-primary mt-3">{{ vehicle_count }} Vehicles</p>
                    <div class="mt-4">
                        <p class="font-weight-900 font-sm">Sign up for Annual and receive <span class="font-italic">1
                                month
                                free!</span></p>
                        <div class="d-flex justify-content-center">
                            <div class="border border-2 p-1 tw-rounded-3xl">
                                <div class="tw-flex tw-items-center tw-space-x-2 tw-rounded-3xl">
                                    <div :class="`tw-px-6 tw-py-2 tw-rounded-3xl tw-cursor-pointer ${billing_period === 'monthly' ? 'tw-bg-qvin-2 tw-text-white' : ''}`"
                                        @click="setPayment('monthly')">
                                        <p class="my-0">Monthly</p>
                                    </div>
                                    <div :class="`tw-px-6 tw-py-2 tw-rounded-3xl tw-cursor-pointer ${billing_period === 'yearly' ? 'tw-bg-qvin-2 tw-text-white' : ''}`"
                                        @click="setPayment('yearly')">
                                        <p class="my-0">Yearly</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="align-items-center mt-4">
                        <div class="form-check">
                            <input v-model="aggreToTerms" class="form-check-input" type="checkbox" id="aggreeToTerms" />
                            <label class="form-check-label ml-1 font-weight-500 font-sm" for="aggreeToTerms">
                                I agree to the <a href="#" class="text-decoration-none text-info">Terms and
                                    Conditions</a> and
                                <a href="#" class="text-decoration-none text-info">Privacy Policy</a> of
                                Q-VIN<sup>TM</sup>.
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="m-auto summary-container">
                        <p class="font-weight-900 mb-1 mt-4 text-gray-primary">Summary</p>
                        <div class="border-1 p-2 summary-sub-container">
                            <p class="font-weight-900 m-0 font-lg text-gray-primary">QVIN<sup>TM</sup> - {{ form.plan }}
                                Plan
                            </p>
                            <p class="font-sm text-gray-primary">{{ vehicle_count }} Vehicles X ${{
                                form.rate }} / {{ billing_period == 'monthly' ? 'month' : 'year' }}
                            </p>

                            <div class="tw-flex font-weight-900 justify-content-between mt-5 font-lg text-gray-primary">
                                <p>Total</p>
                                <p>
                                    {{ totalPrice.toLocaleString('en-US', { minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    }) }} USD
                                </p>

                            </div>
                            <p class="line-height-1 text-gray-secondary text-justify font-sm">By starting Q-VIN™,
                                you
                                acknowledge that
                                your subscription
                                will be renewed {{ billing_period }},
                                and will be included as part of your DOTReady billing.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-column mt-4 row">
                <button type="button"
                    class="btn btn-info font-lg font-weight-bold m-auto mb-1 px-5 py-3 text-white w-auto"
                    :disabled="vehicle_count < 1 || !aggreToTerms || updating" @click="updateSub"> Update Q-VIN
                </button>
                <button type="button" class="btn btn-light font-sm m-auto text-gray-primary w-auto" @click="cancel"
                    :disabled="updating">Cancel Q-VIN
                    Subscription</button>
            </div>
        </div>
        <div>
            <b-modal ref="cancel-modal" size="xl" hide-footer centered>
                <template #modal-title></template>
                <div class="d-block">
                    <div class="row text-center">
                        <div><img :src="qvinLogoUrl" alt="QVIN" style="height: 7vh" /></div>
                        <div>
                            <p class="font-weight-900 font-xl tw-mb-20">
                                Are you sure you wan to cancel?
                            </p>
                            <p class="font-weight-900 mb-7 font-lg">
                                You'll lose coverage on {{ form.vehicle_count }} vehicles, which risks a potential
                                $270,000 in fines without
                                DVIRs!
                            </p>
                            <p class="font-weight-900 mb-6 font-lg">
                                This will delete ALL of your data with us.
                            </p>
                            <p class="font-weight-900 mb-6 font-lg">
                                Are you really going to trust your drivers to manually do DVIRs again?
                            </p>
                        </div>
                    </div>
                    <div class="flex-column mb-3 mt-5 pt-5 row">
                        <button type="button"
                            class="btn btn-info font-lg font-weight-bold m-auto mb-2 px-4 py-2 text-white w-auto"
                            @click="displayCancelModal(false)" :disabled="updating">
                            Just kidding, take me back.
                        </button>
                        <button type="button" class="btn btn-light font-xs m-auto text-gray-primary w-auto"
                            @click="proceedCancel" :disabled="updating">I'm sure, please cancel my Q-VIN
                            Subscription</button>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import '../../../css/slider.css'
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { ServiceFactory } from "../../utils/ServiceFactory";
import axiosUtils from "../../utils/axiosUtil";
const auth = ServiceFactory.get('auth')
export default {
    name: 'UpdatePlan',
    components: {
        VueSlider
    },
    data() {
        return {
            qvinLogoUrl: '/img/QVIN-Logo-no-sl.png',
            user: null,
            billing_period: 'monthly',
            vehicle_count: 1,
            max_vehicles: 3000,
            form : {
                user_id : null,
                subscribed_vehicles: 0,
                billing_period: null,
                plan: null,
                vehicle_count: 0,
                rate: 0,
                total_amount: 0,
            },
            dotOptions: [
                {
                    tooltip: 'always',
                    dotStyle: {
                        zIndex: 3000
                    }
                },
                {
                    tooltip: 'none',
                    disabled: true
                }
            ],
            sliderOptions: {
                dotSize: 20,
            },
            aggreToTerms: false,
            updating: false,
        }
    },
    async created() {
        this.aggreToTerms = true;
        await this.getData();
    },
    computed: {
        totalPrice: function () {
            if (!this.form.plan) return 0;
            let months = this.billing_period === 'monthly' ? 1 : 11;
            let total = (this.vehicle_count * this.form.rate) * months;
            return total;
        },
    },
    methods: {
        setPayment(mode) {
            this.billing_period = mode;
        },
        async getData() {
            axiosUtils.get('/subscribe')
            .then(response => {
                let { billing_period, cancel, num_of_all_vehicles, plan, remaining_vehicles, subscribed_vehicles, total_amount, total_vehicles, user_id } = response.data.data;
                this.form.subscribed_vehicles = subscribed_vehicles;
                this.form.billing_period = billing_period;
                this.form.plan = plan;
                this.form.vehicle_count = total_vehicles;
                this.form.user_id = user_id;
                this.max_vehicles = (total_vehicles > num_of_all_vehicles) ? total_vehicles : num_of_all_vehicles;
                this.vehicle_count = total_vehicles;
                this.billing_period = billing_period;
            }).catch(error => {
                let message = error.response.data.message || 'Something went wrong.';
                this.$notify({ type: 'error', 'text': message });
            });
        },
        updateSub(){
            this.$router.push(`/billing?billing_period=${this.billing_period}&count=${this.vehicle_count}&amount=${this.form.total_amount}&rate=${this.form.rate}`)
            // let form = {
            //     billing_period: this.billing_period,
            //     vehicle_count: this.vehicle_count,
            //     total_amount: this.form.total_amount,
            //     rate: this.form.rate
            // };
            // this.updating = true;
            // axiosUtils.put('/subscribe', form)
            //     .then(response => {
            //         if(response) {
            //             this.$notify({ type: 'success', 'text': "Q-VIN subscription successfully updated!" });
            //             this.updating = false;
            //             this.$router.push('/dashboard')
            //         }
            //     }).catch(error => {
            //         this.updating = false;
            //         let message = error.response.data.message || 'Something went wrong.';
            //         this.$notify({ type: 'error', 'text': message });
            // })
        },
        updateVehicleCount(){
            this.vehicle_count = this.max_vehicles;
        },
        cancel(){
            this.displayCancelModal(true);
        },
        proceedCancel(){
            this.updating = true;
            axiosUtils.put('/cancel/subscription', {})
                .then(response => {
                    if (response) {
                        this.displayCancelModal(false);
                        this.$notify({
                            'type': 'success', 
                            'title': 'Subscription Cancelled', 
                            'text': "We're sorry to see you go! Your subscription has been successfully cancelled. You will now be logged out as part of this process." 
                        });
                        this.updating = false;
                        this.$router.push('/dashboard');
                        setTimeout(() => {
                            this.logout();
                        }, 2000);
                    }
                }).catch(error => {
                    this.updating = false;
                    let message = error.response.data.message || 'Something went wrong.';
                    this.$notify({ type: 'error', 'text': message });
                })
        },
        displayCancelModal(show){
            if(show){
                this.$refs['cancel-modal'].show();
            } else this.$refs['cancel-modal'].hide();
        },
        totalCostComputation(plan) {
            const counter = this.vehicle_count;
            if (plan === 'Starter') {
                if (counter < 101) {
                    return 21;
                } else if (counter >= 101 && counter <= 300) {
                    return 18;
                } else if (counter >= 301 && counter <= 1500) {
                    return 15;
                } else if (counter >= 1501 && counter <= 3000) {
                    return 10;
                }

            } else if (plan === 'Pro') {
                if (counter < 101) {
                    return 30;
                } else if (counter >= 101 && counter <= 300) {
                    return 27;
                } else if (counter >= 301 && counter <= 1500) {
                    return 24;
                } else if (counter >= 1501 && counter <= 3000) {
                    return 18;
                }
            } else {
                return ''
            }
        },
        async logout() {
            const { isSuccess } = await auth.signOutUser('/signout')
            if (isSuccess) window.location.href = '/pricing';
            else this.$notify({ type: 'error', text: 'Something went wrong.' })
        }
    },
    watch: {
        vehicle_count: function () {
            this.form.rate = this.totalCostComputation(this.form.plan);
            this.form.total_amount = this.totalPrice;
        },
        billing_period: function () {
            this.form.total_amount = this.totalPrice;
        }
    }
}
</script>

<style scoped>
.top-feature {
    font-size: 12px;
}

.feature-description {
    font-size: 14px;
}

.slider-container {
    padding-top: 0.7rem;
}

.vue-slider {
    height: 10px !important;
}

:deep(.vue-slider-dot-tooltip-top) {
    top: -5px !important;
}

:deep(.vue-slider-dot-handle) {
    background-color: #025490;
}

:deep(.vue-slider-process) {
    background-color: #025490;
}

.text-gray-primary {
    color: #242424;
}

.text-gray-secondary {
    color: #979797;
}

.slider-main-container {
    box-shadow: 0 0 10px rgb(0 0 0 / 36%);
}
.font-weight-900 {
    font-weight: 900 !important;
}
</style>
