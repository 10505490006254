<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between my-2">
            <h1 class="tw-font-bold tw-text-2xl">Users</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/users/add" role="button"><i
                        class="fa fa-plus"></i> Add User</router-link>
            </div>
        </div>
        <b-card>
            <div class="d-flex justify-content-start gap-3 mb-3">
                <div>
                    <select class="form-select" aria-label="Default select example" v-model="filterBy">
                        <option selected value="" disabled>Filter By...</option>
                        <option value="name">Name</option>
                        <option value="email">Email</option>
                        <option value="nickname">Nickname</option>
                    </select>
                </div>

                <div v-if="filterBy">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                        <input v-model="search" @input="debounceInput" type="text" class="form-control"
                            placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                </div>
            </div>
            <vuetable ref="vuetable" :api-mode="false" :fields="fields" :per-page="perPage" :data-manager="dataManager"
                pagination-path="pagination" :sort-params="getSortParam" :css="css.table">
                <template slot="roles" slot-scope="props">
                    <p>{{ formatRole(props.rowData)}}</p>
                </template>
                <div slot="actions" slot-scope="props">
                    <button class="btn btn-info btn-sm" @click="editHandler(props.rowData)">
                        <i class="fa fa-edit text-white"></i>
                    </button>
                    <button class="btn btn-danger btn-sm" @click="deleteHandler(props.rowData)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </vuetable>
            <div class="d-flex justify-content-end mt-3">
                <Pagination :data="data" :limit="5" @pagination-change-page="changePage" />
            </div>
        </b-card>
    </div>
</template>

<script>
import { ServiceFactory } from "../../utils/ServiceFactory";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue'
import _ from "lodash";
import Spinner from 'vue-simple-spinner';

const user = ServiceFactory.get('user')

export default {
    name: "User",
    components: {
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        Pagination,
        Spinner
    },

    data() {
        return {
            fields: [
                {
                    name: 'full_name',
                    title: 'Full Name',
                    sortField: 'first_name'
                },
                {
                    name: 'email',
                    sortField: 'email'
                },
                {
                    name: 'nickname',
                    sortField: 'nickname'
                },
                {
                    name: 'roles',
                    title: 'Role',
                },
                'actions'
            ],
            perPage: 15,
            data: [],
            currentPage: 1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false
        };
    },
    computed: {
        isAdmin() {
            return this.$store.getters["user/getRoles"] === 'admin'
        }
    },

    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.refresh();
        },
        sortField(n, o) {
            this.getData()
        }
    },

    async created() {
        await this.getData()
    },

    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        getSortParam(sortOrder) {
            console.log(sortOrder)
        },
        changePage(page) {
            this.currentPage = page
            this.getData()
        },
        async getData() {
            this.loading = true
            const { isSuccess, data } = await user.getUsers({
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search
            });
            if (isSuccess) this.data = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
        dataManager(sortOrder) {
            if (sortOrder.length > 0) {
                const direction = (sortOrder[0].direction === 'desc' ? '-' : '')
                this.sortField = `${direction}${sortOrder[0].sortField}`
            }
            return { data: _.slice(this.data.data) };
        },
        editHandler(data) {
            this.$router.push(`/users/${data.id}/edit`)
        },
        async deleteHandler(data) {
            const vm = this
            this.$dialog.confirm('Are you sure, you want to delete this item?')
                .then(async function (dialog) {
                    const { isSuccess } = await user.deleteUser(data.id)
                    if (isSuccess) {
                        await vm.getData()
                        vm.$notify({ type: 'success', 'text': 'Admin user successfully deleted.' })
                    }
                });
        },
        formatRole(data) {
            return _.startCase(data.roles)
        }
    }
};
</script>
