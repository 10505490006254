import Dashboard from "../pages/Dashboard.vue";
import AdminUser from "../pages/AdminUser.vue";
import Subscribers from "../pages/Subscribers/Subscribers.vue";
import Trucks from "../pages/Trucks/Trucks.vue";
import Equipments from "../pages/Equipments/Equipments.vue";
import FormAdmin from "../pages/AdminUser/FormAdmin.vue";
import Setting from "../pages/AdminUser/Setting.vue";
import FormSubscriber from "../pages/Subscribers/FormSubscriber.vue";
import FormEquipment from "../pages/Equipments/FormEquipment.vue";
import FormTruck from "../pages/Trucks/FormTruck.vue";
import Locations from "../pages/Locations/Locations.vue";
import LocationForm from "../pages/Locations/LocationForm.vue";
import Inspections from "../pages/Inspections/Inspections.vue";
import FormInspections from "../pages/Inspections/FormInspections.vue";
import WorkOrders from "../pages/WorkOrders/WorkOrders.vue";
import FormWorkOrder from "../pages/WorkOrders/FormWorkOrder.vue";
import Fleets from "../pages/Fleets/Fleets.vue";
import FleetForm from "../pages/Fleets/FleetForm.vue";
import FleetEditForm from "../pages/Fleets/FleetEditForm.vue";
import DashboardViewSetting from "../pages/DashboardViewSetting.vue";
import InviteDriver from "../pages/Driver/InviteDriver.vue";
import Drivers from "../pages/Driver/Drivers.vue";
import FormDriver from "../pages/Driver/FormDriver.vue";
import FormRequestHistory from "../pages/Driver/FormRequestHistory.vue";
import Companies from "../pages/Companies/Companies.vue";
import CompanyForm from "../pages/Companies/CompanyForm.vue";
import Mechanic from "../pages/Mechanics/Mechanic.vue";
import FormMechanic from "../pages/Mechanics/FormMechanic.vue";
import PoliceOfficer from "../pages/PoliceOfficers/PoliceOfficer.vue";
import FormPoliceOfficer from "../pages/PoliceOfficers/FormPoliceOfficer.vue";
import User from "../pages/User/Users.vue";
import FormUser from "../pages/User/FormUser.vue";
import UpdatePlan from "../pages/Plan/UpdatePlan.vue";
import UpdateBilling from "../pages/Plan/UpdateBilling.vue";
import DemoUserWizard from "../pages/DemoUserWizard.vue";


export default [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/dashboard/view-setting',
        name: 'DashboardViewSetting',
        component: DashboardViewSetting,
    },
    {
        path: '/settings',
        name: 'settings',
        component: Setting
    },
    {
        path: '/billing',
        name: 'Billing',
        component: UpdateBilling
    },
    {
        path: '/update-plan',
        name: 'UpdatePlan',
        component: UpdatePlan
    },
    {
        path: '/adminusers',
        name: 'AdminUser',
        component: AdminUser,
    },
    {
        path: '/adminusers/add',
        name: 'AddAdmin',
        component: FormAdmin
    },
    {
        path: '/adminusers/:id/edit',
        name: 'EditAdmin',
        component: FormAdmin
    },
    {
        path: '/subscribers',
        name: 'Subscriber',
        component: Subscribers
    },
    {
        path: '/subscribers/add',
        name: 'SubscriberAdd',
        component: FormSubscriber
    },
    {
        path: '/subscribers/:id/edit',
        name: 'SubscriberEdit',
        component: FormSubscriber
    },
    {
        path: '/fleets',
        name: 'Fleet',
        component: Fleets,
    },
    {
        path: '/fleets/add',
        name: 'fleets.add',
        component: FleetForm,
    },
    {
        path: '/fleets/:id/edit',
        name: 'Fleet',
        component: FleetEditForm
    },
    {
        path: '/trucks/add',
        name: 'TruckAdd',
        component: FormTruck
    },
    {
        path: '/trucks',
        name: 'Trucks',
        component: Trucks
    },
    {
        path: '/trucks/:id/edit',
        name: 'Trucks',
        component: FormTruck
    },
    {
        path: '/trailers',
        name: 'Trailers',
        component: Trucks
    },
    {
        path: '/trailers/:id/edit',
        name: 'Trailers',
        component: FormTruck
    },
    {
        path: '/equipments',
        name: 'Equipments',
        component: Equipments
    },
    {
        path: '/equipments/add',
        name: 'EquipmentAdd',
        component: FormEquipment
    },
    {
        path: '/equipments/:id/edit',
        name: 'EquipmentEdit',
        component: FormEquipment
    },
    {
        path: '/inspections',
        name: 'Inspection',
        component: Inspections
    },
    {
        path: '/inspections/add',
        name: 'InspectionAdd',
        component: FormInspections
    },
    {
        path: '/inspections/:id/edit',
        name: 'InspectionEdit',
        component: FormInspections
    },
    {
        path: '/work-orders',
        name: 'WorkOrder',
        component: WorkOrders
    },
    {
        path: '/work-orders/add',
        name: 'WorkOrderAdd',
        component: FormWorkOrder
    },
    {
        path: '/work-orders/:id/edit',
        name: 'WorkOrderEdit',
        component: FormWorkOrder
    },
    {
        path: '/invite-drivers',
        name: 'InviteDriver',
        component: InviteDriver
    },
    {
        path: '/drivers',
        name: 'Drivers',
        component: Drivers
    },
    {
        path: '/drivers/:id/edit',
        name: 'EditDrivers',
        component: FormDriver
    },
    {
        path: '/request-history/:driver_id',
        name: 'RequestHistory',
        component: FormRequestHistory
    },
    {
        path: '/locations',
        name: 'Locations',
        component: Locations,
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: ':id/edit',
                component: LocationForm,
                name: 'locations.edit'
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'add',
                component: LocationForm,
                name: 'locations.add'
            },
        ],
    },
    {
        path: '/companies',
        name: 'Companies',
        component: Companies,
        children: [
            {
                path: ':id/edit',
                component: CompanyForm,
                name: 'companies.edit'
            },
            {
                path: 'add',
                component: CompanyForm,
                name: 'companies.add'
            },
        ],
    },
    {
        path: '/mechanics',
        name: 'Mechanics',
        component: Mechanic
    },
    {
        path: '/mechanics/add',
        name: 'AddMechanic',
        component: FormMechanic
    },
    {
        path: '/mechanics/:id/edit',
        name: 'EditMechanic',
        component: FormMechanic
    },
    {
        path: '/police-officers',
        name: 'PoliceOfficers',
        component: PoliceOfficer
    },
    {
        path: '/police-officers/:id/edit',
        name: 'EditPoliceOfficer',
        component: FormPoliceOfficer
    },
        {
        path: '/users',
        name: 'User',
        component: User
    },
    {
        path: '/users/add',
        name: 'AddUser',
        component: FormUser
    },
    {
        path: '/users/:id/edit',
        name: 'EditUser',
        component: FormUser
    },
    {
        path: '/demo-user',
        name: 'DemoUserWizard',
        component: DemoUserWizard
    }
]
