<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <h4 class="tw-font-bold tw-text-xl">Companies</h4>
                    <button class="btn btn-success btn-sm text-white" @click="addHandler()"><i
                            class="fa fa-plus"></i></button>
                </div>
                <div class="row">
                    <data-table ref="userTable" :columns="fields" default_sort_direction="" default_sort_field=""
                        :show_entries="[10, 15, 20, 50, 75, 100]" :show_entries_default="10" url="fleets"
                        :items="companies" @getData="dataManager" :loading="loading">
                        <template v-slot:action="{ item }">
                            <button class="btn btn-info btn-sm text-white" @click="editHandler(item, 'company')"><i
                                    class="fa fa-edit"></i></button>
                            <button class="btn btn-danger btn-sm" @click="deleteHandler(item, 'company')"><i
                                    class="fa fa-trash"></i></button>
                        </template>
                    </data-table>
                </div>
            </div>
        </div>
        <div>
            <b-modal ref="company-modal" size="lg" hide-footer centered>
                <template #modal-title>
                    {{ isAdd ? 'Add Company' : 'Edit Company' }}
                </template>
                <div class="d-block">
                    <company-form :company_id="selected?.id" :fleet="fleetId" @reload="reloadData"
                        :isAdd="isAdd"></company-form>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { ServiceFactory } from "../../utils/ServiceFactory";
import DisplayError from "../../components/common/DisplayError.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import CssForBootstrap4 from '../../Datatable/VuetableCssBootstrap4'
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import DataTable from "../../components/dataTable/DataTable.vue";
import Spinner from 'vue-simple-spinner';
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import CompanyForm from "../Companies/CompanyForm.vue";

const lookup = ServiceFactory.get('lookup')
const company = ServiceFactory.get('company')
export default {
    name: "FormAdmin",
    components: {
        DisplayError,
        CompanySelect,
        dataTable: DataTable,
        Pagination,
        Spinner,
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        css: CssForBootstrap4,
        CompanyForm
    },
    data() {
        return {
            fields: [
                { label: 'Name', field: 'name', sortField: 'name' },
                { label: 'DOT #', field: 'dot_number', sortField: 'dot_number' },
                { label: 'Action', field: 'action' },
            ],
            errors: null,
            loading: false,
            css: VuetableCssBootstrap4,
            companies: [],
            perPage: 10,
            currentPage: 1,
            sortField: null,
            selected: null,
        }
    },
    props: {
        fleetId: {
            default: null
        }
    },
    watch: {
        sortField(n, o) {
            this.getData()
        }
    },
    async created() {
        this.getRoles();
        this.getData();
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        },
        isAdmin() {
            return this.$store.getters["user/getRoles"] === 'admin'
        },
        isFleetOwner() {
            return this.$store.getters["user/getRoles"] === 'fleet_owner'
        },
        isAdd(){
            return this.selected ? false : true;
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const { isSuccess, data } = await company.index({
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                fleet: this.fleetId,
                filterBy: '',
                search: null,
            });
            if (isSuccess) this.companies = { data: data }
            else this.$notify({
                type: 'danger',
                text: 'Something went wrong.'
            });
            this.loading = false
        },
        dataManager(sortOrder) {
            if (sortOrder) {
                const direction = (sortOrder.sort_direction === 'desc' ? '-' : '')
                this.sortField = `${direction}${sortOrder.sort_field}`
            }
            let data = { data: _.slice(this.companies.data) };
            return data;
        },
        async getRoles() {
            const { isSuccess, data } = await lookup.show('roles')
            if (isSuccess) this.roles = data
            else this.$notify({ type: 'error', text: 'Something went wrong.' })

        },
        reloadData(){
            this.getData();
            this.$refs['company-modal'].hide()
        },
        editHandler(data, type) {
            this.selected = data;
            if (this.isAdmin || this.isFleetOwner){
                this.$refs['company-modal'].show()
            }else{
                this.$router.push(`/companies/${data.id}/edit`);
            }
        },
        addHandler(){
            this.selected = null;
            if (this.isAdmin) {
                this.$refs['company-modal'].show()
            } else {
                this.$router.push(`/companies/add`);
            }
        },
        async deleteHandler(item, type) { 
            const { isSuccess, data } = await company.deleteCompany(item.id);
            if (isSuccess) {
                this.getData();
                this.$notify({ type: 'success', text: 'Company successfully deleted.' });
            } else this.$notify({ type: 'danger', text: 'Something went wrong.' });
        }
    }
}
</script>

<style scoped></style>
