import axiosUtils from "../../utils/axiosUtil";

export default {
  async index (params = {}) {
    return axiosUtils.get('/notes', {params: params})
      .then(response => {
        // handle success
        return response.data;
      });
  },
  async show (id) {
    return axiosUtils.get('/notes/'+id)
      .then(response => {
        // handle success
        return response.data
      });
  },
  async store(payload) {
    if (typeof(payload.id) !== 'undefined' && payload.id) {
      var apiEndpoint = '/notes/'+payload.id;
      var apiMethod = 'put';
    }
    else {
      var apiEndpoint = '/notes';
      var apiMethod = 'post';
    }

    return axiosUtils({
      method: apiMethod,
      url: apiEndpoint,
      data: payload
    }).then(response => {
      return response.data;
    });
  },
  async destroy(id) {
    return axiosUtils.delete('/notes/'+id)
      .then(response => {
        return response.data;
      });
  }
}
