<template>
    <div>
        <Nav bg="blue" />
        <div class="container-fluid py-5">
            <div class="row justify-content-center">
                <div class="col-lg-2">
                    <div class="pt-3 sticky-top">
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600"
                                href="#offers-and-pricing">
                                Our offers and pricing
                            </a>
                        </p>
                        <p><a class="tw-no-underline hover:tw-no-underline tw-text-gray-600"
                                href="#purchases">Purchases</a></p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#cancellation">
                                Cancellation Policy
                            </a>
                        </p>
                        <p><a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#refund">Refund
                                Policy</a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#warranty">
                                No Warranty on Purchases
                            </a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600"
                                href="#remedies">Remedies</a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600"
                                href="#subscriptions">Subscriptions</a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#renewals">
                                Automatic Renewals of Subcriptions
                            </a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#comments">
                                Comments and uploading content
                            </a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600"
                                href="#prohibited-uses">Prohibited
                                uses</a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#no-warranty">No
                                Warranty on
                                Website</a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#availability">
                                Availability, errors and inaccuracies
                            </a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#liability">
                                Damages and Limitation of Liability
                            </a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#links">Links to
                                third
                                party websites</a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#intellectual">
                                Intellectual property and DMCA notice and procedure for intellectual property
                                infringement claims
                            </a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600" href="#governing-law">
                                Governing law, severability, dispute resolution and venue
                            </a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600"
                                href="#changes-to-terms">Changes to Terms
                                of Service</a>
                        </p>
                        <p>
                            <a class="tw-no-underline hover:tw-no-underline tw-text-gray-600"
                                href="#questions">Questions</a>
                        </p>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="d-flex justify-content-center">
                        <a class="navbar-brand" href="#"><img alt="qvin logo" src="/img/QVIN-Logo-no-sl.png"
                                style="height: 150px; width: 150px"></a>
                    </div>
                    <div class="text-justify">
                        <h1 class="tw-font-black text-center">Terms of Service</h1>
                        <p class="text-center tw-text-gray-500 tw-text-sm">Last updated February 16th, 2023</p>
                        <div class="mb-3 text-center">
                            <a class="tw-no-underline hover:tw-no-underline tw-font-bold text-dark" href="/contact-us"
                                :class="`${currentRoute === '/contact-us' ? 'pb-1 border-bottom border-info' : ''}`">Contact
                                Us</a>
                        </div>
                        <div>
                            <p>
                                Please read this Terms of Service (“Terms,” “Terms of Service”) carefully before using
                                the www.q-vin.com website (the “Website”) operated by QVIN, a(n) Limited Liability
                                Company formed in Wyoming, United States (“us,” “we,” “our”) as this Terms of Service
                                contains important information regarding limitations of our liability. Your access to
                                and use of this Website is conditional upon your acceptance of and compliance with these
                                Terms. These Terms apply to everyone, including but not limited to visitors, users and
                                others, who wish to access and use the Website.
                            </p>
                            <p>
                                By accessing or using the Website, you agree to be bound by these Terms. If you disagree
                                with any part of the Terms, then you do not have our permission to access or use the
                                Website.
                            </p>
                        </div>
                        <div id="offers-and-pricing" class="pt-3">
                            <p class="font-weight-bold font-xl">Our offers and pricing</p>
                            <p>
                                We offer services and digital products on this Website. The price of these services and
                                digital products is customized based on your needs, which means that we will provide you
                                with a quote after we determine your needs. This price does not include taxes or other
                                fees that you may be charged.
                            </p>
                        </div>
                        <div id="purchases" class="pt-3">
                            <p class="font-weight-bold font-xl">Purchases</p>
                            <p>
                                When you make a purchase on the Website, you will be using a third-party payment
                                processor, that will collect your payment information and process your payment. We are
                                not responsible for the collection, use, sharing or security of your billing information
                                by any third party. The following payment method(s) are accepted by the third-party
                                payment processor: bank transfer, MasterCard, and Visa.
                            </p>
                            <p>
                                You hereby represent and warrant that you have the legal right to use the payment
                                method(s) in connection with any purchase and that the information that you supply to us
                                and to the third-party payment processor(s) is true, correct and complete.
                            </p>
                        </div>
                        <div id="cancellation" class="pt-3">
                            <p class="font-weight-bold font-xl">Cancellation Policy</p>
                            <p>
                                We do not offer you the ability to cancel any purchases that you have made of the
                                services and digital products offered on our Website.
                            </p>
                            <p>
                                We reserve the right to cancel your purchase for any reason, at our sole discretion,
                                including but not limited to fraud, inaccuracies, and unavailability of the items or
                                services purchased. We will let you know immediately if we plan on cancelling your
                                purchase.
                            </p>
                            <p>
                                We will issue you a refund of the full purchase price that you paid if we cancel your
                                purchase.
                            </p>
                        </div>
                        <div id="refund" class="pt-3">
                            <p class="font-weight-bold font-xl">Refund Policy</p>
                            <p>
                                We do not offer refunds on any purchases made on this Website.
                            </p>
                        </div>
                        <div id="warranty" class="pt-3">
                            <p class="font-weight-bold font-xl">NO WARRANTY ON PURCHASES</p>
                            <p>
                                THE ITEMS OR SERVICES DISPLAYED OR SOLD ON THIS WEBSITE ARE PROVIDED “AS IS,” NO
                                WARRANTY, EXPRESS OR IMPLIED (INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, OF
                                SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE) SHALL APPLY TO ANY
                                ITEMS OR SERVICES DISPLAYED OR SOLD ON THIS WEBSITE, WHETHER ARISING BY LAW, COURSE OF
                                DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE.
                            </p>
                        </div>
                        <div id="remedies" class="pt-3">
                            <p class="font-weight-bold font-xl">Remedies</p>
                            <p>
                                You agree that the remedy for breach of these Terms of Service as it relates to your
                                purchase shall be none. You also agree that the remedy for breach of this Terms of
                                Service as it relates to your purchase shall be to pursue dispute resolution as provided
                                in the “governing law, severability, dispute resolution, venue and class action waiver”
                                section below. These remedies are intended to be your sole and exclusive remedies for
                                any breach of this Terms of Service as it relates to your purchase.
                            </p>
                        </div>
                        <div id="subscriptions" class="pt-3">
                            <p class="font-weight-bold font-xl">Subscriptions</p>
                            <p>
                                This Website features products or services that are offered via a subscription model. A
                                subscription model requires regular payments to receive a product or service. The
                                initial term of the subscription is based on your selections. In the event we offer
                                discounted rates for longer-term contracts, you will be obligated to fulfill the term
                                you select.
                            </p>
                            <p>
                                WE DO NOT OFFER THE OPTION TO REVOKE YOUR SUBSCRIPTION SHORTLY AFTER MAKING YOUR
                                PURCHASE.
                            </p>
                            <p>
                                THERE IS NO MINIMUM PURCHASE REQUIRED TO QUALIFY FOR THE SUBSCRIPTION.
                            </p>
                        </div>
                        <div id="renewals" class="pt-3">
                            <p class="font-weight-bold font-xl">AUTOMATIC RENEWALS OF SUBSCRIPTIONS</p>
                            <p>
                                WHEN YOU PURCHASE A SUBSCRIPTION ON THE WEBSITE, YOUR SUBSCRIPTION WILL AUTOMATICALLY
                                RENEW EVERY MONTH. WE WILL AUTOMATICALLY RENEW YOUR SUBSCRIPTION BY USING THE PAYMENT
                                METHOD ON FILE UNTIL YOU CANCEL YOUR SUBSCRIPTION.
                            </p>
                            <p>
                                YOU MAY CANCEL THE AUTOMATIC RENEWALS OF YOUR SUBSCRIPTION VIA EMAIL BY CONTACTING US AT
                                <a class="tw-no-underline hover:tw-no-underline"
                                    href="mailto:info@q-vin.com">INFO@Q-VIN.COM</a>. PLEASE NOTE THAT YOU WILL NO LONGER
                                RECEIVE THE ITEMS OR SERVICES
                                PROVIDED BY THE SUBSCRIPTION ON YOUR CANCELLATION EFFECTIVE DATE.
                            </p>
                            <p>
                                YOU MUST PROVIDE US WITH 30 DAYS NOTICE PRIOR TO YOUR AUTOMATIC RENEWAL DATE OF YOUR
                                INTENT TO CANCEL THE AUTOMATIC RENEWALS FOR THE CANCELLATION TO BE EFFECTIVE.
                            </p>
                        </div>
                        <div id="comments" class="pt-3">
                            <p class="font-weight-bold font-xl">Comments and uploading content</p>
                            <p>
                                By submitting a comment or uploading content onto this Website, you grant QVIN a
                                worldwide, non-exclusive, perpetual, royalty-free license to reproduce, publish and
                                distribute the comment or content. When you make a comment or upload any content onto
                                this Website, you agree that such comment or content may be viewed by other parties and
                                it is your responsibility to ensure that the comment or content does not contain any
                                confidential or proprietary information. You are also responsible for ensuring that your
                                content or comment does not violate any laws, rules or regulations. We reserve the right
                                to remove any content or comment at any time in our sole discretion.
                            </p>
                            <p>
                                The following is a non-exhaustive list of types of content or comments that you are
                                prohibited from posting on our Website:
                            </p>
                            <ul>
                                <li>Pornographic and sexually explicit content;</li>
                                <li>Content displaying, depicting or suggesting violence;</li>
                                <li>Content that exploits or abuses children;</li>
                                <li>Content encouraging or committing illegal acts;</li>
                                <li>Content sharing personal information without consent;</li>
                                <li>Content infringing on someone’s rights, including intellectual property rights;</li>
                                <li>Content advertising products or services without our permission;</li>
                                <li>Content whose purpose is spamming others.</li>
                            </ul>
                        </div>
                        <div id="prohibited-uses" class="pt-3">
                            <p class="font-weight-bold font-xl">Prohibited uses</p>
                            <p>
                                You agree that you will use this Website in accordance with all applicable laws, rules,
                                regulations and these Terms at all times. The following is a non-exhaustive list of
                                prohibited uses of this Website. You agree that you will not perform any of the
                                following prohibited uses:
                            </p>
                            <ol>
                                <li>Impersonating or attempting to impersonate QVIN or its employees, representatives,
                                    subsidiaries or divisions;</li>
                                <li>Misrepresenting your identity or affiliation with any person or entity;</li>
                                <li>Sending or attempting to send any advertising or promotional material, including but
                                    not limited to spam, junk mail, chain mail or any similar material;
                                </li>
                                <li>Engaging in any conduct that restricts or inhibits any person’s use or enjoyment of
                                    the Website, or which, as determined in our sole discretion, may harm us or the
                                    users of this Website or expose us or other users to liability;
                                </li>
                                <li>Using the Website in any manner that could disable, overburden, damage or impair the
                                    Website or interfere with another party’s use of the Website;
                                </li>
                                <li>Using any robot, spider or other similar automatic technology, process or means to
                                    access or use the Website for any purpose, including monitoring or copying any of
                                    the material on this Website;</li>
                                <li>Using any manual process or means to monitor or copy any of the material on this
                                    Website or for any other unauthorized purpose;
                                </li>
                                <li>Using any device, software, means or routine that interferes with the proper working
                                    of the Website, including but not limited to viruses, trojan horses, worms, logic
                                    bombs or other such materials;</li>
                                <li>Attempting to gain unauthorized access to, interfering with, damaging or disrupting
                                    any parts of the Website, the server(s) on which the Website is stored, or any
                                    server, computer or database connected to the Website;</li>
                                <li>Attempting to attack or attacking the Website via a denial-of-service attack or a
                                    distributed denial-of-service attack;
                                </li>
                                <li>Otherwise attempting to interfere with the proper working of the Website;
                                </li>
                                <li>Using the Website in any way that violates any applicable federal, state or local
                                    laws, rules or regulations.</li>
                            </ol>
                        </div>
                        <div id="no-warranty" class="pt-3">
                            <p class="font-weight-bold font-xl">NO WARRANTY ON WEBSITE</p>
                            <p>
                                THIS WEBSITE IS PROVIDED “AS IS,” NO WARRANTY, EXPRESS OR IMPLIED (INCLUDING ANY IMPLIED
                                WARRANTY OF MERCHANTABILITY, OF SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE
                                OR USE) SHALL APPLY TO THIS WEBSITE, WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE
                                OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE.
                            </p>
                        </div>
                        <div id="availability" class="pt-3">
                            <p class="font-weight-bold font-xl">Availability, errors and inaccuracies</p>
                            <p>
                                We assume no liability for the availability, errors or inaccuracies of the information,
                                products or services provided on this Website. We may experience delays in updating
                                information on this Website and in our advertising on other websites. The information,
                                products and services found on the Website may contain errors or inaccuracies or may not
                                be complete or current. Products or services may be incorrectly priced or unavailable.
                                We expressly reserve the right to correct any pricing errors on our Website. The
                                inclusion or offering of any product or service on this Website does not constitute an
                                endorsement or recommendation of such product or service by us.
                            </p>
                        </div>
                        <div id="liability" class="pt-3">
                            <p class="font-weight-bold font-xl">DAMAGES AND LIMITATION OF LIABILITY</p>
                            <p>
                                IN NO EVENT SHALL QVIN OR ANY AFFILIATES, PARTNERS, EMPLOYEES, REPRESENTATIVES,
                                SUBSIDIARIES OR DIVISIONS; BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
                                SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF, RELATING TO OR IN ANY WAY CONNECTED
                                WITH YOUR ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE OR WITH THE DELAY OR INABILITY TO
                                ACCESS, DISPLAY OR USE THIS WEBSITE, INCLUDING BUT NOT LIMITED TO YOUR RELIANCE UPON
                                OPINIONS OR INFORMATION APPEARING ON THIS WEBSITE; ANY COMPUTER VIRUSES, INFORMATION,
                                SOFTWARE, LINKED WEBSITES OPERATED BY THIRD PARTIES, PRODUCTS OR SERVICES OBTAINED
                                THROUGH THIS WEBSITE, WHETHER BASED ON A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT
                                LIABILITY, CONSUMER PROTECTION STATUTES OR OTHERWISE, EVEN IF QVIN HAS BEEN ADVISED OF
                                THE POSSIBILITY OF SUCH DAMAGES. IF, DESPITE THE LIMITATION ABOVE, QVIN IS FOUND LIABLE
                                FOR ANY LOSS OR DAMAGE WHICH ARISES OUT OF OR IN ANY WAY CONNECTED WITH ANY OF THE
                                OCCURRENCES DESCRIBED ABOVE, THEN THE LIABILITY OF QVIN WILL IN NO EVENT EXCEED THE
                                SERVICE FEES YOU PAID TO QVIN IN CONNECTION WITH SUCH TRANSACTION(S) ON THIS WEBSITE.
                                THE LIMITATION OF LIABILITY REFLECTS THE ALLOCATION OF RISK BETWEEN THE PARTIES. THE
                                LIMITATIONS SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY
                                SPECIFIED IN THESE TERMS OF USE IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. THE
                                LIMITATIONS OF LIABILITY PROVIDED IN THESE TERMS OF USE INURE TO THE BENEFIT OF QVIN.
                                THE AGGREGATE LIABILITY OF QVIN ARISING OUT OF OR RELATING TO THIS WEBSITE, WHETHER
                                ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
                                OTHERWISE SHALL BE LIMITED TO THE AMOUNT OF FEES ACTUALLY RECEIVED BY QVIN FROM YOU.
                            </p>
                        </div>
                        <div id="links" class="pt-3">
                            <p class="font-weight-bold font-xl">Links to third party websites</p>
                            <p>
                                This Website may contain hyperlinks to websites operated by third parties and not by us.
                                We provide such hyperlinks for your reference only. We do not control such websites and
                                are not responsible for their contents or the privacy or other practices of such
                                websites. Further, it is your responsibility to take precautions to ensure that whatever
                                links you click on or software that you download, whether from this Website or other
                                websites or applications, is free of such items as viruses, worms, trojan horses,
                                defects and other items of a destructive nature. Our inclusion of hyperlinks to such
                                websites does not imply any endorsement of the material on such websites or any
                                association with their operators.
                            </p>
                        </div>
                        <div id="intellectual" class="pt-3">
                            <p class="font-weight-bold font-xl">
                                Intellectual property and DMCA notice and procedure for intellectual property
                                infringement claims
                            </p>
                            <p>
                                All contents of this Website are ©2023 QVIN or third parties. All rights reserved.
                                Unless specified otherwise, this Website and all content and other materials on this
                                Website including but not limited to all logos, designs, text, graphics, pictures,
                                information, data, software, sound files and arrangement thereof (collectively,
                                “Content”) are the proprietary property of QVIN and are either registered trademarks,
                                trademarks or otherwise protected intellectual property of QVIN or third parties in the
                                United States and/or other countries.
                            </p>
                            <p>
                                If you are aware of a potential infringement of our intellectual property, please
                                contact
                                QVIN team at: <a class="tw-no-underline hover:tw-no-underline"
                                    href="mailto:info@q-vin.com">info@q-vin.com</a>
                            </p>
                            <p>
                                We respect the intellectual property rights of others. It is our policy to respond to
                                any claim that Content posted on the Website infringes on the copyright, trademark or
                                other intellectual property rights of any person or entity.
                            </p>
                            <p>
                                If you believe in good faith that the Content infringes on your intellectual property
                                rights, you or your agent may send us a written notice of such infringement titled
                                “Infringement of Intellectual Property Rights – DMCA.” Your notice to us must include
                                the following information:
                            </p>
                            <ol>
                                <li>
                                    An electronic or physical signature of the person authorized to act on behalf of the
                                    owner of the intellectual property right’s interest;
                                </li>
                                <li>
                                    A description of the work that you claim has been infringed, including the URL
                                    (i.e., web page address) of the location where the work exists or a copy of the
                                    work;
                                </li>
                                <li>
                                    Your name, email, address and telephone number; and
                                </li>
                                <li>
                                    A statement by you that you have a good faith belief that the disputed use is not
                                    authorized by the owner of the work, its agent or the law.
                                </li>
                            </ol>
                            <p>
                                Please note that we will not process your complaint if it is not properly filled out or
                                is incomplete. You may be held accountable for damages, including but not limited to
                                costs and attorneys’ fees for any misrepresentation or bad faith claims regarding the
                                infringement of your intellectual property rights by the Content on this Website.
                            </p>
                            <p>
                                You may submit your claim to us by contacting us at:
                            </p>
                            <p class="mb-0">QVIN</p>
                            <p class="mb-0"><a class="tw-no-underline hover:tw-no-underline"
                                    href="mailto:info@q-vin.com">info@q-vin.com</a></p>
                            <p>UNITED STATES</p>
                        </div>
                        <div id="governing-law" class="pt-3">
                            <p class="font-weight-bold font-xl">
                                Governing law, severability, dispute resolution and venue
                            </p>
                            <p>
                                These Terms shall be governed and construed in accordance with the laws of the state of
                                Tennessee, United States, without regard to its conflict of laws provisions. THESE TERMS
                                SHALL NOT BE GOVERNED BY THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE SALE OF
                                INTERNATIONAL GOODS, THE UNIFORM COMMERCIAL CODE, NOR INCOTERMS.
                            </p>
                            <p>
                                Our failure to enforce any right or provision of these Terms will not be considered a
                                waiver of that right or provision. If any provision of these Terms is held to be invalid
                                or unenforceable by a court, the remaining provisions of these Terms will remain in
                                effect. These Terms constitute the entire agreement between you and us regarding our
                                Website, and supersede and replace any prior agreements we might have had with you
                                regarding the Website.
                            </p>
                            <p>
                                Any controversy or claim arising out of or relating to these Terms including but not
                                limited to the interpretation or breach thereof shall be resolved in a court of
                                competent jurisdiction in Midland County, Texas.
                            </p>
                            <p>
                                YOU AND QVIN AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
                                INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
                                REPRESENTATIVE ACTION.
                            </p>
                        </div>
                        <div id="changes-to-terms" class="pt-3">
                            <p class="font-weight-bold font-xl">Changes to Terms of Service</p>
                            <p>
                                We reserve the right to make changes to these Terms of Service at any time. We will not
                                provide you with any notice when we make changes to these Terms of Service.
                            </p>
                        </div>
                        <div id="questions" class="pt-3">
                            <p class="font-weight-bold font-xl">Questions</p>
                            <p>
                                If you have any questions about our Terms of Service, please contact us at
                                <a class="tw-no-underline hover:tw-no-underline"
                                    href="mailto:info@q-vin.com">info@q-vin.com</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QvinFooter />
    </div>
</template>

<script>
import { ServiceFactory } from "../utils/ServiceFactory";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import DefaultHeaderDropdownAccnt from "../components/containers/DefaultHeaderDropdownAccnt.vue";
import { Header as AppHeader, } from "@coreui/vue";
import LandingPageNav from "../components/Nav/LandingPageNav.vue";
import Nav from "../components/Nav/Nav.vue";
import QvinFooter from "../components/landingPage/QvinFooter.vue";

const authService = ServiceFactory.get('auth')

export default {
    name: 'TermsOfService',
    components: {
        QvinFooter,
        Nav,
        LandingPageNav,
        DefaultHeaderDropdownAccnt,
        VueSlider,
        AppHeader,
    },
    computed: {
        currentRoute() {
            return this.$route.path
        }
    },
    methods: {
        handleRedirect() {
            this.$router.push('/pricing')
        }
    }
}
</script>

<style scoped>
.top-feature {
    font-size: 12px;
}

.feature-description {
    font-size: 14px;
}
.text-blue-qvin {
    color: #025490;
}
</style>
