<template>
    <div class="w-100">
        <div>
            <form @submit.prevent="submitHandler">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="w-100">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email address</label>
                            <input id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control"
                                placeholder="Enter email" type="email" v-model="email" readonly>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Password</label>
                            <!-- <input id="exampleInputPassword1" class="form-control" placeholder="Password" type="password" v-model="password" :disabled="isSuccess"> -->
                            <div class="input-group" id="show_hide_password">
                                <input id="exampleInputPassword1" class="form-control" placeholder="Password"
                                    :type="viewPassword ? 'text' : 'password'" v-model="password" :disabled="isSuccess">
                                <div class="input-group-append" @click="viewPassword = !viewPassword">
                                    <span class="input-group-text"><i
                                            :class="[viewPassword ? 'fa fa-eye-slash' : 'fa fa-eye']"
                                            aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Confirm Password</label>
                            <div class="input-group" id="show_hide_password">
                                <input id="exampleInputPassword1" class="form-control" placeholder="Confirm Password"
                                    :type="viewConfirmPassword ? 'text' : 'password'" v-model="password_confirmation"
                                    :disabled="isSuccess">
                                <div class="input-group-append" @click="viewConfirmPassword = !viewConfirmPassword">
                                    <span class="input-group-text"><i
                                            :class="[viewConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye']"
                                            aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                        <div v-if="errors" class="mb-3">
                            <p class="alert alert-danger mb-1" v-for="(error, index) in errors?.errors?.password">{{
                                error }}</p>
                        </div>
                        <button class="btn btn-primary" type="submit" v-if="!isSuccess">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div>
            <b-modal ref="account-success" hide-footer hide-header centered>
                <div class="d-block m-2 text-center">
                    <div class="m-auto w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="#4dbd74"
                                d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                        </svg>
                    </div>
                    <h4 class="mt-4 mb-4 text-success">Success</h4>
                    <p class="mb-6">Your account has been created successfully</p>
                    <!-- <button @click="$refs['account-success'].hide()" class="btn btn-info text-white">Okay</button> -->
                    <button @click="continueToQvin" class="btn btn-success text-white">Continue to QVIN App</button>
                    <div class="d-flex flex-wrap justify-content-around mobile-provider-container mt-3">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dotready.qvin"
                            v-if="isAndroid && !isWebView">
                            <img alt="google play" class="mobile-provider-logo tw-w-auto"
                                src="/img/mobile-provider-log-google.png">
                        </a>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dotready.qvin"
                            v-if="isIOS && !isWebView">
                            <img alt="apple store" class="mobile-provider-logo tw-w-auto"
                                src="/img/mobile-provider-log-ios.png">
                        </a>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import {ServiceFactory} from "../../utils/ServiceFactory";
import toast from "bootstrap/js/src/toast";

const authService = ServiceFactory.get('auth')
export default {
    name: 'Signup',
    props: ['driver'],
    data() {
        return {
            email: this.driver.email,
            password: '',
            password_confirmation: '',
            errors: null,
            isSuccess: false,
            viewPassword: false,
            viewConfirmPassword: false,
            deviceType: '',
            appUrl: `https://q-vin.com/login`,
            isAndroid: false,
            isIOS: false,
            isWebView: false,
        }
    },
    async created() {
        this.appUrl = `${window?.location?.protocol}//${window?.location?.host}/login` || `https://q-vin.com/login`;
        this.isWebView = window?.location?.search?.includes('wv');
        this.getDeviceType();
    },
    methods: {
        async submitHandler() {
            // this.isSuccess = true;
            // this.$refs['account-success'].show();
            const {isSuccess, errors, data} = await authService.signUp('/invite-drivers/create-account', {
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                invitation_code: this.driver.invitation_code            
            })
            if (isSuccess) {
                this.isSuccess = true;
                this.$refs['account-success'].show();
                this.errors = null;
                registerMessage.postMessage("Registration Success");
            } else {
                this.errors = errors
                console.log(errors)
            }
        },
        continueToQvin(){
            window.location.replace(this.appUrl);
        },
        getDeviceType(){
            const userAgent = navigator?.userAgent || window?.opera;
            if (/android/i.test(userAgent)) {
                this.deviceType = 'android';
                this.isAndroid = true;
                this.isIOS = false;
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                this.deviceType = "iOS";
                this.isIOS = true;
                this.isAndroid = false;
            } else {
                this.isAndroid = true;
                this.isIOS = true;
            }
        }
    }
}
</script>
<style scoped>
.login_img_section {
    background: linear-gradient(rgba(2, 2, 2, .5), rgba(0, 0, 0, .7)), url('https://images.unsplash.com/photo-1504846257989-a76209d9d2ac?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80') center center;
}
.mobile-provider-container a{
    height: fit-content;
}
.mobile-provider-logo {
    height: 3.5rem;
}
</style>
