<template>
    <div class="container-fluid">
            <div>
                <data-table
                    ref="userTable"
                    :columns="fields"
                    default_sort_direction=""
                    default_sort_field=""
                    :show_entries="[10,15,20,50,75,100]"
                    :show_entries_default="perPage"
                    url="trucks"
                    :items="data"
                    @getData="dataManager"
                    :loading="loading"
                >

                    <template v-slot:action="{item}">
                        <button class="btn btn-info btn-sm text-white" @click="editHandler(item)"><i class="fa fa-edit"></i></button>
                        <button class="btn btn-danger btn-sm" @click="deleteHandler(item)"><i class="fa fa-trash"></i></button>
                    </template>
                </data-table>
            </div>
            <div>
                <b-modal ref="add-modal" hide-footer title="Add New Vehicle" centered >
                    <div class="d-block">
                        <form @submit.prevent="submitHandler">
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control border" :class="{'border-danger' :(errors && errors.errors.name)}" aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Location</label>
                                <LocationSelect v-model="form.location_id" :value="form.location_id" :class="{'border border-danger' :(errors && errors.errors.location_id)}"/>
                            </div>
                            <button type="submit" class="btn btn-info text-white">Save</button>
                        </form>
                    </div>
                </b-modal>
            </div>
    </div>
</template>

<script>
import {ServiceFactory} from "../../../utils/ServiceFactory";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableCssBootstrap4 from "../../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../dataTable/LaravelVuePagination.vue';
import _ from "lodash";
import Spinner from 'vue-simple-spinner';
import LocationSelect from "../../../components/common/selects/LocationSelect.vue";
import CssForBootstrap4 from '../../../Datatable/VuetableCssBootstrap4'
import DataTable from "../../../components/dataTable/DataTable.vue";

const inspection = ServiceFactory.get('inspection')

export default {
    name: "Trucks",
    components: {
        LocationSelect,
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        Pagination,
        Spinner,
        css: CssForBootstrap4,
        dataTable: DataTable
    },

    data() {
        return {
            form: {
                name: null,
                location_id: null
            },
            fields: [
                {label: 'Date',field: 'inspection_time',  sortField: 'inspection_time' },
                {label: 'Driver',field: 'driver' },
                {label: 'Ticket',field: 'ticket_number',sortField: 'ticket_number'},
                {label: 'Citations',field: 'violations'},
                {label: 'Type', field: 'type', sortField: 'type',},
                {label: 'Action',field: 'action' },
            ],
            perPage: 10,
            data: {},
            currentPage:1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false,
            errors: null,
        };
    },

    watch: {
        data(newVal, oldVal) {

        },
        sortField(n, o) {
            this.getData()
        },
        '$route.name': {
            handler: function(search) {
                this.getData()
            },
            deep: true,
        }
    },
    computed: {
        module() {
            return 'Inspections'
        },
        moduleUrl() {
            return 'inspections'
        },
        vehicleId() {
            return this.$route.params.id
        }
    },

    async created() {
        await this.getData()
    },

    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        async getData() {
            this.loading = true
            const {isSuccess, data} = await inspection.getInspections(this.moduleUrl,{
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                vehicle: this.vehicleId
            });
            if (isSuccess) this.data = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
        editHandler(data) {
            this.$router.push(`/${this.moduleUrl}/${data.id}/edit`)
        },
        async deleteHandler(data) {
            const vm = this
            this.$dialog.confirm('Are you sure, you want to delete this item?')
                .then(async function (dialog) {
                    const {isSuccess} = await vehicle.deleteTruck(this.moduleUrl, data.id)
                    if (isSuccess) {
                        await vm.getData()
                        vm.$notify({type: 'success', 'text': 'Admin user successfully deleted.'})
                    }
                });
        },
        async submitHandler() {
            const {isSuccess, errors, data} = await vehicle.storeTruck(this.moduleUrl, this.form)
            if (isSuccess) {
                this.form = {}
                this.$router.push(`/${this.moduleUrl}/${data.id}/edit`)
            }
            else this.errors = errors
        },
        dataManager(data) {
            const direction = (data.sort_direction === 'desc' ? '-': '')
            this.sortField = `${direction}${data.sort_field}`
            this.currentPage = data.page
            this.perPage = data.paginate
            this.filterBy = data.queries
            this.search = data.search
            this.getData()
        },
    }
};
</script>

<style>
table >  thead {
    white-space: nowrap;
}


</style>
