<template>
	<div>
		<div>
			<searchable-select :disabled="disabled" ref="inspectionSelect" v-on:input="handleChange" :options="optionList" :sync-type="syncType"></searchable-select>
		</div>
		<div v-if="availableViolations.length" class="pt-2">
			<b-form-group label="Violation(s)">
				<b-form-checkbox-group
					v-model="violations"
					:options="availableViolations"
					stacked>
        </b-form-checkbox-group>
			</b-form-group>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import Inspection from '../../../API/inspection/Inspection';
import SearchableSelect from '../SearchableSelect';

export default {

  data: function () {
    return {
      optionList: [],
      violations: JSON.parse(JSON.stringify(this.existingViolations)),
      violationOptions: [],
      selectedOption: this.value,
      loaded: false
    }
  },

  props: {
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multi: {
      default: false
    },
    syncType: {
      default: 'value'
    },
    vehicleId: {
      default: null
    },
    existingViolations: {
      type: Array,
      default: () => []
    }
  },

  components: {
    SearchableSelect
  },

  created: function () {
    this.getInspections(this.vehicleId);
  },

  watch: {

    selectedOption: function (newVal, oldVal) {
      this.violations = []
      this.$emit('input', newVal);
    },

    value: function (newVal, oldVal) {
      this.selectedOption = newVal;
      this.$refs.inspectionSelect.setSelection(newVal);
    },

    vehicleId: function (newVal) {
      this.selectedOption = null;
      this.getInspections(newVal);
    },

    violations: function (newVal) {
      if (this.loaded) {
        this.$emit('violations-updated', newVal);
      }
    }

  },

  computed: {
    availableViolations: function () {
      return _.filter(this.violationOptions, { inspection_id: this.selectedOption });
    }
  },

  methods: {

    getInspections: function (vehicleId) {
      let vm = this;
      vm.optionList = [];
      vm.violationOptions = []
      if (this.loaded) {
        vm.violations = [];
        vm.selectedOption = null;
      }
      if (!_.isNull(vehicleId)) {
        const filter = `?filter[vehicle_id]=${vehicleId}&filter[type]=sms`
        Inspection.index(filter).then(response => {
          _.each(response.data, function (inspection) {
              console.log(inspection)
            vm.optionList.push({ label: inspection.sms_report_number, value: inspection.id });
            _.each(inspection.violation_listing, function (violation) {
              vm.violationOptions.push({
                value: violation.id,
                text: violation.code + ' ' + violation.description,
                inspection_id: inspection.id
              })
            });
          });
        }).catch(error => {
          console.log(error);
        }).then(() => {
          if (!_.isNull(vm.value)) {
            vm.selectedOption = vm.value;
            vm.$refs.inspectionSelect.setSelection(vm.value);
          }
        }).finally(() => {
          this.loaded = true;
        });
      }
    },

    handleChange: function (event) {
      let eventData = Object.assign({}, event);
      if (this.loaded) {
        if (_.isNull(event)) {
          this.selectedOption = null;
        }
        else if (this.syncType == 'object') {
          this.selectedOption = eventData;
        }
        else {
          if (this.multi) {
            let optionArray = [];
            _.each(eventData, function (option) {
              optionArray.push(option.value);
            });
            this.selectedOption = optionArray;
          }
          else {
            this.selectedOption = event;
          }
        }
      }
    }

  }

}
</script>
