<template>
    <div class="work-order-form">
        <b-row>
            <b-col md="6">
                <b-form-group label="Mechanic" :state="errors.mechanics ? false : null">
                    <mechanic-select :disabled="!canEdit" v-model="workOrder.mechanics"></mechanic-select>
                    <b-form-invalid-feedback>Please select a mechanic name, or select 'Any Mechanic' to allow any mechnic to work on this work order.</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Due Date" :state="errors.due_date ? false : null">
                    <flat-pickr :disabled="!canEdit" v-model="workOrder.due_date" class="form-control" placeholder="Select Date" :config="{enableTime: false, dateFormat: 'Y-m-d'}"></flat-pickr>
                    <div v-if="errors.due_date" class="text-danger small">Please enter a valid due date.</div>
                </b-form-group>
            </b-col>
            <b-col v-if="workOrder.status === 'completed'">
                <b-form-group label="Completed At" :state="errors.completed_at ? false : null">
                    <flat-pickr v-model="workOrder.completed_at" :disabled="!canEdit" class="form-control" placeholder="Select Date" :config="{enableTime: false, dateFormat: 'Y-m-d'}"></flat-pickr>
                    <div v-if="errors.completed_at" class="text-danger small">{{ errors.completed_at ? errors.completed_at[0] : null }}</div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6">
                <b-form-group label="Vehicle or Trailer" :state="errors.vehicle_id ? false : null">
                    <vehicle-select v-model="workOrder.vehicle_id" :multi="false" :disabled="!canEdit"></vehicle-select>
                    <b-form-invalid-feedback>Please select a vehicle.</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Status" :state="errors.status ? false : null">
                    <status-select :disabled="!canEdit" v-model="workOrder.status"></status-select>
                    <b-form-invalid-feedback>Please select a status.</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Equipment Type" :state="errors.equipment_type ? false : null">
                    <div>
                        <searchable-select :disabled="!canEdit" ref="equipmentSelect" v-model="workOrder.equipment_type" :options="equipmentTypeList"></searchable-select>
                    </div>
                    <b-form-invalid-feedback>Please select a equipment type.</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6">
                <b-form-group label="SMS Report #">
                    <inspection-select
                        v-model="workOrder.inspection_id"
                        @violations-updated="handleViolationsUpdated"
                        :existing-violations="workOrder.violations ? workOrder.violations : []"
                        :vehicle-id="workOrder.vehicle_id ? workOrder.vehicle_id : null"
                        :disabled="!canEdit">
                    </inspection-select>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Type" class="pt-2">
                    <b-form-radio-group :disabled="!canEdit" v-model="workOrder.type" :options="typeOptions" :state="errors.type ? false : null"></b-form-radio-group>
                    <b-form-invalid-feedback>Please select a work order type.</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="3">
                <b-form-group label="Mileage" :state="errors.mileage ? false : null">
                    <template v-if="exists">
                        <editable-field :read-only="!canEdit" type="numeric" v-model="workOrder.mileage"></editable-field>
                    </template>
                    <template v-else>
                        <b-form-input v-model="workOrder.mileage" type="number"></b-form-input>
                    </template>
                    <b-form-invalid-feedback>{{ errors.mileage ? errors.mileage[0] : '' }}</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <b-col md="3">
                <b-form-group label="Engine Hours" :state="errors.engine_hours ? false : null">
                    <template v-if="exists">
                        <editable-field :read-only="!canEdit" type="numeric" v-model="workOrder.engine_hours"></editable-field>
                    </template>
                    <template v-else>
                        <b-form-input v-model="workOrder.engine_hours" type="number"></b-form-input>
                    </template>
                    <b-form-invalid-feedback>{{ errors.engine_hours ? errors.engine_hours[0] : '' }}</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <b-col md="4" class="align-self-center">
                <b-form-group :state="errors.paid ? false : null">
                    <b-form-checkbox v-model="workOrder.paid" name="check-button" :disabled="!canEdit" switch>
                        Paid
                    </b-form-checkbox>
                    <b-form-invalid-feedback>{{ errors.paid ? errors.paid[0] : '' }}</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="my-3" v-if="exists">
            <file-listing :files="files" heading="Files" columns="3" v-on:file-deleted="deleteExistingFile($event)"></file-listing>
        </div>
        <b-row class="my-3" v-if="canEdit">
            <b-col>
                <vue-dropzone ref="fileUploader" v-on:vdropzone-success="handleFileUploaded" v-on:vdropzone-queue-complete="handleQueueComplete" :options="uploadOptions" id="file-uploader" :useCustomSlot=true v-on:vdropzone-sending="sendFile" v-on:vdropzone-files-added="handleFileQueued">
                    <div class="dropzone-custom-content">
                        <strong>Click here or drag files to upload</strong>
                    </div>
                </vue-dropzone>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Description" :state="errors.description ? false : null">
                    <template v-if="!exists">
                        <b-form-textarea :state="errors.description ? false : null" v-model="workOrder.description" placeholder="Work order description" :rows="4"></b-form-textarea>
                    </template>
                    <template v-else>
                        <editable-field :read-only="!canEdit" :rows="4" v-model="workOrder.description" type="textarea"></editable-field>
                    </template>
                    <b-form-invalid-feedback>Please enter a description for this work order.</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="workOrder.status === 'completed'">
                <b-form-group  label="Completed Summary"  :state="errors.summary ? false : null">
                    <template v-if="!exists">
                        <b-form-textarea :state="errors.summary ? false : null" v-model="workOrder.summary" placeholder="Summary" :rows="4"></b-form-textarea>
                    </template>
                    <template v-else>
                        <editable-field :read-only="!canEdit" :rows="4" v-model="workOrder.summary" type="textarea"></editable-field>
                    </template>
                    <b-form-invalid-feedback>Please enter a description for this work order.</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col v-if="workOrder.status === 'completed'">
                <b-form-group label="Repairs Completed"  :state="errors.repairs_completed ? false : null">
                    <template v-if="!exists">
                        <b-form-textarea :state="errors.repairs_completed ? false : null" v-model="workOrder.repairs_completed" placeholder="Work order description" :rows="4"></b-form-textarea>
                    </template>
                    <template v-else>
                        <editable-field :read-only="!canEdit" :rows="4" v-model="workOrder.repairs_completed" type="textarea"></editable-field>
                    </template>
                    <b-form-invalid-feedback>Please enter a description for this work order.</b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="!workOrder.id">
            <b-col class="text-right">
                <hr />
                <button class="btn btn-primary my-2" @click="createWorkOrder">Create Work Order</button>
            </b-col>
        </b-row>
        <div v-if="exists">
            <b-row>
                <b-col>
                    <hr />
                    <div class="small text-uppercase font-weight-bold text-muted">Notes</div>
                </b-col>
            </b-row>
            <note-listing entity-type="workorder" :entity-id="workOrder.id" :read-only="!canEdit"></note-listing>
        </div>
        <b-modal ref="closeWorkOrderModal" size="lg" centered title="Close Work Order" ok-title="Close Work Order" @ok="closeWorkOrder" @shown="completeModalShown = true" @hidden="completeModalShown = false">
            <p>To close this work order, please list the repairs that have been completed below.</p>
            <b-form-group label="Completed At" :state="errors.completed_at ? false : null">
                <flat-pickr v-model="workOrder.completed_at" class="form-control" placeholder="Select Date" :config="{enableTime: false, dateFormat: 'Y-m-d', defaultDate: Date.now()}"></flat-pickr>
                <div v-if="errors.completed_at" class="text-danger small">{{ errors.completed_at ? errors.completed_at[0] : null }}</div>
            </b-form-group>
            <b-form-group label="Work Order Summary" :state="errors.summary ? false : null">
                <b-form-input v-model="workOrder.summary" name="summary" :state="errors.summary ? false : null" required placeholder="Enter a brief description of the work performed"></b-form-input>
                <b-form-invalid-feedback>{{ errors.summary ? errors.summary[0] : null }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
                <b-form-textarea v-model="workOrder.repairs_completed" :state="errors.repairs_completed ? false : null" placeholder="List repairs completed" :rows="8"></b-form-textarea>
                <b-form-invalid-feedback>{{ errors.repairs_completed ? errors.repairs_completed[0] : null }}</b-form-invalid-feedback>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Cookies from 'js-cookie';
import VehicleSelect from '../../components/common/selects/VehicleSelect.vue'
import MechanicSelect from '../../components/common/selects/MechanicSelect.vue';
import StatusSelect from '../../components/common/selects/StatusSelect.vue'
import InspectionSelect from '../../components/common/selects/InspectionSelect.vue'
import WorkOrder from '../../API/workorder/WorkOrder';
import NoteListing from '../../components/common/lists/NoteListing.vue';
import EditableField from '../../components/common/EditableField.vue'
import FileListing from '../../pages/Inspections/FileListing.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import SearchableSelect from '../../components/common/SearchableSelect.vue';
import Inspection from "../../API/inspection/Inspection";
import JsCookie from 'js-cookie';

export default {

    data: function () {
        return {
            errors: {},
            loaded: false,
            workOrderId: null,
            workOrder: {},
            inWorkOrder: true,
            loadedStatus: null,
            typeOptions: [
                {text: 'Inspection', value: 'inspection'},
                {text: 'Repair', value: 'repair'}
            ],
            pendingFiles: [],
            files: [],
            uploadOptions: this.getUploadOptions(),
            completeModalShown: false,
            selectedEquipmentType : null,
            equipmentTypeList: [
                { label : 'Large Equipment', value: 'large_equipment' },
                { label : 'Small Equipment', value: 'small_equipment' },
                { label : 'Vehicle', value: 'vehicle' },
                { label : 'Trailer', value: 'trailer' }
            ],
            equipmentType : null,
            values: {}
        }
    },

    props: {
        inModal: {
            type: Boolean,
            default: true
        }
    },

    components: {
        VehicleSelect,
        MechanicSelect,
        InspectionSelect,
        StatusSelect,
        flatPickr,
        NoteListing,
        EditableField,
        FileListing,
        vueDropzone: vue2Dropzone,
        SearchableSelect
    },

    created: function () {
        let vm = this;
        vm.assignValues()

    },

    computed: {

        canEdit: function () {
            return true
        },

        exists: function () {
            return !!this.$route.params.id
        }

    },

    methods: {

        async assignValues () {
            let vm = this;
            if (!this.$route.params.id) return false;
            this.uploadOptions.autoProcessQueue = true;
            await WorkOrder.show(this.$route.params.id)
                .then(workOrder => {
                    let newWorkOrder = _.pick(workOrder, [
                        'id', 'completed_at', 'description', 'due_date', 'equipment_type', 'mechanics',
                        'mileage', 'paid', 'notes', 'repairs_completed', 'status', 'type', 'work_order_id'
                    ]);
                    newWorkOrder.drivers = _.map(newWorkOrder.drivers, function (driver) {
                        return driver.id;
                    });
                    newWorkOrder.vehicle_id = workOrder.vehicle_id
                    // vm.inspection = newInspection;
                    vm.files = workOrder.files;
                    vm.workOrder = newWorkOrder
                    vm.workOrderId = vm.workOrder.id;
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        vm.loaded = true;
                    }, 1000);
                    console.log("====exists", this.exists, this.files);
                });
            // return new Promise(function (resolve, reject) {
            //     vm.workOrder = _.omit(vm.values, ['files']);
            //
            //     if (_.isNull(vm.workOrder.status)){
            //         vm.workOrder.status = 'open';
            //     }
            //
            //     vm.loadedStatus = vm.workOrder.status;
            //
            //     if (_.has(vm.values, 'id')) {
            //         vm.workOrderId = vm.values.id;
            //     }
            //
            //     if (_.has(vm.values, 'files')) {
            //         vm.files = vm.values.files;
            //     }
            //
            //     resolve();
            // });
        },

        createWorkOrder: function() {
            this.inWorkOrder = false;
            this.saveWorkOrder();
        },

        saveWorkOrder: function () {
            let vm = this;
            vm.errors = {};
            let apiRequest = vm.makeApiRequest();

            WorkOrder.store(apiRequest)
                .then(workOrder => {

                    if (!vm.exists) {
                        vm.workOrderId = workOrder.id;
                        if (!vm.$refs.fileUploader.getQueuedFiles().length) {
                            vm.confirmAndRedirect();
                        }
                        else {
                            vm.$refs.fileUploader.processQueue();
                        }
                    }
                    else {
                        vm.$refs.closeWorkOrderModal.hide();
                        vm.confirmAndRedirect();
                    }
                })
                .catch(error => {
                    if (error.response.status == '422') {
                        vm.errors = error.response.data.errors;
                    }
                });
        },

        makeApiRequest: function () {
            if (_.isNull(this.workOrder.status)){
                this.workOrder.status = 'open';
            }

            if (this.workOrder.mechanics && this.workOrder.mechanics.length < 1){
                this.workOrder.mechanics = ['any'];
            }

            let apiRequest = _.pick(this.workOrder, [
                'due_date', 'vehicle_id', 'status', 'type',
                'description', 'repairs_completed', 'id', 'mechanics',
                'mileage', 'engine_hours', 'paid', 'inspection_id',
                'violations', 'completed_at', 'summary', 'equipment_type'
            ]);

            if (_.has(this.$root, ['company', 'id'])) {
                apiRequest.company_id = this.$root.company.id;
            }

            return apiRequest;
        },

        closeWorkOrder: function (event) {
            event.preventDefault();
            this.inWorkOrder = false;
            this.saveWorkOrder();
        },

        getUploadOptions: function () {
            const token = JsCookie.get('token');
            let uploadOptions = {
                autoProcessQueue: false,
                url: '/api/files',
                addRemoveLinks: true,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': `Bearer ${token}`
                }
            };

            if (_.has(this.workOrder, 'id')) {
                uploadOptions.autoProcessQueue = true;
            }

            return uploadOptions;
        },

        handleFileQueued: function (files) {
            let vm = this;
            _.each(files, function (file) {
                vm.pendingFiles.push(file);
            });
        },

        sendFile: function (file, xhr, formData) {
            formData.append('group', 'default');
            formData.append('owner_id', this.workOrderId);
            formData.append('owner_type', 'work_order');
        },

        handleFileUploaded: function (file, response) {
            this.$refs.fileUploader.removeFile(file);
            this.files.push(response);
            if (this.exists) {
                this.$notify({
                    type: 'success',
                    text: 'File uploaded successfully!'
                });
            }
        },

        handleQueueComplete: function () {
            if (!this.exists) {
                this.confirmAndRedirect();
            }
        },

        deleteExistingFile: function (fileId) {
            let vm = this;

            let existingIndex = _.findIndex(vm.files, function (file) {
                return file.id == fileId;
            });

            if (existingIndex > -1) {
                vm.files.splice(existingIndex, 1);
            }
        },

        confirmAndRedirect: function () {
            this.$notify({
                type: 'success',
                text: 'Work order saved successfully!'
            });

            this.$emit('syncWorkOrder', this.workOrder);
            this.$router.push('/work-orders')

            // if (!this.inWorkOrder) {
            //     this.$emit('work-order-created', this.workOrder);
            //     if (!this.inModal) {
            //         this.$router.push({name: 'workorders.index'});
            //     }
            //
            // }
        },

        handleViolationsUpdated: function (event) {
            const rawJson = JSON.parse(JSON.stringify(event));
            this.workOrder.violations = rawJson;
        }
    },

    watch: {
        'workOrder.due_date': function (newVal, oldVal) {
            console.log(this.loaded, 'loaded')
            console.log(this.exists, 'exists')
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.completed_at': function (newVal, oldVal) {
            if (!this.exists || !this.loaded || this.completeModalShown) return;

            this.saveWorkOrder();
        },

        'workOrder.vehicle_id': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.inspection_id': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.violations': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.status': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            if (_.isNull(newVal)) return;

            if (newVal == 'completed') {
                this.inWorkOrder = true;
                this.$refs.closeWorkOrderModal.show();
            } else {
                this.saveWorkOrder();
            }
        },

        'workOrder.type': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.mechanics': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            if(newVal.length < 1) return;

            this.saveWorkOrder();
        },

        'workOrder.description': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.paid': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.mileage': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.engine_hours': function (newVal, oldVal) {
            if (!this.exists || !this.loaded) return;

            this.saveWorkOrder();
        },

        'workOrder.summary': function (newVal, oldVal) {
            if (!this.exists || !this.loaded || this.completeModalShown) return;

            this.saveWorkOrder();
        },

        'workOrder.repairs_completed': function (newVal, oldVal) {
            if (!this.exists || !this.loaded || this.completeModalShown) return;

            this.saveWorkOrder();
        },

    }

}
</script>

<style lang="scss">
.wrap-pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.v-select.disabled .dropdown-toggle, .v-select.disabled .dropdown-toggle .clear, .v-select.disabled .dropdown-toggle input, .v-select.disabled .open-indicator, .v-select.disabled .selected-tag .close {
    background-color: #ffffff !important;
}
</style>
