<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between">
            <h1 class="tw-font-bold tw-text-2xl">{{editing ? 'Edit' : 'Add'}} Fleets</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/adminusers" role="button"><i class="fa fa-arrow-left"></i>  Back to list</router-link>
            </div>
        </div>
        <b-card>
            <div class="w-50">
                <DisplayError :errors="errors" />
            </div>
            <form class="w-50" @submit.prevent="submitHandler">
                <div class="mb-3">
                    <label for="organizationName" class="form-label">Organization Name</label>
                    <input name="organizationName" v-model="form.name" type="text" class="form-control" aria-describedby="organizationName">
                </div>
                <div class="mb-3">
                    <label for="dotNumber" class="form-label">DOT #</label>
                    <input name="dotNumber" v-model="form.dot_number" type="text" class="form-control" aria-describedby="dotNumber">
                </div>
                <!-- <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Nick Name</label>
                    <input v-model="form.nickname" type="type" class="form-control" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Email</label>
                    <input v-model="form.email" type="email" class="form-control">
                </div> -->
                <div class="mb-3">
                    <label for="status" class="form-label">Status</label>
                    <select name="status" id="status" class="form-select" v-model="form.status">
                        <option value="" selected>Please select a value</option>
                        <option :value="status.value" selected v-for="(status, index) in statuses" :key="index">{{formatStatus(status.label)}}</option>
                    </select>
                </div>
                <button type="submit" class="btn btn-info text-white">Save Fleet</button>
            </form>

        </b-card>
    </div>
</template>

<script>
import {ServiceFactory} from "../../utils/ServiceFactory";
import DisplayError from "../../components/common/DisplayError.vue";

const fleet = ServiceFactory.get('fleet')
const lookup = ServiceFactory.get('lookup')
export default {
    name: "FormAdmin",
    components: {
        DisplayError
    },
    data() {
        return {
            form: {
                name: null,
                dot_number: null,
                status: null
            },
            statuses: [
                {value: 'active', label: 'Active'},
                {value: 'inactive', label: 'Inactive'},
            ],
            errors: null
        }
    },
    created() {
        this.getRoles()
        if (this.editing) {
            this.getFleetDetail()
        }
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        }
    },
    methods: {
        async getRoles() {
            const {isSuccess, data} = await lookup.show('roles')
            if (isSuccess) this.roles = data
            else this.$notify({type: 'error', text: 'Something went wrong.'})

        },
        async getFleetDetail() {
            const {isSuccess, data} = await fleet.showFleet(this.$route.params.id)
            if (isSuccess) {
                this.form.name = data.name
                this.form.dot_number = data.dot_number
                this.form.status = data.status
            }
        },
        async submitHandler() {
            if (this.editing) await this.updateFleet()
            else await this.addFleet()
        },
        async addFleet() {
            const {isSuccess, errors} = await fleet.storeFleet(this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Fleet successfully created.'})
                this.form = {}
                this.$router.push('/fleets')
            }
            else this.errors = errors
        },
        async updateFleet() {
            const {isSuccess, errors} = await fleet.updateFleet(this.$route.params.id, this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Fleet successfully updated.'})
                this.form = {}
                this.$router.push('/fleets')
            }
            else this.errors = errors
        },
        formatStatus(status) {
            return _.startCase(status)
        }
    }
}
</script>

<style scoped>

</style>
