<template>
    <div>
        <div class="d-flex justify-content-between">
            <h1>Form Police Officer</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/equipments" role="button"><i
                        class="fa fa-arrow-left"></i> Back to list</router-link>
            </div>
        </div>
        <b-tabs>
            <b-tab title="Profile" active>
                <div class="row">
                    <div class="col-4">
                        <div>
                            <DisplayError :errors="errors" />
                        </div>
                        <form @submit.prevent="submitHandler">
                            <div class="mb-3">
                                <label for="policeName" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.name) }">
                            </div>

                            <div class="mb-3">
                                <label for="policeEmail" class="form-label">Email</label>
                                <input v-model="form.email" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.email) }"
                                    aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="policePhone" class="form-label">Phone Number</label>
                                <input v-model="form.phone_number" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.phone_number) }">
                            </div>

                            <div class="mb-3">
                                <label for="policeDOT" class="form-label">DOT #</label>
                                <input v-model="form.dot_number" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.dot_number) }">
                            </div>

                            <div class="mb-3">
                                <label for="policeOfficeName" class="form-label">Office Name</label>
                                <input v-model="form.office_name" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.office_name) }">
                            </div>

                            <div class="mb-3">
                                <label for="policeBadge" class="form-label">Badge #</label>
                                <input v-model="form.badge_number" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.badge_number) }">
                            </div>

                            <div class="mb-3">
                                <label for="policeDepartment" class="form-label">Department</label>
                                <input v-model="form.department" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.department) }">
                            </div>

                            <div class="mb-3">
                                <label for="policeCountry" class="form-label">Country</label>
                                <input v-model="form.country" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.country) }">
                            </div>

                            <div class="mb-3">
                                <label for="policeCity" class="form-label">City</label>
                                <input v-model="form.city" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.city) }">
                            </div>

                            <div class="mb-3">
                                <label for="policeState" class="form-label">State</label>
                                <input v-model="form.state" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.state) }">
                            </div>

                            <button type="submit" class="btn btn-info text-white">Save Police Officer</button>
                        </form>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <DriverProfileMap add_link="/trailers" title="Equipment" url="/equipment-locations" />
                        </div>
                    </div>
                </div>
            </b-tab>

        </b-tabs>
    </div>
</template>

<script>
import ToggleButton from "../../components/common/ToggleButton.vue";
import SearchableSelect from "../../components/common/SearchableSelect.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import {ServiceFactory} from "../../utils/ServiceFactory";
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import DisplayError from "../../components/common/DisplayError.vue";
import axiosUtils from "../../utils/axiosUtil";
import toast from "bootstrap/js/src/toast";
import TrailerSelect from "../../components/common/selects/TrailerSelect.vue";
import VehicleSelect from "../../components/common/selects/VehicleSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import MapList from "../../components/common/maps/MapList.vue";
import DriverProfileMap from "../../components/common/maps/DriverProfileMap.vue";
import StatusSelect from "../../components/common/selects/StatusSelect.vue";
import DriverSelect from "../../components/common/selects/DriverSelect.vue";
const police = ServiceFactory.get('users')

export default {
    components: {
        DriverSelect, StatusSelect,
        DriverProfileMap,
        MapList,
        VehicleSelect, TrailerSelect, CompanySelect,
        DisplayError,
        LocationSelect,
        SearchableSelect,
        ToggleButton,
        VuePhoneNumberInput
    },
    name: "FormPoliceOfficer",
    data() {
        return {
            form: {
                id:null,
                name: null,
                email: null,
                phone_number: null,
                dot_number: null,
                office_name: null,
                badge_number: null,
                department: null,
                country: null,
                city: null,
                state: null,
            },
            equipment_type: [
                { text: 'Large Equipment', value: 'large_equipment' },
                { text: 'Small Equipment', value: 'small_equipment' }
            ],
            errors: null,
            requestHistories: [],
            qr_path: null,
            invitation_code: null,
            isResending: false,
        }
    },
    async created() {
        await this.getDetails();
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        }
    },
    methods: {
        getDetails() {
            axiosUtils.get(`/police-officers/${this.$route.params.id}`)
                .then(response => {
                    const { id, name, email, phone_number, dot_number, office_name, country, city, state, badge_number, department } = response.data.data
                    this.form.id = id
                    this.form.name = name
                    this.form.email = email
                    this.form.phone_number = phone_number
                    this.form.dot_number = dot_number
                    this.form.country = country
                    this.form.city = city
                    this.form.state = state
                    this.form.badge_number = badge_number
                    this.form.office_name = office_name
                    this.form.department = department
                })
                .catch((_) => {
                    this.$notify({
                        type: 'danger',
                        text: 'Something Went Wrong.'
                    });
                });
        },
        async submitHandler() {
            if (this.editing) await this.updatePoliceOfficer()
            else await this.addPoliceOfficer()
        },
        async updatePoliceOfficer() {
            const { isSuccess, errors } = await police.update(this.$route.params.id, this.form, 'police-officers')
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Police Officer successfully updated.'})
                this.form = {}
                this.$router.push('/police-officers')
            }
            else this.errors = errors
        },
        async addPoliceOfficer() {
            const { isSuccess, errors } = await police.store(this.form, 'police-officers')
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Police Officer successfully created.'})
                this.form = {}
                this.$router.push('/police-officers')
            }
            else this.errors = errors
        },
        hasError(name) {
            return _.has(this.errors, name)
        },
    }
}
</script>

<style scoped>

</style>
