<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between my-2">
            <h1 class="tw-font-bold tw-text-2xl">Equipments</h1>
            <div>
                <!-- <router-link class="btn btn-info text-white fw-bold" to="/equipments/add" role="button"><i class="fa fa-plus"></i> Add Equipments</router-link> -->
                <button @click="$refs['add-modal'].show()" class="btn btn-info text-white fw-bold"><i
                        class="fa fa-plus"></i> Add Equipments</button>
            </div>
        </div>
        <b-card>
            <div class="d-flex justify-content-start gap-3 mb-3">
                <div>
                    <select class="form-select" aria-label="Default select example" v-model="filterBy">
                        <option selected value="" disabled>Filter By...</option>
                        <option :value="item.value" v-for="(item, index) in filterOptions" :key="index">{{ item.label }}
                        </option>
                    </select>
                </div>

                <div v-if="filterBy">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                        <input v-model="search" @input="debounceInput" type="text" class="form-control"
                            placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                </div>
            </div>
            <vuetable ref="vuetable" :api-mode="false" :fields="fields" :per-page="perPage" :data-manager="dataManager"
                pagination-path="pagination" :sort-params="getSortParam" :css="css.table">
                <div slot="actions" slot-scope="props">
                    <button class="btn btn-info btn-sm" @click="editHandler(props.rowData)">
                        <i class="fa fa-edit text-white"></i>
                    </button>
                    <button class="btn btn-danger btn-sm" @click="deleteHandler(props.rowData)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </vuetable>
            <div class="d-flex justify-content-end mt-3">
                <Pagination :data="data" :limit="5" @pagination-change-page="changePage" />
            </div>
            <div>
                <b-modal ref="add-modal" hide-footer title="Add New Equipment" centered>
                    <div class="d-block">
                        <form @submit.prevent="submitHandler">
                            <div class="mb-3">
                                <label for="equipmentName" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control border"
                                    :class="{'border-danger' :(errors && errors.errors.name)}"
                                    aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="equipmentCompany" class="form-label">Company</label>
                                <CompanySelect v-model="form.company_id" :value="form.company_id"
                                    :class="{'border border-danger' :(errors && errors.errors.company_id)}" />
                            </div>

                            <div class="mb-3">
                                <label for="equipmentLocation" class="form-label">Location</label>
                                <LocationSelect v-model="form.location_id" :value="form.location_id"
                                    :companyId="form.company_id"
                                    :class="{'border border-danger' :(errors && errors.errors.location_id)}" />
                            </div>


                            <div class="mb-3">
                                <label for="equipmentComment" class="form-label">Comments</label>
                                <textarea name="comment" class="form-control border" cols="30" rows="10"
                                    v-model="form.comments"
                                    :class="{'border-danger' :(errors && errors.errors.comments)}">{{form.comments}}</textarea>
                            </div>

                            <b-form-group label="Equipment Type" v-slot="{ ariaDescribedby }">
                                <b-form-radio-group v-model="form.type" :options="equipment_type"
                                    :aria-describedby="ariaDescribedby" name="plain-stacked" plain
                                    :class="{'border border-danger' :(errors && errors.errors.type)}"
                                    class="text-center"></b-form-radio-group>
                            </b-form-group>

                            <button type="submit" class="btn btn-info text-white">Save Equipment</button>
                        </form>
                    </div>
                </b-modal>
            </div>
        </b-card>
    </div>
</template>

<script>
import {ServiceFactory} from "../../utils/ServiceFactory";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import _ from "lodash";
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import Spinner from 'vue-simple-spinner';

const equipment = ServiceFactory.get('equipment')

export default {
    name: "Equipments",
    components: {
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        Pagination,
        Spinner,
        LocationSelect,
        CompanySelect
    },

    data() {
        return {
            form: {
                name: null,
                location_id: null,
                company_id: null,
                type: null,
                comments: null,
            },
            fields: [
                {
                    name: 'name',
                    sortField: 'name'
                },
                {
                    name: 'type',
                    sortField: 'type'
                },
                {
                    name: 'company',
                    sortField: 'company'
                },
                {
                    name: 'fleet',
                    sortField: 'fleet'
                },
                {
                    name: 'comments',
                    sortField: 'comments'
                },
                'actions'
            ],
            perPage: 15,
            data: [],
            filterOptions: [
                {value: 'name', label: 'Name'},
                {value: 'type', label: 'Type'},
                {value: 'company', label: 'Company'},
                {value: 'fleet', label: 'Fleet'},
                {value: 'comments', label: 'Comments'},
            ],
            currentPage:1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false,
            equipment_type: [
                { text: 'Large Equipment', value: 'large_equipment' },
                { text: 'Small Equipment', value: 'small_equipment' }
            ],
            errors: null,
        };
    },

    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.refresh();
        },
        sortField(n, o) {
            this.getData()
        }
    },

    async created() {
        await this.getData()
    },

    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        getSortParam(sortOrder) {
            console.log(sortOrder)
        },
        changePage(page) {
            this.currentPage=page
            this.getData()
        },
        async getData() {
            this.loading = true
            const {isSuccess, data} = await equipment.getEquipments({
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search
            });
            if (isSuccess) this.data = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
        dataManager(sortOrder) {
            if (sortOrder.length > 0) {
                const direction = (sortOrder[0].direction === 'desc' ? '-': '')
                this.sortField = `${direction}${sortOrder[0].sortField}`
            }
            return {data: _.slice(this.data.data)};
        },
        editHandler(data) {
            this.$router.push(`/equipments/${data.id}/edit`)
        },
        async deleteHandler(data) {
            const vm = this
            this.$dialog.confirm('Are you sure, you want to delete this item?')
                .then(async function (dialog) {
                    const {isSuccess} = await equipment.deleteEquipment(data.id)
                    if (isSuccess) {
                        await vm.getData()
                        vm.$notify({type: 'success', 'text': 'Admin user successfully deleted.'})
                    }
                });
        },
        async submitHandler() {
            const {isSuccess, errors, data} = await equipment.storeEquipment(this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Equipment successfully created.'})
                this.form = {}
                this.$router.push(`/equipments/${data.id}/edit`)
            }
            else this.errors = errors
        },
    }
};
</script>
