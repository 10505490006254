<template>
    <div>
        <div class="d-flex justify-content-between">
            <h1>{{editing ? 'Edit' : 'Add'}} Equipments</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/equipments" role="button"><i
                        class="fa fa-arrow-left"></i> Back to list</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col col-lg-3">
                <div class="row gap-2">
                    <div>
                        <DisplayError :errors="errors" />
                    </div>
                    <form @submit.prevent="submitHandler">
                        <div class="mb-3">
                            <label for="equipmentName" class="form-label">Name</label>
                            <input v-model="form.name" type="text" class="form-control border"
                                :class="{'border-danger' :(errors && errors.errors.name)}" aria-describedby="emailHelp">
                        </div>

                        <div class="mb-3">
                            <label for="equipmentCompany" class="form-label">Company</label>
                            <CompanySelect v-model="form.company_id" :value="form.company_id"
                                :class="{'border border-danger' :(errors && errors.errors.company_id)}" />
                        </div>

                        <div class="mb-3">
                            <label for="equipmentLocation" class="form-label">Location</label>
                            <LocationSelect v-model="form.location_id" :value="form.location_id"
                                :companyId="form.company_id"
                                :class="{'border border-danger' :(errors && errors.errors.location_id)}" />
                        </div>


                        <div class="mb-3">
                            <label for="equipmentComment" class="form-label">Comments</label>
                            <textarea name="comment" class="form-control border" cols="30" rows="10"
                                v-model="form.comments"
                                :class="{'border-danger' :(errors && errors.errors.comments)}">{{form.comments}}</textarea>
                        </div>

                        <b-form-group label="Equipment Type" v-slot="{ ariaDescribedby }">
                            <b-form-radio-group v-model="form.type" :options="equipment_type"
                                :aria-describedby="ariaDescribedby" name="plain-stacked" plain stacked
                                :class="{'border border-danger' :(errors && errors.errors.type)}"></b-form-radio-group>
                        </b-form-group>

                        <button type="submit" class="btn btn-info text-white">Save Equipment</button>
                    </form>
                </div>
            </div>
            <div class="col col-lg-9">
                <b-tabs>
                    <b-tab title="General">
                        <div class="row gap-2">
                        </div>
                    </b-tab>
                    <b-tab title="Code" active>
                        <div class="row">
                            <button type="button" @click="generateCode()"
                                class="btn btn-info ml-auto mr-2 text-white w-25"><i class="fa fa-gears"></i> Generate
                                Code</button>
                        </div>
                        <div class="row">
                            <b-form-group label="QVIN Code">
                                <editable-field v-model="form.qvin_code"></editable-field>
                            </b-form-group>
                            <!-- <b-form-group label="Current Mileage">
                                <editable-field v-model="form.current_mileage"></editable-field>
                            </b-form-group> -->
                        </div>
                        <div class="row text-center" v-if="form.qvin_code">
                            <qrcode-vue :value="qrCode()" size="200" level="H"></qrcode-vue>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import ToggleButton from "../../components/common/ToggleButton.vue";
import SearchableSelect from "../../components/common/SearchableSelect.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import {ServiceFactory} from "../../utils/ServiceFactory";
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import DisplayError from "../../components/common/DisplayError.vue";
import EditableField from "../../components/common/EditableField.vue";
import QrcodeVue from 'qrcode.vue';
const equipment = ServiceFactory.get('equipment')

export default {
    components: {
        DisplayError,
        LocationSelect,
        SearchableSelect,
        CompanySelect,
        ToggleButton,
        VuePhoneNumberInput,
        EditableField,
        QrcodeVue
    },
    name: "FormEquipment",
    data() {
        return {
            form: {
                id:null,
                name: null,
                type: null,
                comments: null,
                location_id: null,
                company_id: null,
                qvin_code: null,
            },
            equipment_type: [
                { text: 'Large Equipment', value: 'large_equipment' },
                { text: 'Small Equipment', value: 'small_equipment' }
            ],
            errors: null
        }
    },
    async created() {
        if (this.editing) {
            this.getDetails()
        }
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        }
    },
    methods: {
        async getDetails() {
            const {isSuccess, data} = await equipment.showEquipment(this.$route.params.id)
            if (isSuccess) {
                this.form.id = data.id
                this.form.name = data.name
                this.form.location_id = data.location_id
                this.form.comments = data.comments
                this.form.company_id = data.company_id
                this.form.qvin_code = data.qvin_code
                this.form.type = _.snakeCase(data.type)
            }
        },
        async submitHandler() {
            if (this.editing) await this.updateEquipment()
            else await this.addEquipment()
        },
        async updateEquipment(redirect = true) {
            const {isSuccess, errors} = await equipment.updateEquipment(this.$route.params.id, this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Equipment successfully updated.'})
                if(redirect) {
                    this.form = {};
                    this.$router.push('/equipments')
                }
            }
            else this.errors = errors
        },
        async addEquipment() {
            const {isSuccess, errors} = await equipment.storeEquipment(this.form)
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Equipment successfully created.'})
                this.form = {}
                this.$router.push('/equipments')
            }
            else this.errors = errors
        },
        hasError(name) {
            return _.has(this.errors, name)
        },
        qrCode() {
            this.form.qvin_url = `https://q-vin.com/${this.form?.qvin_code}`
             return this.form.qvin_url;
        },
        generateCode(){
            // if(!this.form.vin) this.$notify({type: 'warning', text: 'Please enter VIN # before generating code.'})
            var hash = this.cyrb53(this.form.id);
            this.form.qvin_code = hash;

            this.updateEquipment(false)
        },
        cyrb53 : function(str, seed = 0) {
            let h1 = 0xdeadbeef ^ seed,
                h2 = 0x41c6ce57 ^ seed;
            for (let i = 0, ch; i < str.length; i++) {
                ch = str.charCodeAt(i);
                h1 = Math.imul(h1 ^ ch, 2654435761);
                h2 = Math.imul(h2 ^ ch, 1597334677);
            }

            h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
            h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

            return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        }
    }
}
</script>

<style scoped>

</style>
