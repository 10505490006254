<template>
    <div>
        <div class="d-flex justify-content-between">
            <h1 class="tw-font-bold tw-text-2xl">{{ editing ? 'Edit' : 'Add' }} Mechanic</h1>
            <div>
                <router-link class="btn btn-info text-white fw-bold" to="/mechanics" role="button"><i
                        class="fa fa-arrow-left"></i> Back to list</router-link>
            </div>
        </div>
        <b-tabs>
            <b-tab title="Profile" active>
                <div class="row">
                    <div class="col-4">
                        <div>
                            <DisplayError :errors="errors" />
                        </div>
                        <form @submit.prevent="submitHandler">
                            <div class="mb-3">
                                <label for="mechanicName" class="form-label">Name</label>
                                <input v-model="form.name" type="text" class="form-control border"
                                    :class="{'border-danger' :(errors && errors.errors.name)}"
                                    aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="mechanicEmail" class="form-label">Email</label>
                                <input v-model="form.email" type="text" class="form-control border"
                                    :class="{'border-danger' :(errors && errors.errors.email)}"
                                    aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="mechanicPhone" class="form-label">Phone Number</label>
                                <input v-model="form.phone_number" type="text" class="form-control border"
                                    :class="{'border-danger' :(errors && errors.errors.phone_number)}"
                                    aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="mechanicDOT" class="form-label">DOT #</label>
                                <input v-model="form.dot_number" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.dot_number) }"
                                    aria-describedby="emailHelp">
                            </div>

                            <div class="mb-3">
                                <label for="mechanicCompany" class="form-label">Company</label>
                                <input v-model="form.company" type="text" class="form-control border"
                                    :class="{ 'border-danger': (errors && errors.errors.company) }"
                                    aria-describedby="emailHelp">
                            </div>

                            <p class="text-center font-weight-bold">Send via link</p>
                            <div class="mt-3 d-flex justify-content-center align-items-center gap-2">
                                <label class="radio-inline">
                                    <input v-model="form.send_via" value="sms" type="radio" name="optradio" checked>
                                    Text/SMS
                                </label>
                                <label class="radio-inline">
                                    <input v-model="form.send_via" value="email" type="radio" name="optradio">
                                    Email
                                </label>
                            </div>

                            <button type="submit" class="btn btn-info text-white">
                                {{ editing ? "Save Mechanic" : "Add Mechanic and Send Invite" }}
                            </button>
                            <button @click="resendInvite()" type="button" class="btn btn-success text-white"
                                v-if="editing" :disabled="isResending || responded">
                                Resend Invite
                            </button>
                        </form>
                    </div>
                    <div class="col-8" v-if="editing">
                        <div class="row">
                            <DriverProfileMap add_link="/trailers" title="Equipment" url="/equipment-locations" />
                        </div>
                    </div>
                </div>
            </b-tab>

        </b-tabs>
    </div>
</template>

<script>
import ToggleButton from "../../components/common/ToggleButton.vue";
import SearchableSelect from "../../components/common/SearchableSelect.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import {ServiceFactory} from "../../utils/ServiceFactory";
import LocationSelect from "../../components/common/selects/LocationSelect.vue";
import DisplayError from "../../components/common/DisplayError.vue";
import axiosUtils from "../../utils/axiosUtil";
import toast from "bootstrap/js/src/toast";
import TrailerSelect from "../../components/common/selects/TrailerSelect.vue";
import VehicleSelect from "../../components/common/selects/VehicleSelect.vue";
import CompanySelect from "../../components/common/selects/CompanySelect.vue";
import MapList from "../../components/common/maps/MapList.vue";
import DriverProfileMap from "../../components/common/maps/DriverProfileMap.vue";
import StatusSelect from "../../components/common/selects/StatusSelect.vue";
import DriverSelect from "../../components/common/selects/DriverSelect.vue";
const mechanic = ServiceFactory.get('users');
const user = ServiceFactory.get('user')

export default {
    components: {
        DriverSelect, StatusSelect,
        DriverProfileMap,
        MapList,
        VehicleSelect, TrailerSelect, CompanySelect,
        DisplayError,
        LocationSelect,
        SearchableSelect,
        ToggleButton,
        VuePhoneNumberInput
    },
    name: "FormMechanic",
    data() {
        return {
            form: {
                id: null,
                name: null,
                email: null,
                phone_number: null,
                dot_number: null,
                company: null,
                send_via: null,
            },
            errors: null,
            requestHistories: [],
            isResending: false,
            responded: null,
            user_id: null,
        }
    },
    async created() {
        if (this.editing) {
            this.getDetails()
        }
    },
    computed: {
        editing() {
            return !!this.$route.params.id
        }
    },
    methods: {
        getDetails() {
            axiosUtils.get(`/mechanics/${this.$route.params.id}`)
                .then(response => {
                    const { id, name, email, phone_number, dot_number, company, user_id, send_via, responded } = response.data.data
                    this.form.id = id
                    this.form.name = name
                    this.form.email = email
                    this.form.phone_number = phone_number
                    this.form.dot_number = dot_number
                    this.form.company = company
                    this.form.send_via = send_via
                    this.user_id = user_id
                    this.responded = responded
                })
                .catch((_) => {
                    this.$notify({
                        type: 'danger',
                        text: 'Something Went Wrong.'
                    });
                });
        },
        async submitHandler() {
            if (this.editing) await this.updateMechanic()
            else await this.addMechanic()
        },
        async updateMechanic() {
            const {isSuccess, errors} = await mechanic.update(this.$route.params.id, this.form, 'mechanics')
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Mechanic successfully updated.'})
                this.form = {}
                this.$router.push('/mechanics')
            }
            else this.errors = errors
        },
        async addMechanic() {
            const { isSuccess, errors } = await mechanic.store(this.form, 'mechanics')
            if (isSuccess) {
                this.$notify({type: 'success', text: 'Mechanic successfully created.'})
                this.form = {}
                this.$router.push('/mechanics')
            }
            else this.errors = errors
        },
        hasError(name) {
            return _.has(this.errors, name)
        },
        async resendInvite(){
            this.isResending = true;
            const { isSuccess, errors } = await user.resendInvite(this.user_id)
            if(isSuccess){
                this.$notify({ type: 'success', 'text': 'Invitation has been successfully resent.' });
                this.isResending = false;
            }
            else {
                this.$notify({ type: 'error', 'text': 'Resend invitation failed' });
                this.isResending = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
