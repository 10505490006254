<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between my-2">
            <h1 class="tw-font-bold tw-text-2xl">Subscribers</h1>
            <div>
                <button class="btn btn-info text-white fw-bold" @click="addDotReadyHandler" role="button"><i class="fa fa-plus"></i> Add DOT Ready Subscriber</button>
                <router-link class="btn btn-info text-white fw-bold" to="/subscribers/add" role="button"><i class="fa fa-plus"></i> Add Subscriber</router-link>
            </div>
        </div>
        <b-card>
            <div class="d-flex justify-content-start gap-3 mb-3">
                <div>
                    <select class="form-select" aria-label="Default select example" v-model="filterBy">
                        <option selected value="" disabled>Filter By...</option>
                        <option :value="item.value" v-for="(item, index) in filterOptions" :key="index">{{ item.label }}</option>
                    </select>
                </div>

                <div v-if="filterBy">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                        <input v-model="search" @input="debounceInput" type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                </div>
            </div>
            <b-tabs>
               <b-tab title="Qvin">
                   <vuetable ref="vuetable"
                             :api-mode="false"
                             :fields="fields"
                             :per-page="perPage"
                             :data-manager="dataManager"
                             pagination-path="pagination"
                             :sort-params="getSortParam"
                             :css="css.table"
                   >
                       <div slot="actions" slot-scope="props">
                           <button
                               class="btn btn-info btn-sm"
                               @click="editHandler(props.rowData)"
                           >
                               <i class="fa fa-edit text-white"></i>
                           </button>
                           <button
                               class="btn btn-danger btn-sm"
                               @click="deleteHandler(props.rowData)"
                           >
                               <i class="fa fa-trash"></i>
                           </button>
                       </div>
                   </vuetable>
                   <div class="d-flex justify-content-end mt-3">
                       <Pagination :data="data" :limit="5" @pagination-change-page="changePage" />
                   </div>
               </b-tab>
                <b-tab title="Dot Ready">
                    <template v-if="!loadingDotReady">
                        <data-table-local
                            ref="subTable"
                            :columns="dotReadyFields"
                            default_sort_direction=""
                            default_sort_field=""
                            :show_entries="[10,15,20,50,75,100]"
                            :show_entries_default="15"
                            :items="dotReadyData"
                            :loading="loadingDotReady"
                        >
                            <template v-slot:action="{item}">
                                <button class="btn btn-info btn-sm text-white" @click="showHandler(item)"><i class="fa fa-eye"></i></button>
                            </template>
                        </data-table-local>
                    </template>
                    <template v-else>
                        <div class="d-flex justify-content-center align-items-center gap-3">
                            <Spinner /> Loading
                        </div>
                    </template>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import _ from "lodash";
import Spinner from 'vue-simple-spinner';
import axiosUtil from "../../utils/axiosUtil";

import {ServiceFactory} from "../../utils/ServiceFactory";
import dataTableLocal from "../../components/dataTable/DataTableLocal.vue";
const subscriber = ServiceFactory.get('subscriber')

export default {
    name: "Subscribers",
    components: {
        dataTableLocal,
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        Pagination,
        Spinner
    },

    data() {
        return {
            fields: [
                {name: 'name', title: 'Name', sortField: 'name'},
                {name: 'phone', sortField: 'phone'},
                {name: 'email', sortField: 'email'},
                {name: 'type', sortField: 'type'},
                {name: 'dot_number', title: 'Dot Number', sortField: 'dot_number'},
                {name: 'driver_type', title: 'Driver Type', sortField: 'driver_type'},
                'actions'
            ],
            dotReadyFields: [
                {label: 'Name',field: 'name',  sortField: 'name' },
                {label: 'Trailers',field: 'trailers',  sortField: 'trailers' },
                {label: 'Users',field: 'users',  sortField: 'users' },
                {label: 'Vehicles',field: 'vehicles',  sortField: 'vehicles' },
                {label: 'Actions', field: 'action',}
            ],
            filterOptions: [
                {value: 'name', label: 'Name'},
                {value: 'email', label: 'Email'},
                {value: 'phone', label: 'Phone'},
                {value: 'type', label: 'Type'},
                {value: 'dot_number', label: 'Dot Number'},
                {value: 'driver_type', label: 'Driver Type'},
            ],
            perPage: 15,
            data: [],
            dotReadyData: {},
            currentPage:1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false,
            loadingDotReady:false
        };
    },

    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.refresh();
        },
        sortField(n, o) {
            this.getData()
        }
    },

    async created() {
        await this.getData()
        await this.getDotReadyData()
    },

    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        getSortParam(sortOrder) {
            console.log(sortOrder)
        },
        changePage(page) {
            this.currentPage=page
            this.getData()
        },
        async getData() {
            this.loading = true
            const {isSuccess, data} = await subscriber.getSubcribers({
                perPage: this.perPage,
                currentPage: this.currentPage,
                sortField: this.sortField,
                filterBy: this.filterBy,
                search: this.search
            });
            if (isSuccess) this.data = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong.'
            });
            this.loading = false
        },
        async getDotReadyData() {
            this.loadingDotReady = true
            const {isSuccess, data} = await subscriber.getSubscribersDotReady();
            if (isSuccess) this.dotReadyData = data
            else this.$notify({
                type: 'danger',
                text: 'Something Went Wrong - Dot Ready.'
            });
            this.loadingDotReady = false
        },
        dataManager(sortOrder) {
            if (sortOrder.length > 0) {
                const direction = (sortOrder[0].direction === 'desc' ? '-': '')
                this.sortField = `${direction}${sortOrder[0].sortField}`
            }
            return {data: _.slice(this.data.data)};
        },
        editHandler(data) {
            this.$router.push(`/subscribers/${data.id}/edit`)
        },
        addDotReadyHandler(data) {
            window.location = process.env.MIX_DOT_READY_URL
        },
        async deleteHandler(data) {
            const vm = this
            this.$dialog.confirm('Are you sure, you want to delete this item?')
                .then(async function (dialog) {
                    const {isSuccess} = await subscriber.deleteSubscriber(data.id)
                    if (isSuccess) {
                        await vm.getData()
                        vm.$notify({type: 'success', 'text': 'Subscriber successfully deleted.'})
                    }
                });
        },
        showHandler() {
            window.location =  `${process.env.MIX_DOT_READY_URL}`
        },
    }
};
</script>
