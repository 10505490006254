<template>
    <div class="container-fluid mt-5">
        <p class="font-weight-bold font-xl mb-1">GPS Location History</p>
        <data-table ref="locationsTable" :columns="fields" default_sort_direction="" default_sort_field=""
            :show_entries="[10, 15, 20, 50, 75, 100]" :show_entries_default="15" url="trucks" :items="data"
            @getData="dataManager" :loading="loading">
            <template v-slot:created_at="{ item }">
                <p>{{ item.created_at ? formatDate(item.created_at) : '' }}</p>
            </template>
        </data-table>
    </div>
</template>

<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableCssBootstrap4 from "../../Datatable/VuetableCssBootstrap4";
import Pagination from '../../components/dataTable/LaravelVuePagination.vue';
import DataTable from "../../components/dataTable/DataTable.vue";
import axiosUtils from "../../utils/axiosUtil";
import Spinner from 'vue-simple-spinner';
import moment from 'moment';
import _ from "lodash";

export default {
    name: "GPSLocationList",
    components: {
        Vuetable,
        VuetablePagination,
        VuetableCssBootstrap4,
        Pagination,
        Spinner,
        dataTable: DataTable,
    },

    data() {
        return {
            fields: [
                { label: 'Latitude', field: 'lat', sortField: 'lat' },
                { label: 'Longitude', field: 'lng', sortField: 'lng' },
                { label: 'Speed', field: 'speed', sortField: 'speed' },
                { label: 'Driver', field: 'driver', sortField: 'driver.name' },
                { label: 'Date Created', field: 'created_at', sortField: 'created_at' },
            ],
            perPage: 15,
            data: {},
            dataDotready: {},
            currentPage: 1,
            sortField: null,
            filterBy: "",
            search: null,
            css: VuetableCssBootstrap4,
            loading: false,
            loadingDotReady: false,
            errors: null,
        };
    },

    watch: {
        data(newVal, oldVal) {

        },
        '$route.name': {
            handler: async function () {
                await this.getData()
            },
            deep: true,
        }
    },
    computed: {
        role() {
            return this.$store.getters["user/getRoles"]
        },
        isAdmin() {
            return this.$store.getters["user/getRoles"] === 'admin'
        }
    },

    props: {
        type: {
            default: null
        },
        vehicleId : {
            default: null
        },
    },

    async created() {
        await this.getData()
    },

    methods: {
        debounceInput: _.debounce(function (e) {
            this.getData();
        }, 1000),
        changePage(page) {
            this.currentPage = page
            this.getData()
        },
        async getData() {
            this.loading = true
            const filter = this.filterBy !== '' ? `&filter[search]=${this.filterBy},${this.search}` : '';
            const type = this.type ? `&type=${this.type === "Trucks" ? 'vehicle' : 'trailer'}` : '';
            const buildUrl = `?per_page=${this.perPage}&page=${this.currentPage}&sort=${this.sortField || ''}${filter}${type}`;
            axiosUtils.get(`/truck-locations/${this.vehicleId}${buildUrl}`)
                .then(locations => {
                    this.data = locations.data;
                }).finally(() => {
                }).catch((err) => {
                    this.$notify({ type: 'error', text: 'Something went wrong.' })
                    })
            this.loading = false
        },
        dataManager(data) {
            const direction = (data.sort_direction === 'desc' ? '-' : '')
            this.sortField = `${direction}${data.sort_field}`
            this.currentPage = data.page
            this.perPage = data.paginate
            this.filterBy = data.queries
            this.search = data.search
            this.getData()
        },
        formatDate: function (createdDate) {
            if (!createdDate) {
                return null;
            }

            return moment(createdDate).format('M/D/YYYY, h:mm:ss a');
        },
    }
};
</script>

<style>
table>thead {
    white-space: nowrap;
}

.comments {
    white-space: pre;
}
</style>
