<template>
  <div class="editable-field">
    <div v-if="readOnly">
      <template v-if="type === 'textarea'">
          <b-form-textarea :rows="rows" v-model="editedValue" :placeholder="placeholder" readonly></b-form-textarea>
      </template>
      <template v-else>
        <b-form-input :size="size" v-model="editedValue" readonly></b-form-input>
      </template>
    </div>
    <div v-else>
      <b-form @submit.prevent="commitEdit">
        <b-input-group :size="size">
          <template v-if="type == 'maskedDate'">
            <masked-input v-on:focus="startEditing" v-model="editedValue" type="text" class="form-control" placeholder="MM/DD/YYYY" :mask="[/\d/, /\d/, '\/', /\d/, /\d/, '\/', /\d/, /\d/, /\d/, /\d/]" :guide="true" placeholderChar="_"></masked-input>
            <b-input-group-append v-if="isEditing">
              <button class="btn btn-secondary" @click="stopEditing"><i class="fa fa-times"></i></button>
              <button class="btn btn-primary" type="submit" @click="commitEdit"><i class="fa fa-check"></i></button>
            </b-input-group-append>
          </template>
          <template v-else-if="type == 'textarea'">
            <b-form-textarea :rows="rows" v-model="editedValue" :placeholder="placeholder" @focus.native="startEditing"></b-form-textarea>
            <div v-if="isEditing" class="w-100 text-right pt-2">
              <button class="btn btn-secondary" @click="stopEditing"><i class="fa fa-times"></i></button>
              <button type="submit" class="btn btn-primary"><i class="fa fa-check"></i></button>
            </div>
          </template>
          <template v-else-if="type == 'numeric'">
            <b-form-input type="number" v-model="editedValue" :placeholder="placeholder" @focus.native="startEditing"></b-form-input>
            <b-input-group-append v-if="isEditing">
              <button class="btn btn-secondary" @click="stopEditing"><i class="fa fa-times"></i></button>
              <button type="submit" class="btn btn-primary"><i class="fa fa-check"></i></button>
            </b-input-group-append>
          </template>
          <template v-else>
            <b-form-input v-model="editedValue" :placeholder="placeholder" @focus.native="startEditing"></b-form-input>
            <b-input-group-append v-if="isEditing">
              <button class="btn btn-secondary" @click="stopEditing"><i class="fa fa-times"></i></button>
              <button type="submit" class="btn btn-primary"><i class="fa fa-check"></i></button>
            </b-input-group-append>
          </template>
        </b-input-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';

export default {

  data: function () {
    return {
      isEditing: false,
      currentValue: this.value,
      editedValue: this.value
    }
  },

  props: {
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: ''
    },
    value: {
      default: null
    },
    rows: {
      default: 8
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  components: {
    MaskedInput
  },
  watch: {
    value: function (newVal, oldVal) {
      this.currentValue = newVal;
      this.editedValue = newVal;
    },
  },
    methods: {

    startEditing: function () {
      this.isEditing = true;
    },

    stopEditing: function () {
      this.editedValue = this.currentValue;
      this.isEditing = false;
    },

    commitEdit: function () {
      this.currentValue = this.editedValue;
      this.$emit('input', this.editedValue);
      this.isEditing = false;
    }

  }

}
</script>

<style type="scss">
.is-invalid .form-control {
  border-color: #f86c6b;
}

.editable-field .form-control[readonly] {
  background-color: #ffffff;
}
</style>
