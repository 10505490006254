<template>
    <div>
        <h1 class="tw-font-bold tw-text-2xl">Demo User Wizard</h1>
        <form-wizard title="" subtitle="" color="#20a8d8" ref="formWizard" @on-change="setTab">
            <tab-content title="User" :beforeChange="validate">
                <div class="row mb-3">
                    <div class="col">
                        <label for="firstname" class="form-label">First Name</label>
                        <input name="firstname" v-model="user.first_name" type="text" class="form-control"
                            :class="`${!user.first_name && showError ? 'border-danger' : ''}`">
                    </div>
                    <div class="col">
                        <label for="lastname" class="form-label">Last Name</label>
                        <input name="lastname" v-model="user.last_name" type="text" class="form-control"
                            :class="`${!user.last_name && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="email" class="form-label">Email</label>
                        <input name="email" v-model="user.email" type="text" class="form-control"
                            :class="`${(!user.email || !userEmailValid) && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
            </tab-content>
            <tab-content title="Fleet" :beforeChange="validate">
                <div class="row mb-3">
                    <div class="col">
                        <label for="fleet_name" class="form-label">Name</label>
                        <input name="fleet_name" v-model="fleet.name" type="text" class="form-control"
                            :class="`${!fleet.name && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="fleet_dot_number" class="form-label">DOT #</label>
                        <input name="fleet_dot_number" v-model="fleet.dot_number" type="text" class="form-control"
                            :class="`${!fleet.dot_number && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
            </tab-content>
            <tab-content title="Company" :beforeChange="validate">
                <div class="row mb-3">
                    <div class="col">
                        <label for="company_name" class="form-label">Name</label>
                        <input name="company_name" v-model="company.name" type="text" class="form-control"
                            :class="`${!company.name && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row mb-3" :beforeChange="validate">
                    <div class="col">
                        <label for="company_dot_number" class="form-label">DOT #</label>
                        <input name="company_dot_number" v-model="company.dot_number" type="text" class="form-control"
                            :class="`${!company.dot_number && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="company_dot_number" class="form-label">Fleet</label>
                        <input name="company_dot_number" v-model="company.fleet" type="text" class="form-control"
                            disabled>
                    </div>
                </div>
            </tab-content>
            <tab-content title="Location" :beforeChange="validate">
                <div class="row mb-3">
                    <div class="col">
                        <label for="location_name" class="form-label">Name</label>
                        <input name="location_name" v-model="location.name" type="text" class="form-control"
                            :class="`${!location.name && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="location_company" class="form-label">Company</label>
                        <input name="location_company" v-model="location.company" type="text" class="form-control"
                            disabled>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="location_address" class="form-label">Address</label>
                        <input name="location_address" v-model="location.address" type="text" class="form-control"
                            :class="`${!location.address && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="location_city" class="form-label">City</label>
                        <input name="location_city" v-model="location.city" type="text" class="form-control"
                            :class="`${!location.city && showError ? 'border-danger' : ''}`">
                    </div>
                    <div class="col">
                        <label for="location_state" class="form-label">State</label>
                        <state-select v-model="location.state_id" name="location_state"></state-select>
                    </div>
                    <div class="col">
                        <label for="location_zip" class="form-label">Postal Code</label>
                        <input name="location_zip" v-model="location.postal_code" type="text" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="location_timezone" class="form-label">Timezone</label>
                        <timezone-select ref="timezoneSelect" v-model="location.timezone" name="location_timezone"
                            :class="`${!location.timezone && showError ? 'border-danger' : ''}`"></timezone-select>
                    </div>
                </div>
            </tab-content>
            <tab-content title="Truck" :beforeChange="validate">
                <div class="row mb-3">
                    <div class="col">
                        <label for="truck_name" class="form-label">Name</label>
                        <input name="truck_name" v-model="truck.name" type="text" class="form-control"
                            :class="`${!truck.name && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="truck_vin" class="form-label">VIN #</label>
                        <input name="truck_vin" v-model="truck.vin" type="text" class="form-control"
                            :class="`${!truck.vin && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="truck_location" class="form-label">Location</label>
                        <input name="truck_location" v-model="truck.location" type="text" class="form-control" disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="truck_company" class="form-label">Company</label>
                        <input name="truck_company" v-model="truck.company" type="text" class="form-control" disabled>
                    </div>
                </div>
            </tab-content>
            <tab-content title="Driver">
                <div class="row mb-3">
                    <div class="col">
                        <label for="driver_name" class="form-label">Name</label>
                        <input name="driver_name" v-model="driver.name" type="text" class="form-control"
                            :class="`${!driver.name && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="driver_phone" class="form-label">Phone #</label>
                        <vue-phone-number-input name="driver_phone" @update="onUpdate" v-model="phone" clearable
                            valid-color="#68CD86"
                            :class="`${!driver.phone_number && showError ? 'border-danger' : ''}`" />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="driver_email" class="form-label">Email</label>
                        <input name="driver_email" v-model="driver.email" type="text" class="form-control"
                            :class="`${(!driver.email || !driverEmailValid) && showError ? 'border-danger' : ''}`">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="driver_truck" class="form-label">Truck</label>
                        <input name="driver_truck" v-model="driver.truck" type="text" class="form-control" disabled>
                    </div>
                    <div class="col">
                        <label for="driver_location" class="form-label">Location</label>
                        <input name="driver_location" v-model="driver.location" type="text" class="form-control"
                            disabled>
                    </div>
                    <div class="col">
                        <label for="driver_company" class="form-label">Company</label>
                        <input name="driver_company" v-model="driver.company" type="text" class="form-control" disabled>
                    </div>
                </div>
                <div>
                    <p class="text-center font-weight-bold">Send via link</p>
                    <div class="mt-3 d-flex justify-content-center align-items-center gap-2">
                        <label class="radio-inline">
                            <input v-model="driver.send_via" value="sms" type="radio" name="optradio"> Text/SMS
                        </label>
                        <label class="radio-inline">
                            <input v-model="driver.send_via" value="email" type="radio" name="optradio"> Email
                        </label>
                    </div>
                </div>
            </tab-content>

            <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left mt-3">
                    <wizard-button v-if="props.activeTabIndex > 0"
                        @click.native="props.prevTab(); changeTab()" :disabled="processing"
                        :style="props.fillButtonStyle">Back</wizard-button>
                </div>
                <div class="wizard-footer-right mt-3">
                    <wizard-button @click.native="nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle"
                        :disabled="processing">{{ props.isLastStep ? 'Done' : 'Next' }}</wizard-button>
                </div>
            </template>
        </form-wizard>
    </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import StateSelect from '../components/common/selects/StateSelect.vue';
import TimezoneSelect from '../components/common/selects/TimezoneSelect';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import axiosUtils from "../utils/axiosUtil";

export default {
    name: 'DemoUserWizard',
    components: {
        FormWizard,
        TabContent,
        WizardButton,
        StateSelect,
        TimezoneSelect,
        VuePhoneNumberInput
    },
    data() {
        return {
            user: {
                first_name: null,
                last_name: null,
                email: null,
            },
            fleet: {
                name: null,
                dot_number: null,
            },
            company: {
                name: null,
                dot_number: null,
                fleet: null,
            },
            location: {
                name: null,
                company: null,
                address: null,
                city: null,
                state_id: null,
                postal_code: null,
                timezone: null,
            },
            truck: {
                name: null,
                vin: null,
                location: null,
                company: null,
            },
            driver: {
                name: null,
                phone_number: null,
                email: null,
                truck: null,
                location: null,
                company: null,
                send_via: 'email',
            },
            phone: null,
            tab: 0,
            userEmailValid: false,
            driverEmailValid: false,
            showError: false,
            processing: false,
        }
    },
    methods: {
        onUpdate(e) {
            this.driver.phone_number = e.formattedNumber || '';
        },
        setTab(p, n){
            this.tab = n;
        },
        changeTab(){
            this.showError = false;
        },
        nextTab(){
            let validate = this.validate();
            if(validate){
                this.$refs.formWizard.nextTab();
                if(this.tab < 5) this.changeTab();
            }
        },
        validate(){
            if(this.tab === 0){
                this.showError = true;
                if (!this.user.first_name || !this.user.last_name || (!this.user.email || !this.userEmailValid)){
                    return false;
                }else return true;
            }

            if (this.tab === 1) {
                this.showError = true;
                if (!this.fleet.name || !this.fleet.dot_number) {
                    return false;
                } else {
                    this.company.fleet = this.fleet.name;
                    return true;
                }
            }

            if (this.tab === 2) {
                this.showError = true;
                if (!this.company.name || !this.company.dot_number) {
                    return false;
                } else {
                    this.location.company = this.company.name;
                    return true;
                }
            }

            if (this.tab === 3) {
                this.showError = true;
                if (!this.location.name || !this.location.address || !this.location.city || !this.location.timezone) {
                    return false;
                } else {
                    this.truck.location = this.location.name;
                    this.truck.company = this.company.name;
                    return true;
                }
            }

            if (this.tab === 4) {
                this.showError = true;
                if (!this.truck.name || !this.truck.vin) {
                    return false;
                } else {
                    this.driver.location = this.location.name;
                    this.driver.company = this.company.name;
                    this.driver.truck = this.truck.name;
                    return true;
                }
            }

            if (this.tab === 5) {
                this.showError = true;
                if (!this.driver.name || !this.driver.phone_number || (!this.driver.email || !this.driverEmailValid)) {
                    return false;
                } else {
                    this.processing = true;
                    this.save();
                }
            }
        },
        save(){
            let form = {
                user: this.user,
                fleet: this.fleet,
                company: this.company,
                location: this.location,
                truck: this.truck,
                driver: this.driver,
            }
            axiosUtils.post('/demo-user', form)
                .then(response => {
                    if (response?.data?.messages?.resultCode === 'Error') {
                        response?.data?.messages?.message.forEach(e => {
                            this.$notify({ type: 'error', 'text': e.text });
                        });
                    } else {
                        this.$notify({ type: 'success', 'text': 'Successfully saved!' });
                        this.$router.push('/dashboard');
                    }
                    this.processing = false;
                }).catch(error => {
                    this.processing = false;
                    // this.errors = error.response.data?.errors;
                    let message = error?.response?.data?.message || 'Something went wrong.';
                    this.$notify({ type: 'error', 'text': message });
                })
        },
        validEmail: function (email, type) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(type == 'user'){
                this.userEmailValid = re.test(email);
            } else this.driverEmailValid = re.test(email);
        },
    },
    watch: {
        'user.email': function () {
            this.validEmail(this.user.email, 'user');
        },
        'driver.email': function () {
            this.validEmail(this.driver.email, 'driver');
        }
    }
}
</script>

<style scoped>
:deep(.input-tel input) {
    border: 1px solid #dee2e6 !important;
}

:deep(.input-tel__input::placeholder) {
    color: transparent !important;
}

:deep(.country-selector input) {
    border: 1px solid #dee2e6 !important;
}
</style>
