<template>
    <div>
        <b-tabs>
            <b-tab title="Make" @click="changeTab('make')">
                <div class="align-items-center form-row mb-3">
                    <label for="vehicleName" class="form-label mr-2">Name</label>
                    <input v-model="form.name" type="text" class="border col-sm-8 form-control mr-2" name="vehicleName">
                    <button class="btn btn-info col fw-bold ml-auto text-white" @click="addMake"
                        :disabled="!form.name"><i class="fa fa-plus"></i> Add
                        Make</button>
                </div>
                <div class="d-flex justify-content-start gap-3 mb-3">
                    <div>
                        <select class="form-select" aria-label="Default select example" v-model="make.filterBy">
                            <option selected value="" disabled>Filter By...</option>
                            <option :value="item.value" v-for="(item, index) in filterOptions" :key="index">{{
                                item.label }}
                            </option>
                        </select>
                    </div>

                    <div v-if="make.filterBy">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                            <input v-model="make.search" @input="debounceInput" type="text" class="form-control"
                                placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>

                    <div class="align-items-end ml-auto tw-flex">
                        <label>
                            <input type="checkbox" v-model="other_make" id="other_make" name="other_make" class="mr-2">
                            Other
                        </label>
                    </div>
                </div>
                <data-table ref="makeTable" :columns="makeFields" default_sort_direction="" default_sort_field=""
                    :show_entries="[10, 15, 20, 50, 75, 100]" :show_entries_default="15" url="make" :items="makeData"
                    @getData="makeDataManager" :loading="make.loading">
                    <template v-slot:name="{ item }">
                        <p>{{ item.name }}</p>
                    </template>
                    <template v-slot:type="{ item }">
                        <p>{{ formatType(item.type) }}</p>
                    </template>
                </data-table>
            </b-tab>
            <b-tab title="Model" @click="changeTab('model')">
                <div class="align-items-center form-row mb-3">
                    <label for="vehicleName" class="form-label mr-2">Name</label>
                    <input v-model="form.name" type="text" class="border col-sm-5 form-control mr-2" name="vehicleName">
                    <MakeSelect v-model="form.make_id" :type="vehicleType" class="col-sm-4" />
                    <button class="btn btn-info col fw-bold ml-auto text-white" @click="addModel"
                        :disabled="!form.name || !form.make_id"><i class="fa fa-plus"></i> Add
                        Model</button>
                </div>
                <div class="d-flex justify-content-start gap-3 mb-3">
                    <div>
                        <select class="form-select" aria-label="Default select example" v-model="model.filterBy">
                            <option selected value="" disabled>Filter By...</option>
                            <option :value="item.value" v-for="(item, index) in filterOptions" :key="index">{{
                                item.label }}
                            </option>
                        </select>
                    </div>

                    <div v-if="model.filterBy">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                            <input v-model="model.search" @input="debounceInput" type="text" class="form-control"
                                placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>

                    <div class="align-items-end ml-auto tw-flex">
                        <label>
                            <input type="checkbox" v-model="other_model" id="other_model" name="other_model" class="mr-2">
                            Other
                        </label>
                    </div>
                </div>
                <data-table ref="modelTable" :columns="modelFields" default_sort_direction="" default_sort_field=""
                    :show_entries="[10, 15, 20, 50, 75, 100]" :show_entries_default="15" url="make" :items="modelData"
                    @getData="modelDataManager" :loading="model.loading">
                    <template v-slot:name="{ item }">
                        <p>{{ item.name }}</p>
                    </template>
                    <template v-slot:make="{ item }">
                        <p>{{ item.make }}</p>
                    </template>
                    <template v-slot:type="{ item }">
                        <p>{{ formatType(item.type) }}</p>
                    </template>
                </data-table>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { ServiceFactory } from "../../utils/ServiceFactory";
import axiosUtils from "../../utils/axiosUtil";
import DataTable from "../../components/dataTable/DataTable.vue";
import MakeSelect from "../../components/common/selects/MakeSelect.vue";
export default {
    name: "MakeModelList",
    components: {
        dataTable: DataTable,
        MakeSelect
    },
    data() {
        return {
            make: {
                sortField: null,
                currentPage: 1,
                perPage: 15,
                filterBy: "",
                search: null,
                loading: false,
            },
            model: {
                sortField: null,
                currentPage: 1,
                perPage: 15,
                filterBy: "",
                search: null,
                loading: false,
            },
            makeData: [],
            modelData: [],
            loading: false,
            makeFields: [
                { label: 'Name', field: 'name', sortField: 'name' },
                { label: 'Type', field: 'type' },
            ],
            modelFields: [
                { label: 'Name', field: 'name', sortField: 'name' },
                { label: 'Make', field: 'make', sortField: 'make.name' },
                { label: 'Type', field: 'type' },
            ],
            form: {
                name: null,
                type: null,
                make_id: null,
            },
            filterOptions: [
                { value: 'name', label: 'Name' },
                // { value: 'type', label: 'Type' },
            ],
            activeTab: 'make',
            other_make: false,
            other_model: false,
        }
    },
    props: {
        type: {
            default: null
        }
    },
    computed: {
        vehicleType() {
            return this.type === "Trucks" ? 'vehicle' : 'trailer'
        },
    },
    async created() {
        await this.getMake();
        await this.getModel();
    },
    methods: {
        debounceInput: _.debounce(function (e) {
            if(this.activeTab === 'make'){
                this.getMake();
            }else this.getModel();
        }, 1000),
        makeDataManager(data) {
            const direction = (data.sort_direction === 'desc' ? '-' : '')
            this.make.sortField = `${direction}${data.sort_field}`
            this.make.currentPage = data.page
            this.make.perPage = data.paginate
            this.make.filterBy = data.queries
            this.make.search = data.search
            this.getMake();
        },
        modelDataManager(data) {
            const direction = (data.sort_direction === 'desc' ? '-' : '')
            this.model.sortField = `${direction}${data.sort_field}`
            this.model.currentPage = data.page
            this.model.perPage = data.paginate
            this.model.filterBy = data.queries
            this.model.search = data.search
            this.getModel();
        },
        async getMake() {
            this.make.loading = true;
            const filter = this.make.filterBy !== '' ? `&filter[search]=${this.make.filterBy},${this.make.search}` : '';
            const type = this.type ? `&type=${this.type === "Trucks" ? 'vehicle' : 'trailer'}` : '';
            const buildUrl = `?per_page=${this.make.perPage}&page=${this.make.currentPage}&sort=${this.make.sortField || ''}${filter}${type}`;
            axiosUtils.get(`/makes${buildUrl}`)
                .then(makes => {
                    this.makeData = makes.data;
                    this.make.loading = false;
                }).finally(() => {
                    // this.$refs.makeSelect.setSelection(this.selectedOption)
                }).catch((err) => {
                    this.$notify({ type: 'error', text: 'Something went wrong.' })
                })
        },
        async getOtherMake() {
            this.make.loading = true;
            const filter = this.make.filterBy !== '' ? `&filter[search]=${this.make.filterBy},${this.make.search}` : '';
            const type = this.type ? `&type=${this.type === "Trucks" ? 'vehicle' : 'trailer'}` : '';
            const buildUrl = `?per_page=${this.make.perPage}&page=${this.make.currentPage}&sort=${this.make.sortField || ''}${filter}${type}&other=true`;
            axiosUtils.get(`/makes?${buildUrl}`)
                .then(makes => {
                    this.makeData = makes.data;
                    this.make.loading = false;
                }).finally(() => {
                    // this.$refs.makeSelect.setSelection(this.selectedOption)
                }).catch((err) => {
                    this.$notify({ type: 'error', text: 'Something went wrong.' })
                })
        },
        addMake(){
            this.form.type = this.type === "Trucks" ? 'vehicle' : 'trailer';
            axiosUtils.post(`/makes`, this.form)
                .then(data => {
                    this.$notify({ type: 'success', text: 'Make successfully added.' });
                    this.getMake();
                    this.form.name = null;
                }).catch((err) => {
                    let message = err.response.data.message || 'Something went wrong.';
                    this.$notify({ type: 'error', text: message })
                })
        },
        changeTab(tab){
            this.form.name = null;
            this.form.make_id = null;
            this.activeTab = tab;
        },
        async getModel() {
            this.model.loading = true;
            const filter = this.model.filterBy !== '' ? `&filter[search]=${this.model.filterBy},${this.model.search}` : '';
            const type = this.type ? `&type=${this.type === "Trucks" ? 'vehicle' : 'trailer'}` : '';
            const buildUrl = `?per_page=${this.model.perPage}&page=${this.model.currentPage}&sort=${this.model.sortField || ''}${filter}${type}`;
            axiosUtils.get(`/models${buildUrl}`)
                .then(models => {
                    this.modelData = models.data;
                    this.model.loading = false;
                }).finally(() => {
                }).catch((err) => {
                    this.$notify({ type: 'error', text: 'Something went wrong.' })
                })
        },
        async getOtherModel() {
            this.model.loading = true;
            const filter = this.model.filterBy !== '' ? `&filter[search]=${this.model.filterBy},${this.model.search}` : '';
            const type = this.type ? `&type=${this.type === "Trucks" ? 'vehicle' : 'trailer'}` : '';
            const buildUrl = `?per_page=${this.model.perPage}&page=${this.model.currentPage}&sort=${this.model.sortField || ''}${filter}${type}&other=true`;
            axiosUtils.get(`/models${buildUrl}`)
                .then(models => {
                    this.modelData = models.data;
                    this.model.loading = false;
                }).finally(() => {
                }).catch((err) => {
                    this.$notify({ type: 'error', text: 'Something went wrong.' })
                })
        },
        addModel() {
            this.form.type = this.type === "Trucks" ? 'vehicle' : 'trailer';
            axiosUtils.post(`/models`, this.form)
                .then(data => {
                    this.$notify({ type: 'success', text: 'Model successfully added.' });
                    this.getModel();
                    this.form.name = null;
                    this.form.make_id = null;
                }).catch((err) => {
                    let message = err.response.data.message || 'Something went wrong.';
                    this.$notify({ type: 'error', text: message })
                })
        },
        formatType(type) {
            return type.toLowerCase() === 'vehicle' ? 'Truck' : _.startCase(type);
        },
        reset(tab){
            if(tab === "make"){
                this.make = {
                    sortField: null,
                    currentPage: 1,
                    perPage: 15,
                    filterBy: "",
                    search: null,
                    loading: false,
                };
            }else {
                this.model = {
                    sortField: null,
                    currentPage: 1,
                    perPage: 15,
                    filterBy: "",
                    search: null,
                    loading: false,
                };
            }
        },
    },
    watch: {
        other_make(n, o) {
            this.reset('make');
            if (this.other_make) {
                this.getOtherMake();
            } else this.getMake();
        },
        other_model(n, o) {
            this.reset('model');
            if (this.other_model) {
                this.getOtherModel();
            } else this.getModel();
        },
    }
}
</script>

<style scoped></style>
