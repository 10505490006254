<template>
    <div class="container-fluid px-5">
        <div class="d-flex justify-content-evenly my-1">
            <p class="fw-bolder">You may have heard about us at:</p>
        </div>
        <div class="row">
            <div class="col-md-3 d-flex justify-content-center">
                <img alt="" class="rounded img-fluid" src="/img/dot-ready-logo.png"/>
            </div>
            <div class="col-md-9 d-flex justify-content-center align-items-center">
                <p class="h3 fw-bold">List of trade show logos we will be at and media outlets goes here</p>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>
export default {
    name: "TradeShowLogos"
}
</script>

<style scoped>

</style>
