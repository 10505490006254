<template>
  <div>
    <searchable-select ref="yearSelect" v-model="selectedOption" :options="optionList" :disabled="disabled"></searchable-select>
  </div>
</template>

<script>
import SearchableSelect from '../SearchableSelect';
import moment from 'moment';

export default {

  data: function () {
    return {
      optionList: this.getYearOptions(),
      selectedOption: this.value
    }
  },

  props: {
    value: {
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SearchableSelect
  },

  created: function () {
    let vm = this;
  },

  watch: {

    selectedOption: function (newVal, oldVal) {
      this.$emit('input', newVal);
    },

    value: function (newVal, oldVal) {
      this.$refs.yearSelect.setSelection(newVal);
    }

  },

  methods: {

    getYearOptions: function () {
      let maxYear = moment().year() + 1;
      let minYear = 1920;
      let options = [];

      while (minYear <= maxYear) {
        options.push(minYear);

        ++minYear;
      }

      return options;
    }

  }

}
</script>
