<template>
    <div>
        <div class="container" v-if="!loading">
            <div class="d-flex justify-content-between align-items-center my-3">
                <h1 class="tw-font-bold tw-text-2xl">Dashboard - Show/Hide Panels</h1>
                <div class="d-flex justify-content-between align-items-center gap-2">
                    <router-link class="btn btn-info btn-sm text-white" :to="`/dashboard`" role="button"><i class="fa fa-arrow-left"></i> Go Back</router-link>
                </div>
            </div>
            <table class="table table-striped">
                <thead class="thead-dark">
                <tr>
                    <th>Name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(panel, index) in panels" :key="index">
                        <td class="px-5">{{panel.name}}</td>
                        <td>
                            <select class="form-select w-25" name="is_visible" @change="updatePanel(panel, $event.target.value)">
                                <option value="0" :selected="panel.is_visible === 0">Hide</option>
                                <option value="1" :selected="panel.is_visible === 1">Visible</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    import {ServiceFactory} from "../utils/ServiceFactory"

    const dashboard = ServiceFactory.get('dashboard')
    export default {
        data() {
            return {
                panels: null,
                loading: false,
                selectedOption: null
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            async getData() {
                this.loading = true
                const {isSuccess, data} = await dashboard.getDashboardViews();
                if (isSuccess)  {
                    this.panels = data.dashboard_view
                }
                else this.$notify({
                    type: 'danger',
                    text: 'Something Went Wrong.'
                });
                this.loading = false
            },
            async updatePanel(item, e) {
                const {isSuccess} = await dashboard.updatePanelVisibility(item, e);
                if (isSuccess)  {
                    this.$notify({
                        type: 'success',
                        text: 'Updated successfully'
                    });
                }
                else this.$notify({
                    type: 'danger',
                    text: 'Something Went Wrong.'
                });
            }
        }
    }
</script>

<style scoped>

</style>
