<template>
  <div>
    <div v-if="loaded">
      <b-tabs pills nav-class="justify-content-end" content-class="border-0 p-0">

        <b-tab title="Current" class="p-0">

          <!-- regular folder and files -->
          <div v-for="folder in folderList" :key="folder.id" class="vehicle-file-folder mb-4 pb-2">
            <div class="text-uppercase small font-weight-bold mb-2">
              <template v-if="!folder.company_id">
                {{ folder.name }}
              </template>
              <template v-else>
                <edit-folder-link :folder-id="folder.id" :entity-type="vehicleEntityType" class="edit-folder-link">
                  <div class="d-inline-block">
                    {{ folder.name }}
                  </div>
                </edit-folder-link>
              </template>
            </div>
            <b-row v-if="folder.children && folder.children.length">
              <b-col md="3" v-for="childFolder in folder.children" :key="childFolder.id">
                <vehicle-file :existing-files="childFolder.files" :folder="childFolder" :read-only="readOnly"
                  :vehicle-id="vehicleId" :vehicle-type="vehicleEntityType" :heading="childFolder.name" :archive=false
                  @refresh="getVehicleFiles">
                </vehicle-file>
              </b-col>
            </b-row>
            <div v-else>
              <vehicle-file multiple :file-description="folder.name" :existing-files="folder.files" :folder="folder"
                :read-only="readOnly" :vehicle-id="vehicleId" :vehicle-type="vehicleType" heading="Add New File..."
                :archive=false @refresh="getVehicleFiles">
              </vehicle-file>
            </div>
          </div>

          <div>
            <add-folder-button class="my-2 text-center" :entity-type="vehicleEntityType" :companyId="companyId"
              @refresh="getVehicleFiles"></add-folder-button>
          </div>

        </b-tab>

        <b-tab title="Archived" class="p-0">
          <div v-for="folder in flatFolderList" :key="folder.id" class="vehicle-file-folder mb-4 pb-2"
            v-if="hasArchive(folder)">
            <div class="text-uppercase small font-weight-bold mb-2">{{ folder.name }}</div>
            <b-row v-if="folder.children && folder.children.length">
              <b-col md="3" v-for="childFolder in folder.children" :key="childFolder.id">
                <vehicle-file :existing-files="childFolder.files" :folder="childFolder" :read-only="readOnly"
                  :vehicle-id="vehicleId" :vehicle-type="vehicleEntityType" :heading="childFolder.name" :archive=true
                  @refresh="getVehicleFiles">
                </vehicle-file>
              </b-col>
            </b-row>
            <div v-else>
              <vehicle-file multiple :file-description="folder.name" :existing-files="folder.files" :folder="folder"
                :read-only="readOnly" :vehicle-id="vehicleId" :vehicle-type="vehicleType" heading="Add New File..."
                :archive=true @refresh="getVehicleFiles">
              </vehicle-file>
            </div>
          </div>
        </b-tab>

      </b-tabs>
    </div>
    <div class="text-center py-3" v-else>
      <spinner size="big" message="Loading files..." line-fg-color="#20a8d8" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Spinner from 'vue-simple-spinner';
import AddFolderButton from '../components/files/AddFolderButton.vue';
import EditFolderLink from '../components/files/EditFolderLink.vue';
import VehicleFile from './VehicleFile';
import moment from 'moment';
import JsCookie from "js-cookie";

export default {
  data: function () {
    return {
      loaded: false
    }
  },

  props: {
    vehicleType: {
      type: String,
      default: 'vehicle'
    },
    vehicleId: {
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    companyId: {
      default: null
    },
    type: {
      type: String,
      default: 'Vehicle'
    },
  },

  components: {
    VehicleFile,
    AddFolderButton,
    EditFolderLink,
    Spinner
  },

  mounted: function () {
    const companyId = JsCookie.get('company_id');
    this.getVehicleFiles();
  },

  computed: {
    folderList() {
      let folders = this.$store?.state?.vehicle?.files?.data;
      let list = [];
      
      folders.forEach(fldr => {
        let name = fldr.name.toLowerCase()
        if (name === "expirations"){
          if (this.type === "vehicle") list.unshift(fldr);
        } else if(name === "dvir"){
          list.unshift(fldr);
        } else list.push(fldr);
      });

      return list;
    },

    flatFolderList() {
      return _.flatMap(this.folderList, folder => {
        if (folder.children && folder.children.length) {
          return folder.children;
        }

        return folder;
      });
    },

    vehicleEntityType() {
      if (this.vehicleType === 'trailer') return 'vehicle';
      return this.vehicleType;
    },
  },

  methods: {

    getVehicleFiles: function(){
      Promise.all([
        this.$store.dispatch('getVehicleFiles', this.vehicleId),
        // this.$store.dispatch('getFolders', {
        //   entityType: this.vehicleEntityType,
        //   companyId: companyId
        // })
      ]).then(() => {
        this.loaded = true;
      })
    },

    getFileIcon: function (fileType) {
      switch (fileType) {
        case 'spreadsheet':
          return 'fa-file-excel';
          break;
        case 'pdf':
          return 'fa-file-pdf';
          break;
        case 'image':
          return 'fa-file-image';
          break;
        default:
          return 'fa-file';
          break;
      }
    },

    getExpirationString: function (expirationDate) {
      if (!expirationDate) {
        return null;
      }

      return moment(expirationDate).format('MMM Do, YYYY');
    },

    hasArchive(folder) {
      let hasArchive = false;
      if(folder.files.length > 0){
        let idx = folder.files.findIndex((file) => file.archived && file.deleted_ata !== null);
        hasArchive = idx >= 0 ? true : false;
      }
      return hasArchive;
    },
  }

}
</script>
