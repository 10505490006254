import axiosUtils from "../../utils/axiosUtil";

export default {

  endpoint: '/work-orders',

  index (params = {}) {
    return axiosUtils.get(this.endpoint, {params: params})
      .then(response => {
        return response.data
      });
  },

  show (id) {
    return axiosUtils.get(this.endpoint+'/'+id)
      .then(response => {
        return response.data
      });
  },

  store(payload) {
    if (payload.hasOwnProperty('id') && payload.id) {
      var apiEndpoint = this.endpoint+'/'+payload.id;
      var apiMethod = 'put';
    }
    else {
      var apiEndpoint = this.endpoint;
      var apiMethod = 'post';
    }
    return axiosUtils({
      method: apiMethod,
      url: apiEndpoint,
      data: payload
    }).then(response => {
      return response.data;
    });
  },


  destroy(id, perm = false) {
    const params = {}
    if (perm) {
      params.force = true
    }

    return axiosUtils.delete('/workorders/'+id, { data: params })
      .then(response => {
        return response.data;
      });
  },
  restore(id) {
    return axiosUtils.patch('/workorders/'+id+'/restore');
  }
}
